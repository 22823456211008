import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Collapse, Row, Col, Card, Slider, InputNumber, Typography, Button, Popover, DatePicker } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons';
import { RingProgress } from '@ant-design/plots';
import ReactPlayer from 'react-player'
import moment from 'moment'
const { Title } = Typography;
const { Panel } = Collapse;

class AgencyGoals extends Component {
    constructor(props) {
      super(props);
        this.state = {
            premium: 1,
            premiumPerItem: 1,
            premiumPerPolicy: 1,
            premiumPerHousehold: 1,
            itemsRequired: 1,
            itemsPerHousehold: 1,
            householdsNeeded: 1,
            closingRatio: 1,
            closingRatioPolicy: 1,
            closingRatioHousehold: 1,
            totalMonthly: 1,
            writtenItems: 1,
            loaded: false,
            itemsPerPolicy: 1,
            monthDts: moment().format("MMM YYYY"),
            selectedDate: new Date(),
            quotedBundleRatio: 0,
            writtenBundleRatio: 0
        };
        this.handleSave = this.handleSave.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
    }
    componentDidMount(){
        const userId = localStorage.getItem("userId");
        const agencyId = localStorage.getItem("agencyId");
        this.props.getMarketing(agencyId, moment().format("MMM YYYY"));
        this.props.getTotalSalesAgentSummary(agencyId, moment().startOf('month').format("MM/DD/YYYY"), moment().endOf('month').format("MM/DD/YYYY"));
        //this.props.getAgentPremiums(agencyId, '04/01/2022')
        this.setState({loaded: true});
    }
    componentDidUpdate(prevProps){
        if(this.props.marketing != prevProps.marketing){
            this.setState({
                premium: this.props.marketing.monthlyPremium,
                premiumPerItem: this.props.marketing.premiumPerItem,
                premiumPerPolicy: this.props.marketing.premiumPerPolicy,
                premiumPerHousehold: this.props.marketing.premiumPerHousehold,
                itemsPerHousehold: this.props.marketing.itemsPerHousehold,
                closingRatio: this.props.marketing.closedRatio,
                closingRatioPerPolicy: this.props.marketing.closedRatioPerPolicy,
                closingRatioPerHousehold: this.props.marketing.closedRatioPerHousehold,
                itemsPerPolicy: this.props.marketing.itemsPerPolicy,
                quotedBundleRatio: this.props.marketing.quotedBundleRatio,
                writtenBundleRatio: this.props.marketing.writtenBundleRatio
            });
        }
    }
    onPremiumChange = value => {
        this.setState({
          premium: value,
        });
    };
    onPremiumPerItemChange = value => {
        this.setState({
            premiumPerItem: value,
        });
    };
    onPremiumPerPolicyChange = value => {
        this.setState({
            premiumPerPolicy: value,
        });
    };
    onPremiumPerHouseholdChange = value => {
        this.setState({
            premiumPerHousehold: value,
        });
    };
    onPerItemPolicyChange = value => {
        this.setState({
            itemsPerPolicy: value,
        });
    };
    onItemsPerHouseholdChange = value => {
        this.setState({
            itemsPerHousehold: value,
        });
    };
    onClosingRatioChange = value => {
        this.setState({
            closingRatio: value,
        });
    };
    onClosingRatioPolicyChange = value => {
        this.setState({
            closingRatioPerPolicy: value,
        });
    };
    onClosingRatioHouseholdChange = value => {
        this.setState({
            closingRatioPerHousehold: value,
        });
    };
    onHouseholdsNeededChange = value => {
        this.setState({
            householdsNeeded: value,
        });
    };
    onQuotedBundleRatioChange = value => {
        this.setState({
            quotedBundleRatio: value,
        });
    };
    onWrittenBundleRatioChange = value => {
        this.setState({
            writtenBundleRatio: value,
        });
    };
    handleSave(event) {
        event.preventDefault()
        const userId = localStorage.getItem("userId");
        const agencyId = localStorage.getItem("agencyId");
        const premium = this.state.premium;
        const premiumPerItem = this.state.premiumPerItem;
        const itemsPerHousehold = this.state.itemsPerHousehold;
        const closingRatio = this.state.closingRatio;
        const closingRatioPerPolicy = this.state.closingRatioPerPolicy;
        const closingRatioPerHousehold = this.state.closingRatioPerHousehold;
        const itemsPerPolicy = this.state.itemsPerPolicy;
        const premiumPerPolicy = this.state.premiumPerPolicy;
        const premiumPerHousehold = this.state.premiumPerHousehold;
        const monthDts = this.state.monthDts;
        const quotedBundleRatio = this.state.quotedBundleRatio;
        const writtenBundleRatio = this.state.writtenBundleRatio;
        
        this.props.createMarketing(userId, agencyId, premium, premiumPerItem, itemsPerHousehold, closingRatio, itemsPerPolicy, premiumPerPolicy, premiumPerHousehold, closingRatioPerPolicy, closingRatioPerHousehold, monthDts, quotedBundleRatio, writtenBundleRatio);
        this.setState({
            premium: premium,
            premiumPerItem: premiumPerItem,
            itemsPerHousehold: itemsPerHousehold,
            closingRatio: closingRatio,
            itemsPerPolicy: itemsPerPolicy
        });
    }
    onMonthChange(date, dateString){
        if(dateString != ''){
            const userId = localStorage.getItem("userId");
            const agencyId = localStorage.getItem("agencyId");
            this.setState({monthDts: dateString, selectedDate: date});
            this.props.getMarketing(agencyId, dateString);
            this.props.getTotalSalesAgentSummary(agencyId, moment(date).startOf('month').format("MM/DD/YYYY"), moment(date).endOf('month').format("MM/DD/YYYY"));
            //this.props.getMarketingSummary(agencyId, dateString);
            //const dateTo = moment(date).endOf('month').format("MM/DD/YYYY");
            //const dateFrom = moment(date).startOf('month').format("MM/DD/YYYY");
            //this.props.getActualMarketingSummary(agencyId, dateFrom, dateTo);
        }else{
            this.setState({monthDts: moment().format("MMM YYYY")});
        }
    }
    render() {
        const premiumPerPolicy = this.state.premiumPerItem * this.state.itemsPerPolicy
        const premiumPerHousehold = this.state.premiumPerItem * this.state.itemsPerHousehold

        const writtenItems = this.state.premium / this.state.premiumPerItem;
        const writtenPolicies = this.state.premium / premiumPerPolicy;
        const writtenHouseholds = this.state.premium / premiumPerHousehold;

        const closingRatio = this.state.closingRatio / 100;
        const closingRatioPolicies = this.state.closingRatioPerPolicy / 100;
        const closingRatioHouseholds = this.state.closingRatioPerHousehold / 100;

        const quotedItems = writtenItems / closingRatio;
        const quotedPolicies = writtenPolicies / closingRatioPolicies;
        const quotedHousehold = writtenHouseholds / closingRatioHouseholds;

        const premium = this.state.premium;

        var agentMonthly = [];
        var dailyQuotedBundles = 0;
        var dailyWrittenBundles = 0;
        if(this.props.totalSalesAgentSummary.length > 0) {
            agentMonthly = this.props.totalSalesAgentSummary[0];
        };
        
        const businessDays = agentMonthly.businessDays;
        dailyQuotedBundles = agentMonthly.quotedBundles / agentMonthly.businessDaysSoFar;
        dailyWrittenBundles = agentMonthly.writtenBundles / agentMonthly.businessDaysSoFar;

        const config = {
            color: ['#5B8FF9', '#E8EDF3'],
        };
        return(
            <div>
                <Row>
                    <Col xs={24} sm={24} lg={24}>
                        <Card title="AGENCY GOALS" headStyle={{ backgroundColor: '#3D76DD', fontSize: 30, textAlign: 'center', color: "#fff" }} >
                            <Row>
                                <Col xs={12} sm={12} lg={12}>
                                    <Card title="MONTHLY" headStyle={{ backgroundColor: '#8B8000', color: '#fff', fontSize: '32px', textAlign: 'center' }} style={{borderColor: '#8B8000', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc', marginTop: '10px'}}>
                                        <Row style={{marginBottom: 10}}>
                                            <Col md={24}>
                                                <DatePicker onChange={this.onMonthChange} picker="month" format="MMM YYYY" value={moment(this.state.monthDts, "MMM YYYY")} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={24}>
                                                <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc'}}>
                                                    <Row style={{paddingBottom: 10}}> 
                                                        <Col md={7}>
                                                            <label style={{fontSize: 18}}>Premium Goals</label>
                                                        </Col>
                                                        {/* <Col md={12}>
                                                            <Slider
                                                                min={10000}
                                                                max={500000}
                                                                step={5000}
                                                                onChange={this.onPremiumChange}
                                                                value={this.state.premium}
                                                                onBlur={this.handleSave}
                                                            /> 
                                                        </Col>*/}
                                                        <Col md={5}>
                                                            <InputNumber
                                                                min={10000}
                                                                max={2000000}
                                                                step={5000}
                                                                style={{ marginLeft: 16 }}
                                                                value={this.state.premium}
                                                                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                                onChange={this.onPremiumChange}
                                                                onBlur={this.handleSave}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={7}>
                                                            <label style={{fontSize: 18}}>Premium Per Item</label>
                                                        </Col>
                                                        {/*<Col md={12}>
                                                            <Slider
                                                                min={100}
                                                                max={7500}
                                                                step={1}
                                                                onChange={this.onPremiumPerItemChange}
                                                                value={this.state.premiumPerItem}
                                                                onBlur={this.handleSave}
                                                            /> 
                                                        </Col>*/}
                                                        <Col md={5}>
                                                            <InputNumber
                                                                min={100}
                                                                max={7500}
                                                                step={1}
                                                                style={{ marginLeft: 16 }}
                                                                value={this.state.premiumPerItem}
                                                                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                                onChange={this.onPremiumPerItemChange}
                                                                onBlur={this.handleSave}
                                                            />
                                                            <Popover title="Average Premium Per Item" content="Written Premium / Written Items">
                                                                <QuestionCircleOutlined style={{marginLeft: 10, color: 'Red'}} />
                                                            </Popover>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={7}>
                                                            <label style={{fontSize: 18}}>Premium Per Policy</label>
                                                        </Col>
                                                        {/*<Col md={12}>
                                                            <Slider disabled
                                                                min={100}
                                                                max={7500}
                                                                step={1}
                                                                onChange={this.onPremiumPerPolicyChange}
                                                                value={premiumPerPolicy}
                                                                onBlur={this.handleSave}
                                                            /> 
                                                        </Col>*/}
                                                        <Col md={5}>
                                                            <InputNumber disabled
                                                                min={100}
                                                                max={7500}
                                                                step={1}
                                                                style={{ marginLeft: 16, backgroundColor: '#e6f7ff', color: '#000' }}
                                                                value={new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(premiumPerPolicy)}//{premiumPerPolicy}
                                                                //formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                //parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                                onChange={this.onPremiumPerPolicyChange}
                                                                onBlur={this.handleSave}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={7}>
                                                            <label style={{fontSize: 18}}>Premium Per Household</label>
                                                        </Col>
                                                    {/*  <Col md={12}>
                                                            <Slider disabled
                                                                min={100}
                                                                max={7500}
                                                                step={1}
                                                                onChange={this.onPremiumPerHouseholdChange}
                                                                value={premiumPerHousehold}
                                                                onBlur={this.handleSave}
                                                            />
                                                        </Col> */}
                                                        <Col md={5}>
                                                            <InputNumber disabled
                                                                min={100}
                                                                max={7500}
                                                                step={1}
                                                                style={{ marginLeft: 16, backgroundColor: '#e6f7ff', color: '#000' }}
                                                                value={new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(premiumPerHousehold)}//{premiumPerHousehold}
                                                                //formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                //parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                                onChange={this.onPremiumPerHouseholdChange}
                                                                onBlur={this.handleSave}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Card>
                                                <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc', marginTop: '10px'}}>
                                                    <Row style={{paddingTop: 10}}>
                                                        <Col md={7}>
                                                            <label style={{fontSize: 18}}>Items Per Policy</label>
                                                        </Col>
                                                        {/*<Col md={12}>
                                                            <Slider
                                                                min={0}
                                                                max={10}
                                                                step={.01}
                                                                onChange={this.onPerItemPolicyChange}
                                                                value={this.state.itemsPerPolicy}
                                                                onBlur={this.handleSave}
                                                            /> 
                                                        </Col>*/}
                                                        <Col md={5}>
                                                            <InputNumber
                                                                min={0}
                                                                max={10}
                                                                step={.01}
                                                                style={{ marginLeft: 16 }}
                                                                value={this.state.itemsPerPolicy}
                                                                
                                                                onChange={this.onPerItemPolicyChange}
                                                                onBlur={this.handleSave}
                                                            />
                                                            <Popover title="Average Items Per Policy" content="Written Items / Written Policies">
                                                                <QuestionCircleOutlined style={{marginLeft: 10, color: 'Red'}} />
                                                            </Popover>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={7}>
                                                            <label style={{fontSize: 18}}>Items Per Household</label>
                                                        </Col>
                                                        {/*<Col md={12}>
                                                            <Slider
                                                                min={0}
                                                                max={10}
                                                                step={.01}
                                                                onChange={this.onItemsPerHouseholdChange}
                                                                value={this.state.itemsPerHousehold}
                                                                onBlur={this.handleSave}
                                                            /> 
                                                        </Col>*/}
                                                        <Col md={5}>
                                                            <InputNumber
                                                                min={0}
                                                                max={10}
                                                                step={.01}
                                                                style={{ marginLeft: 16 }}
                                                                value={this.state.itemsPerHousehold}
                                                                onChange={this.onItemsPerHouseholdChange}
                                                                onBlur={this.handleSave}
                                                            />
                                                            <Popover title="Average Items Per Household" content="Written Items / Written Households">
                                                                <QuestionCircleOutlined style={{marginLeft: 10, color: 'Red'}} />
                                                            </Popover>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                                <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc', marginTop: '10px'}}>
                                                    <Row style={{paddingTop: 10}}>
                                                        <Col md={7}>
                                                            <label style={{fontSize: 18}}>Close Ratio Per Item</label>
                                                        </Col>
                                                    {/*  <Col md={12}>
                                                            <Slider
                                                                min={0}
                                                                max={75}
                                                                step={.01}
                                                                onChange={this.onClosingRatioChange}
                                                                value={this.state.closingRatio}
                                                                onBlur={this.handleSave}
                                                            /> 
                                                        </Col>*/}
                                                        <Col md={5}>
                                                            <InputNumber
                                                                min={0}
                                                                max={75}
                                                                step={.01}
                                                                style={{ marginLeft: 16 }}
                                                                value={this.state.closingRatio}
                                                                formatter={value => `${value}%`}
                                                                parser={value => value.replace('%', '')}
                                                                onChange={this.onClosingRatioChange}
                                                                onBlur={this.handleSave}
                                                            />
                                                            <Popover title="Close Ratio Per Item" content="Written Items / Quoted Items">
                                                                <QuestionCircleOutlined style={{marginLeft: 10, color: 'Red'}} />
                                                            </Popover>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={7}>
                                                            <label style={{fontSize: 18}}>Close Ratio Per Policy</label>
                                                        </Col>
                                                        {/* <Col md={12}>
                                                            <Slider
                                                                min={0}
                                                                max={75}
                                                                step={.01}
                                                                onChange={this.onClosingRatioPolicyChange}
                                                                value={this.state.closingRatioPerPolicy}
                                                                onBlur={this.handleSave}
                                                            /> 
                                                        </Col>*/}
                                                        <Col md={5}>
                                                            <InputNumber
                                                                min={0}
                                                                max={75}
                                                                step={.01}
                                                                style={{ marginLeft: 16 }}
                                                                value={this.state.closingRatioPerPolicy}
                                                                formatter={value => `${value}%`}
                                                                parser={value => value.replace('%', '')}
                                                                onChange={this.onClosingRatioPolicyChange}
                                                                onBlur={this.handleSave}
                                                            />
                                                            <Popover title="Close Ratio Per Policy" content="Written Policies / Quoted Policies">
                                                                <QuestionCircleOutlined style={{marginLeft: 10, color: 'Red'}} />
                                                            </Popover>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{paddingBottom: 10}}>
                                                        <Col md={7}>
                                                            <label style={{fontSize: 18}}>Close Ratio Per Household</label>
                                                        </Col>
                                                        {/* <Col md={12}>
                                                        <Slider
                                                                min={0}
                                                                max={75}
                                                                step={.01}
                                                                onChange={this.onClosingRatioHouseholdChange}
                                                                value={this.state.closingRatioPerHousehold}
                                                                onBlur={this.handleSave}
                                                            /> 
                                                        </Col>*/}
                                                        <Col md={5}>
                                                            <InputNumber
                                                                min={0}
                                                                max={75}
                                                                step={.01}
                                                                style={{ marginLeft: 16 }}
                                                                value={this.state.closingRatioPerHousehold}
                                                                formatter={value => `${value}%`}
                                                                parser={value => value.replace('%', '')}
                                                                onChange={this.onClosingRatioHouseholdChange}
                                                                onBlur={this.handleSave}
                                                            />
                                                            <Popover title="Close Ratio Per Household" content="Written Households / Quoted Households">
                                                                <QuestionCircleOutlined style={{marginLeft: 10, color: 'Red'}} />
                                                            </Popover>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                                <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc', marginTop: '10px'}}>
                                                    <Row style={{paddingTop: 10}}>
                                                        <Col md={7}>
                                                            <label style={{fontSize: 18}}>Quoted Bundle Ratio</label>
                                                        </Col>
                                                        <Col md={5}>
                                                            <InputNumber
                                                                min={0}
                                                                max={100}
                                                                step={.01}
                                                                style={{ marginLeft: 16 }}
                                                                value={this.state.quotedBundleRatio}
                                                                formatter={value => `${value}%`}
                                                                parser={value => value.replace('%', '')}
                                                                onChange={this.onQuotedBundleRatioChange}
                                                                onBlur={this.handleSave}
                                                            />
                                                            <Popover title="Quoted Bundle Ratio" content="Quoted Bundles / Quoted Households">
                                                                <QuestionCircleOutlined style={{marginLeft: 10, color: 'Red'}} />
                                                            </Popover>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={7}>
                                                            <label style={{fontSize: 18}}>Written Bundle Ratio</label>
                                                        </Col>
                                                        <Col md={5}>
                                                            <InputNumber
                                                                min={0}
                                                                max={100}
                                                                step={.01}
                                                                style={{ marginLeft: 16 }}
                                                                value={this.state.writtenBundleRatio}
                                                                formatter={value => `${value}%`}
                                                                parser={value => value.replace('%', '')}
                                                                onChange={this.onWrittenBundleRatioChange}
                                                                onBlur={this.handleSave}
                                                            />
                                                            <Popover title="Written Bundle Ratio" content="Written Bundles / Written Households">
                                                                <QuestionCircleOutlined style={{marginLeft: 10, color: 'Red'}} />
                                                            </Popover>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                                <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc', marginTop: '10px'}}>
                                                    <Row>
                                                        <Col md={7}>
                                                            <label style={{fontSize: 18}}>Quoted Items</label>
                                                        </Col>
                                                        {/* <Col md={12}>
                                                            <Slider disabled
                                                                min={0}
                                                                max={10000}
                                                                step={1}
                                                                value={Math.round(quotedItems)}
                                                            /> 
                                                        </Col>*/}
                                                        <Col md={5}>
                                                            <InputNumber disabled
                                                                min={0}
                                                                max={10000}
                                                                step={1}
                                                                style={{ marginLeft: 16, backgroundColor: '#e6f7ff', color: '#000' }}
                                                                value={Math.round(quotedItems)}
                                                            />
                                                            
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={7}>
                                                            <label style={{fontSize: 18}}>Quoted Policies</label>
                                                        </Col>
                                                        {/*<Col md={12}>
                                                            <Slider disabled
                                                                min={0}
                                                                max={10000}
                                                                step={1}
                                                                value={Math.round(quotedPolicies)}
                                                            />
                                                        </Col> */}
                                                        <Col md={5}>
                                                            <InputNumber disabled
                                                                min={0}
                                                                max={10000}
                                                                step={1}
                                                                style={{ marginLeft: 16, backgroundColor: '#e6f7ff', color: '#000' }}
                                                                value={Math.round(quotedPolicies)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={7}>
                                                            <label style={{fontSize: 18}}>Quoted Households</label>
                                                        </Col>
                                                        {/* <Col md={12}>
                                                            <Slider disabled
                                                                min={0}
                                                                max={10000}
                                                                step={1}
                                                                value={Math.round(quotedHousehold)}
                                                            /> 
                                                        </Col>*/}
                                                        <Col md={5}>
                                                            <InputNumber disabled
                                                                min={0}
                                                                max={10000}
                                                                step={1}
                                                                style={{ marginLeft: 16, backgroundColor: '#e6f7ff', color: '#000' }}
                                                                value={Math.round(quotedHousehold)}
                                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Card>
                                                <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc', marginTop: '10px'}}>
                                                    <Row style={{paddingTop: 10}}>
                                                        <Col md={7}>
                                                            <label style={{fontSize: 18}}>Written Items</label>
                                                        </Col>
                                                        {/* <Col md={12}>
                                                            <Slider disabled
                                                                min={0}
                                                                max={10000}
                                                                step={1}
                                                                value={Math.round(writtenItems)}
                                                            /> 
                                                        </Col>*/}
                                                        <Col md={5}>
                                                            <InputNumber disabled
                                                                min={0}
                                                                max={10000}
                                                                step={1}
                                                                style={{ marginLeft: 16, backgroundColor: '#e6f7ff', color: '#000'}}
                                                                value={Math.round(writtenItems)}
                                                            />
                                                            
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={7}>
                                                            <label style={{fontSize: 18}}>Written Policies</label>
                                                        </Col>
                                                        {/* <Col md={12}>
                                                            <Slider disabled
                                                                min={0}
                                                                max={10000}
                                                                step={1}
                                                                value={Math.round(writtenPolicies)}
                                                            />
                                                        </Col> */}
                                                        <Col md={5}>
                                                            <InputNumber disabled
                                                                min={0}
                                                                max={10000}
                                                                step={1}
                                                                style={{ marginLeft: 16, backgroundColor: '#e6f7ff', color: '#000'}}
                                                                value={Math.round(writtenPolicies)}
                                                            />
                                                            
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={7}>
                                                            <label style={{fontSize: 18}}>Written Households</label>
                                                        </Col>
                                                        {/*<Col md={12}>
                                                            <Slider disabled
                                                                min={0}
                                                                max={10000}
                                                                step={1}
                                                                value={Math.round(writtenHouseholds)}
                                                            />
                                                        </Col> */}
                                                        <Col md={5}>
                                                            <InputNumber disabled
                                                                min={0}
                                                                max={10000}
                                                                step={1}
                                                                style={{ marginLeft: 16, backgroundColor: '#e6f7ff', color: '#000' }}
                                                                value={Math.round(writtenHouseholds)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} lg={12}>
                                    <Card title="DAILY" headStyle={{ backgroundColor: '#FCD12A', color: '#fff', fontSize: '32px', textAlign: 'center' }} style={{borderColor: '#FCD12A', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc', marginTop: '10px'}}>
                                        <Row>
                                            <Col md={5}>
                                            </Col>
                                            <Col md={3} style={{textAlign: 'center'}}>
                                                GOAL
                                            </Col>
                                            <Col md={3} style={{textAlign: 'center'}}>
                                                ACTUAL
                                            </Col>
                                        </Row>
                                        <Card  style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc', marginTop: '10px'}}>
                                            <Row style={{paddingBottom: 10}}> 
                                                <Col md={5}>
                                                    <label style={{fontSize: 18}}>Premium</label>
                                                </Col>
                                                <Col md={3}>
                                                    <InputNumber disabled
                                                        min={10000}
                                                        max={50000}
                                                        step={5000}
                                                        style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000' }}
                                                        value={businessDays == undefined ? 0 : new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(premium / businessDays)}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <InputNumber disabled
                                                        min={0}
                                                        max={50000}
                                                        step={1}
                                                        style={{  fontWeight: 'bold', marginLeft: 5, width: '95%', backgroundColor: (premium / businessDays) > (agentMonthly.writtenPremium / agentMonthly.businessDaysSoFar) ? 'red' : 'green', color: '#fff' }}
                                                        value={businessDays == undefined ? 0 : new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(agentMonthly.writtenPremium / agentMonthly.businessDaysSoFar)}
                                                    />
                                                </Col>
                                            </Row>
                                            </Card>
                                            <Card  style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc', marginTop: '10px'}}>
                                            
                                            <Row>
                                                <Col md={5}>
                                                    <label style={{fontSize: 18}}>Quoted Items</label>
                                                </Col>
                                                <Col md={3}>
                                                    <InputNumber disabled
                                                        min={0}
                                                        max={10000}
                                                        step={1}
                                                        style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000' }}
                                                        value={businessDays == undefined ? 0 : (quotedItems / businessDays).toFixed(2)}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <InputNumber disabled
                                                        min={0}
                                                        max={500000}
                                                        step={1}
                                                        style={{  marginLeft: 5, width: '95%', backgroundColor: (quotedItems / businessDays) > (agentMonthly.quotedItems / agentMonthly.businessDaysSoFar) ? 'red' : 'green', color: '#fff' }}
                                                        value={businessDays == undefined ? 0 : (agentMonthly.quotedItems / agentMonthly.businessDaysSoFar).toFixed(2)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={5}>
                                                    <label style={{fontSize: 18}}>Quoted Policies</label>
                                                </Col>
                                                <Col md={3}>
                                                    <InputNumber disabled
                                                        min={0}
                                                        max={10000}
                                                        step={1}
                                                        style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000' }}
                                                        value={businessDays == undefined ? 0 : (quotedPolicies / businessDays).toFixed(2)}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <InputNumber disabled
                                                        min={0}
                                                        max={500000}
                                                        step={1}
                                                        style={{  marginLeft: 5, width: '95%', backgroundColor: (quotedPolicies / businessDays) > (agentMonthly.quotedPolicies / agentMonthly.businessDaysSoFar) ? 'red' : 'green', color: '#fff' }}
                                                        value={businessDays == undefined ? 0 : (agentMonthly.quotedPolicies / agentMonthly.businessDaysSoFar).toFixed(2)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={5}>
                                                    <label style={{fontSize: 18}}>Quoted Households</label>
                                                </Col>
                                                <Col md={3}>
                                                    <InputNumber disabled
                                                        min={0}
                                                        max={10000}
                                                        step={1}
                                                        style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000' }}
                                                        value={businessDays == undefined ? 0 : (quotedHousehold / businessDays).toFixed(2)}
                                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <InputNumber disabled
                                                        min={0}
                                                        max={500000}
                                                        step={1}
                                                        style={{  marginLeft: 5, width: '95%', backgroundColor: (quotedHousehold / businessDays) > (agentMonthly.count / agentMonthly.businessDaysSoFar) ? 'red' : 'green', color: '#fff' }}
                                                        value={businessDays == undefined ? 0 : (agentMonthly.count / agentMonthly.businessDaysSoFar).toFixed(2)}
                                                    />
                                                </Col>
                                            </Row>
                                        </Card>
                                        <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc', marginTop: '10px'}}>
                                            <Row>
                                                <Col md={5}>
                                                    <label style={{fontSize: 18}}>Written Items</label>
                                                </Col>
                                                <Col md={3}>
                                                    <InputNumber disabled
                                                        min={0}
                                                        max={10000}
                                                        step={1}
                                                        style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000'}}
                                                        value={businessDays == undefined ? 0 : (writtenItems / businessDays).toFixed(2)}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <InputNumber disabled
                                                        min={0}
                                                        max={500000}
                                                        step={1}
                                                        style={{  marginLeft: 5, width: '95%', backgroundColor: (writtenItems / businessDays) > (agentMonthly.writtenItems / agentMonthly.businessDaysSoFar) ? 'red' : 'green', color: '#fff' }}
                                                        value={businessDays == undefined ? 0 : (agentMonthly.writtenItems / agentMonthly.businessDaysSoFar).toFixed(2)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={5}>
                                                    <label style={{fontSize: 18}}>Written Policies</label>
                                                </Col>
                                                <Col md={3}>
                                                    <InputNumber disabled
                                                        min={0}
                                                        max={10000}
                                                        step={1}
                                                        style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000'}}
                                                        value={businessDays == undefined ? 0 : (writtenPolicies/ businessDays).toFixed(2)}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <InputNumber disabled
                                                        min={0}
                                                        max={500000}
                                                        step={1}
                                                        style={{  marginLeft: 5, width: '95%', backgroundColor: (writtenPolicies/ businessDays) > (agentMonthly.writtenPolicies / agentMonthly.businessDaysSoFar) ? 'red' : 'green', color: '#fff' }}
                                                        value={businessDays == undefined ? 0 : (agentMonthly.writtenPolicies / agentMonthly.businessDaysSoFar).toFixed(2)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={5}>
                                                    <label style={{fontSize: 18}}>Written Households</label>
                                                </Col>
                                                <Col md={3}>
                                                    <InputNumber disabled
                                                        min={0}
                                                        max={10000}
                                                        step={1}
                                                        style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000' }}
                                                        value={businessDays == undefined ? 0 : (writtenHouseholds / businessDays).toFixed(2)}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <InputNumber disabled
                                                        min={0}
                                                        max={500000}
                                                        step={1}
                                                        style={{  marginLeft: 5, width: '95%', backgroundColor: (writtenHouseholds / businessDays) > (agentMonthly.writtenHouseholds / agentMonthly.businessDaysSoFar) ? 'red' : 'green', color: '#fff' }}
                                                        value={businessDays == undefined ? 0 : (agentMonthly.writtenHouseholds / agentMonthly.businessDaysSoFar).toFixed(2)}
                                                    />
                                                </Col>
                                            </Row>
                                        </Card>
                                        <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc', marginTop: '10px'}}>
                                            <Row>
                                                <Col md={5}>
                                                    <label style={{fontSize: 18}}>Quoted Bundle Ratio</label>
                                                </Col>
                                                <Col md={3}>
                                                    <InputNumber disabled
                                                        min={0}
                                                        max={10000}
                                                        step={1}
                                                        formatter={value => `${value}%`}
                                                        parser={value => value.replace('%', '')}
                                                        style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000'}}
                                                        value={this.state.quotedBundleRatio}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <InputNumber disabled
                                                        min={0}
                                                        max={500000}
                                                        step={1}
                                                        style={{  marginLeft: 5, width: '95%', backgroundColor: this.state.quotedBundleRatio > new Intl.NumberFormat("en-US", {
                                                            style: "percent",
                                                            maximumFractionDigits: 2
                                                            }).format(dailyQuotedBundles / (agentMonthly.count / agentMonthly.businessDaysSoFar)) ? 'red' : 'green', color: '#fff' }}
                                                        value={businessDays == undefined ? 0 : new Intl.NumberFormat("en-US", {
                                                            style: "percent",
                                                            maximumFractionDigits: 2
                                                            }).format(dailyQuotedBundles / (agentMonthly.count / agentMonthly.businessDaysSoFar))}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={5}>
                                                    <label style={{fontSize: 18}}>Written Bundle Ratio</label>
                                                </Col>
                                                <Col md={3}>
                                                    <InputNumber disabled
                                                        min={0}
                                                        max={10000}
                                                        step={1}
                                                        formatter={value => `${value}%`}
                                                        parser={value => value.replace('%', '')}
                                                        style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000'}}
                                                        value={this.state.writtenBundleRatio}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <InputNumber disabled
                                                        min={0}
                                                        max={500000}
                                                        step={1}
                                                        style={{  marginLeft: 5, width: '95%', backgroundColor: this.state.writtenBundleRatio > new Intl.NumberFormat("en-US", {
                                                            style: "percent",
                                                            maximumFractionDigits: 2
                                                            }).format(dailyWrittenBundles / (agentMonthly.writtenHouseholds / agentMonthly.businessDaysSoFar)) ? 'red' : 'green', color: '#fff' }}
                                                        value={businessDays == undefined ? 0 : new Intl.NumberFormat("en-US", {
                                                            style: "percent",
                                                            maximumFractionDigits: 2
                                                            }).format(dailyWrittenBundles / (agentMonthly.writtenHouseholds / agentMonthly.businessDaysSoFar))}
                                                    />
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(AgencyGoals);