import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Collapse, Row, Col, Card, Slider, InputNumber, Typography, Button, Select, List, Progress, Space, DatePicker, Spin } from 'antd'
import moment from 'moment';
const { Title, Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

class SalesProducerGoals extends Component {
    constructor(props) {
      super(props);
        this.state = {
        premium: 0,
        premiumPerItem: 0,
        premiumPerPolicy: 0,
        premiumPerHousehold: 0,
        itemsRequired: 0,
        itemsPerHousehold: 0,
        householdsNeeded: 0,
        closingRatio: 0,
        closingRatioPolicy: 0,
        closingRatioHousehold: 0,
        totalMonthly: 0,
        writtenItems: 0,
        loaded: false,
        itemsPerPolicy: 0,
        agent: 'All',
        agentId: 0,
        quotedBundleRatio: 0,
        writtenBundleRatio: 0,
        monthDts: moment().format("MMM YYYY"),
        selectedDate: moment().startOf('month').format("MM/DD/YYYY")
        };
        this.handleSave = this.handleSave.bind(this);
        this.handleAgentChange = this.handleAgentChange.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
    }
    componentDidMount(){
        const userId = localStorage.getItem("userId");
        const agencyId = localStorage.getItem("agencyId");
        this.props.getSalesProducerGoals(agencyId, this.state.monthDts);
        this.setState({loaded: true});
        if(this.props.viewOnly){
            
        } else {
            
        }
    }
    componentDidUpdate(prevProps, prevState){
       
    }
    handleAgentChange = (e) => {
        console.log(e);
        const agencyId = localStorage.getItem("agencyId");
        const monthDts = this.state.monthDts;
        const selectedDate = this.state.selectedDate;
        if(e != 'All'){
            const agent = e;
            const agentId = this.props.agent.find(a => a.firstName == e).agentId;
            this.setState({agent: e, agentId: agentId});
            this.props.getMarketingAgent(agentId, monthDts);
            console.log(selectedDate);
            this.props.getSalesAgentSummary(agencyId, moment(selectedDate).startOf('month').format("MM/DD/YYYY"), moment(selectedDate).endOf('month').format("MM/DD/YYYY"), agent);
            var threeMonthsAgo = moment().subtract(3, 'months').calendar(); 
            this.props.getMonthlyWrittenBundles(agencyId, moment(threeMonthsAgo).startOf('month').format("MM/DD/YYYY"), moment().endOf('month').format("MM/DD/YYYY"), agent);
        } else {
            this.props.getTotalMarketingAgentUsers(agencyId, monthDts);
            this.props.getTotalSalesAgentSummary(agencyId, moment(selectedDate).startOf('month').format("MM/DD/YYYY"), moment(selectedDate).endOf('month').format("MM/DD/YYYY"));
            this.setState({agent: 'All', agentId: 0});
        }
        
    }
    onPremiumChange = value => {
        this.setState({
          premium: value,
        });
    };
    onPremiumPerItemChange = value => {
        this.setState({
            premiumPerItem: value,
        });
    };
    onPremiumPerPolicyChange = value => {
        this.setState({
            premiumPerPolicy: value,
        });
    };
    onPremiumPerHouseholdChange = value => {
        this.setState({
            premiumPerHousehold: value,
        });
    };
    onPerItemPolicyChange = value => {
        this.setState({
            itemsPerPolicy: value,
        });
    };
    onItemsPerHouseholdChange = value => {
        this.setState({
            itemsPerHousehold: value,
        });
    };
    onClosingRatioChange = value => {
        this.setState({
            closingRatio: value,
        });
    };
    onClosingRatioPolicyChange = value => {
        this.setState({
            closingRatioPerPolicy: value,
        });
    };
    onClosingRatioHouseholdChange = value => {
        this.setState({
            closingRatioPerHousehold: value,
        });
    };
    onQuotedBundleRatioChange = value => {
        this.setState({
            quotedBundleRatio: value,
        });
    };
    onWrittenBundleRatioChange = value => {
        this.setState({
            writtenBundleRatio: value,
        });
    };
    onHouseholdsNeededChange = value => {
        this.setState({
            householdsNeeded: value,
        });
    };
    handleSave(event) {
        const agencyId = localStorage.getItem("agencyId");
        const agentId = this.state.agentId;
        const premium = this.state.premium;
        const premiumPerItem = this.state.premiumPerItem;
        const itemsPerHousehold = this.state.itemsPerHousehold;
        const closingRatio = this.state.closingRatio;
        const closingRatioPerPolicy = this.state.closingRatioPerPolicy;
        const closingRatioPerHousehold = this.state.closingRatioPerHousehold;
        const itemsPerPolicy = this.state.itemsPerPolicy;
        const premiumPerPolicy = this.state.premiumPerPolicy;
        const premiumPerHousehold = this.state.premiumPerHousehold;
        const quotedBundleRatio = this.state.quotedBundleRatio;
        const writtenBundleRatio = this.state.writtenBundleRatio;
        const monthDts = this.state.monthDts;
 
        this.props.createMarketingAgent(agencyId, agentId, premium, premiumPerItem, itemsPerHousehold, closingRatio, itemsPerPolicy, premiumPerPolicy, premiumPerHousehold, closingRatioPerPolicy, closingRatioPerHousehold, quotedBundleRatio, writtenBundleRatio, monthDts);
        
    }
    onMonthChange(date, dateString){
        if(dateString != ''){
            const userId = localStorage.getItem("userId");
            const agencyId = localStorage.getItem("agencyId");
            const agent = this.state.agent;
            const agentId = this.state.agentId;
            this.setState({monthDts: dateString, selectedDate: date});
            if(agent != 'All'){
                this.props.getMarketingAgent(agentId, dateString);
                this.props.getSalesAgentSummary(agencyId, moment(date).startOf('month').format("MM/DD/YYYY"), moment(date).endOf('month').format("MM/DD/YYYY"), agent);
            } else {
                this.props.getTotalMarketingAgentUsers(agencyId, dateString);
                this.props.getTotalSalesAgentSummary(agencyId, moment(date).startOf('month').format("MM/DD/YYYY"), moment(date).endOf('month').format("MM/DD/YYYY"));
            }
            
        }else{
            this.setState({monthDts: moment().format("MMM YYYY")});
        }
    }
    render() {
        // const totalAgentMonthly = this.state.agent == 'All' && this.props.totalMarketingAgent.length > 0 ? this.props.totalMarketingAgent[0] : [];
        // const premium = this.state.agent == 'All' ? totalAgentMonthly.monthlyPremium : this.state.premium;
        // const premiumPerItem = this.state.agent == 'All' ? totalAgentMonthly.premiumPerItem : this.state.premiumPerItem;
        // //const premiumPerPolicy = this.state.agent == 'All' ? 0 : this.state.premiumPerPolicy;
        // //const premiumPerHousehold = this.state.agent == 'All' ? 0 : this.state.premiumPerHousehold;
        // const itemsPerHousehold = this.state.agent == 'All' ? totalAgentMonthly.itemsPerHousehold : this.state.itemsPerHousehold;
        // const closingRatio = this.state.agent == 'All' ? parseFloat(totalAgentMonthly.closedRatio).toFixed(2) : this.state.closingRatio;
        // const closingRatioPerPolicy = this.state.agent == 'All' ? parseFloat(totalAgentMonthly.closedRatioPerPolicy).toFixed(2) : this.state.closingRatioPerPolicy;
        // const closingRatioPerHousehold = this.state.agent == 'All' ? parseFloat(totalAgentMonthly.closedRatioPerHousehold).toFixed(2) : this.state.closingRatioPerHousehold;
        // const itemsPerPolicy = this.state.agent == 'All' ? totalAgentMonthly.itemsPerPolicy : this.state.itemsPerPolicy != null ? this.state.itemsPerPolicy : 0;
        // const quotedBundleRatio = this.state.agent == 'All' ? parseFloat(totalAgentMonthly.quotedBundleRatio).toFixed(2) : this.state.quotedBundleRatio;
        // const writtenBundleRatio = this.state.agent == 'All' ? parseFloat(totalAgentMonthly.writtenBundleRatio).toFixed(2) : this.state.writtenBundleRatio;

        // const premiumPerPolicy = premiumPerItem * itemsPerPolicy
        // const premiumPerHousehold = premiumPerItem * itemsPerHousehold

        // const writtenItems = premiumPerItem > 0 ? premium / premiumPerItem : 0;
        // const writtenPolicies = premiumPerPolicy > 0 ? premium / premiumPerPolicy : 0;
        // const writtenHouseholds = premiumPerHousehold > 0 ? premium / premiumPerHousehold : 0;

        // const closedRatio = closingRatio / 100;
        // const closingRatioPolicies = closingRatioPerPolicy / 100;
        // const closingRatioHouseholds = closingRatioPerHousehold / 100;

        // const quotedItems = closingRatio > 0 ? writtenItems / (closingRatio / 100) : 0;
        // const quotedPolicies = closingRatioPolicies > 0 ? writtenPolicies / closingRatioPolicies : 0;
        // const quotedHousehold = closingRatioHouseholds > 0 ? writtenHouseholds / closingRatioHouseholds : 0;
        // const configBar = {
        //     data: this.props.marketingAgent,
        //     isGroup: true,
        //     yField: 'premiumPerItem',
        //     seriesField: 'premiumPerPolicy',
            
        // };
        
        // var agentMonthly = [];
        // if(this.state.agent != 'All' & this.props.quoteAgentSummaryByAgent.length > 0) {
        //     agentMonthly = this.props.quoteAgentSummaryByAgent[0];
        // } else if(this.state.agent == 'All' & this.props.totalSalesAgentSummary.length > 0) {
        //     agentMonthly = this.props.totalSalesAgentSummary[0];
        // };
        // const agentAvgPerItem = agentMonthly.writtenPremium > 0 ? agentMonthly.writtenPremium / agentMonthly.writtenItems : 0;
        // const agentAvgPerPolicy = agentMonthly.writtenPremium > 0 ? agentMonthly.writtenPremium / agentMonthly.writtenPolicies : 0;
        // const agentAvgPerHousehold = agentMonthly.writtenPremium > 0 ? agentMonthly.writtenPremium / agentMonthly.writtenHouseholds : 0;
        
        // const agentAvgItemsPerPolicy = agentMonthly.writtenItems > 0 ? agentMonthly.writtenItems / agentMonthly.writtenPolicies : 0;
        // const agentAvgItemsPerHousehold = agentMonthly.writtenItems > 0 ? agentMonthly.writtenItems / agentMonthly.writtenHouseholds : 0;

        // const agentClosePerItem = agentMonthly.quotedItems > 0 ? agentMonthly.writtenItems / agentMonthly.quotedItems : 0;
        // const agentClosePerPolicy = agentMonthly.quotedPolicies > 0 ? agentMonthly.writtenPolicies / agentMonthly.quotedPolicies : 0;
        // const agentClosePerHouseholds = agentMonthly.count > 0 ? agentMonthly.writtenHouseholds / agentMonthly.count : 0;

        // const businessDays = this.state.agent == 'All' ? totalAgentMonthly.businessDays : agentMonthly.businessDays;
        // console.log(businessDays + " " + premium);
        // const writtenItemsTrending = agentMonthly.count > 0 ? (agentMonthly.writtenItems / agentMonthly.businessDaysSoFar) * agentMonthly.businessDays : 0;
        // const writtenPoliciesTrending = agentMonthly.count > 0 ? (agentMonthly.writtenPolicies / agentMonthly.businessDaysSoFar) * agentMonthly.businessDays : 0;
        // const writtenHouseholdsTrending = agentMonthly.count > 0 ? (agentMonthly.writtenHouseholds / agentMonthly.businessDaysSoFar) * agentMonthly.businessDays : 0;

        // const quotedItemsTrending = agentMonthly.count > 0 ? (agentMonthly.quotedItems / agentMonthly.businessDaysSoFar) * agentMonthly.businessDays : 0;
        // const quotedPoliciesTrending = agentMonthly.count > 0 ? (agentMonthly.quotedPolicies / agentMonthly.businessDaysSoFar) * agentMonthly.businessDays : 0;
        // const quotedHouseholdsTrending = agentMonthly.count > 0 ? (agentMonthly.count / agentMonthly.businessDaysSoFar) * agentMonthly.businessDays : 0;

        // const quotedBundles = agentMonthly.count > 0 ? agentMonthly.quotedBundles / agentMonthly.count : 0;
        // const writtenBundles = agentMonthly.writtenHouseholds > 0 ?  agentMonthly.writtenBundles / agentMonthly.writtenHouseholds : 0;

        // const dailyQuotedBundles = agentMonthly.quotedBundles / agentMonthly.businessDaysSoFar;
        // const dailyWrittenBundles = agentMonthly.writtenBundles / agentMonthly.businessDaysSoFar;
        // const data = this.props.monthlyWrittenBundles ? this.props.monthlyWrittenBundles : [];
        // const config = {
        //     data,
        //     xField: 'bindMonth',
        //     yField: 'writtenBundleRate',
        //     point: {
        //       shapeField: 'square',
        //       sizeField: 4,
        //     },
        //     interaction: {
        //       tooltip: {
        //         marker: false,
        //       },
        //     },
        //     style: {
        //       lineWidth: 2,
        //     },
        //   };
        const styles = {
            tableCell: {
                border: "1px solid black", 
                width: '100px',
                fontSize: '12px'
            },
        };
        return(
            <div>
                <Row>
                    <Col xs={24} sm={24} lg={24} style={{paddingBottom: 20}}>
                        <Card title="SALES PRODUCER GOALS" headStyle={{ backgroundColor: '#3D76DD', fontSize: 30, textAlign: 'center', color: "#fff" }}>
                            <Row>
                                <Col xs={12} sm={12} lg={12}>
                                    <table style={{width: '100%', border: "1px solid black"}}>
                                        <thead style={{fontWeight: 'bold', backgroundColor: '#fafafa', borderBottom: "1px solid black"}}>
                                            <tr style={{height: '50px', textAlign: 'center'}}>
                                                <th style={{width: '10%'}}>Agent</th>
                                                <th colSpan={3} style={{border: "1px solid black"}}>Premium</th>
                                                <th colSpan={2} style={{border: "1px solid black"}}>Premium Per Item</th>
                                                <th colSpan={2} style={{border: "1px solid black"}}>Premium Per Policy</th>
                                                <th colSpan={2} style={{border: "1px solid black"}}>Premium Per Household</th>
                                                <th colSpan={2} style={{border: "1px solid black"}}>Items Per Policy</th>
                                                <th colSpan={2} style={{border: "1px solid black"}}>Items Per Household</th>
                                                <th colSpan={2} style={{border: "1px solid black"}}>Close Ratio Per Item</th>
                                                <th colSpan={2} style={{border: "1px solid black"}}>Close Ratio Per Policy</th>
                                                <th colSpan={2} style={{border: "1px solid black"}}>Close Ratio Per Household</th>
                                                <th colSpan={2} style={{border: "1px solid black"}}>Quoted Bundle Ratio</th>
                                                <th colSpan={2} style={{border: "1px solid black"}}>Written Bundle Ratio</th>
                                                <th colSpan={3} style={{border: "1px solid black"}}>Quoted Item</th>
                                                <th colSpan={3} style={{border: "1px solid black"}}>Quoted Policies</th>
                                                <th colSpan={3} style={{border: "1px solid black"}}>Quoted Household</th>
                                                <th colSpan={3} style={{border: "1px solid black"}}>Written Item</th>
                                                <th colSpan={3} style={{border: "1px solid black"}}>Written Policies</th>
                                                <th colSpan={3} style={{border: "1px solid black"}}>Written Household</th>
                                            </tr>
                                            <tr>
                                                <th></th>
                                                <th style={styles.tableCell}>GOAL</th>
                                                <th style={styles.tableCell}>ACTUAL</th>
                                                <th style={styles.tableCell}>TRENDING</th>
                                                <th style={styles.tableCell}>GOAL</th>
                                                <th style={styles.tableCell}>ACTUAL</th>
                                                <th style={styles.tableCell}>GOAL</th>
                                                <th style={styles.tableCell}>ACTUAL</th>
                                                <th style={styles.tableCell}>GOAL</th>
                                                <th style={styles.tableCell}>ACTUAL</th>
                                                <th style={styles.tableCell}>GOAL</th>
                                                <th style={styles.tableCell}>ACTUAL</th>
                                                <th style={styles.tableCell}>GOAL</th>
                                                <th style={styles.tableCell}>ACTUAL</th>
                                                <th style={styles.tableCell}>GOAL</th>
                                                <th style={styles.tableCell}>ACTUAL</th>
                                                <th style={styles.tableCell}>GOAL</th>
                                                <th style={styles.tableCell}>ACTUAL</th>
                                                <th style={styles.tableCell}>GOAL</th>
                                                <th style={styles.tableCell}>ACTUAL</th>
                                                <th style={styles.tableCell}>GOAL</th>
                                                <th style={styles.tableCell}>ACTUAL</th>
                                                <th style={styles.tableCell}>GOAL</th>
                                                <th style={styles.tableCell}>ACTUAL</th>
                                                <th style={styles.tableCell}>GOAL</th>
                                                <th style={styles.tableCell}>ACTUAL</th>
                                                <th style={styles.tableCell}>TRENDING</th>
                                                <th style={styles.tableCell}>GOAL</th>
                                                <th style={styles.tableCell}>ACTUAL</th>
                                                <th style={styles.tableCell}>TRENDING</th>
                                                <th style={styles.tableCell}>GOAL</th>
                                                <th style={styles.tableCell}>ACTUAL</th>
                                                <th style={styles.tableCell}>TRENDING</th>
                                                <th style={styles.tableCell}>GOAL</th>
                                                <th style={styles.tableCell}>ACTUAL</th>
                                                <th style={styles.tableCell}>TRENDING</th>
                                                <th style={styles.tableCell}>GOAL</th>
                                                <th style={styles.tableCell}>ACTUAL</th>
                                                <th style={styles.tableCell}>TRENDING</th>
                                                <th style={styles.tableCell}>GOAL</th>
                                                <th style={styles.tableCell}>ACTUAL</th>
                                                <th style={styles.tableCell}>TRENDING</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.salesProducerGoals.map(s => {
                                                const premiumPerPolicy = s.premiumPerItem * s.itemsPerPolicy;
                                                const premiumPerHousehold = s.premiumPerItem * s.itemsPerHousehold;
                                                const dailyAverageSoFar = s.writtenPremium / Math.round(s.businessDaysSoFar);
                                                const premiumTrending = dailyAverageSoFar * s.businessDays

                                                const quotedItemsTrending = s.count > 0 ? (s.quotedItems / s.businessDaysSoFar) * s.businessDays : 0;
                                                const quotedPoliciesTrending = s.count > 0 ? (s.quotedPolicies / s.businessDaysSoFar) * s.businessDays : 0;
                                                const quotedHouseholdsTrending = s.count > 0 ? (s.count / s.businessDaysSoFar) * s.businessDays : 0;

                                                const writtenItemsTrending = s.count > 0 ? (s.writtenItems / s.businessDaysSoFar) * s.businessDays : 0;
                                                const writtenPoliciesTrending = s.count > 0 ? (s.writtenPolicies / s.businessDaysSoFar) * s.businessDays : 0;
                                                const writtenHouseholdsTrending = s.count > 0 ? (s.writtenHouseholds / s.businessDaysSoFar) * s.businessDays : 0;

                                                const writtenItemGoal = s.premiumPerItem > 0 ? s.monthlyPremium / s.premiumPerItem : 0;
                                                const writtenPolicyGoal = premiumPerPolicy > 0 ? s.monthlyPremium / premiumPerPolicy : 0;
                                                const writtenHouseholdGoal = premiumPerHousehold > 0 ? s.monthlyPremium / premiumPerHousehold : 0;
                                            return <tr>
                                                <td>{s.agent}</td>
                                                <td style={styles.tableCell}>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(s.monthlyPremium)}</td>
                                                <td style={styles.tableCell}>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(s.writtenPremium)}</td>
                                                <td style={styles.tableCell}>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(premiumTrending)}</td>
                                                <td style={styles.tableCell}>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(s.premiumPerItem)}</td>
                                                <td style={styles.tableCell}>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(s.writtenPremium > 0 ? s.writtenPremium / s.writtenItems : 0)}</td>
                                                <td style={styles.tableCell}>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(premiumPerPolicy)}</td>
                                                <td style={styles.tableCell}>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(s.writtenPremium > 0 ? s.writtenPremium / s.writtenPolicies : 0)}</td>
                                                <td style={styles.tableCell}>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(premiumPerHousehold)}</td>
                                                <td style={styles.tableCell}>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(s.writtenPremium > 0 ? s.writtenPremium / s.writtenHouseholds : 0)}</td>
                                                <td style={styles.tableCell}>{parseFloat(s.itemsPerPolicy).toFixed(2)}</td>
                                                <td style={styles.tableCell}>{parseFloat(s.writtenItems > 0 ? s.writtenItems / s.writtenPolicies: 0).toFixed(2)}</td>
                                                <td style={styles.tableCell}>{parseFloat(s.itemsPerHousehold).toFixed(2)}</td>
                                                <td style={styles.tableCell}>{parseFloat(s.writtenItems > 0 ? s.writtenItems / s.writtenHouseholds : 0).toFixed(2)}</td>
                                                <td style={styles.tableCell}>{s.closedRatio}</td>
                                                <td style={styles.tableCell}>{s.quotedItems > 0 ? new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(s.writtenItems / s.quotedItems) : 0}</td>
                                                <td style={styles.tableCell}>{s.closedRatioPerPolicy}</td>
                                                <td style={styles.tableCell}>{s.quotedPolicies > 0 ? new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(s.writtenPolicies / s.quotedPolicies) : 0}</td>
                                                <td style={styles.tableCell}>{s.closedRatioPerHousehold}</td>
                                                <td style={styles.tableCell}>{s.count > 0 ? new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(s.writtenHouseholds / s.count) : 0}</td>
                                                <td style={styles.tableCell}>{s.quotedBundleRatio}</td>
                                                <td style={styles.tableCell}>{s.actualQuotedBundleRatio}</td>
                                                <td style={styles.tableCell}>{s.writtenBundleRatio}</td>
                                                <td style={styles.tableCell}>{s.actualWrittenBundleRatio}</td>
                                                <td style={styles.tableCell}>{s.closedRatio > 0 ? Math.round(writtenItemGoal / (s.closedRatio / 100)) : 0}</td>
                                                <td style={styles.tableCell}>{s.quotedItems}</td>
                                                <td style={styles.tableCell}>{quotedItemsTrending}</td>
                                                <td style={styles.tableCell}>{s.closedRatioPerPolicy > 0 ? Math.round(writtenPolicyGoal / (s.closedRatioPerPolicy / 100)) : 0}</td>
                                                <td style={styles.tableCell}>{s.quotedPolicies}</td>
                                                <td style={styles.tableCell}>{quotedPoliciesTrending}</td>
                                                <td style={styles.tableCell}>{s.closedRatioPerHousehold > 0 ? Math.round(writtenHouseholdGoal / (s.closedRatioPerHousehold / 100)) : 0}</td>
                                                <td style={styles.tableCell}>{s.count}</td>
                                                <td style={styles.tableCell}>{quotedHouseholdsTrending}</td>
                                                <td style={styles.tableCell}>{Math.round(writtenItemGoal)}</td>
                                                <td style={styles.tableCell}>{s.writtenItems}</td>
                                                <td style={styles.tableCell}>{Math.round(writtenItemsTrending)}</td>
                                                <td style={styles.tableCell}>{Math.round(writtenPolicyGoal)}</td>
                                                <td style={styles.tableCell}>{s.writtenPolicies}</td>
                                                <td style={styles.tableCell}>{Math.round(writtenPoliciesTrending)}</td>
                                                <td style={styles.tableCell}>{Math.round(writtenHouseholdGoal)}</td>
                                                <td style={styles.tableCell}>{s.writtenHouseholds}</td>
                                                <td style={styles.tableCell}>{Math.round(writtenHouseholdsTrending)}</td>
                                            </tr>
                                        })}
                                        </tbody>
                                    </table>
                                </Col>                   
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(SalesProducerGoals);