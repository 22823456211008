import React, { useState, Component, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { UploadOutlined, ArrowUpOutlined, CheckOutlined, EditOutlined, PlusCircleOutlined, DownOutlined, FileTextOutlined, DeleteOutlined, ArrowRightOutlined, ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import { Upload, Typography, Row, Col, Button, Table, Select, DatePicker, Space, Card, Spin, Checkbox, Anchor, Modal, Dropdown } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import QuoteLogEntry from './QuoteLogEntry';
import TerminationUploadReport from './TerminationUploadReport';
import moment from 'moment';
const { Title, Text } = Typography;
const {Option} = Select;
const { Link } = Anchor;


class TerminationUpload extends Component {
    constructor(props) {
      super(props);
        this.state = {
           terminationColumnHeaders: [],
           terminationFileData: [],
           newBusinessColumnHeaders: [],
           newBusinessFileData: [],
           firstName: '',
           lastName: '',
           subProducer: '',
           zipCode: '',
           productionDate:'',
           productName: '',
           terminationEffectiveDate: '',
           terminationReason: '',
           anniversaryEffectiveDate: '',
           renewalEffectiveDate: '',
           policyNumber: '',
           premiumNew: '',
           premiumOld: '',
           originalYear: '',
           agentNumber: '',
           quotedItemCount: '',
           agencyQuotedItems: 0,
           agencySoldItems: 0,
           agencyCloseRatio: 0.0,
           agencyQuotedPolicies: 0,
           agencySoldPolicies: 0,
           agencyClosedPoliciesRatio: 0,
           agencyQuotedHouseholds: 0,
           agencySoldHouseholds: 0,
           agencyClosedHouseholdsRatio: 0,
           distinctTerminationProducers: [],
           distinctNewBusinessProducers: [],
           distinceProducers: [],
           invalidTerminationFileType: false,
           invalidNewBusinessFileType: false,
           removedColumns: [],
           dataFile: [],
           fileName: '',
           openUpload: false,
           dateTo: '',
           dateFrom: '',
           terminationQuoteLog: [],
           showModal: false,
           terminationUpload: [],
           selectedTerminationUpload: [],
           showDelete: false,
           selectedFile: null,
           hideMonthSelection: false,
           uploadFileList: [],
           manageFile: null,
           isSaving: false
        }
        this.setCsvFile = this.setCsvFile.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onDragUpdate = this.onDragUpdate.bind(this);
        this.handleUploadSave = this.handleUploadSave.bind(this);
        this.onUploadClick = this.onUploadClick.bind(this);
        this.handleFileClick = this.handleFileClick.bind(this);
        this.suggestedRef = React.createRef();
        this.noRef = React.createRef();
        this.handleDeleteFile = this.handleDeleteFile.bind(this);
        
    }
    

    componentDidMount(){
        const userId = localStorage.getItem('userId');
        const agencyId = localStorage.getItem('agencyId');
        const dateFrom = moment().subtract(1, 'month').startOf('month').format('MM/DD/YYYY');
        const dateTo = moment().subtract(1, 'month').endOf('month').format('MM/DD/YYYY');
        this.props.getTerminationUploadFiles(agencyId);
    }
    componentDidUpdate(prevProps){
        
    }
    handleUploadSave(){
        this.setState({isSaving: true});
        const agencyId = localStorage.getItem('agencyId');
        // const dateFrom = moment().startOf('month').format('MM/DD/YYYY');
        // const dateTo = moment().endOf('month').format('MM/DD/YYYY');
        // this.props.getQuoteLogReport(agencyId, dateFrom, dateTo, 'All');
        var terminationUploads = [];
        this.state.terminationFileData.map((item) => {
            if(item[this.state.subProducer] != "") {
                let terminationUpload = {
                    agentNumber : item[this.state.agentNumber],
                    policyNumber : item[this.state.policyNumber],
                    productName : item[this.state.productName],
                    originalYear : item[this.state.originalYear],
                    terminationReason : item[this.state.terminationReason],
                    premiumNew : item[this.state.premiumNew],
                    premiumOld : item[this.state.premiumOld],
                    anniversaryEffectiveDate : item[this.state.anniversaryEffectiveDate],
                    terminationEffectiveDate : item[this.state.terminationEffectiveDate],
                    renewalEffectiveDate : item[this.state.renewalEffectiveDate],
                    firstName: item[this.state.firstName],
                    lastName: item[this.state.lastName],
                    zip: item[this.state.zipCode],
                    productName: item[this.state.productName],
                    agencyId: agencyId,
                    fileName: this.state.fileName
                };
                terminationUploads.push(terminationUpload);
        }
        });
        const dateFrom = moment(terminationUploads[0].productionDate).startOf('month').format('MM/DD/YYYY');
        const dateTo = moment(terminationUploads[0].productionDate).endOf('month').format('MM/DD/YYYY');
        this.props.createTerminationUpload(terminationUploads, dateFrom, dateTo);
        this.props.getQuoteLogReport(agencyId, dateFrom, dateTo, 'All');
        this.props.getQuoteLogTerminationUpload(agencyId, dateFrom, dateTo);
        this.props.getTerminationUploadFiles(agencyId);
        console.log(terminationUploads);
        this.setState({isSaving: false, dataFile: this.state.terminationFileData, hideMonthSelection: false, fileName: '', uploadFileList: [], subProducer: '', firstName: '', lastName: '', zipCode: '', productionDate: '', product: '', quotedItemCount: '', agentNumber: ''});
    }
    onDragEnd = ({ source, destination, draggableId }) => {
        // Make sure we have a valid destination
        if (destination === undefined || destination === null) return null
    
        // If the source and destination columns are the same
        // AND if the index is the same, the item isn't moving
        if (
          source.droppableId === destination.droppableId &&
          destination.index === source.index
        )
          return null
    
        this.setState({droppableBackColor: ""});
        // Set start and end variables
        const start = source.droppableId
        const end = destination.droppableId
        if(start != end){
            console.log(destination.droppableId + " : " + source.droppableId);
            if(destination.droppableId == "headers"){
                this.state.terminationColumnHeaders.splice(destination.index, 0, this.state[source.droppableId]);
                this.setState({[source.droppableId]: ""});
            }else if(source.droppableId == "headers"){
                var previousValue = this.state[destination.droppableId];
                this.setState({[destination.droppableId]: this.state.terminationColumnHeaders[source.index]});
                this.state.terminationColumnHeaders.splice(source.index, 1);
                if(previousValue != ""){
                    this.state.terminationColumnHeaders.splice(source.index, 0, previousValue);
                }
            }
            else{
                this.setState({[destination.droppableId]: this.state[source.droppableId]});
                this.setState({[source.droppableId]: this.state[destination.droppableId]})
            }
        }
        return null
    }
    onDragUpdate = ({ source, destination, draggableId }) => {
        if (destination === undefined || destination === null) return null
        this.setState({droppableBackColor: [destination.droppableId]});
    }
    onUploadClick(){
        const openUpload = this.state.openUpload;
        this.setState({openUpload: !openUpload, refreshReport: false, monthDts: '', selectedLeadSource: '', hideMonthSelection: true});
    }
    handleFileClick = (label) => {
        const agencyId = localStorage.getItem('agencyId');
        console.log(label);
        //this.props.getterminationUpload(agencyId, file);
    }
    handleDeleteFile = () => {
        const agencyId = localStorage.getItem('agencyId');
        const fileName = this.state.selectedFile;
        this.props.deleteTerminationUploadFile(agencyId, fileName);

        const dateFrom = moment().startOf('month').format('MM/DD/YYYY');
        const dateTo = moment().endOf('month').format('MM/DD/YYYY');
        this.props.getQuoteLogReport(agencyId, dateFrom, dateTo);
        this.props.getQuoteLogTerminationUpload(agencyId, dateFrom, dateTo);
        this.props.getTerminationUpload(agencyId, '');
        //this.props.getQuoteLogReport(agencyId, dateFrom, dateTo);
        //this.props.getQuoteLogterminationUpload(agencyId, dateFrom, dateTo);
        this.setState({openUpload: false, selectedFile: null});
    }
    setCsvFile(file, type){
        if(file.type == "text/csv"){
            const reader = new FileReader();
            reader.onload = (e) => {
                const text = e.target.result;
                const delim = ',';
                const rows = text.slice(text.indexOf('\n')+1).split('\r\n');
                const headers = text.slice(0, text.indexOf('\n')).split(delim);
                console.log(headers);
                var quotedItems = 0;
                var soldItems = 0;
                var quotedHouseholds = [];
                var soldHouseholds = [];
                var terminationProducers = [];
                var newBusinessProducers = [];
                console.log(rows);
                const newArray = rows.map( row => {
                    const values = row.split(delim);
                    const eachObject = headers.reduce((obj, header, i) => {
                        obj[header] = values[i];
                        return obj;
                    }, {})
                    return eachObject;
                });
                const distinctQuotedHouseholds = new Set(quotedHouseholds);
                const distinctSoldHouseholds = new Set(soldHouseholds);
                const distinctTerminationProducers = new Set(terminationProducers);
                const distinctNewBusinessProducers = new Set(newBusinessProducers);
                newArray.pop();
                console.log(newArray);
                const fList = this.state.uploadFileList.push(file);
                if(type == 'termination'){
                    this.setState({fileName: file.name, invalidTerminationFileType: false, distinctTerminationProducers: distinctTerminationProducers, terminationColumnHeaders: headers, terminationFileData: newArray, agencyQuotedItems: quotedItems, agencyQuotedHouseholds: distinctQuotedHouseholds.size, agencyQuotedPolicies: quotedHouseholds.length, uploadFileList: fList})
                }else{
                    this.setState({invalidNewBusinessFileType: false, distinctNewBusinessProducers: distinctNewBusinessProducers, newBusinessColumnHeaders: headers, newBusinessFileData: newArray, agencySoldItems: soldItems, agencySoldHouseholds: distinctSoldHouseholds.size, agencySoldPolicies: soldHouseholds.length});
                }
            }
            reader.readAsText(file);
        }
        else{
            type=="termination" ? this.setState({invalidTerminationFileType: true}) : this.setState({invalidNewBusinessFileType: true})
        }
        return false;
    }

    render() {
        const items: MenuProps['items'] = [
            {
              label: '1st menu item',
              key: '1',
            },
            {
              label: '2nd menu item',
              key: '2',
            },
            {
              label: '3rd menu item',
              key: '3',
            },
          ];
          
          const menuProps = {
            items
          };
          const handleChange = (value) => {
            const agencyId = localStorage.getItem('agencyId');
            var file = this.props.terminationUploadFiles.find(s => s.key == value);
            var dateFrom = moment(file.value, 'MMM YYYY').format("MM/DD/YYYY");
            var dateTo = moment(dateFrom).endOf('month').format('MM/DD/YYYY');
            this.props.getTerminationUpload(agencyId, value);
            this.props.getQuoteLogReport(agencyId, dateFrom, dateTo);
            this.props.getQuoteLogTerminationUpload(agencyId, dateFrom, dateTo);
            this.setState({showDelete: true, selectedFile: value});
          }
        const saving = this.state.isSaving;
        return (
            <div>
                <div>
                {/* <Row>
                    <Col>
                        <Button icon={<UploadOutlined />} type='primary' shape="round" onClick={this.onUploadClick}>Upload New Report</Button>
                    </Col>
                </Row> */}
                <Row>
                    <Col>
                        <Title level={3}>Quote Upload</Title>
                    </Col>
                </Row>
                <Row style={{paddingTop: 10, paddingBottom: 20, border: 'solid 1px #3D76DD', borderRadius: "5px", overflow: "hidden", marginTop: 10, boxShadow: '5px 5px 5px #ccc'}}>
                    <Col span={12} style={{padding: 10}}>
                        <Space direction='horizontal'>
                            <Space>
                                <Upload
                                    showUploadList={false}
                                    accept='.csv'
                                    id='csvFile'
                                    beforeUpload={file => {
                                        this.setCsvFile(file, 'termination');
                                        this.setState({hideMonthSelection: true});
                                        return false;
                                    }}>
                                    <Button icon={<UploadOutlined />} type='primary' shape='round'>Upload New File</Button>
                                </Upload>
                                <Text>{this.state.fileName}</Text>
                                {this.state.invalidTerminationFileType == true && <Text type="danger" id="passwordError" style={{fontSize: 16}}>Only .csv files are accepted.</Text>}
                            </Space>
                            {/* <Text style={{fontWeight: 'bold'}}>OR</Text>
                            <Button icon={<FileTextOutlined />} type='primary' shape="round" onClick={this.onUploadClick}>Manage Uploaded Files</Button> */}
                        </Space>
                    </Col>
                    <Col span={6}>
                       <Select
                            style={{width: '100%', border: 'solid 1px #3D76DD', }}
                            size="large"
                            virtual={false}
                            placeholder={<strong style={{color: 'black'}}>View and Manage Files</strong>}
                            name="terminationFiles"
                            value={this.state.selectedFile}
                            onChange={handleChange}>
                            {
                                this.props.terminationUploadFiles.map((s, index) => 
                                    <Option key={index} value={s.key} label={s.key}>{s.key}</Option>
                                )
                            }
                        </Select>
                    </Col>
                    <Col span={6} style={{paddingLeft: 10}}>
                            {this.state.showDelete && <Button type="danger" shape="round" icon={<DeleteOutlined />} onClick={this.handleDeleteFile}> Delete File</Button>}
                    </Col>
                </Row>
                {
                    this.state.fileName != '' &&
                <div style={{paddingTop: 10}}>
                <DragDropContext onDragEnd={this.onDragEnd} onDragUpdate={this.onDragUpdate}>
                <Row>
                    <Col span={4} style={{overflowY: 'scroll', height: 600, paddingRight: 5}}>
                        <Droppable droppableId='headers'>
                            {(provided) => (
                                <div {...provided.droppableProps}  ref={provided.innerRef}>
                                {this.state.terminationColumnHeaders.map((item, i) =>
                                    <div style={{paddingBottom: 10}}>
                                        <Draggable key={item} draggableId={item} index={i}>
                                            {(provided) => (
                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}><div style={{textAlign: 'center', border: 'solid 1px #3D76DD', borderRadius: "10px", overflow: "hidden", height: '40px'  }}>{item}</div></div>
                                            )}
                                        </Draggable>
                                    </div>
                                )}
                                {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </Col>
                    <Col span={1} style={{display: 'flex', justifyContent: 'center', marginTop: 50}}>
                        <Space direction="vertical">
                            <ArrowRightOutlined style={{fontSize: 22, color: '#3D76DD'}} />
                            <ArrowLeftOutlined style={{fontSize: 22, color: '#3D76DD'}} />
                        </Space>
                    </Col>
                    <Col span={19} style={{paddingLeft: 10}}>
                        <Row>
                            <Col flex="auto">
                                <Droppable droppableId="agentNumber">
                                        {(provided) => (
                                            <Card size="small" title={<Text style={{color: '#fff'}}>AGENT<br />NUMBER</Text>} headStyle={{ backgroundColor: '#3D76DD', }} style={{borderRadius: "10px",overflow: "hidden"}}>
                                                <div {...provided.droppableProps}  ref={provided.innerRef} style={{minHeight: '100px', backgroundColor: this.state.droppableBackColor == "agentNumber" ? '#d2ecff' : '#fff'}}>
                                                    <div>
                                                        <Draggable key="ag" draggableId="ag" index={0}>
                                                            {(provided) => (
                                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                    {this.state.agentNumber != "" &&
                                                                    <div style={{textAlign: 'center', border: 'solid 1px #3D76DD', borderRadius: "10px", overflow: "hidden", height: '40px' }}>
                                                                        {this.state.agentNumber}
                                                                    </div>
                                                                    }
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    </div>
                                                    {provided.placeholder}
                                                </div>
                                            </Card>
                                        )}
                                </Droppable>
                            </Col>
                            <Col flex="auto">
                                <Droppable droppableId='policyNumber'>
                                    {(provided) => (
                                        <Card size="small" title={<Text style={{color: '#fff'}}>POLICY<br />NUMBER</Text>} headStyle={{ backgroundColor: '#3D76DD', color: '#fff'}} style={{borderRadius: "10px",overflow: "hidden"}}>
                                            <div {...provided.droppableProps}  ref={provided.innerRef} style={{minHeight: '100px', backgroundColor: this.state.droppableBackColor == "policyNumber" ? '#d2ecff' : '#fff'}}>
                                                <div>
                                                    <Draggable key="sp" draggableId="sp" index={1}>
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                {this.state.policyNumber != "" &&
                                                                <div style={{textAlign: 'center', border: 'solid 1px #3D76DD', borderRadius: "10px", overflow: "hidden", height: '40px' }}>
                                                                    {this.state.policyNumber}
                                                                </div>
                                                                }
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </div>
                                                {provided.placeholder}
                                            </div>
                                        </Card>
                                    )}
                                </Droppable>
                            </Col>
                            <Col flex="auto">
                                <Droppable droppableId='firstName'>
                                    {(provided) => (
                                        <Card size="small" title={<Text style={{color: '#fff'}}>FIRST<br /> NAME</Text>} headStyle={{ backgroundColor: '#3D76DD', color: '#fff'}} style={{borderRadius: "10px",overflow: "hidden"}}>
                                            <div {...provided.droppableProps}  ref={provided.innerRef} style={{minHeight: '100px', backgroundColor: this.state.droppableBackColor == "firstName" ? '#d2ecff' : '#fff'}}>
                                                <div>
                                                    <Draggable key="fn" draggableId="fn" index={2}>
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                {this.state.firstName != "" &&
                                                                <div style={{textAlign: 'center', border: 'solid 1px #3D76DD', borderRadius: "10px", overflow: "hidden", height: '40px' }}>
                                                                    {this.state.firstName}
                                                                </div>
                                                                }
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </div>
                                                {provided.placeholder}
                                            </div>
                                        </Card>
                                    )}
                                </Droppable>
                            </Col>
                            <Col flex="auto">
                                <Droppable droppableId='lastName'>
                                    {(provided) => (
                                        <Card size="small" title={<Text style={{color: '#fff'}}>LAST<br />NAME</Text>} headStyle={{ backgroundColor: '#3D76DD', color: '#fff'}} style={{borderRadius: "10px",overflow: "hidden"}}>
                                            <div {...provided.droppableProps}  ref={provided.innerRef} style={{minHeight: '100px', backgroundColor: this.state.droppableBackColor == "lastName" ? '#d2ecff' : '#fff'}}>
                                                <div>
                                                    <Draggable key="ln" draggableId="ln" index={3}>
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                {this.state.lastName != "" &&
                                                                <div style={{textAlign: 'center', border: 'solid 1px #3D76DD', borderRadius: "10px", overflow: "hidden", height: '40px' }}>
                                                                    {this.state.lastName}
                                                                </div>
                                                                }
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </div>
                                                {provided.placeholder}
                                            </div>
                                        </Card>
                                    )}
                                </Droppable>
                            </Col>
                            <Col flex="auto">
                                <Droppable droppableId='zipCode'>
                                    {(provided) => (
                                        <Card size="small" title={<Text style={{color: '#fff'}}>ZIP<br />CODE</Text>} headStyle={{ backgroundColor: '#3D76DD', color: '#fff'}} style={{borderRadius: "10px",overflow: "hidden"}}>
                                            <div {...provided.droppableProps}  ref={provided.innerRef} style={{minHeight: '100px', backgroundColor: this.state.droppableBackColor == "zipCode" ? '#d2ecff' : '#fff'}}>
                                                <div>
                                                    <Draggable key="zc" draggableId="zc" index={4}>
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                {this.state.zipCode != "" &&
                                                                <div style={{textAlign: 'center', border: 'solid 1px #3D76DD', borderRadius: "10px", overflow: "hidden", height: '40px' }}>
                                                                    {this.state.zipCode}
                                                                </div>
                                                                }
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </div>
                                                {provided.placeholder}
                                            </div>
                                        </Card>
                                    )}
                                </Droppable>
                            </Col>
                            <Col flex="auto">
                                <Droppable droppableId='terminationEffectiveDate'>
                                    {(provided) => (
                                        <Card size="small" title={<Text style={{color: '#fff'}}>TERMINATION<br />EFFECTIVE DATE</Text>} headStyle={{ backgroundColor: '#3D76DD', color: '#fff'}} style={{borderRadius: "10px",overflow: "hidden"}}>
                                            <div {...provided.droppableProps}  ref={provided.innerRef} style={{minHeight: '100px', backgroundColor: this.state.droppableBackColor == "terminationEffectiveDate" ? '#d2ecff' : '#fff'}}>
                                                <div>
                                                    <Draggable key="ted" draggableId="ted" index={5}>
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                {this.state.terminationEffectiveDate != "" &&
                                                                <div style={{textAlign: 'center', border: 'solid 1px #3D76DD', borderRadius: "10px", overflow: "hidden", height: '40px' }}>
                                                                    {this.state.terminationEffectiveDate}
                                                                </div>
                                                                }
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </div>
                                                {provided.placeholder}
                                            </div>
                                        </Card>
                                    )}
                                </Droppable>
                            </Col>
                            <Col flex="auto">
                                <Droppable droppableId='renewalEffectiveDate'>
                                    {(provided) => (
                                        <Card size="small" title={<Text style={{color: '#fff'}}>RENEWAL<br />EFFECTIVE DATE</Text>} headStyle={{ backgroundColor: '#3D76DD', color: '#fff'}} style={{borderRadius: "10px",overflow: "hidden"}}>
                                            <div {...provided.droppableProps}  ref={provided.innerRef} style={{minHeight: '100px', backgroundColor: this.state.droppableBackColor == "renewalEffectiveDate" ? '#d2ecff' : '#fff'}}>
                                                <div>
                                                    <Draggable key="red" draggableId="red" index={6}>
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                {this.state.renewalEffectiveDate != "" &&
                                                                <div style={{textAlign: 'center', border: 'solid 1px #3D76DD', borderRadius: "10px", overflow: "hidden", height: '40px' }}>
                                                                    {this.state.renewalEffectiveDate}
                                                                </div>
                                                                }
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </div>
                                                {provided.placeholder}
                                            </div>
                                        </Card>
                                    )}
                                </Droppable>
                            </Col>
                            <Col flex="auto">
                                <Droppable droppableId='anniversaryEffectiveDate'>
                                    {(provided) => (
                                        <Card size="small" title={<Text style={{color: '#fff'}}>ANNIVERSARY<br />EFFECTIVE DATE</Text>} headStyle={{ backgroundColor: '#3D76DD', color: '#fff'}} style={{borderRadius: "10px",overflow: "hidden"}}>
                                            <div {...provided.droppableProps}  ref={provided.innerRef} style={{minHeight: '100px', backgroundColor: this.state.droppableBackColor == "anniversaryEffectiveDate" ? '#d2ecff' : '#fff'}}>
                                                <div>
                                                    <Draggable key="aed" draggableId="aed" index={7}>
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                {this.state.anniversaryEffectiveDate != "" &&
                                                                <div style={{textAlign: 'center', border: 'solid 1px #3D76DD', borderRadius: "10px", overflow: "hidden", height: '40px' }}>
                                                                    {this.state.anniversaryEffectiveDate}
                                                                </div>
                                                                }
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </div>
                                                {provided.placeholder}
                                            </div>
                                        </Card>
                                    )}
                                </Droppable>
                            </Col>
                            <Col flex="auto">
                                <Droppable droppableId='productName'>
                                    {(provided) => (
                                        <Card size="small" title={<Text style={{color: '#fff'}}>PRODUCT<br />NAME</Text>} headStyle={{ backgroundColor: '#3D76DD', color: '#fff'}} style={{borderRadius: "10px",overflow: "hidden"}}>
                                            <div {...provided.droppableProps}  ref={provided.innerRef} style={{minHeight: '100px', backgroundColor: this.state.droppableBackColor == "productName" ? '#d2ecff' : '#fff'}}>
                                                <div>
                                                    <Draggable key="p" draggableId="p" index={8}>
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                {this.state.productName != "" &&
                                                                <div style={{textAlign: 'center', border: 'solid 1px #3D76DD', borderRadius: "10px", overflow: "hidden", height: '40px' }}>
                                                                    {this.state.productName}
                                                                </div>
                                                                }
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </div>
                                                {provided.placeholder}
                                            </div>
                                        </Card>
                                    )}
                                </Droppable>
                            </Col>
                            <Col flex="auto">
                                <Droppable droppableId='originalYear'>
                                    {(provided) => (
                                        <Card size="small" title={<Text style={{color: '#fff'}}>ORIGINAL<br />YEAR</Text>} headStyle={{ backgroundColor: '#3D76DD', color: '#fff'}} style={{borderRadius: "10px",overflow: "hidden"}}>
                                            <div {...provided.droppableProps}  ref={provided.innerRef} style={{minHeight: '100px', backgroundColor: this.state.droppableBackColor == "originalYear" ? '#d2ecff' : '#fff'}}>
                                                <div>
                                                    <Draggable key="oy" draggableId="oy" index={9}>
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                {this.state.originalYear != "" &&
                                                                <div style={{textAlign: 'center', border: 'solid 1px #3D76DD', borderRadius: "10px", overflow: "hidden", height: '40px' }}>
                                                                    {this.state.originalYear}
                                                                </div>
                                                                }
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </div>
                                                {provided.placeholder}
                                            </div>
                                        </Card>
                                    )}
                                </Droppable>
                            </Col>
                            <Col flex="auto">
                                <Droppable droppableId='terminationReason'>
                                    {(provided) => (
                                        <Card size="small" title={<Text style={{color: '#fff'}}>TERMINATION<br />REASON</Text>} headStyle={{ backgroundColor: '#3D76DD', color: '#fff'}} style={{borderRadius: "10px",overflow: "hidden"}}>
                                            <div {...provided.droppableProps}  ref={provided.innerRef} style={{minHeight: '100px', backgroundColor: this.state.droppableBackColor == "terminationReason" ? '#d2ecff' : '#fff'}}>
                                                <div>
                                                    <Draggable key="tr" draggableId="tr" index={10}>
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                {this.state.terminationReason != "" &&
                                                                <div style={{textAlign: 'center', border: 'solid 1px #3D76DD', borderRadius: "10px", overflow: "hidden", height: '40px' }}>
                                                                    {this.state.terminationReason}
                                                                </div>
                                                                }
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </div>
                                                {provided.placeholder}
                                            </div>
                                        </Card>
                                    )}
                                </Droppable>
                            </Col>
                            <Col flex="auto">
                                <Droppable droppableId='premiumNew'>
                                    {(provided) => (
                                        <Card size="small" title={<Text style={{color: '#fff'}}>PREMIUM<br />NEW</Text>} headStyle={{ backgroundColor: '#3D76DD', color: '#fff'}} style={{borderRadius: "10px",overflow: "hidden"}}>
                                            <div {...provided.droppableProps}  ref={provided.innerRef} style={{minHeight: '100px', backgroundColor: this.state.droppableBackColor == "premiumNew" ? '#d2ecff' : '#fff'}}>
                                                <div>
                                                    <Draggable key="pn" draggableId="pn" index={11}>
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                {this.state.premiumNew != "" &&
                                                                <div style={{textAlign: 'center', border: 'solid 1px #3D76DD', borderRadius: "10px", overflow: "hidden", height: '40px' }}>
                                                                    {this.state.premiumNew}
                                                                </div>
                                                                }
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </div>
                                                {provided.placeholder}
                                            </div>
                                        </Card>
                                    )}
                                </Droppable>
                            </Col>
                            <Col flex="auto">
                                <Droppable droppableId='premiumOld'>
                                    {(provided) => (
                                        <Card size="small" title={<Text style={{color: '#fff'}}>PREMIUM<br />OLD</Text>} headStyle={{ backgroundColor: '#3D76DD', color: '#fff'}} style={{borderRadius: "10px",overflow: "hidden"}}>
                                            <div {...provided.droppableProps}  ref={provided.innerRef} style={{minHeight: '100px', backgroundColor: this.state.droppableBackColor == "premiumOld" ? '#d2ecff' : '#fff'}}>
                                                <div>
                                                    <Draggable key="tr" draggableId="tr" index={12}>
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                {this.state.premiumOld != "" &&
                                                                <div style={{textAlign: 'center', border: 'solid 1px #3D76DD', borderRadius: "10px", overflow: "hidden", height: '40px' }}>
                                                                    {this.state.premiumOld}
                                                                </div>
                                                                }
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </div>
                                                {provided.placeholder}
                                            </div>
                                        </Card>
                                    )}
                                </Droppable>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </DragDropContext>
            <Row style={{paddingBottom: 20}}>
                <Col md={24}>
                    <Space>
                        <Button icon={<UploadOutlined />} type='primary' shape='round' onClick={this.handleUploadSave}>UPLOAD AND SAVE</Button>
                        <Button icon={<CloseOutlined />} type='danger' shape='round' onClick={() => this.setState({hideMonthSelection: false, fileName: '', uploadFileList: []})}>CANCEL</Button>
                    </Space>
                </Col>
            </Row>
            
            </div>
            
            }
            </div>
            <div style={{marginTop: 30}}>
                <TerminationUploadReport hideMonthSelection={true}  />
            </div>
        </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(TerminationUpload);