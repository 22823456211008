import React from 'react';
import { Modal, Button } from 'antd';

export const IdleTimeOutModal = ({showModal, handleClose, handleLogout, remainingTime}) => {

    return (

        <Modal
          title="Session Expire!"
          visible={showModal}
          onOk={handleClose}
          onCancel={handleLogout}
          footer={[
            <Button key="back" onClick={handleLogout}>
              Log Me Out
            </Button>,
            <Button key="submit" type="primary" onClick={handleClose}>
              Keep Me Signed In
            </Button>,
          ]}
        >
          <p>Your session is about to expire</p>
        </Modal>
    )
}
