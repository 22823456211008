import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Row, Col, List, Typography, Space, Table, InputNumber, DatePicker, Spin, Button } from 'antd';
import { Pie } from '@ant-design/plots';
import moment from 'moment';
const {Title} = Typography;

class VendorTypeSummaryChart extends Component {
    constructor(props) {
        super(props);
          this.state = {

          }
    }
    componentDidMount() {
        const agencyId = localStorage.getItem('agencyId');
        const dateFrom = moment(this.props.dateFrom).format("MM/DD/YYYY");
        const dateTo = moment(this.props.dateTo).format("MM/DD/YYYY");
        this.props.getQuoteLogVendorTypeSummary(agencyId, dateFrom, dateTo);
    }
    componentDidUpdate(prevProps) {
        if(this.props.dateFrom != prevProps.dateFrom){
            if(this.props.dateFrom != null){
                console.log(this.props.dateFrom);
                const agencyId = localStorage.getItem('agencyId');
                const dateFrom = moment(this.props.dateFrom).format("MM/DD/YYYY");
                const dateTo = moment(this.props.dateTo).format("MM/DD/YYYY");
                this.props.getQuoteLogVendorTypeSummary(agencyId, dateFrom, dateTo);
            }
        }
    }
    render() {
        const summaryVendorZipColumns = [
            {
                title: 'Vendor',
                dataIndex: 'vendor',
            },
            {
                title: 'Quoted Prospects',
                dataIndex: 'count',
                render: text => <span style={{textAlign: 'center'}}>{text}</span>,
            },
            {
                title: 'Written Households',
                dataIndex: 'writtenHouseholds',
            },
            {
                title: 'Close Ratio',
                dataIndex: 'closeRatio',
            },
        ];
        const data = this.props.vendorTypeSummary;
        const config = {
            appendPadding: 10,
            data,
            angleField: 'writtenItem',
            colorField: 'typeName',
            radius: 0.6,
            label: {
              type: 'outer',
              content: '{name} {percentage}',
            },
            interactions: [
              {
                type: 'pie-legend-active',
              },
              {
                type: 'element-active',
              },
            ],
        };
        return (
            <Row>
                <Col span={24}>
                    <Pie {...config} />
                </Col>
            </Row>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(VendorTypeSummaryChart);