import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Card, Input, Radio, Row, Col, List, Typography, Select, Button, Checkbox, Alert } from 'antd'
import { EditOutlined, SaveOutlined, DeleteOutlined } from '@ant-design/icons';
const { Title } = Typography;
const {Option} = Select;

class Vendors extends Component {
    constructor(props) {
      super(props);
        this.state = {
            id: 0,
            vendorName: '', 
            vendorType: '',
            vendorId: 0,
            spend: '0', 
            writtenPremium: '0', 
            writtenItems: '0',
            state: '',
            rate: '0',
            quoteRatio: '0',
            closeRatio: '0',
            checked: false,
            addedNew: false,
            inactive: false,
            inactiveFilter: 'Active',
            autoBundle: false
        }
        this.handleListClick = this.handleListClick.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleNumberChange = this.handleNumberChange.bind(this);
        this.handleNew = this.handleNew.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
    }
    
    componentDidMount() {
        const agencyId = localStorage.getItem('agencyId');
        this.props.getVendors(agencyId);
        this.props.getTypeVendorTypes(agencyId);
    }

    handleListClick = (vendor) => {
        this.setState({
            id: vendor.id,
            vendorName: vendor.name, 
            vendorType: String(vendor.vendorType), 
            spend: vendor.spend, 
            writtenPremium: vendor.writtenPremium, 
            writtenItems: vendor.writtenItems,
            state: vendor.state,
            rate: vendor.rate,
            quoteRatio: vendor.quoteRatio,
            closeRatio: vendor.closeRatio,
            vendorId: vendor.vendorId,
            inactive: vendor.inactive,
            autoBundle: vendor.autoBundle
        });
    }
    handleStateChange = (e) => {
        this.setState({state: e});
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handleVendorTypeChange = (e) => {
        this.setState({vendorType: e});
    }
    handleNumberChange = (e, name) => {
        this.setState({
            [name]: e
        })
    }
    handleCheckChange = () => {
        this.setState({inactive: !this.state.inactive});
    }
    handleSave(event) {
        event.preventDefault()
        const agencyId = localStorage.getItem('agencyId');
        const id = this.state.id;
        const vendorId = this.state.vendorId;
        const name = this.state.vendorName;
        const vendorType = this.state.vendorType;
        const writtenItems = 0;
        const writtenPremium = 0;
        const spend = 0;
        const state = '';
        const rate = 0;
        const quoteRatio = 0;
        const closeRatio = 0;
        const inactive = this.state.inactive;
        const autoBundle = this.state.autoBundle;
        this.props.createVendor(id, name, vendorType, writtenItems, writtenPremium, spend, state, rate, quoteRatio, closeRatio, agencyId, vendorId, inactive, autoBundle)
        this.setState({addedNew: true});
        this.setState({
            id: 0,
            vendorName: '', 
            vendorType: '', 
            spend: '0', 
            writtenPremium: '0', 
            writtenItems: '0',
            state: '',
            rate: '0',
            quoteRatio: '0',
            closeRatio: '0',
            inactive: false,
            vendorId: 0
        });
        //this.props.updateVendor(id, name, vendorType, writtenItems, writtenPremium, spend, state, rate, quoteRatio, closeRatio);
            
    }
    handleNew(e) {
        this.setState({
            id: 0,
            vendorName: '', 
            vendorType: '', 
            spend: '0', 
            writtenPremium: '0', 
            writtenItems: '0',
            state: '',
            rate: '0',
            quoteRatio: '0',
            closeRatio: '0'
        });
    }
    handleRadioChange(e) {
        this.setState({inactiveFilter: e.target.value});
    }
    handleCheckAutoBundleChange = () => {
        this.setState({autoBundle: !this.state.autoBundle});
    }
    render() {
        let stateList = require('../data/states.json');
        const agencyId = localStorage.getItem('agencyId');
        return (
            <Row>
                <Col span={8} style={{paddingRight: 10}}>
                    <List header={
                        <Row>
                            <Col span={8}>
                                <Title level={3}>Lead Source</Title>
                            </Col>
                            <Col span={8}>
                                <Radio.Group size="small" defaultValue="Active" buttonStyle="solid" onChange={this.handleRadioChange} value={this.state.inactiveFilter} style={{position: 'absolute', right: 0}}>
                                    <Radio.Button value="Active">Active</Radio.Button>
                                    <Radio.Button value="Inactive">Inactive</Radio.Button>
                                </Radio.Group>
                                {/* <Button onClick={this.handleNew} style={{ position: "absolute", right: 10 }}><EditOutlined />New</Button> */}
                            </Col>
                            <Col span={8}>
                                <Button shape='round' size='small' onClick={this.handleNew} style={{ position: "absolute", right: 10 }}><EditOutlined />New</Button>
                            </Col>
                        </Row>} itemLayout="horizontal" dataSource={this.state.inactiveFilter == 'Active' ? this.props.vendors.filter(v => v.inactive == false) : this.props.vendors.filter(v => v.inactive == true)} renderItem={item => (
                        <List.Item actions={[<a key="list-loadmore-edit" onClick={() => this.handleListClick(item)}>edit</a>]}>
                            <List.Item.Meta title={item.name} 
                                // description={<Checkbox onClick={() => this.handleCheckChange(item.id)} checked={item.selected}>Add To My Marketing Bucket</Checkbox>} 
                            />
                        </List.Item>
                    )} /> 
                </Col>
                <Col span={16}>
                    <Card title="ADD NEW LEAD SOURCE" id="divRegister" headStyle={{ backgroundColor: '#3D76DD', color: '#fff' }}>
                        <Row>
                            <Col span={12}>
                                <div>
                                    <label htmlFor="vendorName">Name</label><br />
                                    <Input placeholder="Lead Source Name" name="vendorName" onChange={e => this.handleChange(e)} value={this.state.vendorName} />
                                </div>
                                <div>
                                    <label htmlFor="vendorType">Marketing Bucket</label><br />
                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder="Please select"
                                        name="vendorType"
                                        value={this.state.vendorType}
                                        onChange={(e) => this.handleVendorTypeChange(e)}>
                                        {
                                            this.props.typeVendorTypes.filter(v => v.inactive == false).map(vendorType => 
                                                <Option key={vendorType.id}>{vendorType.name}</Option>
                                            )
                                        }
                                    </Select>     
                                </div>
                                <div style={{paddingTop: 10}}>
                                    <Checkbox onClick={() => this.handleCheckAutoBundleChange()} checked={this.state.autoBundle}>Set Lead Source as a Bundle</Checkbox>
                                </div>
                                <div>
                                    <Checkbox onClick={() => this.handleCheckChange()} checked={this.state.inactive}>Inactive</Checkbox>
                                </div>
                                {/* <div>
                                    <label htmlFor="state">State</label><br />
                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder="Please select"
                                        name="state"
                                        value={this.state.state}
                                        onChange={(e) => this.handleStateChange(e)}>
                                        {
                                            stateList.map(state => 
                                                <Option key={state.abbreviation}>{state.name}</Option>
                                            )
                                        }
                                    </Select>                    
                                </div>
                                <div>
                                    <label htmlFor="rate">Transfer/Response/Contact Rate</label><br />
                                    <InputNumber min={0} max={100} step={1} value={this.state.rate} onChange={e => this.handleNumberChange(e, 'rate')} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                                </div>
                                <div>
                                    <label htmlFor="quoteRatio">Quote Ratio</label><br />
                                    <InputNumber min={0} max={100} step={1} value={this.state.quoteRatio} onChange={e => this.handleNumberChange(e, 'quoteRatio')} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                                </div>
                                <div>
                                    <label htmlFor="closeRatio">Close Ratio</label><br />
                                    <InputNumber min={0} max={100} step={1} value={this.state.closeRatio} onChange={e => this.handleNumberChange(e, 'closeRatio')} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                                </div>
                                <div>
                                    <label htmlFor="spend">Spend</label><br />
                                    <InputNumber min={0} max={1000000} step={1} value={this.state.spend} onChange={e => this.handleNumberChange(e, 'spend')} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                                </div>
                                <div>
                                    <label htmlFor="writtenPremium">Written Premium</label><br />
                                    <InputNumber min={0} max={1000000} step={1} value={this.state.writtenPremium} onChange={e => this.handleNumberChange(e, 'writtenPremium')} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                                </div>
                                <div>
                                    <label htmlFor="writtenItems">Written Items</label><br />
                                    <InputNumber min={0} max={1000000} step={1} value={this.state.writtenItems} onChange={e => this.handleNumberChange(e, 'writtenItems')}  />
                                </div>
                                <div>
                                    <label>Item Acquisition Cost</label><br />
                                    {new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(this.state.spend / this.state.writtenItems)}
                                </div>
                                <div>
                                    <label>Avg Premium Per Item</label><br />
                                    {new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(this.state.writtenPremium / this.state.writtenItems)}
                                </div> */}
                                
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <div>
                                    <Button shape="round" type="primary" onClick={this.handleSave} style={{ marginTop: 16 }}>
                                        <SaveOutlined />{this.state.id == '' ? 'Submit' : 'Update'}
                                    </Button>
                                    {
                                        (this.state.addedNew == true) &&
                                        <Alert type="success" message="Lead Source has been saved" />
                                    }
                                    {/* {
                                        (this.state.id != '') && 
                                            <Button type="danger" onClick={this.handleDelete} style={{ marginTop: 16, position: "absolute", right: 0 }} >
                                                <Icon type="delete" />Delete
                                            </Button>
                                    } */}
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Vendors);