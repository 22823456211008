import React, { Component } from 'react';
import { Collapse, Row, Col, Card, Slider, InputNumber, Typography } from 'antd'
const { Title } = Typography;
const { Panel } = Collapse;

class CompPlanMonthly extends Component {
    constructor(props) {
      super(props);
        this.state = {

        }
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange = (e, name) => {
        this.setState({
            [name]: e
        })
    }
    render() {
        return(
            <div>
            <Row style={{paddingTop: "10px" }}>
                <Col md={12} style={{textAlign: "right"}}>
                    LSP BASE MONTHLY SALARY (ADD TAXES)
                </Col>
                <Col md={12}>
                    <InputNumber min={1} max={10000} step={1} style={{ marginLeft: 16 }} value={this.state.lspBaseMonthlySalary} onChange={e => this.handleChange(e, "lspBaseMonthlySalary")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                </Col>
            </Row>
            <Row style={{paddingTop: "10px" }}>
                <Col md={12} style={{textAlign: "right"}}>
                    MARKETING SPEND
                </Col>
                <Col md={12}>
                    <InputNumber min={1} max={10000} step={1} style={{ marginLeft: 16 }} value={this.state.marketingSpend} onChange={e => this.handleChange(e, "marketingSpend")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                </Col>
            </Row>
            <Row style={{paddingTop: "10px" }}>
                <Col md={12} style={{textAlign: "right"}}>
                    MONTHLY OVERHEAD
                </Col>
                <Col md={12}>
                    <InputNumber min={1} max={10000} step={1} style={{ marginLeft: 16 }} value={this.state.monthlyOverhead} onChange={e => this.handleChange(e, "monthlyOverhead")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                </Col>
            </Row>
            <Row style={{paddingTop: "10px" }}>
                <Col md={12} style={{textAlign: "right"}}>
                    SALES AGENT COMP ON NEW BUSINESS
                </Col>
                <Col md={12}>
                    <InputNumber min={1} max={100} step={1} style={{ marginLeft: 16 }} value={this.state.salesAgentComp} onChange={e => this.handleChange(e, "salesAgentComp")} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                </Col>
            </Row>
            <Row style={{paddingTop: "10px" }}>
                <Col md={12} style={{textAlign: "right"}}>
                    LINE 10 RENEWALS % OF PREMIUM
                </Col>
                <Col md={12}>
                    <InputNumber min={1} max={100} step={1} style={{ marginLeft: 16 }} value={this.state.line10Renewals} onChange={e => this.handleChange(e, "line10Renewals")} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                </Col>
            </Row>
            <Row style={{paddingTop: "10px" }}>
                <Col md={12} style={{textAlign: "right"}}>
                    12 MONTH POLICY RENEWAL % OF PREMIUM
                </Col>
                <Col md={12}>
                    <InputNumber min={1} max={100} step={1} style={{ marginLeft: 16 }} value={this.state.monthPolicyRenewal} onChange={e => this.handleChange(e, "monthPolicyRenewal")} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                </Col>
            </Row>
            <Row style={{paddingTop: "10px" }}>
                <Col md={12} style={{textAlign: "right"}}>
                    POLICY RETENTION
                </Col>
                <Col md={12}>
                    <InputNumber min={1} max={10000} step={1} style={{ marginLeft: 16 }} value={this.state.policyRetention} onChange={e => this.handleChange(e, "policyRetention")} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                </Col>
            </Row>
            <Row style={{paddingTop: "10px" }}>
                <Col md={12} style={{textAlign: "right"}}>
                    BASE COMP
                </Col>
                <Col md={12}>
                    <InputNumber min={1} max={10000} step={1} style={{ marginLeft: 16 }} value={this.state.baseComp} onChange={e => this.handleChange(e, "baseComp")} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                </Col>
            </Row>
            <Row style={{paddingTop: "10px" }}>
                <Col md={12} style={{textAlign: "right"}}>
                    6 MONTH POLICIES ENHANCED COMP
                </Col>
                <Col md={12}>
                    <InputNumber min={1} max={100} step={1} style={{ marginLeft: 16 }} value={this.state.monthPolicyEnhanced} onChange={e => this.handleChange(e, "monthPolicyEnhanced")} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                </Col>
            </Row>
            <Row style={{paddingTop: "10px" }}>
                <Col md={12} style={{textAlign: "right"}}>
                    12 MONTH POLICIES ENHANCED COMP
                </Col>
                <Col md={12}>
                    <InputNumber min={1} max={100} step={1} style={{ marginLeft: 16 }} value={this.state.monthPolicyEnhanced12} onChange={e => this.handleChange(e, "monthPolicyEnhanced12")} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                </Col>
            </Row>
            <Row style={{paddingTop: "10px" }}>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Employee Count	
                            </th>
                            <th>
                                PREMIUM PER AGENT		
                            </th>
                            <th>
                                PREMIUM GOAL		
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <InputNumber min={1} max={100} step={1} style={{ margin: 10 }} value={this.state.employeeCount} onChange={e => this.handleChange(e, "employeeCount")} />
                            </td>
                            <td>
                                <InputNumber min={1} max={100} step={1} style={{ margin: 10 }} value={this.state.premiumPerAgent} onChange={e => this.handleChange(e, "premiumPerAgent")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </td>
                            <td>
                            {new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.employeeCount * this.state.premiumPerAgent)}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Row>
            <Row style={{paddingTop: "10px" }}>
                <table style={{width: '100%'}}>
                    <thead>
                        <tr>
                            <th>Months 1 Through 12</th>
                            <th>1</th>
                            <th>2</th>
                            <th>3</th>
                            <th>4</th>
                            <th>5</th>
                            <th>6</th>
                            <th>7</th>
                            <th>8</th>
                            <th>9</th>
                            <th>10</th>
                            <th>11</th>
                            <th>12</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{backgroundColor: '#b7eb8f'}}>Monthly Renewal Premium</td>
                            <td>
                                <InputNumber min={1} max={10000} step={1} style={{ margin: 10 }} value={this.state.monthlyPremium1} onChange={e => this.handleChange(e, "monthlyPremium1")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </td>
                            <td>
                                <InputNumber min={1} max={10000} step={1} style={{ margin: 10 }} value={this.state.monthlyPremium2} onChange={e => this.handleChange(e, "monthlyPremium2")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </td>
                            <td>
                                <InputNumber min={1} max={10000} step={1} style={{ margin: 10 }} value={this.state.monthlyPremium3} onChange={e => this.handleChange(e, "monthlyPremium3")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </td>
                            <td>
                                <InputNumber min={1} max={10000} step={1} style={{ margin: 10 }} value={this.state.monthlyPremium4} onChange={e => this.handleChange(e, "monthlyPremium4")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </td>
                            <td>
                                <InputNumber min={1} max={10000} step={1} style={{ margin: 10 }} value={this.state.monthlyPremium5} onChange={e => this.handleChange(e, "monthlyPremium5")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </td>
                            <td>
                                <InputNumber min={1} max={10000} step={1} style={{ margin: 10 }} value={this.state.monthlyPremium6} onChange={e => this.handleChange(e, "monthlyPremium6")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </td>
                            <td>
                                <InputNumber min={1} max={10000} step={1} style={{ margin: 10 }} value={this.state.monthlyPremium7} onChange={e => this.handleChange(e, "monthlyPremium7")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </td>
                            <td>
                                <InputNumber min={1} max={10000} step={1} style={{ margin: 10 }} value={this.state.monthlyPremium8} onChange={e => this.handleChange(e, "monthlyPremium8")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </td>
                            <td>
                                <InputNumber min={1} max={10000} step={1} style={{ margin: 10 }} value={this.state.monthlyPremium9} onChange={e => this.handleChange(e, "monthlyPremium9")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </td>
                            <td>
                                <InputNumber min={1} max={10000} step={1} style={{ margin: 10 }} value={this.state.monthlyPremium10} onChange={e => this.handleChange(e, "monthlyPremium10")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </td>
                            <td>
                                <InputNumber min={1} max={10000} step={1} style={{ margin: 10 }} value={this.state.monthlyPremium11} onChange={e => this.handleChange(e, "monthlyPremium11")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </td>
                            <td>
                                <InputNumber min={1} max={10000} step={1} style={{ margin: 10 }} value={this.state.monthlyPremium12} onChange={e => this.handleChange(e, "monthlyPremium12")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor: '#ffd591'}}>New Business Premium</td>
                            <td>
                                <InputNumber min={1} max={10000} step={1} style={{ margin: 10 }} value={this.state.newBusinessPremium1} onChange={e => this.handleChange(e, "newBusinessPremium1")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </td>
                            <td>
                                <InputNumber min={1} max={10000} step={1} style={{ margin: 10 }} value={this.state.newBusinessPremium2} onChange={e => this.handleChange(e, "newBusinessPremium2")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </td>
                            <td>
                                <InputNumber min={1} max={10000} step={1} style={{ margin: 10 }} value={this.state.newBusinessPremium3} onChange={e => this.handleChange(e, "newBusinessPremium3")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </td>
                            <td>
                                <InputNumber min={1} max={10000} step={1} style={{ margin: 10 }} value={this.state.newBusinessPremium4} onChange={e => this.handleChange(e, "newBusinessPremium4")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </td>
                            <td>
                                <InputNumber min={1} max={10000} step={1} style={{ margin: 10 }} value={this.state.newBusinessPremium5} onChange={e => this.handleChange(e, "newBusinessPremium5")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </td>
                            <td>
                                <InputNumber min={1} max={10000} step={1} style={{ margin: 10 }} value={this.state.newBusinessPremium6} onChange={e => this.handleChange(e, "newBusinessPremium6")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </td>
                            <td>
                                <InputNumber min={1} max={10000} step={1} style={{ margin: 10 }} value={this.state.newBusinessPremium7} onChange={e => this.handleChange(e, "newBusinessPremium7")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </td>
                            <td>
                                <InputNumber min={1} max={10000} step={1} style={{ margin: 10 }} value={this.state.newBusinessPremium8} onChange={e => this.handleChange(e, "newBusinessPremium8")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </td>
                            <td>
                                <InputNumber min={1} max={10000} step={1} style={{ margin: 10 }} value={this.state.newBusinessPremium9} onChange={e => this.handleChange(e, "newBusinessPremium9")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </td>
                            <td>
                                <InputNumber min={1} max={10000} step={1} style={{ margin: 10 }} value={this.state.newBusinessPremium10} onChange={e => this.handleChange(e, "newBusinessPremium10")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </td>
                            <td>
                                <InputNumber min={1} max={10000} step={1} style={{ margin: 10 }} value={this.state.newBusinessPremium11} onChange={e => this.handleChange(e, "newBusinessPremium11")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </td>
                            <td>
                                <InputNumber min={1} max={10000} step={1} style={{ margin: 10 }} value={this.state.newBusinessPremium12} onChange={e => this.handleChange(e, "newBusinessPremium12")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor: '#ffd591'}}>New Business Enhanced Comp 6 MONTH POLICIES</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format((this.state.newBusinessPremium1 * (this.state.line10Renewals / 100)) * (this.state.monthPolicyEnhanced / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format((this.state.newBusinessPremium2 * (this.state.line10Renewals / 100)) * (this.state.monthPolicyEnhanced / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format((this.state.newBusinessPremium3 * (this.state.line10Renewals / 100)) * (this.state.monthPolicyEnhanced / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format((this.state.newBusinessPremium4 * (this.state.line10Renewals / 100)) * (this.state.monthPolicyEnhanced / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format((this.state.newBusinessPremium5 * (this.state.line10Renewals / 100)) * (this.state.monthPolicyEnhanced / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format((this.state.newBusinessPremium6 * (this.state.line10Renewals / 100)) * (this.state.monthPolicyEnhanced / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format((this.state.newBusinessPremium7 * (this.state.line10Renewals / 100)) * (this.state.monthPolicyEnhanced / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format((this.state.newBusinessPremium8 * (this.state.line10Renewals / 100)) * (this.state.monthPolicyEnhanced / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format((this.state.newBusinessPremium9 * (this.state.line10Renewals / 100)) * (this.state.monthPolicyEnhanced / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format((this.state.newBusinessPremium10 * (this.state.line10Renewals / 100)) * (this.state.monthPolicyEnhanced / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format((this.state.newBusinessPremium11 * (this.state.line10Renewals / 100)) * (this.state.monthPolicyEnhanced / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format((this.state.newBusinessPremium12 * (this.state.line10Renewals / 100)) * (this.state.monthPolicyEnhanced / 100))}
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor: '#ffd591'}}>New Business Enhance comp 12 MONTH POLICIES</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format((this.state.newBusinessPremium1 * (this.state.monthPolicyRenewal / 100)) * (this.state.monthPolicyEnhanced12 / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format((this.state.newBusinessPremium2 * (this.state.monthPolicyRenewal / 100)) * (this.state.monthPolicyEnhanced12 / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format((this.state.newBusinessPremium3 * (this.state.monthPolicyRenewal / 100)) * (this.state.monthPolicyEnhanced12 / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format((this.state.newBusinessPremium4 * (this.state.monthPolicyRenewal / 100)) * (this.state.monthPolicyEnhanced12 / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format((this.state.newBusinessPremium5 * (this.state.monthPolicyRenewal / 100)) * (this.state.monthPolicyEnhanced12 / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format((this.state.newBusinessPremium6 * (this.state.monthPolicyRenewal / 100)) * (this.state.monthPolicyEnhanced12 / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format((this.state.newBusinessPremium7 * (this.state.monthPolicyRenewal / 100)) * (this.state.monthPolicyEnhanced12 / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format((this.state.newBusinessPremium8 * (this.state.monthPolicyRenewal / 100)) * (this.state.monthPolicyEnhanced12 / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format((this.state.newBusinessPremium9 * (this.state.monthPolicyRenewal / 100)) * (this.state.monthPolicyEnhanced12 / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format((this.state.newBusinessPremium10 * (this.state.monthPolicyRenewal / 100)) * (this.state.monthPolicyEnhanced12 / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format((this.state.newBusinessPremium11 * (this.state.monthPolicyRenewal / 100)) * (this.state.monthPolicyEnhanced12 / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format((this.state.newBusinessPremium12 * (this.state.monthPolicyRenewal / 100)) * (this.state.monthPolicyEnhanced12 / 100))}
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor: '#d9d9d9'}}>BASE Comp</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.monthlyPremium1 * (this.state.baseComp / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.monthlyPremium2 * (this.state.baseComp / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.monthlyPremium3 * (this.state.baseComp / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.monthlyPremium4 * (this.state.baseComp / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.monthlyPremium5 * (this.state.baseComp / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.monthlyPremium6 * (this.state.baseComp / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.monthlyPremium7 * (this.state.baseComp / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.monthlyPremium8 * (this.state.baseComp / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.monthlyPremium9 * (this.state.baseComp / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.monthlyPremium10 * (this.state.baseComp / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.monthlyPremium11 * (this.state.baseComp / 100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.monthlyPremium12 * (this.state.baseComp / 100))}
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor: '#bae7ff'}}>BASE Comp (New Business) 6 Month Policy Renewal</td>
                            <td style={{textAlign: "center"}}></td>
                            <td style={{textAlign: "center"}}></td>
                            <td style={{textAlign: "center"}}></td>
                            <td style={{textAlign: "center"}}></td>
                            <td style={{textAlign: "center"}}></td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(((this.state.newBusinessPremium1*(this.state.line10Renewals/100))-((1-(this.state.policyRetention/100))/5*this.state.monthlyPremium1))*(this.state.baseComp/100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(((this.state.newBusinessPremium2*(this.state.line10Renewals/100))-((1-(this.state.policyRetention/100))/5*this.state.newBusinessPremium2))*(this.state.baseComp/100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(((this.state.newBusinessPremium3*(this.state.line10Renewals/100))-((1-(this.state.policyRetention/100))/5*this.state.newBusinessPremium3))*(this.state.baseComp/100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(((this.state.newBusinessPremium4*(this.state.line10Renewals/100))-((1-(this.state.policyRetention/100))/5*this.state.newBusinessPremium4))*(this.state.baseComp/100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(((this.state.newBusinessPremium5*(this.state.line10Renewals/100))-((1-(this.state.policyRetention/100))/5*this.state.newBusinessPremium5))*(this.state.baseComp/100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(((this.state.newBusinessPremium6*(this.state.line10Renewals/100))-((1-(this.state.policyRetention/100))/5*this.state.newBusinessPremium6))*(this.state.baseComp/100))}
                            </td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(((this.state.newBusinessPremium7*(this.state.line10Renewals/100))-((1-(this.state.policyRetention/100))/5*this.state.newBusinessPremium7))*(this.state.baseComp/100)
                                +
                                ((this.state.monthlyPremium1*(this.state.line10Renewals/100))-((1-(this.state.policyRetention/100))*this.state.monthlyPremium1))*(this.state.baseComp/100))}
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor: '#bae7ff'}}>BASE Comp (New Business) 12 Month Policy Renewal</td>
                            <td style={{textAlign: "center"}}></td>
                            <td style={{textAlign: "center"}}></td>
                            <td style={{textAlign: "center"}}></td>
                            <td style={{textAlign: "center"}}></td>
                            <td style={{textAlign: "center"}}></td>
                            <td style={{textAlign: "center"}}></td>
                            <td style={{textAlign: "center"}}></td>
                            <td style={{textAlign: "center"}}></td>
                            <td style={{textAlign: "center"}}></td>
                            <td style={{textAlign: "center"}}></td>
                            <td style={{textAlign: "center"}}></td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(((this.state.newBusinessPremium1*(this.state.monthPolicyRenewal/100))-((1-(this.state.policyRetention/100))/5*this.state.newBusinessPremium7))*(this.state.baseComp/100))}
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor: '#95de64'}}>Sales Agents: Monthly Base Salary</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.lspBaseMonthlySalary * this.state.employeeCount)}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.lspBaseMonthlySalary * this.state.employeeCount)}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.lspBaseMonthlySalary * this.state.employeeCount)}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.lspBaseMonthlySalary * this.state.employeeCount)}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.lspBaseMonthlySalary * this.state.employeeCount)}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.lspBaseMonthlySalary * this.state.employeeCount)}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.lspBaseMonthlySalary * this.state.employeeCount)}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.lspBaseMonthlySalary * this.state.employeeCount)}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.lspBaseMonthlySalary * this.state.employeeCount)}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.lspBaseMonthlySalary * this.state.employeeCount)}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.lspBaseMonthlySalary * this.state.employeeCount)}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.lspBaseMonthlySalary * this.state.employeeCount)}
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor: '#95de64'}}>Sales Agents: Monthly Commission</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.newBusinessPremium1 * (this.state.salesAgentComp / 100))}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.newBusinessPremium2 * (this.state.salesAgentComp / 100))}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.newBusinessPremium3 * (this.state.salesAgentComp / 100))}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.newBusinessPremium4 * (this.state.salesAgentComp / 100))}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.newBusinessPremium5 * (this.state.salesAgentComp / 100))}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.newBusinessPremium6 * (this.state.salesAgentComp / 100))}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.newBusinessPremium7 * (this.state.salesAgentComp / 100))}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.newBusinessPremium8 * (this.state.salesAgentComp / 100))}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.newBusinessPremium9 * (this.state.salesAgentComp / 100))}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.newBusinessPremium10 * (this.state.salesAgentComp / 100))}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.newBusinessPremium11 * (this.state.salesAgentComp / 100))}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.newBusinessPremium12 * (this.state.salesAgentComp / 100))}
                            </td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor: '#d9f7be'}}>NET PROFIT ECP ONLY</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.newBusinessPremium1 * (this.state.salesAgentComp / 100))}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.newBusinessPremium2 * (this.state.salesAgentComp / 100))}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.newBusinessPremium3 * (this.state.salesAgentComp / 100))}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.newBusinessPremium4 * (this.state.salesAgentComp / 100))}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.newBusinessPremium5 * (this.state.salesAgentComp / 100))}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.newBusinessPremium6 * (this.state.salesAgentComp / 100))}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.newBusinessPremium7 * (this.state.salesAgentComp / 100))}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.newBusinessPremium8 * (this.state.salesAgentComp / 100))}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.newBusinessPremium9 * (this.state.salesAgentComp / 100))}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.newBusinessPremium10 * (this.state.salesAgentComp / 100))}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.newBusinessPremium11 * (this.state.salesAgentComp / 100))}</td>
                            <td style={{textAlign: "center"}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(this.state.newBusinessPremium12 * (this.state.salesAgentComp / 100))}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Row>
            </div>
        );
    }
}
export default CompPlanMonthly;