import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import * as Actions from './humbleActions';

export default function configureStore(history, initialState) {
    const reducers = {
      action: Actions.reducer,
      //home: Home.reducer,
      //product: Product.reducer
    };
  
    const middleware = [
      thunk,
      routerMiddleware(history)
    ];
  
    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
      enhancers.push(window.devToolsExtension());
    }
  
    const rootReducer = combineReducers({
      ...reducers,
      routing: routerReducer
    });
  
    return createStore(
      rootReducer,
      initialState,
      compose(applyMiddleware(...middleware), ...enhancers)
    );
  }