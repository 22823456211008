import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Card, Input, Row, Col, List, Typography, Select, Button, Checkbox, Radio } from 'antd';
import { DeleteOutlined, SaveOutlined, EditOutlined } from '@ant-design/icons';
const {Title, Text} = Typography
const {Option} = Select

class VendorType extends Component {
    constructor(props) {
      super(props);
        this.state = {
            id: 0,
            agencyId: this.props.agencyId,
            name: '',
            inactive: false,
            inactiveFilter: 'Active',
            autoBundle: false
        }
        this.handleSave = this.handleSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleListClick = this.handleListClick.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleNew = this.handleNew.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.handleCheckAutoBundleChange = this.handleCheckAutoBundleChange.bind(this);
    }
    componentDidMount() {
        const agencyId = localStorage.getItem('agencyId');
        this.props.getAgent(agencyId);
        this.props.getTypeVendorTypes(agencyId);
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    
    handleSave(event) {
        event.preventDefault();
        const id = this.state.id;
        const name = this.state.name;
        const agencyId = localStorage.getItem('agencyId');
        const inactive = this.state.inactive;
        const autoBundle = this.state.autoBundle;
        this.props.createTypeVendorType(id, name, agencyId, inactive, autoBundle);

        this.setState({
            id: 0,
            name: '',
            inactive: false,
            autoBundle: false
        })
    }
    handleDelete(event) {
        event.preventDefault();
        const id = this.state.id;
 
        this.props.deleteVendorType(id);

        this.setState({
            id: 0,
            name: ''
        })
    }
    handleListClick = (vendorType) => {
        this.setState({
            id: vendorType.id,
            name: vendorType.name,
            inactive: vendorType.inactive,
            autoBundle: vendorType.autoBundle
        });
    }
    handleNew(e) {
        this.setState({
            id: 0,
            name: '',
            inactive: false
        });
    }
    handleCheckChange = () => {
        this.setState({inactive: !this.state.inactive});
    }
    handleCheckAutoBundleChange = () => {
        this.setState({autoBundle: !this.state.autoBundle});
    }
    handleRadioChange(e) {
        this.setState({inactiveFilter: e.target.value});
    }
    render() {
        return (
            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={8} style={{paddingLeft: 16}}>
                            <List header={
                                <Row>
                                    <Col md={8}><Title level={4}>Marketing Bucket</Title></Col>
                                    <Col md={8}>
                                        <Radio.Group size="small" defaultValue="Active" buttonStyle="solid" onChange={this.handleRadioChange} value={this.state.inactiveFilter}>
                                            <Radio.Button value="Active">Active</Radio.Button>
                                            <Radio.Button value="Inactive">Inactive</Radio.Button>
                                        </Radio.Group>
                                    </Col>
                                    <Col md={8}><Button shape='round' size='small' onClick={this.handleNew} style={{ position: "absolute", right: 10 }}><EditOutlined />New</Button></Col>
                                </Row>
                                } itemLayout="horizontal" dataSource={this.state.inactiveFilter == 'Active' ? this.props.typeVendorTypes.filter(v => v.inactive == false) : this.props.typeVendorTypes.filter(v => v.inactive == true)} renderItem={item => (
                                <List.Item actions={[<a key="list-loadmore-edit" onClick={() => this.handleListClick(item)}>edit</a>]}>
                                    <List.Item.Meta 
                                        title={item.name}
                                    />
                                </List.Item>
                            )} /> 
                        </Col>
                        <Col span={16} style={{paddingLeft: 16}}>
                            <Card title="ADD NEW MARKETING BUCKET" id="divVendorType" headStyle={{ backgroundColor: '#3D76DD', color: '#fff' }}>
                                <Row>
                                    <Col md={12}>
                                        <div>
                                            <label htmlFor="dts">Name</label><br />
                                            <Input value={this.state.name} name='name' onChange={e => this.handleChange(e)}  />
                                        </div>
                                        <div style={{paddingTop: 10}}>
                                            <Checkbox onClick={() => this.handleCheckChange()} checked={this.state.inactive}>Inactive</Checkbox>
                                        </div>
                                        <div style={{paddingTop: 10, display: 'none'}}>
                                            <Checkbox onClick={() => this.handleCheckAutoBundleChange()} checked={this.state.autoBundle}>Set Lead Category as a Bundle</Checkbox>
                                        </div>
                                        <div>
                                            <Button shape="round" type="primary" onClick={this.handleSave} style={{ marginTop: 16 }}>
                                                <SaveOutlined /> {this.state.id == '' ? 'Submit' : 'Update'}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(VendorType);