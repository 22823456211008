import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Row, Col, InputNumber, Typography, Select, Button, List, Input, Cascader, DatePicker, Radio } from 'antd'
import { SaveOutlined } from '@ant-design/icons';
import moment from 'moment'
const { Title, Text } = Typography;
const {Option} = Select;


class DataLead extends Component {
    constructor(props) {
      super(props);
        this.state = {
            dataLeadMonthlyCount: 0,
            dataLeadTransferRatio: 0,
            dataLeadQuoteRatio: 0,
            actualDataLeadMonthlyCount: 0,
            actualDataLeadTransferRatio: 0,
            actualDataLeadQuoteRatio: 0,
            dataLeadCloseRatio: 0,
            dataLeadPerHousehold: 0,
            dataLeadTransferRatioCalc: 0,
            dataLeadQuoteRatioCalc: 0,
            dataLeadCloseRatioCalc: 0,
            dataLeadTotalPremium: 0,
            dataLeadCampaignCount: 0,
            dataLeadMonthlyCost: 0,
            dataLeadMonthlyCostCalc: 0,
            dataLeadCostPerItems: 0,
            name: '',
            selectedLeadSourceId: '',
            monthDts: moment().format("MMM YYYY"),
            leadSourceFilter: 'Active',
        };
        this.handleSave = this.handleSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
    }
    componentDidMount() {
        const userId = localStorage.getItem('userId');
        const agencyId = localStorage.getItem('agencyId');
        this.props.getTypeVendorTypeLeadSources(agencyId);
        this.props.getDataLead(agencyId, moment().format("MMM YYYY"));
        this.props.getLeadSource(agencyId);
    }
    componentDidUpdate(prevProps){
        if(prevProps.dataLead != this.props.dataLead){
            this.props.onDataLeadTotal();
        }
    }
    handleVendorChange = (e) => {
        var vendor = this.props.vendors.find(v => v.id == e)
        const dataLeadTransferRatio = vendor.rate;
        const dataLeadCloseRatio = vendor.closeRatio;
        const dataLeadQuoteRatio = vendor.quoteRatio;
        const selectedVendorId = vendor.id;
        this.setState({
            vendor: e,
            selectedVendorId: selectedVendorId,
            dataLeadTransferRatio: dataLeadTransferRatio,
            dataLeadCloseRatio: dataLeadCloseRatio,
            dataLeadQuoteRatio: dataLeadQuoteRatio
        });
    }
    onDataLeadMonthlyCountChange = value => {
        this.setState({
            dataLeadMonthlyCount: value
        })
    }
    onDataLeadTransferRatioChange = value => {
        this.setState({
            dataLeadTransferRatio: value
        });
    }
    onDataLeadQuoteRatioChange = value => {
        this.setState({
            dataLeadQuoteRatio: value
        });
    }
    onActualDataLeadMonthlyCountChange = value => {
        this.setState({
            actualDataLeadMonthlyCount: value
        })
    }
    onActualDataLeadTransferRatioChange = value => {
        this.setState({
            actualDataLeadTransferRatio: value
        });
    }
    onActualDataLeadQuoteRatioChange = value => {
        this.setState({
            actualDataLeadQuoteRatio: value
        });
    }
    onDataLeadCloseRatioChange = value => {
        this.setState({
            dataLeadCloseRatio: value
        });
    }
    onDataLeadCampaignCountChange = value => {
        this.setState({
            dataLeadCampaignCount: value
        });
    }
    onDataLeadMonthlyCostChange = value => {
        this.setState({
            dataLeadMonthlyCost: value
        });
    }
    onActualDataLeadMonthlyCostChange = value => {
        this.setState({
            actualDataLeadMonthlyCost: value
        });
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    onMonthChange(date, dateString) {
        const agencyId = localStorage.getItem("agencyId");
        if(dateString != ''){
            const dateTo = moment(date).endOf('month').format("MM/DD/YYYY");
            const dateFrom = moment(date).startOf('month').format("MM/DD/YYYY");
            this.props.getVendorSummary(agencyId, dateFrom, dateTo, '');
            this.setState({
                monthDts: dateString
            });
            this.props.getDataLead(agencyId, moment(date).format("MMM YYYY"));
        }
        else{
            this.setState({
                monthDts: moment().format("MMM YYYY")
            });
        } 
    };
    handleListClick = (dataLead) => {
        var leadSource = this.props.vendorSummary.filter(v => v.vendor == dataLead.typeQuoteLogLeadSourceId);
        var leadSourceType = this.props.typeVendorTypesLeadSources.filter(c => c.label == leadSource[0].vendorType);
        var selectedValues = dataLead.typeQuoteLogLeadSourceId;
        this.setState({
            id: dataLead.id,
            name: dataLead.name,
            selectedValues: selectedValues,
            selectedLeadSourceId: dataLead.typeQuoteLogLeadSourceId,
            vendor: String(dataLead.vendorId),
            dataLeadMonthlyCount: dataLead.monthlyCount, 
            dataLeadTransferRatio: dataLead.transferRatio, 
            dataLeadQuoteRatio: dataLead.quoteRatio, 
            dataLeadCloseRatio: dataLead.closeRatio, 
            dataLeadCampaignCount: dataLead.leadCount, 
            dataLeadMonthlyCost: dataLead.monthlyCost,
            actualDataLeadMonthlyCount: dataLead.actualMonthlyCount, 
            actualDataLeadTransferRatio: dataLead.actualTransferRatio, 
            actualDataLeadQuoteRatio: dataLead.actualQuoteRatio,
            actualDataLeadMonthlyCost: dataLead.actualMonthlyCost
        });
    }
    handleSave(e) {
        e.preventDefault()
        const userId = localStorage.getItem("userId");
        const agencyId = localStorage.getItem("agencyId");
        const typeQuoteLogLeadSourceId = this.state.selectedLeadSourceId;
        const vendorId = 0;
        const name = this.state.name;
        const monthlyCount = this.state.dataLeadMonthlyCount;
        const transferRate = this.state.dataLeadTransferRatio;
        const quoteRatio = this.state.dataLeadQuoteRatio;
        const closeRatio = this.state.dataLeadCloseRatio;
        const dataLeadCampaignCount = this.state.dataLeadCampaignCount;
        const dataLeadMonthlyCost = this.state.dataLeadMonthlyCost;
        const premiumPerItem = this.props.premiumPerItem;

        const actualMonthlyCount = this.state.actualDataLeadMonthlyCount;
        const actualTransferRate = this.state.actualDataLeadTransferRatio;
        const actualQuoteRatio = this.state.actualDataLeadQuoteRatio;
        const actualDataLeadMonthlyCost = this.state.actualDataLeadMonthlyCost;

        const dataLeadQuoteRatioCalc = this.state.dataLeadMonthlyCount * (this.state.dataLeadQuoteRatio / 100);

        const dataLeadQuotedItems = this.props.itemsPerHousehold * dataLeadQuoteRatioCalc;
        const dataLeadPerHousehold = dataLeadQuotedItems / this.props.itemsPerHousehold;
        const dataLeadQuotedPolicies = dataLeadQuotedItems / this.props.itemsPerPolicy;

        const dataLeadSoldItems = dataLeadQuotedItems * (this.state.dataLeadCloseRatio / 100);
        const dataLeadSoldHouseholds = dataLeadPerHousehold * (this.state.dataLeadCloseRatio / 100);
        const dataLeadSoldPolicies = dataLeadQuotedPolicies * (this.state.dataLeadCloseRatio / 100);
        const monthDts = this.state.monthDts;

        const dataLeadTotalPremium = dataLeadSoldItems * this.props.premiumPerItem;

        const filterLeadSource = this.props.vendorSummary ? this.props.vendorSummary.filter(v => v.vendor == this.state.selectedLeadSourceId) : [];
        const actualDataLeadQuotedItems = filterLeadSource.length > 0 ? filterLeadSource[0].quotedItems : 0;
        const actualDataLeadPerHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].quotedHouseholds : 0;
        const actualDataLeadQuotedPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].quotedPolicies : 0;

        const actualDataLeadSoldItems = filterLeadSource.length > 0 ? filterLeadSource[0].writtenItems : 0;
        const actualDataLeadSoldHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].writtenHouseholds : 0;
        const actualDataLeadSoldPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].writtenPolicies : 0;

        const actualDataLeadMonthlyCostCalc = this.state.actualDataLeadMonthlyCost * 1;
        const actualDataLeadMonthlyCostWrittenItems = actualDataLeadMonthlyCostCalc / actualDataLeadSoldItems;
        const actualDataLeadMonthlyHousehold = actualDataLeadMonthlyCostCalc / actualDataLeadSoldHousehold;
        const actualDataLeadMonthlyPolicy = actualDataLeadMonthlyCostCalc / actualDataLeadSoldPolicies;

        const dataLeadMonthlyCostCalc = this.state.dataLeadMonthlyCost * 1;
        const dataLeadMonthlyCostWrittenItems = dataLeadMonthlyCostCalc / dataLeadSoldItems;
        const dataLeadMonthlyHousehold = dataLeadMonthlyCostCalc / dataLeadSoldHouseholds;
        const dataLeadMonthlyPolicy = dataLeadMonthlyCostCalc / dataLeadSoldPolicies;

        this.props.createDataLead(
            agencyId,
            typeQuoteLogLeadSourceId,
            userId, 
            name, 
            vendorId, 
            monthlyCount, 
            transferRate, 
            quoteRatio, 
            closeRatio,
            dataLeadMonthlyCost,
            actualMonthlyCount,
            actualTransferRate, 
            actualQuoteRatio,
            actualDataLeadMonthlyCost,
            dataLeadPerHousehold, 
            dataLeadQuotedPolicies, 
            dataLeadQuotedItems, 
            dataLeadSoldHouseholds, 
            dataLeadSoldPolicies, 
            dataLeadSoldItems, 
            monthDts, 
            dataLeadTotalPremium,
            premiumPerItem,
            dataLeadMonthlyCostWrittenItems, 
            dataLeadMonthlyHousehold,
            dataLeadMonthlyPolicy,
            actualDataLeadMonthlyCostWrittenItems, 
            actualDataLeadMonthlyHousehold, 
            actualDataLeadMonthlyPolicy);

        this.setState({
            name: '', 
            dataLeadMonthlyCount: '', 
            dataLeadTransferRatio: '', 
            dataLeadQuoteRatio: '', 
            dataLeadCloseRatio: '', 
            dataLeadMonthlyTransferCount: '', 
            dataLeadMonthlyCost: '',
            selectedValues: ''
        });
        
    }
    handleRadioChange(e) {
        this.setState({leadSourceFilter: e.target.value});
    }
    render() {
        const dataLeadTransferRatioCalc = this.state.dataLeadMonthlyCount * (this.state.dataLeadTransferRatio / 100);
        const dataLeadQuoteRatioCalc = dataLeadTransferRatioCalc * (this.state.dataLeadQuoteRatio / 100);
        const dataLeadCloseRatioCalc = dataLeadQuoteRatioCalc * (this.state.dataLeadCloseRatio / 100);

        const dataLeadQuotedItems = this.props.itemsPerHousehold * dataLeadQuoteRatioCalc;
        const dataLeadPerHousehold = dataLeadQuotedItems / this.props.itemsPerHousehold;
        const dataLeadQuotedPolicies = dataLeadQuotedItems / this.props.itemsPerPolicy;

        const dataLeadTotalPremium = dataLeadPerHousehold * this.props.premiumPerItem;

        const dataLeadSoldItems = dataLeadQuotedItems * (this.state.dataLeadCloseRatio / 100);
        const dataLeadSoldHousehold = dataLeadPerHousehold * (this.state.dataLeadCloseRatio / 100);
        const dataLeadSoldPolicies = dataLeadQuotedPolicies * (this.state.dataLeadCloseRatio / 100);

        const filterLeadSource = this.props.vendorSummary ? this.props.vendorSummary.filter(v => v.typeQuoteLogLeadSourceId == this.state.selectedLeadSourceId) : [];
        const actualDataLeadQuotedItems = filterLeadSource.length > 0 ? filterLeadSource[0].quotedItems : 0;
        const actualDataLeadPerHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].quotedHouseholds : 0;
        const actualDataLeadQuotedPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].quotedPolicies : 0;

        const actualDataLeadSoldItems = filterLeadSource.length > 0 ? filterLeadSource[0].writtenItems : 0;
        const actualDataLeadSoldHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].writtenHouseholds : 0;
        const actualDataLeadSoldPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].writtenPolicies : 0;

        const actualDataLeadCloseRatio = actualDataLeadSoldHousehold > 0 ? actualDataLeadSoldHousehold / actualDataLeadPerHousehold : 0;
        const actualDataLeadTotalPremium = filterLeadSource.length > 0 ? filterLeadSource[0].writtenPremium : 0
        const actualPremiumPerItem = actualDataLeadTotalPremium > 0 ? actualDataLeadTotalPremium / actualDataLeadSoldItems : 0;
        
        const actualDataLeadMonthlyCostCalc = this.state.actualDataLeadMonthlyCost * 1;
        const actualDataLeadCostPerItems = actualDataLeadMonthlyCostCalc / actualDataLeadSoldItems;
        const actualDataLeadMonthlyHousehold = actualDataLeadMonthlyCostCalc / actualDataLeadSoldHousehold;
        const actualDataLeadMonthlyPolicy = actualDataLeadMonthlyCostCalc / actualDataLeadSoldPolicies;

        const dataLeadMonthlyCostCalc = this.state.dataLeadMonthlyCost * 1;
        const dataLeadCostPerItems = dataLeadMonthlyCostCalc / dataLeadSoldHousehold;
        const dataLeadMonthlyCostWrittenItems = dataLeadMonthlyCostCalc / dataLeadSoldItems;
        const dataLeadMonthlyHousehold = dataLeadMonthlyCostCalc / dataLeadSoldHousehold;
        const dataLeadMonthlyPolicy = dataLeadMonthlyCostCalc / dataLeadSoldPolicies;

        const dataLeadCostPerQuotedHousehold = dataLeadMonthlyCostCalc / dataLeadPerHousehold;
        const actualDataLeadCostPerQuotedHousehold = actualDataLeadMonthlyCostCalc / actualDataLeadPerHousehold;

        const onChange = (value) => {
            var dataLeadLst = this.props.dataLead.filter(t => t.typeQuoteLogLeadSourceId == value);
            if(dataLeadLst.length > 0){
                var dataLead = dataLeadLst[0];
                this.setState({
                    id: dataLead.id,
                    name: dataLead.name,
                    selectedValues: value,
                    selectedLeadSourceId: dataLead.typeQuoteLogLeadSourceId,
                    vendor: String(dataLead.vendorId),
                    dataLeadMonthlyCount: dataLead.monthlyCount, 
                    dataLeadTransferRatio: dataLead.transferRatio, 
                    dataLeadQuoteRatio: dataLead.quoteRatio, 
                    dataLeadCloseRatio: dataLead.closeRatio, 
                    dataLeadCampaignCount: dataLead.leadCount, 
                    dataLeadMonthlyCost: dataLead.monthlyCost,
                    actualDataLeadMonthlyCount: dataLead.actualMonthlyCount, 
                    actualDataLeadTransferRatio: dataLead.actualTransferRatio, 
                    actualDataLeadQuoteRatio: dataLead.actualQuoteRatio,
                    actualDataLeadMonthlyCost: dataLead.actualMonthlyCost
                });
            }
            else{
                console.log(value);
                this.setState({
                    id: 0,
                    name: '',
                    selectedValues: value,
                    selectedLeadSourceId: value, 
                    dataLeadMonthlyCount: 0,
                    dataLeadResponseRate: 0,
                    dataLeadQuoteRatio: 0,
                    dataLeadCloseRatio: 0,
                    dataLeadPerHousehold: 0,
                    dataLeadResponseRateCalc: 0,
                    dataLeadQuoteRatioCalc: 0,
                    dataLeadCloseRatioCalc: 0,
                    dataLeadTotalPremium: 0,
                    dataLeadMonthlyCost: 0,
                    dataLeadMonthlyCostCalc: 0,
                    dataLeadCostWrittenItems: 0,
                    actualDataLeadMonthlyCount: 0,
                    actualDataLeadResponseRate: 0,
                    actualDataLeadQuoteRatio: 0,
                    actualDataLeadMonthlyCost: 0
                });
            }
        };

        const styles = {
            disabledInputText: {
                width: '100%', 
                marginLeft: 10, 
                color: '#000'
            },
        };
        return(
            <div>
                <Row>
                    <Col span={10}>
                        <List header={<Title level={4}>LEAD SOURCE</Title>} itemLayout="horizontal" dataSource={this.props.dataLead} renderItem={item => (
                            <List.Item actions={[<a key="list-loadmore-edit" onClick={() => this.handleListClick(item)}>edit</a>]}>
                                <List.Item.Meta title={item.name} />
                            </List.Item>
                        )} /> 
                    </Col>
                    <Col span={14}>
                    <Row style={{paddingBottom: 15}}>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Month
                                </div>
                            </Col>
                            <Col md={16}>
                                <DatePicker style={{ width: '100%', marginLeft: 10 }} onChange={this.onMonthChange} picker="month" format="MMM YYYY" value={moment(this.state.monthDts, "MMM YYYY")}   />
                            </Col>
                        </Row>
                        <Row style={{paddingBottom: 15}}>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0 }}>
                                    Lead Source
                                </div>
                            </Col>
                            <Col md={16}>
                                <Radio.Group style={{marginLeft: 10}} size="small" defaultValue="Active" buttonStyle="solid" onChange={this.handleRadioChange} value={this.state.leadSourceFilter}>
                                    <Radio.Button value="Active">Active</Radio.Button>
                                    <Radio.Button value="Inactive">Inactive</Radio.Button>
                                </Radio.Group>
                                <Select
                                    virtual={false}
                                    style={{ width: '100%', marginLeft: 10 }}
                                    dropdownStyle={{ minWidth: "20%" }}
                                    placeholder="Please select"
                                    name="leadSource"
                                    value={this.state.selectedValues}
                                    onChange={onChange}>
                                    {
                                        this.state.leadSourceFilter == "Active" ?
                                        this.props.leadSource.filter(ls => ls.inactive == false).map(ls => 
                                            <Option key={ls.id} value={ls.id}>{ls.name}</Option>
                                        )
                                        :
                                        this.props.leadSource.filter(ls => ls.inactive == true).map(ls => 
                                            <Option key={ls.id} value={ls.id}>{ls.name}</Option>
                                        )
                                    }
                                </Select>
                                {/* <Cascader
                                    style={{ width: '100%', marginLeft: 10 }}
                                    options={this.props.typeVendorTypesLeadSources.filter(c => c.children.length > 0)}
                                    onChange={onChange}
                                    maxTagCount="responsive"
                                    placeholder="Select Lead Source"
                                    value={this.state.selectedValues}
                                /> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                                          
                            </Col>
                            <Col md={8} style={{textAlign: 'center', fontWeight: 'bold'}}>
                                FORECASTED
                            </Col>
                            <Col md={8} style={{textAlign: 'center', fontWeight: 'bold'}}>
                                ACTUALS
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Monthly Data Lead Count
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={0} max={999999} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.dataLeadMonthlyCount} onChange={this.onDataLeadMonthlyCountChange} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={0} max={999999} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.actualDataLeadMonthlyCount} onChange={this.onActualDataLeadMonthlyCountChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Contact Rate
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={.1} max={100} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.dataLeadTransferRatio} onChange={this.onDataLeadTransferRatioChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={.1} max={100} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.actualDataLeadTransferRatio} onChange={this.onActualDataLeadTransferRatioChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Quote Ratio
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={0} max={100} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.dataLeadQuoteRatio} onChange={this.onDataLeadQuoteRatioChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={0} max={100} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.actualDataLeadQuoteRatio} onChange={this.onActualDataLeadQuoteRatioChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Close Ratio
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={0} max={100} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.dataLeadCloseRatio} onChange={this.onDataLeadCloseRatioChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={0} max={100} step={1} style={{ width: '100%', marginLeft: 10 }} value={new Intl.NumberFormat("en-US", {
                                    style: "percent",
                                    maximumFractionDigits: 2
                                    }).format(actualDataLeadCloseRatio)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Premium Per Item
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber disabled style={styles.disabledInputText} value={this.props.premiumPerItem} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')}  />
                            </Col>
                            <Col md={8}>
                                <InputNumber disabled style={styles.disabledInputText} value={actualPremiumPerItem.toFixed(2)} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Quoted Households
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={dataLeadPerHousehold.toFixed(2)} name="quotedHouseholds" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={styles.disabledInputText} value={actualDataLeadPerHousehold} name="quotedHouseholds" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Quoted Polices
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={dataLeadQuotedPolicies.toFixed(2)} name="quotedPolicies" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={styles.disabledInputText} value={actualDataLeadQuotedPolicies} name="quotedPolicies" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Quoted Items
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={dataLeadQuotedItems.toFixed(2)} name="quotedItems" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={styles.disabledInputText} value={actualDataLeadQuotedItems} name="quotedItems" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Sold Households
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={dataLeadSoldHousehold.toFixed(2)} name="soldHouseholds" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={styles.disabledInputText} value={actualDataLeadSoldHousehold} name="soldHouseholds" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Sold Polices
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={dataLeadSoldPolicies.toFixed(2)} name="soldPolicies" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={styles.disabledInputText} value={actualDataLeadSoldPolicies} name="soldPolicies" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Sold Items
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={styles.disabledInputText} value={dataLeadSoldItems.toFixed(2)} name="soldItems" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={styles.disabledInputText} value={actualDataLeadSoldItems} name="soldItems" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                TOTAL WRITTEN PREMIUM
                            </Col>
                            <Col md={8}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(dataLeadTotalPremium)}</Text>
                            </Col>
                            <Col md={8}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualDataLeadTotalPremium)}</Text>
                            </Col>
                        </Row>
                        <Row md={24} style={{backgroundColor: "#F5D5CB", fontWeight: 'bold'}}>
                            LIVE WEB LEAD ACQUISITION COST
                        </Row>
                        <Row>
                            <Col md={8}>
                                Live Web Lead Monthly Cost
                            </Col>
                            <Col md={8}>
                                <InputNumber min={.1} max={999999} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.dataLeadMonthlyCost} onChange={this.onDataLeadMonthlyCostChange} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={.1} max={999999} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.actualDataLeadMonthlyCost} onChange={this.onActualDataLeadMonthlyCostChange} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                Live Web Lead Cost Per Quoted Household
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(dataLeadCostPerQuotedHousehold)}</Text>
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualDataLeadCostPerQuotedHousehold)}</Text>
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                Live Web Lead Item Acquistion Cost 
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(dataLeadMonthlyCostWrittenItems)}</Text>
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualDataLeadCostPerItems)}</Text>
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                Live Web Lead Policy Acquistion Cost 
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(dataLeadMonthlyPolicy)}</Text>
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualDataLeadMonthlyPolicy)}</Text>
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                Live Web Lead Household Acquistion Cost 
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}> {new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(dataLeadMonthlyHousehold)}</Text>
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualDataLeadMonthlyHousehold)}</Text>
                            </Col>
                        </Row>
                        <Row style={{paddingBottom: 10}}>
                            <Col span={24} style={{padding: 16}}>
                                <Button shape="round" type="primary" onClick={this.handleSave} style={{ position: "absolute", right: 0, fontWeight: 'bold', boxShadow: '5px 5px 5px #ccc' }}>
                                    <SaveOutlined /> SAVE
                                </Button>
                            </Col>                           
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(DataLead);