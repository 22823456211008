import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Card, Input, Row, Col, List, Typography, Select, Button, Radio, Checkbox } from 'antd';
import { DeleteOutlined, SaveOutlined, PlusCircleOutlined } from '@ant-design/icons';
const {Title, Text} = Typography
const {Option} = Select

class ReferringAgent extends Component {
    constructor(props) {
      super(props);
        this.state = {
            id: 0,
            name: '',
            invalid: false,
            inactive: false,
            agentFilter: 'Active'
        }
        this.handleSave = this.handleSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleListClick = this.handleListClick.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.handleNewUser = this.handleNewUser.bind(this);
    }
    componentDidMount() {
        const agencyId = localStorage.getItem('agencyId');
        this.props.getReferringAgent(agencyId);
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handleBlur = (e) => {
        this.props.getUserAgent(e.target.value);
    }
    handleSave(event) {
        event.preventDefault();
        const agencyId = localStorage.getItem('agencyId');
        const id = this.state.id;
        const name = this.state.name;
        const inactive = this.state.inactive

        if(name != ''){
            this.props.createReferringAgent(agencyId, id, name, inactive);

            this.setState({
                id: 0,
                name: '',
                inactive: false
            });
        } else {
            this.setState({invalid: true});
        }
    }
    handleDelete(event) {
        event.preventDefault();
        const id = this.state.id;
 
        this.props.deleteAgent(id);

        this.setState({
            id: 0,
            firstName: '',
            email: '',
            dailyQuoteGoal: ''
        })
    }
    handleListClick = (agent) => {
        this.setState({
            id: agent.id,
            name: agent.name, 
            inative: agent.inactive
        });
    }
    handleStatusChange = (e) => {
        console.log(e);
        this.setState({status: e.value});
    }
    handleRadioChange(e) {
        this.setState({agentFilter: e.target.value});
    }
    handleCheckChange(e) {
        this.setState({inactive: e.target.checked});
    }
    handleNewUser(){
        this.setState({
            id: 0,
            name: '', 
            inative: false
        });

    }
    render() {
        console.log(this.props.userAgent);
        return (
            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={6} style={{paddingLeft: 16}}>
                            <List header={
                                <Row>
                                    <Col md={8}><Title level={4}>Referring Agents</Title></Col>
                                    <Col md={8} style={{textAlign: 'center'}}>
                                        <Radio.Group size="small" defaultValue="Active" buttonStyle="solid" onChange={this.handleRadioChange} value={this.state.agentFilter}>
                                            <Radio.Button value="Active">Active</Radio.Button>
                                            <Radio.Button value="Inactive">Inactive</Radio.Button>
                                        </Radio.Group>
                                    </Col>
                                    <Col md={8}>
                                        <Button type="primary" size="small" shape="round" style={{position: 'absolute', right: 0, fontWeight: 'bold'}} onClick={this.handleNewUser}><PlusCircleOutlined /> New Agent</Button>
                                    </Col>
                                </Row>
                                } itemLayout="horizontal" dataSource={this.state.agentFilter == "Active" ? this.props.referringAgent.filter(a => !a.inactive) : this.props.referringAgent.filter(a => a.inactive)} renderItem={item => (
                                <List.Item actions={[<a key="list-loadmore-edit" onClick={() => this.handleListClick(item)}>edit</a>]}>
                                    <List.Item.Meta 
                                        title={item.name}
                                    />
                                </List.Item>
                            )} /> 
                        </Col>
                        <Col span={18} style={{paddingLeft: 16}}>
                            <Card title="Add Referring Agents" id="divReferringAgents" headStyle={{ backgroundColor: '#3D76DD', color: '#fff' }}>
                                <Row>
                                    <Col md={12}>
                                        <div>
                                            <label htmlFor="dts">Name <span style={{color: 'Red'}}>*</span></label><br />
                                            <Input value={this.state.name} name='name' onChange={e => this.handleChange(e)}  />
                                        </div>
                                        <div>
                                            <Checkbox checked={this.state.inactive} onChange={(e) => this.handleCheckChange(e)}>
                                                Inactive 
                                            </Checkbox>
                                        </div>
                                        <div>
                                            <Button shape="round" type="primary" onClick={this.handleSave} style={{ marginTop: 16 }}>
                                                <SaveOutlined /> {this.state.id == '' ? 'Submit' : 'Update'}
                                            </Button>
                                        </div>
                                        {
                                                (this.state.invalid == true) ?
                                                    <Text type="danger" id="invalid">Required fields.</Text>
                                                    :
                                                    <span></span>
                                            }
                                    </Col>
                                    <Col md={12}>

                                    </Col>
                                </Row>
                                
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ReferringAgent);