import React, { Component } from 'react';
import { Collapse, Row, Col, Card, Slider, InputNumber, Typography } from 'antd'
import CompPlanMonthly from './CompPlanMonthly';
const { Title } = Typography;
const { Panel } = Collapse;

class CompPlan extends Component {
    constructor(props) {
      super(props);
        this.state = {
            renewalsTotalBookPremium: 0,
            renewalsCompReduction: 0,
            renewalsTotalCompReduction: 0,
            ipsMonthlyUpside: 0,
            ipsTotalBookPremium: 0,
            ipsTotalCompReduction: 0,
            broadenTotalCompReduction: 0,
            eaFundReduction: 0,
            line19Premium: 0,
            line19CompReduction: 0,
            line19TotalCompReduction: 0,
            alrLifePremium: 0,
            alrCompIncrease: 0,
            alrTotalCompReduction: 0,
            totalAnnualIncome: 0,
            totalMonthlyIncome: 0
        };
        this.handleChange = this.handleChange.bind(this);
    }   
    handleChange = (e, name) => {
        this.setState({
            [name]: e
        })
        this.setState({renewalsTotalCompReduction: (this.state.renewalsCompReduction / 100) * this.state.renewalsTotalBookPremium})
    }
    functionCalRenewalTotal = () => {
        this.setState({renewalsTotalCompReduction: (this.state.renewalsCompReduction / 100) * this.state.renewalsTotalBookPremium})
    }
    render() {
        var total = 
        ((this.state.renewalsCompReduction / 100) * this.state.renewalsTotalBookPremium) + 
        ((this.state.alrCompIncrease / 100) * this.state.alrLifePremium) + 
        ((this.state.line19CompReduction / 100) * this.state.line19Premium) + 
        this.state.eaFundReduction + 
        this.state.broadenTotalCompReduction + 
        ((this.state.ipsMonthlyUpside / 100) * this.state.renewalsTotalBookPremium);
        return(
            <div>
            <Row>
                <Col span={12}>
                    <Collapse defaultActiveKey={['1']}>
                        <Panel header={<Title level={4}>RENEWALS</Title>} key="1" style={{backgroundColor: "#97E45D"}}>
                            <Row style={{paddingTop: "10px" }}>
                                <Col md={12} style={{textAlign: "right"}}>
                                    TOTAL BOOK PREMIUM
                                </Col>
                                <Col md={12}>
                                    <InputNumber min={1} max={10000} step={1} style={{ marginLeft: 16 }} value={this.state.renewalsTotalBookPremium} onChange={e => this.handleChange(e, "renewalsTotalBookPremium")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                                </Col>
                            </Row>
                            <Row style={{paddingTop: "10px" }}>
                                <Col md={12} style={{textAlign: "right"}}>
                                    COMP REDUCTION
                                </Col>
                                <Col md={12}>
                                    <InputNumber min={1} max={10000} step={1} style={{ marginLeft: 16 }} value={this.state.renewalsCompReduction} onChange={e => this.handleChange(e, "renewalsCompReduction")} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                                </Col>
                            </Row>
                            <Row style={{paddingTop: "10px" }}>
                                <Col md={12} style={{textAlign: "right"}}>
                                    TOTAL COMP REDUCTION
                                </Col>
                                <Col md={12}>
                                    <span style={{ marginLeft: 16 }}>
                                        {new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD"
                                        }).format((this.state.renewalsCompReduction / 100) * this.state.renewalsTotalBookPremium)}  
                                    </span>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={<Title level={4}>IPS UPSIDE</Title>} key="2" style={{backgroundColor: "#97E45D"}}>
                            <Row style={{paddingTop: "10px" }}>
                                <Col md={12} style={{textAlign: "right"}}>
                                    MONTHLY IPS UPSIDE
                                </Col>
                                <Col md={12}>
                                    <InputNumber min={1} max={10000} step={1} style={{ marginLeft: 16 }} value={this.state.ipsMonthlyUpside} onChange={e => this.handleChange(e, "ipsMonthlyUpside")} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                                </Col>
                            </Row>
                            <Row style={{paddingTop: "10px" }}>
                                <Col md={12} style={{textAlign: "right"}}>
                                    TOTAL BOOK PREMIUM
                                </Col>
                                <Col md={12}>
                                    <span style={{ marginLeft: 16 }}>
                                    {new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD"
                                        }).format(this.state.renewalsTotalBookPremium)}
                                    </span>
                                </Col>
                            </Row>
                            <Row style={{paddingTop: "10px" }}>
                                <Col md={12} style={{textAlign: "right"}}>
                                    TOTAL COMP REDUCTION
                                </Col>
                                <Col md={12}>
                                    <span style={{ marginLeft: 16 }}>
                                        {new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD"
                                        }).format((this.state.ipsMonthlyUpside / 100) * this.state.renewalsTotalBookPremium)}
                                    </span>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={<Title level={4}>BROADEN YOUR BOOK</Title>} key="3" style={{backgroundColor: "#97E45D"}}>
                            <Row style={{paddingTop: "10px" }}>
                                <Col md={12} style={{textAlign: "right"}}>
                                    TOTAL COMP REDUCTION
                                </Col>
                                <Col md={12}>
                                    <InputNumber min={1} max={10000} step={1} style={{ marginLeft: 16 }} value={this.state.broadenTotalCompReduction} onChange={e => this.handleChange(e, "broadenTotalCompReduction")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={<Title level={4}>EA FUNDS REDUCTION</Title>} key="4" style={{backgroundColor: "#97E45D"}}>
                            <Row style={{paddingTop: "10px" }}>
                                <Col md={12} style={{textAlign: "right"}}>
                                    EA FUND REDUCTION
                                </Col>
                                <Col md={12}>
                                    <InputNumber min={1} max={10000} step={1} style={{ marginLeft: 16 }} value={this.state.eaFundReduction} onChange={e => this.handleChange(e, "eaFundReduction")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={<Title level={4}>LINE 19 COMP REDUCTION</Title>} key="5" style={{backgroundColor: "#97E45D"}}>
                            <Row style={{paddingTop: "10px" }}>
                                <Col md={12} style={{textAlign: "right"}}>
                                    LINE 19 PREMIUM
                                </Col>
                                <Col md={12}>
                                    <InputNumber min={1} max={10000} step={1} style={{ marginLeft: 16 }} value={this.state.line19Premium} onChange={e => this.handleChange(e, "line19Premium")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                                </Col>
                            </Row>
                            <Row style={{paddingTop: "10px" }}>
                                <Col md={12} style={{textAlign: "right"}}>
                                    LINE 19 COMP REDUCTION %
                                </Col>
                                <Col md={12}>
                                    <InputNumber min={1} max={10000} step={1} style={{ marginLeft: 16 }} value={this.state.line19CompReduction} onChange={e => this.handleChange(e, "line19CompReduction")} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                                </Col>
                            </Row>
                            <Row style={{paddingTop: "10px" }}>
                                <Col md={12} style={{textAlign: "right"}}>
                                    TOTAL COMP REDUCTION
                                </Col>
                                <Col md={12}>
                                    <span style={{ marginLeft: 16 }}>
                                        {new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD"
                                        }).format((this.state.line19CompReduction / 100) * this.state.line19Premium)}
                                    </span>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={<Title level={4}>ALR</Title>} key="6" style={{backgroundColor: "#97E45D"}}>
                            <Row style={{paddingTop: "10px" }}>
                                <Col md={12} style={{textAlign: "right"}}>
                                    PROJECTED LIFE PREMIUM
                                </Col>
                                <Col md={12}>
                                    <InputNumber min={1} max={10000} step={1} style={{ marginLeft: 16 }} value={this.state.alrLifePremium} onChange={e => this.handleChange(e, "alrLifePremium")} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                                </Col>
                            </Row>
                            <Row style={{paddingTop: "10px" }}>
                                <Col md={12} style={{textAlign: "right"}}>
                                    COMP INCREASE
                                </Col>
                                <Col md={12}>
                                    <InputNumber min={1} max={10000} step={1} style={{ marginLeft: 16 }} value={this.state.alrCompIncrease} onChange={e => this.handleChange(e, "alrCompIncrease")} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                                </Col>
                            </Row>
                            <Row style={{paddingTop: "10px" }}>
                                <Col md={12} style={{textAlign: "right"}}>
                                    TOTAL COMP REDUCTION
                                </Col>
                                <Col md={12}>
                                    <span style={{ marginLeft: 16 }}>
                                        {new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD"
                                        }).format((this.state.alrCompIncrease / 100) * this.state.alrLifePremium)}
                                    </span>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
                <Col span={12}>
                    <Row style={{paddingTop: "10px", backgroundColor: "#ffccc7", fontWeight: "bold" }}>
                        <Col md={12} style={{textAlign: "right"}}>
                            TOTAL ANNUAL INCOME TO BE REPLACED
                        </Col>
                        <Col md={12}>
                            <span style={{ marginLeft: 16 }}>
                                {new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(
                                    total
                                )}
                            </span>
                        </Col>
                    </Row>
                    <Row style={{backgroundColor: "#ffccc7", fontWeight: "bold" }}>
                        <Col md={12} style={{textAlign: "right"}}>
                            TOTAL MONTHLY INCOME TO BE REPLACED
                        </Col>
                        <Col md={12}>
                            <span style={{ marginLeft: 16 }}>
                                {new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(
                                    total / 12
                                )}
                            </span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <CompPlanMonthly />
                </Col>               
            </Row>
            </div>
        );
    }
}
export default CompPlan;