import React, { Component, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { UploadOutlined, ArrowUpOutlined, CheckOutlined, EditOutlined, PlusCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Upload, Typography, Row, Col, Button, Table, Select, DatePicker, Space, Card, Spin, Checkbox, Anchor, Modal, Radio } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import QuoteLogEntry from './QuoteLogEntry';
import moment from 'moment';
const { Title, Text } = Typography;
const {Option} = Select;
const { Link } = Anchor;


class TerminationUploadReport extends Component {
    constructor(props) {
      super(props);
        this.state = {
           terminationColumnHeaders: [],
           terminationFileData: [],
           newBusinessColumnHeaders: [],
           newBusinessFileData: [],
           firstName: '',
           lastName: '',
           subProducer: '',
           zipCode: '',
           productionDate:'',
           product: '',
           agentNumber: '',
           quotedItemCount: '',
           agencyQuotedItems: 0,
           agencySoldItems: 0,
           agencyCloseRatio: 0.0,
           agencyQuotedPolicies: 0,
           agencySoldPolicies: 0,
           agencyClosedPoliciesRatio: 0,
           agencyQuotedHouseholds: 0,
           agencySoldHouseholds: 0,
           agencyClosedHouseholdsRatio: 0,
           distinctTerminationProducers: [],
           distinctNewBusinessProducers: [],
           distinceProducers: [],
           invalidTerminationFileType: false,
           invalidNewBusinessFileType: false,
           removedColumns: [],
           dataFile: [],
           fileName: '',
           openUpload: false,
           dateTo: '',
           dateFrom: '',
           terminationQuoteLog: [],
           showModal: false,
           terminationUpload: [],
           selectedTerminationUpload: [],
           agent: '',
           selectedMonth: moment().format("MMM YYYY"),
           view: 'uncompleted',
           scrollToId: 0,
           selectedMatches: ['Suggested', 'Exact', 'No Matches'],
           showAddedModal: false
        }
        this.setCsvFile = this.setCsvFile.bind(this);
        this.handleSelectedLeadSourceChange = this.handleSelectedLeadSourceChange.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onDragUpdate = this.onDragUpdate.bind(this);
        this.handleUploadSave = this.handleUploadSave.bind(this);
        this.onUploadClick = this.onUploadClick.bind(this);
        this.handleTableClick = this.handleTableClick.bind(this);
        this.handleEntryVisibleChange = this.handleEntryVisibleChange.bind(this);
        this.handleFileClick = this.handleFileClick.bind(this);
        this.handleNoActionClick = this.handleNoActionClick.bind(this);
        this.handleUncompletedClick = this.handleUncompletedClick.bind(this);
        this.suggestedRef = React.createRef();
        this.noRef = React.createRef();
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleAddProspect = this.handleAddProspect.bind(this);
        this.onViewChange = this.onViewChange.bind(this);
        this.ignoreNoMatch = this.ignoreNoMatch.bind(this);
        this.handleAddedSubmitModal = this.handleAddedSubmitModal.bind(this);
    }
    
    componentDidMount(){
        const userId = localStorage.getItem('userId');
        const agencyId = localStorage.getItem('agencyId');
        this.props.getAgent(agencyId);
        this.props.getTerminationUploadFiles(agencyId);
        const dateFrom = moment().startOf('month').format('MM/DD/YYYY');
        const dateTo = moment().endOf('month').format('MM/DD/YYYY');
        if(!this.props.hideMonthSelection){
            this.props.getQuoteLogReport(agencyId, dateFrom, dateTo);
            this.props.getQuoteLogTerminationUpload(agencyId, dateFrom, dateTo);
            this.props.getSingleAgent(agencyId, userId);
            this.setState({dateFrom: dateFrom, dateTo: dateTo});
        } 
    }
    componentDidUpdate(prevProps){
        if(this.props.singleAgent != prevProps.singleAgent)
        {
            const agencyId = localStorage.getItem('agencyId');
            const agent = this.props.singleAgent;
            this.setState({agent: agent});
            if(agent.status == 4){
                this.props.getTerminationUploadByMonth(agencyId, this.state.dateFrom, this.state.dateTo, '');
            } else {
                this.props.getTerminationUploadByMonth(agencyId, this.state.dateFrom, this.state.dateTo, agent.producerNo);
            }
        }
        if(this.props.quoteLogTerminationUploads != prevProps.quoteLogTerminationUploads){
            this.setState({terminationQuoteLog: this.props.quoteLogTerminationUploads });
            if(this.state.scrollToId > 0)
            {
                console.log(this.state.scrollToId);
                document.querySelector("#div" + this.state.scrollToId).scrollIntoView({ behavior: 'smooth' });
                this.setState({scrollToId: 0});
            }
        }
        if(this.props.terminationUpload != prevProps.terminationUpload){
            this.setState({terminationUpload: this.props.terminationUpload })
        }
        
    }
    handleSelectedLeadSourceChange(value){
        const valid = this.state.monthDts != '' && value != '';
        this.setState({selectedLeadSource: value, valid: valid});
    }
    onViewChange(e){
        console.log(e.target.value);
        this.setState({view: e.target.value});
    }
    onMonthChange(date, dateString){
        const agencyId = localStorage.getItem('agencyId');
        const dateFrom = moment(date).startOf('month').format('MM/DD/YYYY');
        const dateTo = moment(date).endOf('month').format('MM/DD/YYYY');
        if(dateString != ''){
            this.setState({dateFrom: moment(date).startOf('month').format('MM/DD/YYYY'), dateTo: moment(date).endOf('month').format('MM/DD/YYYY'), selectedMonth: date});
        }else{
            this.setState({dateFrom: moment().startOf('month').format('MM/DD/YYYY'), dateTo: moment().endOf('month').format('MM/DD/YYYY')});
        }
        const userId = localStorage.getItem('userId');
        var agent = this.state.agent; //this.props.agent.find(a => a.userId == userId);
        this.props.getQuoteLogReport(agencyId, dateFrom, dateTo);
        this.props.getQuoteLogTerminationUpload(agencyId, dateFrom, dateTo);
        if(agent.status == 4){
            this.props.getTerminationUploadByMonth(agencyId, dateFrom, dateTo, '');
        }else{
            this.props.getTerminationUploadByMonth(agencyId, dateFrom, dateTo, agent.producerNo);
        }
    }
    handleEntryVisibleChange = () => {
        const visible = !this.state.visible;
        this.setState({ visible: visible });
        const seed = Math.random();
        if(visible == false){
            console.log("Seed: " + seed);
            console.log("QuoteId: " + this.state.selectedQuoteLog.id);
            if(this.state.selectedQuoteLog.id > 0){
                this.setState({seed: seed, showModal: true});
            } else {
                this.setState({seed: seed, showAddedModal: true});
            }
        }
    }
    handleUploadSave(){
        const agencyId = localStorage.getItem('agencyId');
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo;
        this.props.getQuoteLogReport(agencyId, dateFrom, dateTo, 'All');
        var terminationUploads = [];
        this.state.terminationFileData.map((item) => {
            let terminationUpload = {
                producerNo: item[this.state.subProducer],
                firstName: item[this.state.firstName],
                lastName: item[this.state.lastName],
                zip: item[this.state.zipCode],
                productionDate: item[this.state.productionDate],
                product: item[this.state.product],
                itemCount: item[this.state.quotedItemCount],
                agencyId: agencyId,
                fileName: this.state.fileName
            };
            terminationUploads.push(terminationUpload);
        });
        this.props.createTerminationUpload(terminationUploads, dateFrom, dateTo);
        console.log(terminationUploads);
        this.setState({dataFile: this.state.terminationFileData});
    }
    onDragEnd = ({ source, destination, draggableId }) => {
        // Make sure we have a valid destination
        if (destination === undefined || destination === null) return null
    
        // If the source and destination columns are the same
        // AND if the index is the same, the item isn't moving
        if (
          source.droppableId === destination.droppableId &&
          destination.index === source.index
        )
          return null
    
        this.setState({droppableBackColor: ""});
        // Set start and end variables
        const start = source.droppableId
        const end = destination.droppableId
        if(start != end){
            console.log(destination.droppableId + " : " + source.droppableId);
            if(destination.droppableId == "headers"){
                this.state.terminationColumnHeaders.splice(destination.index, 0, this.state[source.droppableId]);
                this.setState({[source.droppableId]: ""});
            }else if(source.droppableId == "headers"){
                var previousValue = this.state[destination.droppableId];
                this.setState({[destination.droppableId]: this.state.terminationColumnHeaders[source.index]});
                this.state.terminationColumnHeaders.splice(source.index, 1);
                if(previousValue != ""){
                    this.state.terminationColumnHeaders.splice(source.index, 0, previousValue);
                }
            }
            else{
                this.setState({[destination.droppableId]: this.state[source.droppableId]});
                this.setState({[source.droppableId]: this.state[destination.droppableId]})
            }
        }
        return null
    }
    onDragUpdate = ({ source, destination, draggableId }) => {
        if (destination === undefined || destination === null) return null
        this.setState({droppableBackColor: [destination.droppableId]});
    }
    onUploadClick(){
        const openUpload = this.state.openUpload;
        this.setState({openUpload: !openUpload, refreshReport: false, monthDts: '', selectedLeadSource: ''});
    }
    handleTableClick = (quoteLogId) => {
        console.log(quoteLogId);
        const agencyId = localStorage.getItem('agencyId');
        var quoteLog = this.props.quoteLog.find(q => q.id == quoteLogId);
        console.log(quoteLog);
        if(quoteLog != undefined){
            this.setState({
                selectedQuoteLog: quoteLog,
                visible: true,
                soldAuto: 0
            });
        }
    }
    handleNoActionClick = (quoteLogId) => {
        console.log(quoteLogId);
        const agencyId = localStorage.getItem('agencyId');
        var quoteLog = this.props.quoteLog.find(q => q.id == quoteLogId);
        this.props.verifyQuoteLog(quoteLog);

        var terminationQuoteLog = this.state.terminationQuoteLog;
        var i = terminationQuoteLog.findIndex((obj => obj.id == quoteLogId));
        terminationQuoteLog[i].verified = true;
        this.setState({terminationQuoteLog: terminationQuoteLog});
    }
    handleUncompletedClick = (quoteLogId) => {
        console.log(quoteLogId);
        const agencyId = localStorage.getItem('agencyId');
        var quoteLog = this.props.quoteLog.find(q => q.id == quoteLogId);
        this.props.verifyQuoteLog(quoteLog);

        var terminationQuoteLog = this.state.terminationQuoteLog;
        var i = terminationQuoteLog.findIndex((obj => obj.id == quoteLogId));
        terminationQuoteLog[i].verified = false;
        this.setState({terminationQuoteLog: terminationQuoteLog});
    }
    handleFileClick = (file) => {
        const agencyId = localStorage.getItem('agencyId');
        this.props.getTerminationUpload(agencyId, file);
    }
    handleCloseModal(){
        this.setState({showModal: false, selectedQuoteLog: []});
    }
    handleSubmitModal(markVerified){
        const agencyId = localStorage.getItem('agencyId');
        if(this.state.selectedQuoteLog.id > 0){
            var quoteLog = this.props.quoteLog.find(q => q.id == this.state.selectedQuoteLog.id);
            this.props.verifyQuoteLog(quoteLog);
            const scrollToId = this.state.selectedQuoteLog.id;
            var terminationQuoteLog = this.state.terminationQuoteLog;
            var i = terminationQuoteLog.findIndex((obj => obj.id == this.state.selectedQuoteLog.id));
            terminationQuoteLog[i].verified = markVerified;
            this.setState({terminationQuoteLog: terminationQuoteLog, selectedQuoteLog: [], showModal: false, scrollToId: scrollToId});
            this.props.getQuoteLogTerminationUpload(agencyId, this.state.dateFrom, this.state.dateTo);
        }else{
            var terminationUpload = this.state.terminationUpload;
            var updatedTerminationUpload = this.state.terminationUpload;
            this.state.selectedTerminationUpload.map(item => {
                updatedTerminationUpload = updatedTerminationUpload.map(group => {
                    if (group.id === item.id) {
                        return {
                        ...group,
                        added: true
                        };
                    } else {
                        return group;
                    }
                });
                this.setState({terminationUpload: updatedTerminationUpload});
            });
            this.setState({selectedQuoteLog: [], showModal: false});
            this.props.getQuoteLogTerminationUpload(agencyId, this.state.dateFrom, this.state.dateTo);
        } 
    }
    handleAddedSubmitModal(){
        const agencyId = localStorage.getItem('agencyId');
        var terminationUpload = this.state.terminationUpload;
        var updatedTerminationUpload = this.state.terminationUpload;
        this.state.selectedTerminationUpload.map(item => {
            updatedTerminationUpload = updatedTerminationUpload.map(group => {
                if (group.id === item.id) {
                    return {
                    ...group,
                    added: true
                    };
                } else {
                    return group;
                }
            });
            this.setState({terminationUpload: updatedTerminationUpload});
        });
        this.setState({selectedQuoteLog: [], showAddedModal: false});
        this.props.getQuoteLogTerminationUpload(agencyId, this.state.dateFrom, this.state.dateTo);
    }
    handleAddProspect = (terminationQuote) => {
        console.log(terminationQuote);
        const agencyId = localStorage.getItem('agencyId');
        var dwelling = [
            {
                "id": 1,
                "name": "HOME / TOWNHOME",
                "cnt": 0,
                "checked": false
            },
            {
                "id": 3,
                "name": "CONDO",
                "cnt": 0,
                "checked": false
            },
            {
                "id": 5,
                "name": "RENTER",
                "cnt": 0,
                "checked": false
            },
            {
                "id": 7,
                "name": "MANUFACTURED HOME",
                "cnt": 0,
                "checked": false
            }
        ];
        var specialty = [
            {
                "id": 4,
                "name": "UMBRELLA",
                "cnt": 0,
                "checked": false,
            },
            {
                "id": 5,
                "name": "LPP",
                "cnt": 0,
                "checked": false
            },
            {
                "id": 6,
                "name": "LIFE",
                "cnt": 0,
                "checked": false
            },
            {
                "id": 9,
                "name": "FLOOD",
                "cnt": 0,
                "checked": false
            },
            {
                "id": 8,
                "name": "BOAT",
                "cnt": 0,
                "checked": false,
            },
            {
                "id": 10,
                "name": "MOTOR CLUB",
                "cnt": 0,
                "checked": false
            },
            {
                "id": 11,
                "name": "INVANTAGE",
                "cnt": 0,
                "checked": false
            },
            {
                "id": 12,
                "name": "HURRICANE",
                "cnt": 0,
                "checked": false
            },
            {
                "id": 13,
                "name": "SPECIALTY AUTO (Motorcycle, ATV, Golf Cart)",
                "cnt": 0,
                "checked": false,
            }
        ];
        var autoCnt = 0;
        var commercialAutoCnt = 0;
        var agent = '';
        var firstName = '';
        var lastName = '';
        var zip = '';
        var producerNo = 0;
        var productionDate = '';
        terminationQuote.map(item => {
            var productName = 
                item.product == 'Homeowners' ? 'HOME / TOWNHOME' 
                : 
                item.product == 'Condominiums' ? 'CONDO'
                :
                item.product == 'Renters' ? 'RENTER'
                :
                item.product == 'Mobilehome' ? 'MANUFACTURED HOME'
                :
                item.product == 'Personal Umbrella' ? 'UMBRELLA'
                :
                item.product == 'Boat Owners' ? 'BOAT'
                :
                item.product == 'Auto - Special' ? 'SPECIALTY AUTO (Motorcycle, ATV, Golf Cart)'
                :
                '';
            if(item.product == 'Standard Auto'){
                autoCnt = item.itemCount
            }
            if(item.product == 'Non - Standard Auto'){
                commercialAutoCnt = item.itemCount
            }
            const terminationProducerNo = item.producerNo.replace(/\s/, '');
            console.log(terminationProducerNo);
            agent = this.props.agent.filter(b => b.producerNo != "" & b.producerNo != null).find(a => terminationProducerNo.includes(a.producerNo.replace(/\s/, '')));
            firstName = item.firstName;
            lastName = item.lastName;
            zip = item.zip;
            producerNo = item.producerNo;
            productionDate = item.productionDate;
            const specialtyData = specialty;
            const updatedSpecialtyData = specialtyData.map(group => {
                if (group.name === productName) {
                    return {
                    ...group,
                    checked: true,
                    cnt: item.itemCount
                    };
                } else {
                    return group;
                }
            });
            specialty = updatedSpecialtyData;
            const dwellingData = dwelling;
            const updatedDwellingData = dwellingData.map(group => {
                if (group.name === productName) {
                    return {
                    ...group,
                    checked: true,
                    cnt: item.itemCount
                    };
                } else {
                    return group;
                }
            });
            dwelling = updatedDwellingData;
        });
        
        var selectedQuoteLog = {
            agencyId: agencyId,
            agent: agent == undefined ? "" : agent.firstName,
            auto: autoCnt,
            dts: productionDate,
            dwelling: 0,
            firstName: firstName,
            ineligible: "N/A",
            lastName: lastName,
            leadSource: '',
            notes: "",
            objection: "",
            specialty: 0,
            status: "",
            id: 0,
            zip: zip,
            createdDts: moment().format('MM/DD/YYYY'),
            bundle: false,
            bindDate: null,
            totalPremium: 0,
            commercialAuto: commercialAutoCnt,
            typeQuoteLogLeadSourceId: 0,
            insuranceScore: 0,
            selfGenerated: false,
            currentCarrier: "",
            insuranceScoreAuto: 0,
            currentCarrierAuto: "",
            producerNo: producerNo,
            verified: false,
            dwelling: dwelling,
            specialty: specialty
        };
        console.log(selectedQuoteLog);
        this.setState({selectedQuoteLog: selectedQuoteLog, visible: true, soldAuto: 0, selectedTerminationUpload: terminationQuote});
    }
    setCsvFile(file, type){
        if(file.type == "text/csv"){
            const reader = new FileReader();
            reader.onload = (e) => {
                const text = e.target.result;
                const delim = ',';
                const rows = text.slice(text.indexOf('\n')+1).split('\r\n');
                const headers = text.slice(0, text.indexOf('\n')).split(delim);
                console.log(headers);
                var quotedItems = 0;
                var soldItems = 0;
                var quotedHouseholds = [];
                var soldHouseholds = [];
                var terminationProducers = [];
                var newBusinessProducers = [];
                console.log(rows);
                const newArray = rows.map( row => {
                    const values = row.split(delim);
                    const eachObject = headers.reduce((obj, header, i) => {
                        obj[header] = values[i];
                        return obj;
                    }, {})
                    return eachObject;
                });
                const distinctQuotedHouseholds = new Set(quotedHouseholds);
                const distinctSoldHouseholds = new Set(soldHouseholds);
                const distinctTerminationProducers = new Set(terminationProducers);
                const distinctNewBusinessProducers = new Set(newBusinessProducers);
                newArray.pop();
                console.log(newArray);
                if(type == 'termination'){
                    this.setState({fileName: file.name, invalidTerminationFileType: false, distinctTerminationProducers: distinctTerminationProducers, terminationColumnHeaders: headers, terminationFileData: newArray, agencyQuotedItems: quotedItems, agencyQuotedHouseholds: distinctQuotedHouseholds.size, agencyQuotedPolicies: quotedHouseholds.length})
                }else{
                    this.setState({invalidNewBusinessFileType: false, distinctNewBusinessProducers: distinctNewBusinessProducers, newBusinessColumnHeaders: headers, newBusinessFileData: newArray, agencySoldItems: soldItems, agencySoldHouseholds: distinctSoldHouseholds.size, agencySoldPolicies: soldHouseholds.length});
                }
            }
            reader.readAsText(file);
        }
        else{
            type=="termination" ? this.setState({invalidTerminationFileType: true}) : this.setState({invalidNewBusinessFileType: true})
        }
        return false;
    }
    ignoreNoMatch = (terminationQuote) => {
        var terminationUpload = this.state.terminationUpload;
            var updatedTerminationUpload = this.state.terminationUpload;
            terminationQuote.map(item => {
                updatedTerminationUpload = updatedTerminationUpload.map(group => {
                    if (group.id === item.id) {
                        return {
                        ...group,
                        added: true
                        };
                    } else {
                        return group;
                    }
                });
                this.setState({terminationUpload: updatedTerminationUpload});
            });
    }
    render() {
        const filterMatchOptions = ['Suggested', 'Exact', 'No Matches'];
        const allProducers = [...this.state.distinctTerminationProducers, ...this.state.distinctNewBusinessProducers];
        const result = this.props.terminationUpload ? this.props.terminationUpload.filter(function (a) {
            var key = a.firstName + '|' + a.lastName + '|' + a.zip + '|' + a.producerNo;
            if (!this[key]) {
                this[key] = true;
                return true;
            }
        }, Object.create(null)) : []
        var quoteLogTerminationUploads = this.state.terminationQuoteLog;// ? this.state.terminationQuoteLog : [];
        var terminationUpload = this.state.terminationUpload;
        console.log(result);
        const onCheckboxChange: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
            console.log('checked = ', checkedValues);
            this.setState({selectedMatches: checkedValues})
          };
        let usedTermination = [];  
        return (
            <div>
                {!this.props.hideMonthSelection &&
                <div>
                <Row>
                    <Col>
                        <Title level={3}>Quote Verification Report</Title>
                    </Col>
                </Row>
                <Row style={{paddingTop: 5, paddingBottom: 20, border: 'solid 1px #3D76DD', borderRadius: "5px", overflow: "hidden", marginTop: 10, boxShadow: '5px 5px 5px #ccc'}}>
                    <Col md={24} style={{padding: 10}}>
                        <Space>
                            <Text style={{fontWeight: 'bold'}}>Choose the month you would like to work</Text>
                            <DatePicker onChange={this.onMonthChange} picker="month" format="MMM YYYY" value={moment(this.state.selectedMonth, "MMM YYYY")} />
                        </Space>
                    </Col>
                    {/* <Col md={6}>
                        {
                            this.props.terminationUploadFiles &&
                            this.props.terminationUploadFiles.map((file) =>
                                <Button type="link" onClick={() => this.handleFileClick(file)}>{file}</Button>
                            )
                        }
                    </Col> */}
                </Row>
                </div>
                }
                {
                    this.props.isLoading || this.props.isTerminationLoading ?
                    <div style={{textAlign: 'center'}}> <Spin tip="Processing data.  This may take a few minutes... "><p></p></Spin> </div> :
                    this.props.terminationUpload &&
                    this.props.terminationUpload.length == 0 ?
                    <div style={{textAlign: 'center', marginTop: 10, fontWeight: 'bold', fontSize: 20}}>
                        <Text>No data has been uploaded for the month selected.</Text>
                    </div>
                    : this.props.terminationUpload &&
                    <div>
                        <Row>
                            <Col span={24}>
                                {/* <Button size="lg" type="link" onClick={() => this.suggestedRef.current.scrollIntoView({ behavior: 'smooth' })}>Suggested Matches</Button> */}
                                {/* <Button size="lg" type="link" onClick={() => this.noRef.current.scrollIntoView({ behavior: 'smooth' })}>No Matches</Button> */}
                            </Col>
                        </Row>
                        <Row style={{paddingTop: 10}}>
                            <Col span={12}>
                                <Space direction='horizontal'>
                                    <Radio.Group buttonStyle="solid" value={this.state.view} onChange={this.onViewChange} style={{ marginBottom: 5, marginTop: 10 }}>
                                        <Radio.Button value="uncompleted">Uncompleted</Radio.Button>
                                        <Radio.Button value="completed">Completed</Radio.Button>
                                        <Radio.Button value="all">All</Radio.Button>
                                    </Radio.Group>
                                    <div style={{marginLeft: 20}}>
                                        <Text style={{fontWeight: 'bold'}}>Filter Matches:</Text><br />
                                        <Checkbox.Group value={this.state.selectedMatches} onChange={onCheckboxChange} options={filterMatchOptions} defaultValue={['Suggested', 'Exact', 'No Matches']} style={{ padding: 10, border: 'solid 1px #3D76DD', borderRadius: "5px", overflow: "hidden"}} />
                                    </div>
                                </Space>
                            </Col>
                            <Col span={12} style={{paddingTop: 10}}>
                                
                            </Col>
                        </Row>
                        
                        <Row style={{paddingTop: 10}}>
                            <Col span={24}>
                                <Card title="Matches" headStyle={{ backgroundColor: '#3D76DD', color: '#fff' }} style={{borderRadius: "20px",overflow: "hidden"}} ref={this.suggestedRef}>
                                {
                                    result &&
                                    result.map((termination) =>  {
                                        //console.log(usedTermination);
                                        if(!usedTermination.includes(termination.id)) {
                                            
                                        const rows = quoteLogTerminationUploads.filter(q => 
                                            (q.firstName.toLowerCase().trim() == termination.firstName.toLowerCase().trim() && 
                                            q.lastName.toLowerCase().trim() == termination.lastName.toLowerCase().trim() &&
                                            q.zip.trim() == termination.zip.trim())
                                            ||
                                            (q.firstName.toLowerCase().trim() != termination.firstName.toLowerCase().trim() && 
                                            q.lastName.toLowerCase().trim() == termination.lastName.toLowerCase().trim() &&
                                            q.zip.trim() == termination.zip.trim())
                                            ||
                                            (q.firstName.toLowerCase().trim() == termination.firstName.toLowerCase().trim() && 
                                            q.lastName.toLowerCase().trim() != termination.lastName.toLowerCase().trim() &&
                                            q.zip.trim() == termination.zip.trim())
                                            ||
                                            (q.firstName.toLowerCase().trim() == termination.firstName.toLowerCase().trim() && 
                                            q.lastName.toLowerCase().trim() == termination.lastName.toLowerCase().trim() &&
                                            q.zip.trim() != termination.zip.trim())
                                            ||
                                            (q.firstName.toLowerCase().trim() == termination.firstName.toLowerCase().trim() && 
                                            q.lastName.toLowerCase().trim() == termination.lastName.toLowerCase().trim() &&
                                            q.zip.trim() == termination.zip.trim())
                                            );
                                        const quote = quoteLogTerminationUploads.find(q => 
                                            (q.firstName.toLowerCase().trim() == termination.firstName.toLowerCase().trim() && 
                                            q.lastName.toLowerCase().trim() == termination.lastName.toLowerCase().trim() &&
                                            q.zip.trim() == termination.zip.trim())
                                            ||
                                            (q.firstName.toLowerCase().trim() != termination.firstName.toLowerCase().trim() && 
                                            q.lastName.toLowerCase().trim() == termination.lastName.toLowerCase().trim() &&
                                            q.zip.trim() == termination.zip.trim())
                                            ||
                                            (q.firstName.toLowerCase().trim() == termination.firstName.toLowerCase().trim() && 
                                            q.lastName.toLowerCase().trim() != termination.lastName.toLowerCase().trim() &&
                                            q.zip.trim() == termination.zip.trim())
                                            ||
                                            (q.firstName.toLowerCase().trim() == termination.firstName.toLowerCase().trim() && 
                                            q.lastName.toLowerCase().trim() == termination.lastName.toLowerCase().trim() &&
                                            q.zip.trim() != termination.zip.trim())
                                            ||
                                            (q.firstName.toLowerCase().trim() == termination.firstName.toLowerCase().trim() && 
                                            q.lastName.toLowerCase().trim() == termination.lastName.toLowerCase().trim() &&
                                            q.zip.trim() == termination.zip.trim())
                                            );
                                            let itemSum = 0;
                                            rows.map(x => itemSum += x.itemCount);
                                            //console.log(itemSum);
                                        const exactQuote = quoteLogTerminationUploads.filter(q => 
                                            q.firstName.toLowerCase().trim() == termination.firstName.toLowerCase().trim() && 
                                            q.lastName.toLowerCase().trim() == termination.lastName.toLowerCase().trim() &&
                                            q.zip.trim() == termination.zip.trim()).length;
                                            
                                        return rows.length > 0 ?
                                        <div id={"div" + quote.id} style={{paddingBottom: 20, display: quote.verified && this.state.view =='uncompleted' || !quote.verified && this.state.view =='completed' ? 'none' : 'block'}}>
                                            <Card style={{display: (exactQuote > 0 && this.state.selectedMatches.includes('Exact')) || (exactQuote == 0 && this.state.selectedMatches.includes('Suggested')) ? 'block' : 'none',  borderRadius: "20px",overflow: "hidden", borderColor: '#3D76DD', boxShadow: '5px 5px 5px #ccc', backgroundColor: quote.verified ? '#E6FFE6' : '#fff'}}>
                                                <Row>
                                                    <Col md={24}>
                                                        <Title level={4}>{exactQuote > 0 ? 'Exact Match' : 'Suggested Match'}</Title>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                    {
                                                            !quote.verified ?
                                                            <Space direction="vertical">
                                                                <Button type="primary" shape="round" size="small" onClick={() => this.handleTableClick(quote.id)}><EditOutlined /> View & Edit</Button>
                                                                {/* <Button type="primary" shape="round" size="small"><ArrowUpOutlined /> Merge Selected</Button> */}
                                                                <Button type="primary" shape="round" size="small" onClick={() => this.handleNoActionClick(quote.id)}><CheckOutlined /> Mark As Completed</Button>
                                                            </Space>
                                                            :
                                                            <Button type="primary" shape="round" size="small" onClick={() => this.handleUncompletedClick(quote.id)}><EditOutlined /> Mark As Uncompleted</Button>
                                                        }
                                                    </Col>
                                                    <Col md={21}>
                                                        <Text style={{fontWeight: 'bold'}}>HUMBLE 100 QUOTE</Text>
                                                        <table style={{width: '100%'}}>
                                                            <thead style={{fontWeight: 'bold', backgroundColor: '#fafafa', textAlign: 'left'}}>
                                                                <tr>
                                                                    <th style={{width: '14%'}}>Date</th>
                                                                    <th style={{width: '14%'}}>First Name</th>
                                                                    <th style={{width: '14%'}}>Last Name</th>
                                                                    <th style={{width: '14%'}}>Zip</th>
                                                                    <th style={{width: '14%'}}>Product</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                // quoteLogTerminationUploads.filter(q => 
                                                                // (q.firstName.toLowerCase().trim() == termination.firstName.toLowerCase().trim() && 
                                                                // q.lastName.toLowerCase().trim() == termination.lastName.toLowerCase().trim() &&
                                                                // q.zip.trim() == termination.zip.trim() &&
                                                                // termination.producerNo.trim().includes(q.producerNo.trim()))
                                                                // ||
                                                                // (q.firstName.toLowerCase().trim() != termination.firstName.toLowerCase().trim() && 
                                                                // q.lastName.toLowerCase().trim() == termination.lastName.toLowerCase().trim() &&
                                                                // q.zip.trim() == termination.zip.trim() &&
                                                                // termination.producerNo.trim().includes(q.producerNo.trim()))
                                                                // ||
                                                                // (q.firstName.toLowerCase().trim() == termination.firstName.toLowerCase().trim() && 
                                                                // q.lastName.toLowerCase().trim() != termination.lastName.toLowerCase().trim() &&
                                                                // q.zip.trim() == termination.zip.trim() &&
                                                                // termination.producerNo.trim().includes(q.producerNo.trim()))
                                                                // ||
                                                                // (q.firstName.toLowerCase().trim() == termination.firstName.toLowerCase().trim() && 
                                                                // q.lastName.toLowerCase().trim() == termination.lastName.toLowerCase().trim() &&
                                                                // q.zip.trim() != termination.zip.trim() &&
                                                                // termination.producerNo.trim().includes(q.producerNo.trim()))
                                                                // ||
                                                                // (q.firstName.toLowerCase().trim() == termination.firstName.toLowerCase().trim() && 
                                                                // q.lastName.toLowerCase().trim() == termination.lastName.toLowerCase().trim() &&
                                                                // q.zip.trim() == termination.zip.trim() &&
                                                                // !termination.producerNo.trim().includes(q.producerNo.trim())))
                                                                rows.map((item) =>
                                                                <tr style={{borderBottom: 'solid #ccc 1px'}}>
                                                                    <td>{moment(item.dts).format('MM/DD/YYYY')}</td>
                                                                    <td style={{backgroundColor: item.firstName.toLowerCase().trim() != termination.firstName.toLowerCase().trim() && '#ffe6ee'}}>{item.firstName}</td>
                                                                    <td style={{backgroundColor: item.lastName.toLowerCase().trim() != termination.lastName.toLowerCase().trim() && '#ffe6ee'}}>{item.lastName}</td>
                                                                    <td style={{backgroundColor: item.zip != termination.zip && '#ffe6ee'}}>{item.zip}</td>
                                                                    <td>{item.product}</td>
                                                                </tr>
                                                                )
                                                                }
                                                            </tbody>
                                                        </table>
                                                        <hr />
                                                        <Text style={{fontWeight: 'bold', selfAlign: 'center'}}>TERMINATION REPORT</Text>
                                                        <table style={{width: '100%'}}>
                                                            <thead style={{fontWeight: 'bold', backgroundColor: '#fafafa', textAlign: 'left'}}>
                                                                <tr>
                                                                    <th style={{width: '14%'}}>Date</th>
                                                                    <th style={{width: '14%'}}>First Name</th>
                                                                    <th style={{width: '14%'}}>Last Name</th>
                                                                    <th style={{width: '14%'}}>Zip</th>
                                                                    <th style={{width: '14%'}}>Product</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.props.terminationUpload.filter(ser => 
                                                                ser.firstName == termination.firstName && 
                                                                ser.lastName == termination.lastName &&
                                                                ser.zip == termination.zip
                                                                ||
                                                                ser.firstName != termination.firstName && 
                                                                ser.lastName == termination.lastName &&
                                                                ser.zip == termination.zip
                                                                ||
                                                                ser.firstName == termination.firstName && 
                                                                ser.lastName != termination.lastName &&
                                                                ser.zip == termination.zip
                                                                ||
                                                                ser.firstName == termination.firstName && 
                                                                ser.lastName == termination.lastName &&
                                                                ser.zip != termination.zip
                                                                ||
                                                                ser.firstName == termination.firstName && 
                                                                ser.lastName == termination.lastName &&
                                                                ser.zip == termination.zip).map((item) => {
                                                                    
                                                                    usedTermination.push(item.id);
                                                                    return(<tr style={{borderBottom: 'solid #ccc 1px'}}>
                                                                        <td>{moment(item.terminationEffectiveDate).format('MM/DD/YYYY')}</td>
                                                                        <td>{item.firstName}</td>
                                                                        <td>{item.lastName}</td>
                                                                        <td>{item.zip}</td>
                                                                        <td>{item.productName}</td>
                                                                    </tr>)
                                                                }
                                                                )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </div>
                                        :
                                        <div></div>
                                        }
                                    }
                                    )
                                
                                }
                                </Card>
                            </Col>
                        </Row>
                        <Row style={{paddingTop: 10}}>
                            <Col span={24}>
                                <Card title="No Matches" headStyle={{ backgroundColor: '#3D76DD', color: '#fff' }} style={{borderRadius: "20px",overflow: "hidden", display: this.state.view =='completed' || !this.state.selectedMatches.includes('No Matches') ? 'none' : 'block'}} ref={this.noRef}>
                                    {
                                        result &&
                                        result.map((termination) => {
                                            const noOtherMatch = quoteLogTerminationUploads.filter(q => 
                                                (q.firstName.toLowerCase().trim() != termination.firstName.toLowerCase().trim() && 
                                                q.lastName.toLowerCase().trim() != termination.lastName.toLowerCase().trim() &&
                                                q.zip.trim() != termination.zip.trim())
                                                ||
                                                (q.lastName.toLowerCase().trim() != termination.lastName.toLowerCase().trim() &&
                                                q.zip.trim() != termination.zip.trim())
                                                ||
                                                (q.firstName.toLowerCase().trim() != termination.firstName.toLowerCase().trim() &&
                                                q.zip.trim() != termination.zip.trim())
                                                ||
                                                (q.firstName.toLowerCase().trim() != termination.firstName.toLowerCase().trim() &&
                                                q.lastName.toLowerCase().trim() != termination.lastName.toLowerCase().trim() &&
                                                q.zip.trim() != termination.zip.trim())
                                                ).length;
                                            var terminationQuote = terminationUpload.filter(ser => 
                                                ser.firstName == termination.firstName && 
                                                ser.lastName == termination.lastName &&
                                                ser.zip == termination.zip);
                                            return noOtherMatch == quoteLogTerminationUploads.length ?
                                            terminationQuote.length > 0 &&
                                            <div style={{paddingBottom: 20, display: terminationQuote[0] && terminationQuote[0].added ? 'none' : 'block'}}>
                                                <Card style={{borderRadius: "20px",overflow: "hidden", borderColor: '#3D76DD', boxShadow: '5px 5px 5px #ccc'}}>
                                                    <Row>
                                                        <Col md={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                        {terminationQuote[0] && terminationQuote[0].added ?
                                                            <div></div>
                                                            :
                                                            <Space direction="vertical">
                                                                <Button type="primary" shape="round" size="small" onClick={() => this.handleAddProspect(terminationQuote)}><PlusCircleOutlined /> Add Quoted Prospect</Button>
                                                                <Button type="primary" shape="round" size="small" onClick={() => this.ignoreNoMatch(terminationQuote)}><CloseOutlined /> Reviewed And Ignore</Button>
                                                            </Space>
                                                        }
                                                        </Col>
                                                        <Col md={21}>
                                                            <Text style={{fontWeight: 'bold', selfAlign: 'center'}}>TERMINATION REPORT</Text>
                                                            <table style={{width: '100%'}}>
                                                                <thead style={{fontWeight: 'bold', backgroundColor: '#fafafa', textAlign: 'left'}}>
                                                                    <tr>
                                                                        <th style={{width: '15%'}}>Termination Date</th>
                                                                        <th style={{width: '15%'}}>First Name</th>
                                                                        <th style={{width: '15%'}}>Last Name</th>
                                                                        <th style={{width: '15%'}}>Zip</th>
                                                                        <th style={{width: '15%'}}>Product</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {terminationUpload.filter(ser => 
                                                                    ser.firstName == termination.firstName && 
                                                                    ser.lastName == termination.lastName &&
                                                                    ser.zip == termination.zip
                                                                    ||
                                                                    ser.firstName != termination.firstName && 
                                                                    ser.lastName == termination.lastName &&
                                                                    ser.zip == termination.zip
                                                                    ||
                                                                    ser.firstName == termination.firstName && 
                                                                    ser.lastName != termination.lastName &&
                                                                    ser.zip == termination.zip
                                                                    ||
                                                                    ser.firstName == termination.firstName && 
                                                                    ser.lastName == termination.lastName &&
                                                                    ser.zip != termination.zip
                                                                    ||
                                                                    ser.firstName == termination.firstName && 
                                                                    ser.lastName == termination.lastName &&
                                                                    ser.zip == termination.zip).map((item) => {
                                                                    return (<tr style={{borderBottom: 'solid #ccc 1px'}}>
                                                                        <td>{moment(item.terminationEffectiveDate).format('MM/DD/YYYY')}</td>
                                                                        <td>{item.firstName}</td>
                                                                        <td>{item.lastName}</td>
                                                                        <td>{item.zip}</td>
                                                                        <td>{item.productName}</td>
                                                                    </tr>)
                                                                    }
                                                                    )
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </div>
                                            
                                            :
                                            <div></div>
                                        })
                                    }
                                </Card>
                            </Col>
                        </Row>
                        <QuoteLogEntry hideButton={true} visible={this.state.visible} selectedQuoteLog={this.state.selectedQuoteLog} onVisibleChange={this.handleEntryVisibleChange} dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} type={this.state.reportFilter} />
                    </div>
                }
                <Modal
                    title="The Humble 100"
                    visible={this.state.showModal}
                    onCancel={this.handleCloseModal}
                    footer={[
                        <Button key="submit" onClick={() => this.handleSubmitModal(true)}>
                            Yes
                        </Button>,
                        <Button key="close" type="primary" onClick={() => this.handleSubmitModal(false)}>
                            No
                        </Button>,
                    ]}>
                    <p>Mark As Completed?</p>
                </Modal>
                <Modal
                    title="The Humble 100"
                    visible={this.state.showAddedModal}
                    onCancel={() => this.setState({showAddedModal: false})}
                    footer={[
                        <Button key="closeAdded" type="primary" onClick={this.handleAddedSubmitModal}>
                            Close
                        </Button>,
                    ]}>
                    <p>Quoted Prospect Has Been Added.</p>
                </Modal>
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(TerminationUploadReport);