import React, { Component, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { UploadOutlined, ArrowUpOutlined, CheckOutlined, EditOutlined, PlusCircleOutlined, DownOutlined, FileTextOutlined, DeleteOutlined, ArrowRightOutlined, ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import { Upload, Typography, Row, Col, Button, Table, Select, DatePicker, Space, Card, Spin, Checkbox, Anchor, Modal, Dropdown } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import QuoteLogEntry from './QuoteLogEntry';
import NewBusinessUploadReport from './NewBusinessUploadReport';
import moment from 'moment';
const { Title, Text } = Typography;
const {Option} = Select;
const { Link } = Anchor;


class NewBusinessUpload extends Component {
    constructor(props) {
      super(props);
        this.state = {
           seriousColumnHeaders: [],
           seriousFileData: [],
           newBusinessColumnHeaders: [],
           newBusinessFileData: [],
           firstName: '',
           lastName: '',
           customerName: '',
           subProducer: '',
           zipCode: '',
           productionDate:'',
           issuedDate:'',
           writtenDate:'',
           product: '',
           agentNumber: '',
           quotedItemCount: '',
           dispositionCode: '',
           agencyQuotedItems: 0,
           agencySoldItems: 0,
           agencyCloseRatio: 0.0,
           agencyQuotedPolicies: 0,
           agencySoldPolicies: 0,
           agencyClosedPoliciesRatio: 0,
           agencyQuotedHouseholds: 0,
           agencySoldHouseholds: 0,
           agencyClosedHouseholdsRatio: 0,
           distinctSeriousProducers: [],
           distinctNewBusinessProducers: [],
           distinceProducers: [],
           invalidSeriousFileType: false,
           invalidNewBusinessFileType: false,
           removedColumns: [],
           dataFile: [],
           fileName: '',
           openUpload: false,
           dateTo: '',
           dateFrom: '',
           seriousQuoteLog: [],
           showModal: false,
           seriousUpload: [],
           selectedSeriousUpload: [],
           showDelete: false,
           selectedFile: null,
           premium: '',
           hideMonthSelection: true,
           selectedMonth: moment().format('MMM yyyy')
        }
        this.setCsvFile = this.setCsvFile.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onDragUpdate = this.onDragUpdate.bind(this);
        this.handleUploadSave = this.handleUploadSave.bind(this);
        this.onUploadClick = this.onUploadClick.bind(this);
        this.handleFileClick = this.handleFileClick.bind(this);
        this.suggestedRef = React.createRef();
        this.noRef = React.createRef();
        this.handleDeleteFile = this.handleDeleteFile.bind(this);
    }
    
    componentDidMount(){
        const userId = localStorage.getItem('userId');
        const agencyId = localStorage.getItem('agencyId');
        const dateFrom = moment().subtract(1, 'month').startOf('month').format('MM/DD/YYYY');
        const dateTo = moment().subtract(1, 'month').endOf('month').format('MM/DD/YYYY');
        this.props.getNewBusinessUploadFiles(agencyId);
    }
    componentDidUpdate(prevProps){
        
    }
    handleUploadSave(){
        const agencyId = localStorage.getItem('agencyId');
        //const dateFrom = moment().startOf('month').format('MM/DD/YYYY');
        //const dateTo = moment().endOf('month').format('MM/DD/YYYY');
        //this.props.getQuoteLogReport(agencyId, dateFrom, dateTo, 'All');
        var newBusinessUploads = [];
        this.state.newBusinessFileData.map((item) => {
            let newBusinessUpload = {
                producerNo: item[this.state.subProducer],
                customerName: item[this.state.customerName],
                zip: item[this.state.zipCode],
                writtenDate: item[this.state.writtenDate],
                issuedDate: item[this.state.issuedDate],
                product: item[this.state.product],
                itemCount: item[this.state.quotedItemCount],
                premium: item[this.state.premium].replace("$", ''),
                agencyId: agencyId,
                fileName: this.state.fileName,
                dispositionCode: item[this.state.dispositionCode]
            };
            newBusinessUploads.push(newBusinessUpload);
        });
        const dateFrom = moment(newBusinessUploads[0].issuedDate).startOf('month').format('MM/DD/YYYY');
        const dateTo = moment(newBusinessUploads[0].issuedDate).endOf('month').format('MM/DD/YYYY');
        this.props.createNewBusinessUpload(newBusinessUploads, dateFrom, dateTo); 
        this.props.getQuoteLogReport(agencyId, dateFrom, dateTo, 'All');
        this.props.getQuoteLogNewBusinessUpload(agencyId, dateFrom, dateTo);
        this.props.getNewBusinessUploadFiles(agencyId);
        const month = moment(dateFrom).format("MMM YYYY");
        this.setState({dataFile: this.state.newBusinessFileData, fileName: '', hideMonthSelection: false, selectedMonth: month});
    }
    onDragEnd = ({ source, destination, draggableId }) => {
        // Make sure we have a valid destination
        if (destination === undefined || destination === null) return null
    
        // If the source and destination columns are the same
        // AND if the index is the same, the item isn't moving
        if (
          source.droppableId === destination.droppableId &&
          destination.index === source.index
        )
          return null
    
        this.setState({droppableBackColor: ""});
        // Set start and end variables
        const start = source.droppableId
        const end = destination.droppableId
        if(start != end){
            console.log(destination.droppableId + " : " + source.droppableId);
            if(destination.droppableId == "headers"){
                this.state.newBusinessColumnHeaders.splice(destination.index, 0, this.state[source.droppableId]);
                this.setState({[source.droppableId]: ""});
            }else if(source.droppableId == "headers"){
                var previousValue = this.state[destination.droppableId];
                this.setState({[destination.droppableId]: this.state.newBusinessColumnHeaders[source.index]});
                this.state.newBusinessColumnHeaders.splice(source.index, 1);
                if(previousValue != ""){
                    this.state.newBusinessColumnHeaders.splice(source.index, 0, previousValue);
                }
            }
            else{
                this.setState({[destination.droppableId]: this.state[source.droppableId]});
                this.setState({[source.droppableId]: this.state[destination.droppableId]});
            }
        }
        return null
    }
    onDragUpdate = ({ source, destination, draggableId }) => {
        if (destination === undefined || destination === null) return null
        this.setState({droppableBackColor: [destination.droppableId]});
    }
    onUploadClick(){
        const openUpload = this.state.openUpload;
        this.setState({openUpload: !openUpload, refreshReport: false, monthDts: '', selectedLeadSource: '', hideMonthSelection: true});
    }
    handleFileClick = (label) => {
        const agencyId = localStorage.getItem('agencyId');
        console.log(label);
        //this.props.getSeriousUpload(agencyId, file);
    }
    handleDeleteFile = () => {
        const agencyId = localStorage.getItem('agencyId');
        const fileName = this.state.selectedFile;
        this.props.deleteNewBusinessUploadFile(agencyId, fileName);

        const dateFrom = moment().startOf('month').format('MM/DD/YYYY');
        const dateTo = moment().endOf('month').format('MM/DD/YYYY');
        this.props.getQuoteLogReport(agencyId, dateFrom, dateTo);
        this.props.getQuoteLogNewBusinessUpload(agencyId, dateFrom, dateTo);
        this.props.getNewBusinessUpload(agencyId, '');
        this.setState({openUpload: false, selectedFile: null});
    }
    setCsvFile(file){
        if(file.type == "text/csv"){
            const reader = new FileReader();
            reader.onload = (e) => {
                const text = e.target.result;
                var regex = ",(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)";
                const delim = ',';
                const rows = text.slice(text.indexOf('\n')+1).split('\r\n');
                const headers = text.slice(0, text.indexOf('\n')).split(delim);
                console.log(headers);
                var quotedItems = 0;
                var soldItems = 0;
                var quotedHouseholds = [];
                var soldHouseholds = [];
                var seriousProducers = [];
                var newBusinessProducers = [];
                console.log(rows);
                const newArray = rows.map( row => {
                    const values = row.split(delim);
                    const eachObject = headers.reduce((obj, header, i) => {
                        obj[header] = values[i];
                        return obj;
                    }, {})
                    return eachObject;
                });
                const distinctQuotedHouseholds = new Set(quotedHouseholds);
                const distinctSoldHouseholds = new Set(soldHouseholds);
                const distinctSeriousProducers = new Set(seriousProducers);
                const distinctNewBusinessProducers = new Set(newBusinessProducers);
                newArray.pop();
                console.log(newArray);
                this.setState({fileName: file.name, invalidNewBusinessFileType: false, distinctSeriousProducers: distinctSeriousProducers, newBusinessColumnHeaders: headers, newBusinessFileData: newArray, agencyQuotedItems: quotedItems, agencyQuotedHouseholds: distinctQuotedHouseholds.size, agencyQuotedPolicies: quotedHouseholds.length})
            }
            reader.readAsText(file);
        }
        else{
            this.setState({invalidNewBusinessFileType: true});
        }
        return false;
    }
    render() {
        const handleChange = (value) => {
            const agencyId = localStorage.getItem('agencyId');
            var file = this.props.newBusinessUploadFiles.find(s => s.key == value);
            var dateFrom = moment(file.value, 'MMM YYYY').format("MM/DD/YYYY");
            var dateTo = moment(dateFrom).endOf('month').format('MM/DD/YYYY');
            this.props.getNewBusinessUpload(agencyId, value);
            this.props.getQuoteLogReport(agencyId, dateFrom, dateTo);
            this.props.getQuoteLogNewBusinessUpload(agencyId, dateFrom, dateTo);
            this.setState({showDelete: true, selectedFile: value});
        }
        return (
            <div>
                <div>
                    <Row>
                        <Col>
                            <Title level={3}>New Business Upload</Title>
                        </Col>
                    </Row>
                <Row style={{paddingTop: 10, paddingBottom: 20, border: 'solid 1px #3D76DD', borderRadius: "5px", overflow: "hidden", marginTop: 10, boxShadow: '5px 5px 5px #ccc'}}>
                    <Col span={12} style={{padding: 10}}>
                        <Space direction='horizontal'>
                            <Space>
                                <Upload
                                    showUploadList={false}
                                    accept='.csv'
                                    id='csvFile'
                                    beforeUpload={file => {
                                        this.setCsvFile(file);
                                        this.setState({hideMonthSelection: true});
                                        return false;
                                    }}>
                                    <Button icon={<UploadOutlined />} type='primary' shape='round'>Upload New File</Button>
                                </Upload>
                                <Text>{this.state.fileName}</Text>
                                {this.state.invalidNewBusinessFileType == true && <Text type="danger" id="passwordError" style={{fontSize: 16}}>Only .csv files are accepted.</Text>}
                            </Space>
                            {/* <Button icon={<FileTextOutlined />} type='primary' shape="round" onClick={this.onUploadClick}>Manage Uploaded Files</Button> */}
                        </Space>
                    </Col>
                    <Col span={6}>
                        <Select
                            style={{ width: '100%', border: 'solid 1px #3D76DD' }}
                            value={this.state.selectedFile}
                            virtual={false}
                            size="large"
                            placeholder={<strong style={{color: 'black'}}>View and Manage Files</strong>}
                            name="seriousFiles"
                            onChange={handleChange}>
                            {
                                this.props.newBusinessUploadFiles.map((s, index) => 
                                    <Option key={index} value={s.key} label={s.key}>{s.key}</Option>
                                )
                            }
                        </Select>                     
                    </Col>
                    <Col span={6} style={{paddingLeft: 10}}>
                        {this.state.showDelete && <Button type="danger" shape="round" icon={<DeleteOutlined />} onClick={this.handleDeleteFile}> Delete File</Button>}
                    </Col>
                </Row>
                {
                    this.state.fileName != '' &&
                <div style={{paddingTop: 10}}>
                <DragDropContext onDragEnd={this.onDragEnd} onDragUpdate={this.onDragUpdate}>
                <Row>
                    <Col md={3} style={{overflowY: 'scroll', height: 600, paddingRight: 5}}>
                        <Droppable droppableId='headers'>
                            {(provided) => (
                                <div {...provided.droppableProps}  ref={provided.innerRef}>
                                {this.state.newBusinessColumnHeaders.map((item, i) =>
                                    <div style={{paddingBottom: 10}}>
                                        <Draggable key={item} draggableId={item} index={i}>
                                            {(provided) => (
                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}><div style={{textAlign: 'center', border: 'solid 1px #3D76DD', borderRadius: "10px", overflow: "hidden", height: '40px'  }}>{item}</div></div>
                                            )}
                                        </Draggable>
                                    </div>
                                )}
                                {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </Col>
                    <Col md={1} style={{display: 'flex', justifyContent: 'center', marginTop: 50}}>
                        <Space direction="vertical">
                            <ArrowRightOutlined style={{fontSize: 22, color: '#3D76DD'}} />
                            <ArrowLeftOutlined style={{fontSize: 22, color: '#3D76DD'}} />
                        </Space>
                    </Col>
                    <Col md={20} style={{paddingLeft: 10}}>
                        <Row style={{justifyContent: 'space-between'}}>
                            <Col md={2}>
                                <Droppable droppableId="agentNumber">
                                        {(provided) => (
                                            <Card size="small" title={<span>AGENT<br />NUMBER</span>} headStyle={{ backgroundColor: '#3D76DD', color: '#fff', textAlign: 'center'}} style={{borderRadius: "10px",overflow: "hidden"}}>
                                                <div {...provided.droppableProps}  ref={provided.innerRef} style={{minHeight: '100px', backgroundColor: this.state.droppableBackColor == "agentNumber" ? '#d2ecff' : '#fff'}}>
                                                    <div>
                                                        <Draggable key="ag" draggableId="ag" index={0}>
                                                            {(provided) => (
                                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                    {this.state.agentNumber != "" &&
                                                                    <div style={{textAlign: 'center', border: 'solid 1px #3D76DD', borderRadius: "10px", overflow: "hidden", height: '40px' }}>
                                                                        {this.state.agentNumber}
                                                                    </div>
                                                                    }
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    </div>
                                                    {provided.placeholder}
                                                </div>
                                            </Card>
                                        )}
                                </Droppable>
                            </Col>
                            <Col md={2}>
                                <Droppable droppableId='subProducer'>
                                    {(provided) => (
                                        <Card size="small" title={<span>SUB<br />PRODUCER</span>} headStyle={{ backgroundColor: '#3D76DD', color: '#fff', textAlign: 'center'}} style={{borderRadius: "10px",overflow: "hidden"}}>
                                            <div {...provided.droppableProps}  ref={provided.innerRef} style={{minHeight: '100px', backgroundColor: this.state.droppableBackColor == "subProducer" ? '#d2ecff' : '#fff'}}>
                                                <div>
                                                    <Draggable key="sp" draggableId="sp" index={1}>
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                {this.state.subProducer != "" &&
                                                                <div style={{textAlign: 'center', border: 'solid 1px #3D76DD', borderRadius: "10px", overflow: "hidden", height: '40px' }}>
                                                                    {this.state.subProducer}
                                                                </div>
                                                                }
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </div>
                                                {provided.placeholder}
                                            </div>
                                        </Card>
                                    )}
                                </Droppable>
                            </Col>
                            <Col md={2}>
                                <Droppable droppableId='customerName'>
                                    {(provided) => (
                                        <Card size="small" title={<span>CUSTOMER<br />NAME</span>} headStyle={{ backgroundColor: '#3D76DD', color: '#fff', textAlign: 'center'}} style={{borderRadius: "10px",overflow: "hidden"}}>
                                            <div {...provided.droppableProps}  ref={provided.innerRef} style={{minHeight: '100px', backgroundColor: this.state.droppableBackColor == "customerName" ? '#d2ecff' : '#fff'}}>
                                                <div>
                                                    <Draggable key="cn" draggableId="fn" index={2}>
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                {this.state.customerName != "" &&
                                                                <div style={{textAlign: 'center', border: 'solid 1px #3D76DD', borderRadius: "10px", overflow: "hidden", height: '40px' }}>
                                                                    {this.state.customerName}
                                                                </div>
                                                                }
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </div>
                                                {provided.placeholder}
                                            </div>
                                        </Card>
                                    )}
                                </Droppable>
                            </Col>
                            {/* <Col md={2}>
                                <Droppable droppableId='zipCode'>
                                    {(provided) => (
                                        <Card size="small" title="CUSTOMER ZIP CODE" headStyle={{ backgroundColor: '#3D76DD', color: '#fff'}} style={{borderRadius: "10px",overflow: "hidden"}}>
                                            <div {...provided.droppableProps}  ref={provided.innerRef} style={{minHeight: '100px', backgroundColor: this.state.droppableBackColor == "zipCode" ? '#d2ecff' : '#fff'}}>
                                                <div>
                                                    <Draggable key="zc" draggableId="zc" index={3}>
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                {this.state.zipCode != "" &&
                                                                <div style={{textAlign: 'center', border: 'solid 1px #3D76DD', borderRadius: "10px", overflow: "hidden", height: '40px' }}>
                                                                    {this.state.zipCode}
                                                                </div>
                                                                }
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </div>
                                                {provided.placeholder}
                                            </div>
                                        </Card>
                                    )}
                                </Droppable>
                            </Col> */}
                            <Col md={2}>
                                <Droppable droppableId='issuedDate'>
                                    {(provided) => (
                                        <Card size="small" title={<span>ISSUED<br />DATE</span>} headStyle={{ backgroundColor: '#3D76DD', color: '#fff', textAlign: 'center'}} style={{borderRadius: "10px",overflow: "hidden"}}>
                                            <div {...provided.droppableProps}  ref={provided.innerRef} style={{minHeight: '100px', backgroundColor: this.state.droppableBackColor == "issuedDate" ? '#d2ecff' : '#fff'}}>
                                                <div>
                                                    <Draggable key="pd" draggableId="pd" index={4}>
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                {this.state.issuedDate != "" &&
                                                                <div style={{textAlign: 'center', border: 'solid 1px #3D76DD', borderRadius: "10px", overflow: "hidden", height: '40px' }}>
                                                                    {this.state.issuedDate}
                                                                </div>
                                                                }
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </div>
                                                {provided.placeholder}
                                            </div>
                                        </Card>
                                    )}
                                </Droppable>
                            </Col>
                            <Col md={2}>
                                <Droppable droppableId='writtenDate'>
                                    {(provided) => (
                                        <Card size="small" title={<span>WRITTEN<br />DATE</span>} headStyle={{ backgroundColor: '#3D76DD', color: '#fff', textAlign: 'center'}} style={{borderRadius: "10px",overflow: "hidden"}}>
                                            <div {...provided.droppableProps}  ref={provided.innerRef} style={{minHeight: '100px', backgroundColor: this.state.droppableBackColor == "writtenDate" ? '#d2ecff' : '#fff'}}>
                                                <div>
                                                    <Draggable key="pd" draggableId="pd" index={5}>
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                {this.state.writtenDate != "" &&
                                                                <div style={{textAlign: 'center', border: 'solid 1px #3D76DD', borderRadius: "10px", overflow: "hidden", height: '40px' }}>
                                                                    {this.state.writtenDate}
                                                                </div>
                                                                }
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </div>
                                                {provided.placeholder}
                                            </div>
                                        </Card>
                                    )}
                                </Droppable>
                            </Col>
                            <Col md={2}>
                                <Droppable droppableId='product'>
                                    {(provided) => (
                                        <Card size="small" title={<span>PRODUCT<br/>&nbsp;</span>} headStyle={{ backgroundColor: '#3D76DD', color: '#fff', textAlign: 'center'}} style={{borderRadius: "10px",overflow: "hidden"}}>
                                            <div {...provided.droppableProps}  ref={provided.innerRef} style={{minHeight: '100px', backgroundColor: this.state.droppableBackColor == "product" ? '#d2ecff' : '#fff'}}>
                                                <div>
                                                    <Draggable key="p" draggableId="p" index={6}>
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                {this.state.product != "" &&
                                                                <div style={{textAlign: 'center', border: 'solid 1px #3D76DD', borderRadius: "10px", overflow: "hidden", height: '40px' }}>
                                                                    {this.state.product}
                                                                </div>
                                                                }
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </div>
                                                {provided.placeholder}
                                            </div>
                                        </Card>
                                    )}
                                </Droppable>
                            </Col>
                            <Col md={2}>
                                <Droppable droppableId='quotedItemCount'>
                                    {(provided) => (
                                        <Card size="small" title={<span>ITEM<br />COUNT</span>} headStyle={{ backgroundColor: '#3D76DD', color: '#fff', textAlign: 'center'}} style={{borderRadius: "10px",overflow: "hidden"}}>
                                            <div {...provided.droppableProps}  ref={provided.innerRef} style={{minHeight: '100px', backgroundColor: this.state.droppableBackColor == "quotedItemCount" ? '#d2ecff' : '#fff'}}>
                                                <div>
                                                    <Draggable key="ic" draggableId="ic" index={7}>
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                {this.state.quotedItemCount != "" &&
                                                                <div style={{textAlign: 'center', border: 'solid 1px #3D76DD', borderRadius: "10px", overflow: "hidden", height: '40px' }}>
                                                                    {this.state.quotedItemCount}
                                                                </div>
                                                                }
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </div>
                                                {provided.placeholder}
                                            </div>
                                        </Card>
                                    )}
                                </Droppable>
                            </Col>
                            <Col md={2}>
                                <Droppable droppableId='premium'>
                                    {(provided) => (
                                        <Card size="small" title={<span>PREMIUM<br/>&nbsp;</span>} headStyle={{ backgroundColor: '#3D76DD', color: '#fff', textAlign: 'center'}} style={{borderRadius: "10px",overflow: "hidden"}}>
                                            <div {...provided.droppableProps}  ref={provided.innerRef} style={{minHeight: '100px', backgroundColor: this.state.droppableBackColor == "premium" ? '#d2ecff' : '#fff'}}>
                                                <div>
                                                    <Draggable key="prem" draggableId="prem" index={8}>
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                {this.state.premium != "" &&
                                                                <div style={{textAlign: 'center', border: 'solid 1px #3D76DD', borderRadius: "10px", overflow: "hidden", height: '40px' }}>
                                                                    {this.state.premium}
                                                                </div>
                                                                }
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </div>
                                                {provided.placeholder}
                                            </div>
                                        </Card>
                                    )}
                                </Droppable>
                            </Col>
                            <Col md={2}>
                                <Droppable droppableId='dispositionCode'>
                                    {(provided) => (
                                        <Card size="small" title={<span>DISPOSITION<br />CODE</span>} headStyle={{ backgroundColor: '#3D76DD', color: '#fff', textAlign: 'center'}} style={{borderRadius: "10px",overflow: "hidden"}}>
                                            <div {...provided.droppableProps}  ref={provided.innerRef} style={{minHeight: '100px', backgroundColor: this.state.droppableBackColor == "dispositionCode" ? '#d2ecff' : '#fff'}}>
                                                <div>
                                                    <Draggable key="dispCode" draggableId="dispCode" index={9}>
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                {this.state.dispositionCode != "" &&
                                                                <div style={{textAlign: 'center', border: 'solid 1px #3D76DD', borderRadius: "10px", overflow: "hidden", height: '40px' }}>
                                                                    {this.state.dispositionCode}
                                                                </div>
                                                                }
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </div>
                                                {provided.placeholder}
                                            </div>
                                        </Card>
                                    )}
                                </Droppable>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </DragDropContext>
            <Row style={{paddingBottom: 20, paddingTop: 20}}>
                <Col md={24}>
                    <Space>
                        <Button icon={<UploadOutlined />} type='primary' shape='round' onClick={this.handleUploadSave}>UPLOAD AND SAVE</Button>
                        <Button icon={<CloseOutlined />} type='danger' shape='round' onClick={() => this.setState({hideMonthSelection: false, fileName: ''})}>CANCEL</Button>
                    </Space>
                </Col>
            </Row>
            
            </div>
            
            }
            </div>
            <div style={{marginTop: 20}}>
                <NewBusinessUploadReport hideMonthSelection={true} selectedMonth={this.state.selectedMonth} />   
            </div>
        </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(NewBusinessUpload);