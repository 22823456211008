const UPDATE_CREATE_VENDOR = 'UPDATE_CREATE_VENDOR';
const UPDATE_UPDATE_VENDOR = 'UPDATE_UPDATE_VENDOR';
const UPDATE_GET_VENDORTYPE = 'UPDATE_GET_VENDORTYPE';
const UPDATE_GET_VENDORS = 'UPDATE_GET_VENDORS';
const UPDATE_CREATE_USER = 'UPDATE_CREATE_USER';
const GET_CREATE_USER = 'GET_CREATE_USER';
const UPDATE_LOGIN_USER = 'UPDATE_LOGIN_USER';
const UPDATE_CREATE_MARKETING = 'UPDATE_CREATE_MARKETING';
const UPDATE_GET_MARKETING = 'UPDATE_GET_MARKETING';
const UPDATE_GET_TELEMARKETING = 'UPDATE_GET_TELEMARKETING';
const UPDATE_CREATE_TELEMARKETING = 'UPDATE_CREATE_TELEMARKETING';
const UPDATE_GET_LIVELEAD = 'UPDATE_GET_LIVELEAD';
const UPDATE_CREATE_LIVELEAD = 'UPDATE_CREATE_LIVELEAD';
const UPDATE_GET_MAILER = 'UPDATE_GET_MAILER';
const UPDATE_CREATE_MAILER = 'UPDATE_CREATE_MAILER';
const UPDATE_GET_SOCIALMEDIA = 'UPDATE_GET_SOCIALMEDIA';
const UPDATE_CREATE_SOCIALMEDIA = 'UPDATE_CREATE_SOCIALMEDIA';
const UPDATE_GET_DATALEAD = 'UPDATE_GET_DATALEAD';
const UPDATE_CREATE_DATALEAD = 'UPDATE_CREATE_DATALEAD';
const UPDATE_GET_AGEDLEAD = 'UPDATE_GET_AGEDLEAD';
const UPDATE_CREATE_AGEDLEAD = 'UPDATE_CREATE_AGEDLEAD';
const UPDATE_GET_SALESAGENT = 'UPDATE_GET_SALESAGENT';
const UPDATE_CREATE_SALESAGENT = 'UPDATE_CREATE_SALESAGENT';
const UPDATE_GET_AGENT = 'UPDATE_GET_AGENT';
const UPDATE_GET_LEADSOURCE = 'UPDATE_GET_LEADSOURCE';
const UPDATE_GET_OBJECTION = 'UPDATE_GET_OBJECTION';
const UPDATE_GET_INELIGIBLE = 'UPDATE_GET_INELIGIBLE';
const UPDATE_GET_DWELLING = 'UPDATE_GET_DWELLING';
const UPDATE_GET_STATUS = 'UPDATE_GET_STATUS';
const UPDATE_GET_SPECIALTY = 'UPDATE_GET_SPECIALTY';
const UPDATE_CREATE_QUOTELOG = 'UPDATE_CREATE_QUOTELOG';
const UPDATE_GET_QUOTELOG = 'UPDATE_GET_QUOTELOG';
const UPDATE_CREATE_AGENCY = 'UPDATE_CREATE_AGENCY';
const UPDATE_GET_AGENCY = 'UPDATE_GET_AGENCY';
const UPDATE_CREATE_AGENT = 'UPDATE_CREATE_AGENT';
const UPDATE_GET_QUOTELOGSUMMARY = 'UPDATE_GET_QUOTELOGSUMMARY';
const UPDATE_GET_QUOTELOGVENDORSUMMARY = 'UPDATE_GET_QUOTELOGVENDORSUMMARY';
const UPDATE_GET_USERAGENT = 'UPDATE_GET_USERAGENT';
const UPDATE_CREATE_USERAGENT = 'UPDATE_CREATE_USERAGENT';
const UPDATE_DELETE_AGENT = 'UPDATE_DELETE_AGENT';
const UPDATE_CREATE_LEADSOURCE = 'UPDATE_CREATE_LEADSOURCE';
const UPDATE_HANDLE_ERROR = 'UPDATE_HANDLE_ERROR';
const UPDATE_DELETE_QUOTELOG = 'UPDATE_DELETE_QUOTELOG';
const UPDATE_SEARCH_QUOTELOG = 'UPDATE_SEARCH_QUOTELOG';
const UPDATE_CREATE_PAYMENT = 'UPDATE_CREATE_PAYMENT';
const UPDATE_GET_QUOTELOGZIPSUMMARY = 'UPDATE_GET_QUOTELOGZIPSUMMARY';
const UPDATE_GET_QUOTELOGDWELLINGS = 'UPDATE_GET_QUOTELOGDWELLINGS';
const UPDATE_GET_QUOTELOGSPECIALTY = 'UPDATE_GET_QUOTELOGSPECIALTY';
const UPDATE_GET_AGENTSTALKTIME = 'UPDATE_GET_AGENTSTALKTIME';
const UPDATE_GET_FORGOTPASSWORD = 'UPDATE_GET_FORGOTPASSWORD';
const UPDATE_CREATE_SOLDQUOTELOG = 'UPDATE_CREATE_SOLDQUOTELOG';
const UPDATE_GET_QUOTELOGDWELLINGSSOLD = 'UPDATE_GET_QUOTELOGDWELLINGSSOLD';
const UPDATE_GET_QUOTELOGSPECIALTYSOLD = 'UPDATE_GET_QUOTELOGSPECIALTYSOLD';
const UPDATE_GET_QUOTELOGSOLD = 'UPDATE_GET_QUOTELOGSOLD';
const UPDATE_GET_ONBOARDING = 'UPDATE_GET_ONBOARDING';
const UPDATE_DELETE_VENDOR = 'UPDATE_DELETE_VENDOR';
const UPDATE_QUOTELOG_LOADING = 'UPDATE_QUOTELOG_LOADING';
const UPDATE_GET_MARKETINGSUMMARY = 'UPDATE_GET_MARKETINGSUMMARY';
const UPDATE_GET_REPORTSUMMARY = 'UPDATE_GET_REPORTSUMMARY';
const UPDATE_GET_VENDORSUMMARY = 'UPDATE_GET_VENDORSUMMARY';
const UPDATE_UPDATE_ONBOARDING = 'UPDATE_UPDATE_ONBOARDING';
const UPDATE_SEARCH_ONBOARDING = 'UPDATE_SEARCH_ONBOARDING';
const UPDATE_CREATE_QUOTELOGSOLDQUOTELOG = 'UPDATE_CREATE_QUOTELOGSOLDQUOTELOG';
const UPDATE_GET_TYPEVENDORTYPE = 'UPDATE_GET_TYPEVENDORTYPE';
const UPDATE_CREATE_TYPEVENDORTYPE = 'UPDATE_CREATE_TYPEVENDORTYPE';
const UPDATE_GET_AGENTPREMIUMS = 'UPDATE_GET_AGENTPREMIUMS';
const UPDATE_CREATE_ZIPUPLOAD = 'UPDATE_CREATE_ZIPUPLOAD';
const UPDATE_GET_ZIPUPLOADS = 'UPDATE_GET_ZIPUPLOADS';
const UPDATE_GET_ZIPUPLOADFILENAMES = 'UPDATE_GET_ZIPUPLOADFILENAMES';
const UPDATE_DELETE_ZIPUPLOAD = 'UPDATE_DELETE_ZIPUPLOAD';
const UPDATE_GET_VENDORTYPESUMMARY = 'UPDATE_GET_VENDORTYPESUMMARY';
const UPDATE_GET_POLICIESSUMMARY = 'UPDATE_GET_POLICIESSUMMARY';
const UPDATE_GET_AVERAGEPREMIUM = 'UPDATE_GET_AVERAGEPREMIUM';
const UPDATE_GET_QUOTEAGENTSUMMARY = 'UPDATE_GET_QUOTEAGENTSUMMARY';
const UPDATE_ACTIVITYSUMMARY_LOADING = 'UPDATE_ACTIVITYSUMMARY_LOADING';
const UPDATE_GET_QUOTEAGENTSUMMARYBYAGENT = 'UPDATE_GET_QUOTEAGENTSUMMARYBYAGENT';
const UPDATE_GET_CITYSTATE = 'UPDATE_GET_CITYSTATE';
const UPDATE_CREATE_MARKETINGAGENT = 'UPDATE_CREATE_MARKETINGAGENT';
const UPDATE_GET_MARKETINGAGENT = 'UPDATE_GET_MARKETINGAGENT';
const UPDATE_GET_AVERAGEPREMIUMBYAGENT = 'UPDATE_GET_AVERAGEPREMIUMBYAGENT';
const UPDATE_GET_QUOTELOGDAILYITEMS = 'UPDATE_GET_QUOTELOGDAILYITEMS';
const UPDATE_GET_TYPEVENDORTYPELEADSOURCE = 'UPDATE_GET_TYPEVENDORTYPELEADSOURCE';
const UPDATE_GET_MARKETINGAGENTUSERS = 'UPDATE_GET_MARKETINGAGENTUSERS';
const UPDATE_GET_TOTALMARKETINGAGENTUSERS = 'UPDATE_GET_TOTALMARKETINGAGENTUSERS';
const UPDATE_GET_DASHBOARD = 'UPDATE_GET_DASHBOARD';
const UPDATE_GET_TOTALSALESAGENTSUMMARY = 'UPDATE_GET_TOTALSALESAGENTSUMMARY';
const UPDATE_GET_ACTUALMARKETINGSUMMARY = 'UPDATE_GET_ACTUALMARKETINGSUMMARY';
const UPDATE_GET_DNQREPORT = 'UPDATE_GET_DNQREPORT';
const UPDATE_CREATE_SERIOUSUPLOAD = 'UPDATE_CREATE_SERIOUSUPLOAD';
const UPDATE_GET_SERIOUSUPLOAD = 'UPDATE_GET_SERIOUSUPLOAD';
const UPDATE_GET_SERIOUSUPLOADFILES = 'UPDATE_GET_SERIOUSUPLOADFILES';
const UPDATE_GET_QUOTELOGSERIOUSUPLOAD = 'UPDATE_GET_QUOTELOGSERIOUSUPLOAD';
const UPDATE_UPDATE_VERIFYQUOTELOG = 'UPDATE_UPDATE_VERIFYQUOTELOG';
const UPDATE_GET_SINGLEAGENT = 'UPDATE_GET_SINGLEAGENT';
const UPDATE_GET_ALLAGENCIES = 'UPDATE_GET_ALLAGENCIES';
const UPDATE_DELETE_SERIOUSUPLOADFILES = 'UPDATE_DELETE_SERIOUSUPLOADFILES';
const UPDATE_CREATE_NEWBUSINESSUPLOAD = 'UPDATE_CREATE_NEWBUSINESSUPLOAD';
const UPDATE_GET_NEWBUSINESSUPLOAD = 'UPDATE_GET_NEWBUSINESSUPLOAD';
const UPDATE_GET_NEWBUSINESSUPLOADFILES = 'UPDATE_GET_NEWBUSINESSUPLOADFILES';
const UPDATE_GET_QUOTELOGNEWBUSINESSUPLOAD = 'UPDATE_GET_QUOTELOGNEWBUSINESSUPLOAD';
const UPDATE_DELETE_NEWBUSINESSUPLOADFILES = 'UPDATE_DELETE_NEWBUSINESSUPLOADFILES';
const UPDATE_GET_REFERRINGAGENT = 'UPDATE_GET_REFERRINGAGENT';
const UPDATE_CREATE_REFERRINGAGENT = 'UPDATE_CREATE_REFERRINGAGENT';
const UPDATE_GET_CURRENTCARRIERSUMMARY = 'UPDATE_GET_CURRENTCARRIERSUMMARY';
const GET_CREATE_SERIOUSUPLOAD = 'GET_CREATE_SERIOUSUPLOAD';
const GET_CREATE_NEWBUSINESSUPLOAD = 'GET_CREATE_NEWBUSINESSUPLOAD';
const GET_CREATE_TERMINATIONUPLOAD = 'GET_CREATE_TERMINATIONUPLOAD';
const UPDATE_GET_MONTHLYWRITTENBUNDLES = 'UPDATE_GET_MONTHLYWRITTENBUNDLES';
const UPDATE_GET_REFERRINGAGENTSUMMARY = 'UPDATE_GET_REFERRINGAGENTSUMMARY';
const UPDATE_AVERAGESUMMARY_LOADING = 'UPDATE_AVERAGESUMMARY_LOADING';
const UPDATE_GET_EDUCATIONSECTIONS = 'UPDATE_GET_EDUCATIONSECTIONS';
const UPDATE_CREATE_EDUCATIONSECTIONS = 'UPDATE_CREATE_EDUCATIONSECTIONS';
const UPDATE_GET_EDUCATIONMATERIALS = 'UPDATE_GET_EDUCATIONMATERIALS';
const UPDATE_CREATE_EDUCATIONMATERIALS = 'UPDATE_CREATE_EDUCATIONMATERIALS';
const UPDATE_GET_ACQUISITIONCOST = 'UPDATE_GET_ACQUISITIONCOST';
const UPDATE_CREATE_TERMINATIONUPLOAD = 'UPDATE_CREATE_TERMINATIONUPLOAD';
const UPDATE_GET_TERMINATIONUPLOAD = 'UPDATE_GET_TERMINATIONUPLOAD';
const UPDATE_GET_TERMINATIONUPLOADFILES = 'UPDATE_GET_TERMINATIONUPLOADFILES';
const UPDATE_GET_QUOTELOGTERMINATIONUPLOAD = 'UPDATE_GET_QUOTELOGTERMINATIONUPLOAD';
const UPDATE_DELETE_TERMINATIONUPLOADFILES = 'UPDATE_DELETE_TERMINATIONUPLOADFILES';
const UPDATE_GET_AUTOPOINTS = 'UPDATE_GET_AUTOPOINTS';
const UPDATE_GET_DWELLINGPOINTS = 'UPDATE_GET_DWELLINGPOINTS';
const UPDATE_GET_SPECIALTYPOINTS = 'UPDATE_GET_SPECIALTYPOINTS';
const UPDATE_GET_LEADSOURCEAGENTSUMMARY = 'UPDATE_GET_LEADSOURCEAGENTSUMMARY';
const UPDATE_GET_SALESPRODUCERGOALS = 'UPDATE_GET_SALESPRODUCERGOALS';
const UPDATE_CREATE_EMCARRIER = 'UPDATE_CREATE_EMCARRIER';
const UPDATE_GET_EMCARRIERS = 'UPDATE_GET_EMCARRIERS'
const UPDATE_CREATE_EMPRODUCT = 'UPDATE_CREATE_EMPRODUCT';
const UPDATE_GET_EMPRODUCTS = 'UPDATE_GET_EMPRODUCTS';
const UPDATE_GET_EMPRODUCTCHECKBOXES = 'UPDATE_GET_EMPRODUCTCHECKBOXES';

const initialState = { 
    vendors: [], 
    vendorTypes: [], 
    user: '', 
    marketing: [], 
    telemarketing: [], 
    liveLead: [], 
    mailer: [], 
    socialMedia: [], 
    dataLead: [], 
    agedLead: [], 
    agent: [],
    leadSource: [],
    objection: [],
    ineligible: [],
    status: [],
    dwelling: [],
    specialty: [],
    salesAgent: [],
    quoteLog: [],
    isLoading: false,
    angencyId: '',
    agency: '',
    quoteLogSummary: [],
    quoteLogVendorSummary: [],
    error: '',
    paymentId: '',
    quoteLogZipSummary: [],
    quoteLogDwellings: [],
    quoteLogSpecialty: [],
    agentsTalkTime: [],
    forgotPasswordResult: [],
    quoteLogSold: [],
    dwellingSold: [],
    specialtySold: [],
    onboarding: [],
    isQuoteLogLoading: false,
    marketingSummary: [],
    reportSummary: [],
    onboardingRecord: [],
    typeVendorTypes: [],
    agentPremiums: [],
    zipUpload: '',
    zipCounts: [],
    zipUploadFilename: [],
    vendorTypeSummary: [],
    policiesSummary: [],
    quoteLogAveragePremium: [],
    quoteAgentSummary: [],
    quoteAgentSummaryByAgent: [],
    cityState: [],
    marketingAgent: [],
    dailyItems: [],
    typeVendorTypesLeadSources: [],
    marketingAgentUsers: [],
    totalMarketingAgent: '',
    dashboard: [],
    totalSalesAgentSummary: [],
    actualMarketingSummary: [],
    dnqReport: [],
    seriousUploads: [],
    seriousUploadFiles: [],
    quoteLogSeriousUploads: [],
    singleQuoteLog: [],
    singleAgent: '',
    agencies: [],
    newBusinessUploads: [],
    newBusinessUploadFiles: [],
    quoteLogNewBusinessUploads: [],
    referringAgent: [],
    currentCarrierSummary: [],
    monthlyWrittenBundles: [],
    referringAgentSummary: [],
    isAverageSummaryLoading: false,
    educationSections: [],
    educationMaterials: [],
    acquisitionCost: [],
    terminationUploads: [],
    terminationUploadFiles: [],
    quoteLogTerminationUploads: [],
    autoPoints: [],
    dwellingPoints: [],
    specialtyPoints: [],
    leadSourceAgentSummary: [],
    salesProducerGoals: [],
    emCarriers: [],
    emProducts: [],
    emProductCheckboxes: []
};
let config = require('../config.json');

export const actionCreators = {
    createVendor: (id, name, vendorType, writtenItems, writtenPremium, spend, state, rate, quoteRatio, closeRatio, agencyId, vendorId, inactive, autoBundle) => async (dispatch) => {
        var data = JSON.stringify({ id: id, name: name, vendorType: vendorType, writtenItems: writtenItems, writtenPremium: writtenPremium, spend: spend, state: state, rate: rate, quoteRatio: quoteRatio, closeRatio: closeRatio, agencyId: agencyId, inactive: inactive, vendorId: vendorId, autobundle: autoBundle });
        const url = config.baseUrl + '/api/Vendor';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const vendors = await response.json();

        dispatch({ type: UPDATE_CREATE_VENDOR, vendors });
    },
    updateVendor: (id, name, vendorType, writtenItems, writtenPremium, spend, state, rate, quoteRatio, closeRatio) => async (dispatch) => {
        var data = JSON.stringify({ id: id, name: name, vendorType: vendorType, writtenItems: writtenItems, writtenPremium: writtenPremium, spend: spend, state: state, rate: rate, quoteRatio: quoteRatio, closeRatio: closeRatio });
        const url = config.baseUrl + '/api/Vendor/UpdateVendor';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const vendors = await response.json();

        dispatch({ type: UPDATE_UPDATE_VENDOR, vendors });
    },
    getVendorTypes: () => async (dispatch, getState) => {
        if (getState().action.vendorTypes.length > 0) {
            return
        }

        const url = config.baseUrl + '/api/VendorType';
        const response = await fetch(url);
        const vendorTypes = await response.json();

        dispatch({ type: UPDATE_GET_VENDORTYPE, vendorTypes });
        
    },
    getVendors: (agencyId) => async (dispatch, getState) => {
        // if (getState().action.vendors.length > 0) {
        //     return
        // }

        const url = config.baseUrl + '/api/Vendor?agencyId=' + agencyId;
        const response = await fetch(url);
        const vendors = await response.json();

        dispatch({ type: UPDATE_GET_VENDORS, vendors });
        
    },
    createUser: (userName, firstName, lastName, email, password, company, phone, address, address2, city, state, zip, paymentId, status) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        var data = JSON.stringify({ userName: userName, firstName: firstName, lastName: lastName, email: email, password: password, company: company, phone: phone, address: address, address2: address2, city: city, state: state, zip: zip, paymentId: paymentId, status: status });
        const url = config.baseUrl + '/api/User';
        try{
            const response = await fetch(url, {
                method: 'POST',
                body: data,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            if(response.ok){
                const user = await response.json();
                dispatch({ type: UPDATE_CREATE_USER, user, isLoading });
            }
            // else{
            //     dispatch({ type: UPDATE_HANDLE_ERROR, response.JSON, isLoading });
            // }
        } catch(error){
            const errorMessage = error.message;
            dispatch({ type: UPDATE_HANDLE_ERROR, errorMessage, isLoading });
        }
    },
    loginUser: (userName, password) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        var data = JSON.stringify({ userName: userName, firstName: "", lastName: "", email: "", password: password, company: "" });
        const url = config.baseUrl + '/api/User/Login';
        
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const user = await response.json();

        dispatch({ type: UPDATE_LOGIN_USER, user, isLoading });
    },
    createMarketing: (userId, agencyId, monthlyPremium, premiumPerItem, itemsPerHousehold, closedRatio, itemsPerPolicy, premiumPerPolicy, premiumPerHousehold, closingRatioPerPolicy, closingRatioPerHousehold, monthDts, quotedBundleRatio, writtenBundleRatio) => async (dispatch) => {
        var data = JSON.stringify({ userId: userId, agencyId: agencyId, monthlyPremium: monthlyPremium, premiumPerItem: premiumPerItem, itemsPerHousehold: itemsPerHousehold, closedRatio: closedRatio, itemsPerPolicy: itemsPerPolicy, premiumPerPolicy: premiumPerPolicy, premiumPerHousehold: premiumPerHousehold, closedRatioPerPolicy: closingRatioPerPolicy, closedRatioPerHousehold: closingRatioPerHousehold, monthDts: monthDts, quotedBundleRatio: quotedBundleRatio, writtenBundleRatio: writtenBundleRatio });
        const url = config.baseUrl + '/api/Marketing';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const marketing = await response.json();

        dispatch({ type: UPDATE_CREATE_MARKETING, marketing });
    },
    getMarketing: (agencyId, monthDts) => async (dispatch) => {
        const url = config.baseUrl + '/api/Marketing/' + agencyId + "/" + monthDts;
        const response = await fetch(url);

        const marketing = await response.json();

        dispatch({ type: UPDATE_GET_MARKETING, marketing });
    },
    createTelemarketing: (agencyId, userId, name, typeQuoteLogLeadSourceId, monthlyCallCount, transferRate, quoteRatio, closeRatio, contactRate, telemarketerCount, telemarketerMonthlyCost, telemarketingQuotedHouseholds, telemarketingQuotedPolicies, telemarketingQuotedItems, telemarketingSoldHouseholds, telemarketingSoldPolicies, telemarketingSoldItems, monthDts, telemarketingTotalPremium, premiumPerItem, actualMonthlyCallCount, actualTransferRate, actualQuoteRatio, actualContactRate, actualTelemarketerMonthlyCost, itemAcquistionCost, householdAcquistionCost, policyAcquistionCost, actualItemAcquistionCost, actualHouseholdAcquistionCost, actualPolicyAcquistionCost, dataLeadCount, actualDataLeadCount, costPerDataLead,  actualCostPerDataLead, monthlyCost, actualMonthlyCost) => async (dispatch) => {
        var data = JSON.stringify({ agencyId: agencyId, userId: userId, name: name, typeQuoteLogLeadSourceId: typeQuoteLogLeadSourceId, monthlyCallCount: monthlyCallCount, transferRate: transferRate, quoteRatio: quoteRatio, closeRatio: closeRatio, telemarketerCount: telemarketerCount, telemarketerMonthlyCost: telemarketerMonthlyCost, quotedHouseholds: telemarketingQuotedHouseholds, quotedPolicies: telemarketingQuotedPolicies, quotedItems: telemarketingQuotedItems, soldHouseholds: telemarketingSoldHouseholds, soldPolicies: telemarketingSoldPolicies, soldItems: telemarketingSoldItems, monthDts: monthDts, totalPremium: telemarketingTotalPremium, verndorId: 0, premiumPerItem: premiumPerItem, actualMonthlyCallCount: actualMonthlyCallCount, actualTransferRate: actualTransferRate, actualQuoteRatio: actualQuoteRatio, actualContactRate: actualContactRate, contactRate: contactRate, actualTelemarketerMonthlyCost: actualTelemarketerMonthlyCost, itemAcquistionCost: itemAcquistionCost, householdAcquistionCost: householdAcquistionCost, policyAcquistionCost: policyAcquistionCost, actualItemAcquistionCost: actualItemAcquistionCost, actualHouseholdAcquistionCost: actualHouseholdAcquistionCost, actualPolicyAcquistionCost: actualPolicyAcquistionCost, dataLeadCount: dataLeadCount, actualDataLeadCount: actualDataLeadCount, costPerDataLead: costPerDataLead,  actualCostPerDataLead: actualCostPerDataLead, monthlyCost: monthlyCost, actualMonthlyCost: actualMonthlyCost });
        const url = config.baseUrl + '/api/Telemarketing';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const telemarketing = await response.json();

        dispatch({ type: UPDATE_CREATE_TELEMARKETING, telemarketing });
    },
    getTelemarketing: (agencyId, monthDts) => async (dispatch) => {
        const url = config.baseUrl + '/api/Telemarketing/' + agencyId + "/" + monthDts;
        const response = await fetch(url);

        const telemarketing = await response.json();

        dispatch({ type: UPDATE_GET_TELEMARKETING, telemarketing });
    },
    createLiveLead: (userId, agencyId, typeQuoteLogLeadSourceId, name, vendorId, monthlyCount, transferRate, quoteRatio, closeRatio, transferCount, monthlyCost, quotedHouseholds, quotedPolicies, quotedItems, soldHouseholds, soldPolicies, soldItems, monthDts, totalPremium, premiumPerItem, actualMonthlyCount, actualQuoteRatio, actualMonthlyCost, itemAcquistionCost, householdAcquistionCost, policyAcquistionCost, actualItemAcquistionCost, actualHouseholdAcquistionCost, actualPolicyAcquistionCost) => async (dispatch) => {
        var data = JSON.stringify({ userId: userId, agencyId: agencyId, typeQuoteLogLeadSourceId: typeQuoteLogLeadSourceId, name: name, vendorId: vendorId, monthlyCount: monthlyCount, transferRatio: transferRate, quoteRatio: quoteRatio, closeRatio: closeRatio, monthlyTransferCount: transferCount, monthlyCost: monthlyCost, quotedHouseholds: quotedHouseholds, quotedPolicies: quotedPolicies, quotedItems: quotedItems, soldHouseholds: soldHouseholds, soldPolicies: soldPolicies, soldItems: soldItems, monthDts: monthDts, totalPremium: totalPremium, transferRatio: "0", premiumPerItem: premiumPerItem, actualMonthlyCount: actualMonthlyCount, actualQuoteRatio: actualQuoteRatio, actualMonthlyCost: actualMonthlyCost, itemAcquistionCost: itemAcquistionCost, householdAcquistionCost: householdAcquistionCost, policyAcquistionCost: policyAcquistionCost, actualItemAcquistionCost: actualItemAcquistionCost, actualHouseholdAcquistionCost: actualHouseholdAcquistionCost, actualPolicyAcquistionCost: actualPolicyAcquistionCost });
        const url = config.baseUrl + '/api/LiveLead';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const liveLead = await response.json();

        dispatch({ type: UPDATE_CREATE_LIVELEAD, liveLead });
    },
    getLiveLead: (agencyId, monthDts) => async (dispatch) => {
        const url = config.baseUrl + '/api/LiveLead/' + agencyId + "/" + monthDts;
        const response = await fetch(url);

        const liveLead = await response.json();

        dispatch({ type: UPDATE_GET_LIVELEAD, liveLead });
    },
    createMailer: (userId, agencyId, typeQuoteLogLeadSourceId, name, vendorId, monthlyCount, responseRate, quoteRatio, actualMonthlyCount, actualResponseRate, actualQuoteRatio, closeRatio, monthlyCost, actualMonthlyCost, quotedHouseholds, quotedPolicies, quotedItems, soldHouseholds, soldPolicies, soldItems, monthDts, totalPremium, premiumPerItem, itemAcquistionCost, householdAcquistionCost, policyAcquistionCost, actualItemAcquistionCost, actualHouseholdAcquistionCost, actualPolicyAcquistionCost) => async (dispatch) => {
        var data = JSON.stringify({ userId: userId, agencyId: agencyId, typeQuoteLogLeadSourceId: typeQuoteLogLeadSourceId, name: name, vendorId: vendorId, monthlyCount: monthlyCount, responseRate: responseRate, quoteRatio: quoteRatio, closeRatio: closeRatio, monthlyCost: monthlyCost, actualMonthlyCost: actualMonthlyCost, quotedHouseholds: quotedHouseholds, quotedPolicies: quotedPolicies, quotedItems: quotedItems, soldHouseholds: soldHouseholds, soldPolicies: soldPolicies, soldItems: soldItems, monthDts: monthDts, totalPremium: totalPremium, actualMonthlyCount: actualMonthlyCount, actualResponseRate: actualResponseRate, actualQuoteRatio: actualQuoteRatio, itemAcquistionCost: itemAcquistionCost, householdAcquistionCost: householdAcquistionCost, policyAcquistionCost: policyAcquistionCost, actualItemAcquistionCost: actualItemAcquistionCost, actualHouseholdAcquistionCost: actualHouseholdAcquistionCost, actualPolicyAcquistionCost: actualPolicyAcquistionCost, premiumPerItem: premiumPerItem});
        const url = config.baseUrl + '/api/Mailer';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const mailer = await response.json();

        dispatch({ type: UPDATE_CREATE_MAILER, mailer });
    },
    getMailer: (agencyId, monthDts) => async (dispatch) => {
        const url = config.baseUrl + '/api/Mailer/' + agencyId + "/" + monthDts;
        const response = await fetch(url);

        const mailer = await response.json();

        dispatch({ type: UPDATE_GET_MAILER, mailer });
    },
    createSocialMedia: (userId, agencyId, typeQuoteLogLeadSourceId, name, vendorId, monthlyCount, transferRate, quoteRatio, closeRatio, campaignCount, monthlyCost, actualMonthlyCount, actualTransferRate, actualQuoteRatio, actualCampaignCount, actualMonthlyCost, quotedHouseholds, quotedPolicies, quotedItems, soldHouseholds, soldPolicies, soldItems, monthDts, totalPremium, premiumPerItem, itemAcquistionCost, householdAcquistionCost, policyAcquistionCost, actualItemAcquistionCost, actualHouseholdAcquistionCost, actualPolicyAcquistionCost) => async (dispatch) => {
        var data = JSON.stringify({ userId: userId, agencyId: agencyId, typeQuoteLogLeadSourceId: typeQuoteLogLeadSourceId, name: name, vendorId: vendorId, monthlyCount: monthlyCount, transferRatio: transferRate, quoteRatio: quoteRatio, closeRatio: closeRatio, campaignCount: campaignCount, monthlyCost: monthlyCost, actualMonthlyCount: actualMonthlyCount, actualTransferRatio: actualTransferRate, actualQuoteRatio: actualQuoteRatio, actualCampaignCount: actualCampaignCount, actualMonthlyCost: actualMonthlyCost, quotedHouseholds: quotedHouseholds, quotedPolicies: quotedPolicies, quotedItems: quotedItems, soldHouseholds: soldHouseholds, soldPolicies: soldPolicies, soldItems: soldItems, monthDts: monthDts, totalPremium: totalPremium, itemAcquistionCost: itemAcquistionCost, householdAcquistionCost: householdAcquistionCost, policyAcquistionCost: policyAcquistionCost, actualItemAcquistionCost: actualItemAcquistionCost, actualHouseholdAcquistionCost: actualHouseholdAcquistionCost, actualPolicyAcquistionCost: actualPolicyAcquistionCost, premiumPerItem: premiumPerItem });
        const url = config.baseUrl + '/api/SocialMedia';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const socialMedia = await response.json();

        dispatch({ type: UPDATE_CREATE_SOCIALMEDIA, socialMedia });
    },
    getSocialMedia: (agencyId, monthDts) => async (dispatch) => {
        const url = config.baseUrl + '/api/SocialMedia/' + agencyId + "/" + monthDts;
        const response = await fetch(url);

        const socialMedia = await response.json();

        dispatch({ type: UPDATE_GET_SOCIALMEDIA, socialMedia });
    },
    createDataLead: (agencyId, typeQuoteLogLeadSourceId, userId, name, vendorId, monthlyCount, transferRate, quoteRatio, closeRatio, monthlyCost, actualMonthlyCount, actualTansferRate, actualQuoteRatio, actualMonthlyCost, quotedHouseholds, quotedPolicies, quotedItems, soldHouseholds, soldPolicies, soldItems, monthDts, totalPremium, premiumPerItem, itemAcquistionCost, householdAcquistionCost, policyAcquistionCost, actualItemAcquistionCost, actualHouseholdAcquistionCost, actualPolicyAcquistionCost) => async (dispatch) => {
        var data = JSON.stringify({agencyId: agencyId, typeQuoteLogLeadSourceId: typeQuoteLogLeadSourceId, userId: userId, name: name, vendorId: vendorId, monthlyCount: monthlyCount, transferRatio: transferRate, quoteRatio: quoteRatio, closeRatio: closeRatio, monthlyCost: monthlyCost, actualMonthlyCount: actualMonthlyCount, actualTansferRate: actualTansferRate, actualQuoteRatio: actualQuoteRatio, actualMonthlyCost: actualMonthlyCost, quotedHouseholds: quotedHouseholds, quotedPolicies: quotedPolicies, quotedItems: quotedItems, soldHouseholds: soldHouseholds, soldPolicies: soldPolicies, soldItems: soldItems, monthDts: monthDts, totalPremium: totalPremium, premiumPerItem: premiumPerItem, itemAcquistionCost: itemAcquistionCost, householdAcquistionCost: householdAcquistionCost, policyAcquistionCost: policyAcquistionCost, actualItemAcquistionCost: actualItemAcquistionCost, actualHouseholdAcquistionCost: actualHouseholdAcquistionCost, actualPolicyAcquistionCost: actualPolicyAcquistionCost });
        const url = config.baseUrl + '/api/DataWebLead';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const dataLead = await response.json();

        dispatch({ type: UPDATE_CREATE_DATALEAD, dataLead });
    },
    getDataLead: (agencyId, monthDts) => async (dispatch) => {
        const url = config.baseUrl + '/api/DataWebLead/' + agencyId + "/" + monthDts;
        const response = await fetch(url);

        const dataLead = await response.json();

        dispatch({ type: UPDATE_GET_DATALEAD, dataLead });
    },
    createAgedLead: (agencyId, typeQuoteLogLeadSourceId, userId, name, vendorId, monthlyCount, transferRate, quoteRatio, closeRatio, monthlyCost, actualMonthlyCount, actualTansferRate, actualQuoteRatio, actualMonthlyCost, quotedHouseholds, quotedPolicies, quotedItems, soldHouseholds, soldPolicies, soldItems, monthDts, totalPremium, premiumPerItem, itemAcquistionCost, householdAcquistionCost, policyAcquistionCost, actualItemAcquistionCost, actualHouseholdAcquistionCost, actualPolicyAcquistionCost) => async (dispatch) => {
        var data = JSON.stringify({ agencyId: agencyId, typeQuoteLogLeadSourceId: typeQuoteLogLeadSourceId, userId: userId, name: name, vendorId: vendorId, monthlyCount: monthlyCount, transferRatio: transferRate, quoteRatio: quoteRatio, closeRatio: closeRatio, monthlyCost: monthlyCost, actualMonthlyCount: actualMonthlyCount, actualTansferRate: actualTansferRate, actualQuoteRatio: actualQuoteRatio, actualMonthlyCost: actualMonthlyCost, quotedHouseholds: quotedHouseholds, quotedPolicies: quotedPolicies, quotedItems: quotedItems, soldHouseholds: soldHouseholds, soldPolicies: soldPolicies, soldItems: soldItems, monthDts: monthDts, totalPremium: totalPremium, premiumPerItem: premiumPerItem, itemAcquistionCost: itemAcquistionCost, householdAcquistionCost: householdAcquistionCost, policyAcquistionCost: policyAcquistionCost, actualItemAcquistionCost: actualItemAcquistionCost, actualHouseholdAcquistionCost: actualHouseholdAcquistionCost, actualPolicyAcquistionCost: actualPolicyAcquistionCost });
        const url = config.baseUrl + '/api/AgedLead';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const agedLead = await response.json();

        dispatch({ type: UPDATE_CREATE_AGEDLEAD, agedLead });
    },
    getAgedLead: (agencyId, monthDts) => async (dispatch) => {
        const url = config.baseUrl + '/api/AgedLead/' + agencyId + "/" + monthDts;
        const response = await fetch(url);

        const agedLead = await response.json();

        dispatch({ type: UPDATE_GET_AGEDLEAD, agedLead });
    },
    createSalesAgent: (userId, topAgentSales, midAgentSales, lowAgentSales, topAgentNumber, midAgentNumber, lowAgentNumber, topAgentCloseRatio, midAgentCloseRatio, lowAgentCloseRatio) => async (dispatch) => {
        var data = JSON.stringify({ userId: userId, topAgentSales: topAgentSales, midAgentSales: midAgentSales, lowAgentSales: lowAgentSales, topAgentNumber: topAgentNumber, midAgentNumber: midAgentNumber, lowAgentNumber: lowAgentNumber, topAgentCloseRatio: topAgentCloseRatio, midAgentCloseRatio: midAgentCloseRatio, lowAgentCloseRatio: lowAgentCloseRatio });
        const url = config.baseUrl + '/api/SalesAgent';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const salesAgent = await response.json();

        dispatch({ type: UPDATE_CREATE_SALESAGENT, salesAgent });
    },
    getSalesAgent: (userId) => async (dispatch) => {
        const url = config.baseUrl + '/api/SalesAgent/' + userId;
        const response = await fetch(url);

        const salesAgent = await response.json();

        dispatch({ type: UPDATE_GET_SALESAGENT, salesAgent });
    },
    getAgent: (agencyId) => async (dispatch) => {
        const url = config.baseUrl + '/api/QuoteLog/' + agencyId;
        const response = await fetch(url);

        const agent = await response.json();

        dispatch({ type: UPDATE_GET_AGENT, agent });
    },
    getSingleAgent: (agencyId, userId) => async (dispatch) => {
        const url = config.baseUrl + '/api/QuoteLog/' + agencyId;
        const response = await fetch(url);

        const agent = await response.json();
        const singleAgent = agent.find(a => a.userId == userId);
        console.log(singleAgent);
        dispatch({ type: UPDATE_GET_SINGLEAGENT, singleAgent });
    },
    getLeadSource: (agencyId) => async (dispatch) => {
        const url = config.baseUrl + '/api/QuoteLog/GetLeadSource?agencyId=' + agencyId;;
        const response = await fetch(url);

        const leadSource = await response.json();

        dispatch({ type: UPDATE_GET_LEADSOURCE, leadSource });
    },
    getDwelling: () => async (dispatch) => {
        const url = config.baseUrl + '/api/QuoteLog/GetDwelling';
        const response = await fetch(url);

        const dwelling = await response.json();

        dispatch({ type: UPDATE_GET_DWELLING, dwelling });
    },
    getSpecialty: () => async (dispatch) => {
        const url = config.baseUrl + '/api/QuoteLog/GetSpecialty';
        const response = await fetch(url);

        const specialty = await response.json();

        dispatch({ type: UPDATE_GET_SPECIALTY, specialty });
    },
    getObjection: () => async (dispatch) => {
        const url = config.baseUrl + '/api/QuoteLog/GetObjection';
        const response = await fetch(url);

        const objection = await response.json();

        dispatch({ type: UPDATE_GET_OBJECTION, objection });
    },
    getIneligible: () => async (dispatch) => {
        const url = config.baseUrl + '/api/QuoteLog/GetIneligible';
        const response = await fetch(url);

        const ineligible = await response.json();

        dispatch({ type: UPDATE_GET_INELIGIBLE, ineligible });
    },
    getStatus: () => async (dispatch) => {
        const url = config.baseUrl + '/api/QuoteLog/GetStatus';
        const response = await fetch(url);

        const status = await response.json();

        dispatch({ type: UPDATE_GET_STATUS, status });
    },
    createQuoteLog: (id, date, agent, status, firstName, lastName, leadSource, dwelling, auto, specialty, objection, ineligible, notes, agencyId, dateFrom, dateTo, zip, bundle, type, typeQuoteLogLeadSourceId, commercialAuto, insuranceScore, selfGenerated, currentCarrier, insuranceScoreAuto, currentCarrierAuto, agentId, referringAgentId, requote, emProducts) => async (dispatch) => {
        var isQuoteLogLoading = true;
        dispatch({ type: UPDATE_QUOTELOG_LOADING, isQuoteLogLoading });
        var data = JSON.stringify({ id: id, dts: date, agent: agent, status: status, firstName: firstName, lastName: lastName, leadSource: leadSource, dwelling: dwelling, auto: auto, specialty: specialty, objection: objection, ineligible: ineligible, notes: notes, agencyId: agencyId, dateFrom: dateFrom, dateTo: dateTo, zip: zip, bundle: bundle, type: type, typeQuoteLogLeadSourceId: typeQuoteLogLeadSourceId, commercialAuto: commercialAuto, insuranceScore: insuranceScore, selfGenerated: selfGenerated, currentCarrier: currentCarrier, insuranceScoreAuto: insuranceScoreAuto, currentCarrierAuto: currentCarrierAuto, agentId: agentId, referringAgentId: referringAgentId, requote: requote, emProducts: emProducts });
        const url = config.baseUrl + '/api/QuoteLog';
        try{
            const response = await fetch(url, {
                method: 'POST',
                body: data,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            const quoteLog = await response.json();
            dispatch({ type: UPDATE_CREATE_QUOTELOG, quoteLog, isQuoteLogLoading });
        }
        catch(error){
            const errorMessage = error.message;
            dispatch({ type: UPDATE_HANDLE_ERROR, errorMessage });
        }
    },
    getQuoteLog: (agencyId) => async (dispatch) => {
        const url = config.baseUrl + '/api/QuoteLog?agencyId=' + agencyId;
        const response = await fetch(url);

        const quoteLog = await response.json();

        dispatch({ type: UPDATE_GET_QUOTELOG, quoteLog });
    },
    getArchivedQuoteLog: (agencyId, dateFrom, dateTo, type, pageNumber, rows) => async (dispatch) => {
        var isQuoteLogLoading = true;
        dispatch({ type: UPDATE_QUOTELOG_LOADING, isQuoteLogLoading });
        const url = config.baseUrl + '/api/QuoteLog/GetQuoteLog?agencyId=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&type=' + type + '&pageNumber=' + pageNumber + '&rows=' + rows;
        const response = await fetch(url);

        const quoteLog = await response.json();

        dispatch({ type: UPDATE_GET_QUOTELOG, quoteLog, isQuoteLogLoading });
    },
    getQuoteLogReport: (agencyId, dateFrom, dateTo, type) => async (dispatch) => {
        var isQuoteLogLoading = true;
        dispatch({ type: UPDATE_QUOTELOG_LOADING, isQuoteLogLoading });
        const url = config.baseUrl + '/api/QuoteLog/GetQuoteLogReport?agencyId=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&type=' + type;
        const response = await fetch(url);

        const quoteLog = await response.json();

        dispatch({ type: UPDATE_GET_QUOTELOG, quoteLog, isQuoteLogLoading });
    },
    createAgency: (name, phone, address, address2, city, state, zip, userId) => async (dispatch) => {
        var data = JSON.stringify({ name: name, phone: phone, address: address, address2: address2, city: city, state: state, zip: zip, userId: userId });
        const url = config.baseUrl + '/api/Agency';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const agencyId = await response.json();

        dispatch({ type: UPDATE_CREATE_AGENCY, agencyId });
    },
    createAgent: (id, firstName, email, dailyQuoteGoal, agencyId, password, userName, status, userId, producerNo, onlySelf, sendBragEmail) => async (dispatch) => {
        var data = JSON.stringify({ id: id, firstName: firstName, email: email, dailyQuoteGoal: dailyQuoteGoal, agencyId: agencyId, password: password, userName: userName, status: status, userId: userId, producerNo: producerNo, onlySelf: onlySelf, sendBragEmail: sendBragEmail });
        const url = config.baseUrl + '/api/QuoteLog/AddAgent';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const agent = await response.json();

        dispatch({ type: UPDATE_CREATE_AGENT, agent });
    },
    getAgency: (userId) => async (dispatch) => {
        const url = config.baseUrl + '/api/Agency/' + userId;
        const response = await fetch(url);

        const agency = await response.json();

        dispatch({ type: UPDATE_GET_AGENCY, agency });
    },
    getAllAgencies: () => async (dispatch) => {
        const url = config.baseUrl + '/api/Agency';
        const response = await fetch(url);

        const agencies = await response.json();

        dispatch({ type: UPDATE_GET_ALLAGENCIES, agencies });
    },
    getQuoteLogSummary: (agencyId, dateFrom, dateTo, quoteLog) => async (dispatch) => {
        //const url = config.baseUrl + '/api/QuoteLog/GetQuoteLogSummary?agencyId=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo;
        const url = config.baseUrl + '/api/QuoteLog/GetQuoteLogSummary';
        var data = JSON.stringify({ agencyId: agencyId, dateFrom: dateFrom, dateTo: dateTo, quoteLog: quoteLog });
        //const response = await fetch(url);
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const quoteLogSummary = await response.json();

        dispatch({ type: UPDATE_GET_QUOTELOGSUMMARY, quoteLogSummary });
    },
    getQuoteLogVendorSummary: (agencyId, dateFrom, dateTo) => async (dispatch) => {
        const url = config.baseUrl + '/api/QuoteLog/GetQuoteLogVendorSummary?agencyId=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo;
        const response = await fetch(url);

        const quoteLogVendorSummary = await response.json();

        dispatch({ type: UPDATE_GET_QUOTELOGVENDORSUMMARY, quoteLogVendorSummary });
    },
    getUserAgent: (email) => async (dispatch) => {
        const url = config.baseUrl + '/api/User/GetUserAgent?email=' + email;
        const response = await fetch(url);

        const userAgent = await response.json();

        dispatch({ type: UPDATE_GET_USERAGENT, userAgent });
    },
    createUserAgent: (email, password) => async (dispatch) => {
        var data = JSON.stringify({ email: email, password: password });
        const url = config.baseUrl + '/api/User/AddUserAgent';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const user = await response.json();

        dispatch({ type: UPDATE_CREATE_USERAGENT, user });
    },
    deleteAgent: (id) => async (dispatch) => {
        const url = config.baseUrl + '/api/QuoteLog/DeleteAgent?id=' + id;
        const response = await fetch(url, {
            method: 'POST'
        });
        const agent = await response.json();

        dispatch({ type: UPDATE_DELETE_AGENT, agent });
    },
    createLeadSource: (agencyId, name, vendorId) => async (dispatch) => {
        var data = JSON.stringify({ agencyId: agencyId, name: name, vendorId: vendorId });
        const url = config.baseUrl + '/api/QuoteLog/AddLeadSource';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const leadSource = await response.json();

        dispatch({ type: UPDATE_CREATE_LEADSOURCE, leadSource });
    },
    deleteQuoteLog: (id, dateFrom, dateTo, agencyId) => async (dispatch) => {
        var isQuoteLogLoading = true;
        dispatch({ type: UPDATE_QUOTELOG_LOADING, isQuoteLogLoading });
        const url = config.baseUrl + '/api/QuoteLog/DeleteQuoteLog?id=' + id + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&agencyId=' + agencyId;
        const response = await fetch(url, {
            method: 'POST'
        });
        const quoteLog = await response.json();

        dispatch({ type: UPDATE_DELETE_QUOTELOG, quoteLog, isQuoteLogLoading });
    },
    searchQuoteLog: (agencyId, searchFilter, searchText, dateFrom, dateTo) => async (dispatch) => {
        var isQuoteLogLoading = true;
        dispatch({ type: UPDATE_QUOTELOG_LOADING, isQuoteLogLoading });
        const url = config.baseUrl + '/api/QuoteLog/SearchQuoteLog?agencyId=' + agencyId + '&searchFilter=' + searchFilter + '&searchText=' + searchText + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo;
        const response = await fetch(url, {
            method: 'POST'
        });
        const quoteLog = await response.json();

        dispatch({ type: UPDATE_SEARCH_QUOTELOG, quoteLog, isQuoteLogLoading });
    },
    makePayment: (nonce, amount) => async (dispatch) => {
        var data = JSON.stringify({ sourceId: nonce, amount: amount });
        const url = config.baseUrl + '/api/User/MakeSquarePayment';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const paymentId = await response.json();

        dispatch({ type: UPDATE_CREATE_PAYMENT, paymentId });
    },
    getQuoteLogZipSummary: (agencyId, dateFrom, dateTo, vendors, agent) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/Reports/GetQuoteLogZipSummary?agencyId=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&vendor=' + vendors + '&agent=' + agent;
        const response = await fetch(url);

        const quoteLogZipSummary = await response.json();

        dispatch({ type: UPDATE_GET_QUOTELOGZIPSUMMARY, quoteLogZipSummary, isLoading });
    },
    getQuoteLogStateSummary: (agencyId, dateFrom, dateTo, vendors, agent) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/Reports/GetQuoteLogStateSummary?agencyId=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&vendor=' + vendors + '&agent=' + agent;
        const response = await fetch(url);

        const quoteLogZipSummary = await response.json();

        dispatch({ type: UPDATE_GET_QUOTELOGZIPSUMMARY, quoteLogZipSummary, isLoading });
    },
    getQuoteLogCitySummary: (agencyId, dateFrom, dateTo, vendors, agent) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/Reports/GetQuoteLogCitySummary?agencyId=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&vendor=' + vendors + '&agent=' + agent;
        const response = await fetch(url);

        const quoteLogZipSummary = await response.json();

        dispatch({ type: UPDATE_GET_QUOTELOGZIPSUMMARY, quoteLogZipSummary, isLoading });
    },
    getQuoteLogDwellings: (quoteLogId) => async (dispatch) => {
        const url = config.baseUrl + '/api/QuoteLog/GetQuoteLogDwelling?quoteLogId=' + quoteLogId;
        const response = await fetch(url);

        const quoteLogDwellings = await response.json();

        dispatch({ type: UPDATE_GET_QUOTELOGDWELLINGS, quoteLogDwellings });
    },
    getQuoteLogSpecialty: (quoteLogId) => async (dispatch) => {
        const url = config.baseUrl + '/api/QuoteLog/GetQuoteLogSpecialty?quoteLogId=' + quoteLogId;
        const response = await fetch(url);

        const quoteLogSpecialty = await response.json();

        dispatch({ type: UPDATE_GET_QUOTELOGSPECIALTY, quoteLogSpecialty });
    },
    getAgentsTalkTime: (agencyId, dts) => async (dispatch) => {
        const url = config.baseUrl + '/api/Reports/GetAgentTalkTime?agencyId=' + agencyId + '&dts=' + dts;
        const response = await fetch(url);

        const agentsTalkTime = await response.json();

        dispatch({ type: UPDATE_GET_AGENTSTALKTIME, agentsTalkTime });
    },
    saveAgentsTalkTime: (agentTalkTime) => async (dispatch) => {
        var data = JSON.stringify({ agentTalkTime: agentTalkTime });
        const url = config.baseUrl + '/api/Reports/SaveAgentTalkTime';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        // const leadSource = await response.json();
        // dispatch({ type: UPDATE_CREATE_LEADSOURCE, leadSource });
    },
    getQuoteLogVendorZipSummary: (agencyId, dateFrom, dateTo) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/Reports/GetQuoteLogVendorZipSummary?agencyId=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo;
        const response = await fetch(url);

        const quoteLogVendorSummary = await response.json();

        dispatch({ type: UPDATE_GET_QUOTELOGVENDORSUMMARY, quoteLogVendorSummary, isLoading });
    },
    forgotPassword: (email) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/User/ForgotPassword?email=' + email;
        const response = await fetch(url);

        const forgotPasswordResult = await response.json();

        dispatch({ type: UPDATE_GET_FORGOTPASSWORD, forgotPasswordResult, isLoading });
    },
    createSoldQuoteLog: (id, quoteLogId, bindDate, effectiveDate, auto, autoPremium, dwellingSoldCheckboxes, specialtySoldCheckboxes, bundle, commercialAuto, commercialAutoPremium) => async (dispatch) => {
        var data = JSON.stringify({ id: id, quoteLogId: quoteLogId, bindDate: bindDate, effectiveDate: effectiveDate, auto: auto, autoPremium: autoPremium, dwellingSoldCheckboxes: dwellingSoldCheckboxes, specialtySoldCheckboxes: specialtySoldCheckboxes, bundle: bundle, commercialAuto: commercialAuto, commercialAutoPremium: commercialAutoPremium });
        const url = config.baseUrl + '/api/QuoteLog/AddQuoteLogSold';
        try{
            const response = await fetch(url, {
                method: 'POST',
                body: data,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            const quoteLogSold = await response.json();
            dispatch({ type: UPDATE_CREATE_SOLDQUOTELOG, quoteLogSold });
        }
        catch(error){
            const errorMessage = error.message;
            dispatch({ type: UPDATE_HANDLE_ERROR, errorMessage });
        }
    },
    addQuoteLogSoldQuoteLog: (bindDate, effectiveDate, soldAuto, autoPremium, dwellingSoldCheckboxes, specialtySoldCheckboxes, soldBundle, date, agent, status, firstName, lastName, leadSource, dwelling, auto, specialty, objection, ineligible, notes, agencyId, dateFrom, dateTo, zip, bundle, type, commercialAuto, soldCommercialAuto, commercialAutoPremium, typeQuoteLogLeadSourceId, insuranceScore, selfGenerated, currentCarrier, insuranceScoreAuto, currentCarrierAuto, agentId, referringAgentId, requote) => async (dispatch) => {
        var isQuoteLogLoading = true;
        dispatch({ type: UPDATE_QUOTELOG_LOADING, isQuoteLogLoading });
        var data = JSON.stringify({ bindDate: bindDate, effectiveDate: effectiveDate, soldAuto: soldAuto, autoPremium: autoPremium, dwellingSoldCheckboxes: dwellingSoldCheckboxes, specialtySoldCheckboxes: specialtySoldCheckboxes, soldBundle: soldBundle, dts: date, agent: agent, status: status, firstName: firstName, lastName: lastName, leadSource: leadSource, dwelling: dwelling, auto: auto, specialty: specialty, objection: objection, ineligible: ineligible, notes: notes, agencyId: agencyId, dateFrom: dateFrom, dateTo: dateTo, zip: zip, bundle: bundle, type: type, commercialAuto: commercialAuto, soldCommercialAuto: soldCommercialAuto, commercialAutoPremium: commercialAutoPremium, typeQuoteLogLeadSourceId: typeQuoteLogLeadSourceId, insuranceScore: insuranceScore, selfGenerated: selfGenerated, currentCarrier: currentCarrier, insuranceScoreAuto: insuranceScoreAuto, currentCarrierAuto: currentCarrierAuto, agentId: agentId, referringAgentId: referringAgentId, requote: requote });
        const url = config.baseUrl + '/api/QuoteLog/QuoteLog_QuoteLogSold';
        try{
            const response = await fetch(url, {
                method: 'POST',
                body: data,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            const quoteLog = await response.json();
            dispatch({ type: UPDATE_CREATE_QUOTELOGSOLDQUOTELOG, quoteLog, isQuoteLogLoading });
        }
        catch(error){
            const errorMessage = error.message;
            dispatch({ type: UPDATE_HANDLE_ERROR, errorMessage });
        }
    },
    getQuoteLogDwellingsSold: (quoteLogId) => async (dispatch) => {
        const url = config.baseUrl + '/api/QuoteLog/GetQuoteLogDwellingSold?quoteLogId=' + quoteLogId;
        const response = await fetch(url);

        const quoteLogDwellingsSold = await response.json();

        dispatch({ type: UPDATE_GET_QUOTELOGDWELLINGSSOLD, quoteLogDwellingsSold });
    },
    getQuoteLogSpecialtySold: (quoteLogId) => async (dispatch) => {
        const url = config.baseUrl + '/api/QuoteLog/GetQuoteLogSpecialtySold?quoteLogId=' + quoteLogId;
        const response = await fetch(url);

        const quoteLogSpecialtySold = await response.json();

        dispatch({ type: UPDATE_GET_QUOTELOGSPECIALTYSOLD, quoteLogSpecialtySold });
    },
    getQuoteLogSold: (quoteLogId) => async (dispatch) => {
        const url = config.baseUrl + '/api/QuoteLog/GetQuoteLogSold?quoteLogId=' + quoteLogId;
        const response = await fetch(url);

        const quoteLogSold = await response.json();

        dispatch({ type: UPDATE_GET_QUOTELOGSOLD, quoteLogSold });
    },
    getOnboarding: (agencyId, dateFrom, dateTo) => async (dispatch) => {
        const url = config.baseUrl + '/api/QuoteLog/GetOnboarding?agencyId=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo;
        const response = await fetch(url);

        const onboarding = await response.json();

        dispatch({ type: UPDATE_GET_ONBOARDING, onboarding });
    },
    deleteVendor: (id, agencyId) => async (dispatch) => {
        const url = config.baseUrl + '/api/Vendor/DeleteVendor?id=' + id + '&agencyId=' + agencyId;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const vendors = await response.json();

        dispatch({ type: UPDATE_DELETE_VENDOR, vendors });
    },
    getMarketingSummary: (agencyId, month) => async (dispatch) => {
        const url = config.baseUrl + '/api/Marketing/getmarketingsummary?agencyId=' + agencyId + '&monthDts=' + month
        const response = await fetch(url);

        const marketingSummary = await response.json();

        dispatch({ type: UPDATE_GET_MARKETINGSUMMARY, marketingSummary });
    },
    getReportSummary: (agencyId, dateFrom, dateTo) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/Reports/GetReportSummary?agencyId=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo;
        const response = await fetch(url);

        const reportSummary = await response.json();

        dispatch({ type: UPDATE_GET_REPORTSUMMARY, reportSummary, isLoading });
    },
    getVendorSummary: (agencyId, dateFrom, dateTo, agent, referringAgent) => async (dispatch) => {
        var isQuoteLogLoading = true;
        dispatch({ type: UPDATE_QUOTELOG_LOADING, isQuoteLogLoading });
        agent = agent == null ? '' : agent;
        referringAgent = referringAgent == null ? '' : referringAgent;
        const url = config.baseUrl + '/api/Reports/GetQuoteLogVendorSummary?agencyId=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&agent=' + agent + '&referringAgent=' + referringAgent;
        const response = await fetch(url);

        const vendorSummary = await response.json();

        dispatch({ type: UPDATE_GET_VENDORSUMMARY, vendorSummary, isQuoteLogLoading });
    },
    updateOnboarding: (id, quoteLogId, bindDate, effectiveDate, auto, autoPremium, dwellingSoldCheckboxes, specialtySoldCheckboxes, trailingDocuments) => async (dispatch) => {
        var data = JSON.stringify({ id: id, quoteLogId: quoteLogId, bindDate: bindDate, effectiveDate: effectiveDate, auto: auto, autoPremium: autoPremium, dwellingSoldCheckboxes: dwellingSoldCheckboxes, specialtySoldCheckboxes: specialtySoldCheckboxes, trailingDocuments: trailingDocuments });
        const url = config.baseUrl + '/api/QuoteLog/updateOnboarding';
        try{
            const response = await fetch(url, {
                method: 'POST',
                body: data,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            const onboardingRecord = await response.json();
            dispatch({ type: UPDATE_UPDATE_ONBOARDING, onboardingRecord });
        }
        catch(error){
            const errorMessage = error.message;
            dispatch({ type: UPDATE_HANDLE_ERROR, errorMessage });
        }
    },
    searchOnboarding: (agencyId, searchFilter, searchText) => async (dispatch) => {
        var isQuoteLogLoading = true;
        dispatch({ type: UPDATE_QUOTELOG_LOADING, isQuoteLogLoading });
        const url = config.baseUrl + '/api/QuoteLog/SearchOnboarding?agencyId=' + agencyId + '&searchFilter=' + searchFilter + '&searchText=' + searchText;
        const response = await fetch(url, {
            method: 'POST'
        });
        const onboarding = await response.json();

        dispatch({ type: UPDATE_SEARCH_ONBOARDING, onboarding, isQuoteLogLoading });
    },
    getTypeVendorTypes: (agencyId) => async (dispatch) => {
        const url = config.baseUrl + '/api/TypeVendorType?agencyId=' + agencyId;
        const response = await fetch(url);
        const typeVendorTypes = await response.json();

        dispatch({ type: UPDATE_GET_TYPEVENDORTYPE, typeVendorTypes });
        
    },
    getTypeVendorTypeLeadSources: (agencyId) => async (dispatch) => {
        const url = config.baseUrl + '/api/TypeVendorType/GetTypeVendorTypeLeadSources?agencyId=' + agencyId;
        const response = await fetch(url);
        const typeVendorTypesLeadSources = await response.json();

        dispatch({ type: UPDATE_GET_TYPEVENDORTYPELEADSOURCE, typeVendorTypesLeadSources });
        
    },
    createTypeVendorType: (id, name, agencyId, inactive, autoBundle) => async (dispatch) => {
        var data = JSON.stringify({ id: id, name: name, agencyId: agencyId, inactive: inactive, autoBundle: autoBundle });
        const url = config.baseUrl + '/api/TypeVendorType';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const typeVendorTypes = await response.json();

        dispatch({ type: UPDATE_CREATE_TYPEVENDORTYPE, typeVendorTypes });
    },
    getAgentPremiums: (agencyId, monthDts) => async (dispatch) => {
        const url = config.baseUrl + '/api/Reports/GetAgentPremiums?agencyid=' + agencyId + '&monthdts=' + monthDts;
        const response = await fetch(url);
        const agentPremiums = await response.json();

        dispatch({ type: UPDATE_GET_AGENTPREMIUMS, agentPremiums });
        
    },
    createZipUpload: (monthDts, fileName, typeQuoteLogLeadSourceId, agencyId, zipCount) => async (dispatch) => {
        var data = JSON.stringify({ monthDts: monthDts, fileName: fileName, typeQuoteLogLeadSourceId: typeQuoteLogLeadSourceId, agencyId: agencyId, zipCount: zipCount });
        const url = config.baseUrl + '/api/ZipUpload';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const zipUpload = await response.json();

        dispatch({ type: UPDATE_CREATE_ZIPUPLOAD, zipUpload });
    },
    getZipUploads: (agencyId, dateFrom, dateTo) => async (dispatch) => {
        const url = config.baseUrl + '/api/ZipUpload?agencyid=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo;
        const response = await fetch(url);
        const zipCounts = await response.json();

        dispatch({ type: UPDATE_GET_ZIPUPLOADS, zipCounts });
        
    },
    getZipUploadFilenames: (agencyId, dateFrom, dateTo) => async (dispatch) => {
        const url = config.baseUrl + '/api/ZipUpload/GetZipUploadFilenames?agencyid=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo;
        const response = await fetch(url);
        const zipUploadFilename = await response.json();

        dispatch({ type: UPDATE_GET_ZIPUPLOADFILENAMES, zipUploadFilename });
        
    },
    deleteZipUpload: (agencyId, dateFrom, dateTo, monthDts, fileName) => async (dispatch) => {
        var data = JSON.stringify({ monthDts: monthDts, fileName: fileName, agencyId: agencyId, dateTo: dateTo, dateFrom: dateFrom });
        const url = config.baseUrl + '/api/ZipUpload/DeleteZipUpload';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const zipCounts = await response.json();

        dispatch({ type: UPDATE_DELETE_ZIPUPLOAD, zipCounts });
    },
    getQuoteLogVendorTypeSummary: (agencyId, dateFrom, dateTo) => async (dispatch) => {
        const url = config.baseUrl + '/api/Reports/GetQuoteLogVendorTypeSummary?agencyid=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo;
        const response = await fetch(url);
        const vendorTypeSummary = await response.json();

        dispatch({ type: UPDATE_GET_VENDORTYPESUMMARY, vendorTypeSummary });
        
    },
    getQuoteLogPoliciesSummary: (agencyId, dateFrom, dateTo, agent, leadSourceType) => async (dispatch) => {
        var isActivityLoading = true;
        dispatch({ type: UPDATE_ACTIVITYSUMMARY_LOADING, isActivityLoading });
        const url = config.baseUrl + '/api/Reports/GetQuoteLogPoliciesSummary?agencyid=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&agent=' + agent + '&leadSourceType=' + leadSourceType;
        const response = await fetch(url);
        const policiesSummary = await response.json();

        dispatch({ type: UPDATE_GET_POLICIESSUMMARY, policiesSummary, isActivityLoading });
        
    },
    getQuoteLogAveragePremium: (agencyId) => async (dispatch) => {
        var isAverageSummaryLoading = true;
        dispatch({ type: UPDATE_AVERAGESUMMARY_LOADING, isAverageSummaryLoading });
        const url = config.baseUrl + '/api/QuoteLog/GetQuoteLogPremiumAverage?agencyid=' + agencyId;
        const response = await fetch(url);
        const quoteLogAveragePremium = await response.json();

        dispatch({ type: UPDATE_GET_AVERAGEPREMIUM, quoteLogAveragePremium, isAverageSummaryLoading });
        
    },
    getQuoteLogAverageItem: (agencyId) => async (dispatch) => {
        var isAverageSummaryLoading = true;
        dispatch({ type: UPDATE_AVERAGESUMMARY_LOADING, isAverageSummaryLoading });
        const url = config.baseUrl + '/api/QuoteLog/GetQuoteLogItemsAverage?agencyid=' + agencyId;
        const response = await fetch(url);
        const quoteLogAveragePremium = await response.json();

        dispatch({ type: UPDATE_GET_AVERAGEPREMIUM, quoteLogAveragePremium, isAverageSummaryLoading });
        
    },
    getQuoteLogAveragePremiumByAgent: (agencyId, agent) => async (dispatch) => {
        const url = config.baseUrl + '/api/QuoteLog/GetQuoteLogPremiumAverageByAgent?agencyid=' + agencyId + '&agent=' + agent;
        const response = await fetch(url);
        const quoteLogAveragePremium = await response.json();

        dispatch({ type: UPDATE_GET_AVERAGEPREMIUM, quoteLogAveragePremium });
        
    },
    getQuoteLogAverageItemByAgent: (agencyId, agent) => async (dispatch) => {
        const url = config.baseUrl + '/api/QuoteLog/GetQuoteLogItemsAverageByAgent?agencyid=' + agencyId + '&agent=' + agent;
        const response = await fetch(url);
        const quoteLogAveragePremium = await response.json();

        dispatch({ type: UPDATE_GET_AVERAGEPREMIUM, quoteLogAveragePremium });
        
    },
    // getQuoteAgentSummary: (agencyId, dateFrom, dateTo) => async (dispatch) => {
    //     var isActivityLoading = true;
    //     dispatch({ type: UPDATE_ACTIVITYSUMMARY_LOADING, isActivityLoading });
    //     //const url = config.baseUrl + '/api/QuoteLog/GetQuoteAgentSummary?agencyid=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo;
    //     const url = config.baseUrl + '/api/Reports/GetAgentSummaryTest?agencyid=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo;
    //     const response = await fetch(url);
    //     const quoteAgentSummary = await response.json();

    //     dispatch({ type: UPDATE_GET_QUOTEAGENTSUMMARY, quoteAgentSummary, isActivityLoading });
        
    // },
    getQuoteAgentSummary: (agencyId, dateFrom, dateTo) => async (dispatch) => {
        var isActivityLoading = true;
        dispatch({ type: UPDATE_ACTIVITYSUMMARY_LOADING, isActivityLoading });
        const url = config.baseUrl + '/api/QuoteLog/GetAgentSummary?agencyid=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo;
        
        const response = await fetch(url);
        const quoteAgentSummary = await response.json();

        dispatch({ type: UPDATE_GET_QUOTEAGENTSUMMARY, quoteAgentSummary, isActivityLoading });
        
    },
    getQuoteAgentSummaryByAgent: (agencyId, dateFrom, dateTo, agent) => async (dispatch) => {
        var isActivityLoading = true;
        dispatch({ type: UPDATE_ACTIVITYSUMMARY_LOADING, isActivityLoading });
        const url = config.baseUrl + '/api/QuoteLog/GetQuoteAgentSummaryByAgent?agencyid=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&agent=' + agent;
        const response = await fetch(url);
        const quoteAgentSummaryByAgent = await response.json();

        dispatch({ type: UPDATE_GET_QUOTEAGENTSUMMARYBYAGENT, quoteAgentSummaryByAgent, isActivityLoading });
        
    },
    getQuoteAgentSummaryDnq: (agencyId, dateFrom, dateTo, excludeDnq) => async (dispatch) => {
        var isActivityLoading = true;
        dispatch({ type: UPDATE_ACTIVITYSUMMARY_LOADING, isActivityLoading });
        const url = config.baseUrl + '/api/Reports/GetAgentSummaryTest?agencyid=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&excludeDnq=' + excludeDnq;
        const response = await fetch(url);
        const quoteAgentSummary = await response.json();

        dispatch({ type: UPDATE_GET_QUOTEAGENTSUMMARY, quoteAgentSummary, isActivityLoading });
        
    },
    getSalesAgentSummary: (agencyId, dateFrom, dateTo, agent) => async (dispatch) => {
        var isActivityLoading = true;
        dispatch({ type: UPDATE_ACTIVITYSUMMARY_LOADING, isActivityLoading });
        const url = config.baseUrl + '/api/QuoteLog/GetSalesAgentSummary?agencyid=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&agent=' + agent;
        const response = await fetch(url);
        const quoteAgentSummaryByAgent = await response.json();

        dispatch({ type: UPDATE_GET_QUOTEAGENTSUMMARYBYAGENT, quoteAgentSummaryByAgent, isActivityLoading });
        
    },
    getCityState: (zipCode) => async (dispatch) => {
        var isActivityLoading = true;
        dispatch({ type: UPDATE_ACTIVITYSUMMARY_LOADING, isActivityLoading });
        const url = "https://secure.shippingapis.com/ShippingAPI.dll?API= CityStateLookup&XML=";
        const xml = '<CityStateLookupRequest USERID="212HUMBL8049"><ZipCode ID="0"><Zip5>' + zipCode + '</Zip5></ZipCode></CityStateLookupRequest>';
        const response = await fetch(url + xml);
        const cityState = await response.text();

        dispatch({ type: UPDATE_GET_CITYSTATE, cityState, isActivityLoading });
        
    },
    createMarketingAgent: (agencyId, agentId, monthlyPremium, premiumPerItem, itemsPerHousehold, closedRatio, itemsPerPolicy, premiumPerPolicy, premiumPerHousehold, closingRatioPerPolicy, closingRatioPerHousehold, quotedBundleRatio, writtenBundleRatio, monthDts) => async (dispatch) => {
        var isActivityLoading = true;
        dispatch({ type: UPDATE_ACTIVITYSUMMARY_LOADING, isActivityLoading });
        var data = JSON.stringify({ agencyId: agencyId, agentId: agentId, monthlyPremium: monthlyPremium, premiumPerItem: premiumPerItem, itemsPerHousehold: itemsPerHousehold, closedRatio: closedRatio, itemsPerPolicy: itemsPerPolicy, premiumPerPolicy: premiumPerPolicy, premiumPerHousehold: premiumPerHousehold, closedRatioPerPolicy: closingRatioPerPolicy, closedRatioPerHousehold: closingRatioPerHousehold, quotedBundleRatio: quotedBundleRatio, writtenBundleRatio: writtenBundleRatio, monthDts: monthDts });
        const url = config.baseUrl + '/api/Marketing/AddMarketingAgent';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const marketingAgent = await response.json();

        dispatch({ type: UPDATE_CREATE_MARKETINGAGENT, marketingAgent, isActivityLoading });
    },
    getMarketingAgent: (agentId, monthDts) => async (dispatch) => {
        const url = config.baseUrl + '/api/Marketing/GetMarketingAgent?agentId=' + agentId + '&monthDts=' + monthDts;
        const response = await fetch(url);

        const marketingAgent = await response.json();

        dispatch({ type: UPDATE_GET_MARKETINGAGENT, marketingAgent });
    },
    getQuoteLogDailyItems: (agencyId, dateFrom, dateTo, agent, leadSourceList, type) => async (dispatch) => {
        var isActivityLoading = true;
        dispatch({ type: UPDATE_ACTIVITYSUMMARY_LOADING, isActivityLoading });
        var typeFunction = type == 'items' ? 'GetQuoteLogDailyItems' : type == 'policies' ? 'GetQuoteLogDailyPolicies' : 'GetQuoteLogDailyHouseholds';
        const url = config.baseUrl + '/api/QuoteLog/' + typeFunction + '?agencyid=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&agent=' + agent + '&leadSourceList=' + leadSourceList;
        const response = await fetch(url);
        const dailyItems = await response.json();

        dispatch({ type: UPDATE_GET_QUOTELOGDAILYITEMS, dailyItems, isActivityLoading });
        
    },
    getMarketingAgentUsers: (agencyId) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/Marketing/GetAllMarketingAgent?agencyId=' + agencyId;
        const response = await fetch(url);

        const marketingAgentUsers = await response.json();

        dispatch({ type: UPDATE_GET_MARKETINGAGENTUSERS, marketingAgentUsers });
    },
    getTotalMarketingAgentUsers: (agencyId, monthDts) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/Marketing/GetTotalMarketingAgent?agencyId=' + agencyId + '&monthDts=' + monthDts;
        const response = await fetch(url);

        const totalMarketingAgent = await response.json();

        dispatch({ type: UPDATE_GET_TOTALMARKETINGAGENTUSERS, totalMarketingAgent });
    },
    getDashboard: (agencyId) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/Reports/GetDashboard?agencyId=' + agencyId;
        const response = await fetch(url);

        const dashboard = await response.json();

        dispatch({ type: UPDATE_GET_DASHBOARD, dashboard, isLoading });
    },
    getTotalSalesAgentSummary: (agencyId, dateFrom, dateTo) => async (dispatch) => {
        var isActivityLoading = true;
        dispatch({ type: UPDATE_ACTIVITYSUMMARY_LOADING, isActivityLoading });
        const url = config.baseUrl + '/api/QuoteLog/GetTotalSalesAgentSummary?agencyId=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo;
        const response = await fetch(url);

        const totalSalesAgentSummary = await response.json();

        dispatch({ type: UPDATE_GET_TOTALSALESAGENTSUMMARY, totalSalesAgentSummary, isActivityLoading });
    },
    getActualMarketingSummary: (agencyId, dateFrom, dateTo) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/Marketing/GetActualMarketingSummary?agencyId=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo;
        const response = await fetch(url);

        const actualMarketingSummary = await response.json();

        dispatch({ type: UPDATE_GET_ACTUALMARKETINGSUMMARY, actualMarketingSummary });
    },
    getDNQReport: (agencyId, dateFrom, dateTo, agent) => async (dispatch) => {
        var isQuoteLogLoading = true;
        dispatch({ type: UPDATE_QUOTELOG_LOADING, isQuoteLogLoading });
        const url = config.baseUrl + '/api/Reports/GetDNQReport?agencyId=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + "&agent=" + agent;
        const response = await fetch(url);

        const dnqReport = await response.json();

        dispatch({ type: UPDATE_GET_DNQREPORT, dnqReport, isQuoteLogLoading });
    },
    createSeriousUpload: (seriousUploads, dateFrom, dateTo) => async (dispatch) => {
        var isSeriousLoading = true;
        dispatch({ type: GET_CREATE_SERIOUSUPLOAD, isSeriousLoading });
        var data = JSON.stringify({ seriousUploads: seriousUploads, dateFrom: dateFrom, dateTo: dateTo });
        const url = config.baseUrl + '/api/SeriousUpload';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const seriousUpload = await response.json();

        dispatch({ type: UPDATE_CREATE_SERIOUSUPLOAD, seriousUpload, isSeriousLoading });
    },
    getSeriousUpload: (agencyId, fileName) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/SeriousUpload?agencyId=' + agencyId + '&fileName=' + fileName;
        const response = await fetch(url);

        const seriousUpload = await response.json();

        dispatch({ type: UPDATE_GET_SERIOUSUPLOAD, seriousUpload, isLoading });
    },
    getSeriousUploadByMonth: (agencyId, dateFrom, dateTo, producerNo) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/SeriousUpload/GetSeriousUploadsByMonth?agencyId=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&producerNo=' + producerNo;
        const response = await fetch(url);

        const seriousUpload = await response.json();

        dispatch({ type: UPDATE_GET_SERIOUSUPLOAD, seriousUpload, isLoading });
    },
    getSeriousUploadFiles: (agencyId) => async (dispatch) => {
        const url = config.baseUrl + '/api/SeriousUpload/GetUploadedFiles?agencyId=' + agencyId;
        const response = await fetch(url);

        const seriousUploadFiles = await response.json();

        dispatch({ type: UPDATE_GET_SERIOUSUPLOADFILES, seriousUploadFiles });
    },
    getQuoteLogSeriousUpload: (agencyId, dateFrom, dateTo) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/SeriousUpload/GetQuoteLogSeriousUploads?agencyId=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo;
        const response = await fetch(url);

        const quoteLogSeriousUploads = await response.json();

        dispatch({ type: UPDATE_GET_QUOTELOGSERIOUSUPLOAD, quoteLogSeriousUploads, isLoading });
    },
    createNewBusinessUpload: (newBusinessUploads, dateFrom, dateTo) => async (dispatch) => {
        var isNewBusinessLoading = true;
        dispatch({ type: GET_CREATE_NEWBUSINESSUPLOAD, isNewBusinessLoading });
        var data = JSON.stringify({ newBusinessUploads: newBusinessUploads, dateFrom: dateFrom, dateTo: dateTo });
        const url = config.baseUrl + '/api/NewBusinessUpload';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const newBusinessUpload = await response.json();

        dispatch({ type: UPDATE_CREATE_NEWBUSINESSUPLOAD, newBusinessUpload, isNewBusinessLoading });
    },
    getNewBusinessUpload: (agencyId, fileName) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/NewBusinessUpload?agencyId=' + agencyId + '&fileName=' + fileName;
        const response = await fetch(url);

        const newBusinessUpload = await response.json();

        dispatch({ type: UPDATE_GET_NEWBUSINESSUPLOAD, newBusinessUpload, isLoading });
    },
    getNewBusinessUploadByMonth: (agencyId, dateFrom, dateTo, producerNo) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/NewBusinessUpload/GetNewBusinessUploadsByMonth?agencyId=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&producerNo=' + producerNo;
        const response = await fetch(url);

        const newBusinessUpload = await response.json();

        dispatch({ type: UPDATE_GET_NEWBUSINESSUPLOAD, newBusinessUpload, isLoading });
    },
    getNewBusinessUploadFiles: (agencyId) => async (dispatch) => {
        const url = config.baseUrl + '/api/NewBusinessUpload/GetUploadedFiles?agencyId=' + agencyId;
        const response = await fetch(url);

        const newBusinessUploadFiles = await response.json();

        dispatch({ type: UPDATE_GET_NEWBUSINESSUPLOADFILES, newBusinessUploadFiles });
    },
    getQuoteLogNewBusinessUpload: (agencyId, dateFrom, dateTo) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/NewBusinessUpload/GetQuoteLogNewBusinessUploads?agencyId=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo;
        const response = await fetch(url);

        const quoteLogNewBusinessUploads = await response.json();

        dispatch({ type: UPDATE_GET_QUOTELOGNEWBUSINESSUPLOAD, quoteLogNewBusinessUploads, isLoading });
    },
    createTerminationUpload: (terminationUploads, dateFrom, dateTo) => async (dispatch) => {
        var isTerminationLoading = true;
        dispatch({ type: GET_CREATE_TERMINATIONUPLOAD, isTerminationLoading });
        var data = JSON.stringify({ terminationUploads: terminationUploads, dateFrom: dateFrom, dateTo: dateTo });
        const url = config.baseUrl + '/api/TerminationUpload';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const terminationUpload = await response.json();

        dispatch({ type: GET_CREATE_TERMINATIONUPLOAD, terminationUpload, isTerminationLoading });
    },
    getTerminationUpload: (agencyId, fileName) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/TerminationUpload?agencyId=' + agencyId + '&fileName=' + fileName;
        const response = await fetch(url);

        const terminationUpload = await response.json();

        dispatch({ type: UPDATE_GET_TERMINATIONUPLOAD, terminationUpload, isLoading });
    },
    getTerminationUploadByMonth: (agencyId, dateFrom, dateTo, producerNo) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/TerminationUpload/GetTerminationUploadsByMonth?agencyId=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&producerNo=' + producerNo;
        const response = await fetch(url);

        const terminationUpload = await response.json();

        dispatch({ type: UPDATE_GET_TERMINATIONUPLOAD, terminationUpload, isLoading });
    },
    getTerminationUploadFiles: (agencyId) => async (dispatch) => {
        const url = config.baseUrl + '/api/TerminationUpload/GetUploadedFiles?agencyId=' + agencyId;
        const response = await fetch(url);

        const terminationUploadFiles = await response.json();

        dispatch({ type: UPDATE_GET_TERMINATIONUPLOADFILES, terminationUploadFiles });
    },
    getQuoteLogTerminationUpload: (agencyId, dateFrom, dateTo) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/TerminationUpload/GetQuoteLogTerminationUploads?agencyId=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo;
        const response = await fetch(url);

        const quoteLogTerminationUploads = await response.json();

        dispatch({ type: UPDATE_GET_QUOTELOGTERMINATIONUPLOAD, quoteLogTerminationUploads, isLoading });
    },
    verifyQuoteLog: (quoteLogData) => async (dispatch) => {
        var data = JSON.stringify({quoteLogValue: quoteLogData});
        const url = config.baseUrl + '/api/QuoteLog/VerifyQuoteLog';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const singleQuoteLog = await response.json();

        dispatch({ type: UPDATE_UPDATE_VERIFYQUOTELOG, singleQuoteLog });
    },
    deleteSeriousUploadFile: (agencyId, fileName) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/SeriousUpload/DeleteFile?agencyId=' + agencyId + '&fileName=' + fileName;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const seriousUploadFiles = await response.json();

        dispatch({ type: UPDATE_DELETE_SERIOUSUPLOADFILES, seriousUploadFiles });
    },
    deleteNewBusinessUploadFile: (agencyId, fileName) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/NewBusinessUpload/DeleteFile?agencyId=' + agencyId + '&fileName=' + fileName;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const newBusinessUploadFiles = await response.json();

        dispatch({ type: UPDATE_DELETE_NEWBUSINESSUPLOADFILES, newBusinessUploadFiles });
    },
    deleteTerminationUploadFile: (agencyId, fileName) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/TerminationUpload/DeleteFile?agencyId=' + agencyId + '&fileName=' + fileName;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const terminationUploadFiles = await response.json();

        dispatch({ type: UPDATE_DELETE_TERMINATIONUPLOADFILES, terminationUploadFiles });
    },
    getReferringAgent: (agencyId) => async (dispatch) => {
        const url = config.baseUrl + '/api/ReferringAgent?agencyId=' + agencyId;
        const response = await fetch(url);

        const referringAgent = await response.json();

        dispatch({ type: UPDATE_GET_REFERRINGAGENT, referringAgent });
    },
    createReferringAgent: (agencyId, id, name, inactive) => async (dispatch) => {
        var data = JSON.stringify({ agencyId: agencyId, id: id, name: name, inactive: inactive });
        const url = config.baseUrl + '/api/ReferringAgent';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const referringAgent = await response.json();

        dispatch({ type: UPDATE_CREATE_REFERRINGAGENT, referringAgent });
    },
    getCurrentCarrierSummary: (agencyId, dateFrom, dateTo, agent, referringAgent) => async (dispatch) => {
        var isQuoteLogLoading = true;
        dispatch({ type: UPDATE_QUOTELOG_LOADING, isQuoteLogLoading });
        agent = agent == null ? '' : agent;
        referringAgent = referringAgent == null ? '' : referringAgent;
        const url = config.baseUrl + '/api/Reports/GetCurrentCarrierSummary?agencyId=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&agent=' + agent + '&referringAgent=' + referringAgent;
        const response = await fetch(url);

        const currentCarrierSummary = await response.json();

        dispatch({ type: UPDATE_GET_CURRENTCARRIERSUMMARY, currentCarrierSummary, isQuoteLogLoading });
    },
    getMonthlyWrittenBundles: (agencyId, dateFrom, dateTo, agent) => async (dispatch) => {
        var isQuoteLogLoading = true;
        dispatch({ type: UPDATE_QUOTELOG_LOADING, isQuoteLogLoading });
        const url = config.baseUrl + '/api/Reports/GetMonthlyWrittenBundles?agencyId=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&agent=' + agent;
        const response = await fetch(url);

        const monthlyWrittenBundles = await response.json();

        dispatch({ type: UPDATE_GET_MONTHLYWRITTENBUNDLES, monthlyWrittenBundles, isQuoteLogLoading });
    },
    getReferringAgentSummary: (agencyId, dateFrom, dateTo) => async (dispatch) => {
        var isQuoteLogLoading = true;
        dispatch({ type: UPDATE_QUOTELOG_LOADING, isQuoteLogLoading });
        const url = config.baseUrl + '/api/Reports/GetReferringAgentSummary?agencyId=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo;
        const response = await fetch(url);

        const referringAgentSummary = await response.json();

        dispatch({ type: UPDATE_GET_REFERRINGAGENTSUMMARY, referringAgentSummary, isQuoteLogLoading });
    },
    getEducationSections: (agencyId) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/Education?agencyId=' + agencyId;
        const response = await fetch(url);

        const educationSections = await response.json();

        dispatch({ type: UPDATE_GET_EDUCATIONSECTIONS, educationSections, isLoading });
    },
    createEducationSections: (agencyId, name) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        var data = JSON.stringify({ agencyId: agencyId, name: name });
        const url = config.baseUrl + '/api/Education';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const educationSections = await response.json();

        dispatch({ type: UPDATE_CREATE_EDUCATIONSECTIONS, educationSections });
    },
    getEducationMaterials: (agencyId) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/Education/GetEducationMaterials?agencyId=' + agencyId;
        const response = await fetch(url);

        const educationMaterials = await response.json();

        dispatch({ type: UPDATE_GET_EDUCATIONMATERIALS, educationMaterials, isLoading });
    },
    createEducationMaterials: (agencyId, educationSectionId, materialFile) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const data = new FormData();
        if(materialFile != null){
            data.append('materialFile', materialFile);
        }
        const url = config.baseUrl + '/api/Education/AddEducationMaterial?agencyId=' + agencyId + "&educationSectionId=" + educationSectionId;
        const response = await fetch(url, {
            method: "POST",
            body: data
        });
        const educationMaterials = await response.json();

        dispatch({ type: UPDATE_CREATE_EDUCATIONMATERIALS, educationMaterials });
    },
    updateEducationMaterials: (agencyId, educationMaterialId, educationSectionId, sourceIndex, destinationIndex) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        var data = JSON.stringify({agencyId: agencyId, educationMaterialId: educationMaterialId, educationSectionId: educationSectionId, sourceIndex: sourceIndex, destinationIndex: destinationIndex });
        const url = config.baseUrl + '/api/Education/UpdateEducationMaterial';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const educationMaterials = await response.json();

        dispatch({ type: UPDATE_CREATE_EDUCATIONMATERIALS, educationMaterials });
    },
    updateEducationSections: (agencyId, educationSectionId, sourceIndex, destinationIndex) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        var data = JSON.stringify({agencyId: agencyId, educationSectionId: educationSectionId, sourceIndex: sourceIndex, destinationIndex: destinationIndex });
        const url = config.baseUrl + '/api/Education/UpdateEducationSection';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const educationSections = await response.json();

        dispatch({ type: UPDATE_CREATE_EDUCATIONSECTIONS, educationSections });
    },
    deleteEducationMaterial: (agencyId, educationMaterialId) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        var data = JSON.stringify({agencyId: agencyId, educationMaterialId: educationMaterialId, sourceIndex: 0, destinationIndex: 0 });
        const url = config.baseUrl + '/api/Education/DeleteEducationMaterial';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const educationMaterials = await response.json();

        dispatch({ type: UPDATE_CREATE_EDUCATIONMATERIALS, educationMaterials });
    },
    renameEducationSection: (id, agencyId, name) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        var data = JSON.stringify({ id: id, agencyId: agencyId, name: name });
        const url = config.baseUrl + '/api/Education/RenameEducationSection';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const educationSections = await response.json();

        dispatch({ type: UPDATE_CREATE_EDUCATIONSECTIONS, educationSections });
    },
    deleteEducationSection: (id, agencyId, name) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        var data = JSON.stringify({ id: id, agencyId: agencyId, name: name });
        const url = config.baseUrl + '/api/Education/DeleteEducationSection';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const educationSections = await response.json();

        dispatch({ type: UPDATE_CREATE_EDUCATIONSECTIONS, educationSections });
    },
    getCostAcquisition: (agencyId, dateFrom, dateTo, monthDts) => async (dispatch) => {
        var isActivityLoading = true;
        dispatch({ type: UPDATE_ACTIVITYSUMMARY_LOADING, isActivityLoading });
        const url = config.baseUrl + '/api/marketing/getcostperacquisition?agencyId=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&monthDts=' + monthDts;
        const response = await fetch(url);

        const acquisitionCost = await response.json();

        dispatch({ type: UPDATE_GET_ACQUISITIONCOST, acquisitionCost, isActivityLoading });
    },
    updateDwellingPoints: (id, typeQuoteLogDwellingId, points, agencyId) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        var data = JSON.stringify({ id: id, typeQuoteLogDwellingId: typeQuoteLogDwellingId, points: points, agencyId: agencyId });
        const url = config.baseUrl + '/api/Points/AddDwellingPoints';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const dwellingPoints = await response.json();

        dispatch({ type: UPDATE_GET_DWELLINGPOINTS, dwellingPoints });
    },
    updateSpecialtyPoints: (id, typeQuoteLogSpecialtyId, points, agencyId) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        var data = JSON.stringify({ id: id, typeQuoteLogSpecialtyId: typeQuoteLogSpecialtyId, points: points, agencyId: agencyId });
        const url = config.baseUrl + '/api/Points/AddSpecialtyPoints';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const specialtyPoints = await response.json();

        dispatch({ type: UPDATE_GET_SPECIALTYPOINTS, specialtyPoints });
    },
    updateAutoPoints: (id, typeAuto, points, agencyId) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        var data = JSON.stringify({ id: id, typeAuto: typeAuto, points: points, agencyId: agencyId });
        const url = config.baseUrl + '/api/Points/AddAutoPoints';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const autoPoints = await response.json();

        dispatch({ type: UPDATE_GET_AUTOPOINTS, autoPoints });
    },
    getDwellingPoints: (agencyId) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/points/getdwellingpoints?agencyId=' + agencyId;
        const response = await fetch(url);

        const dwellingPoints = await response.json();

        dispatch({ type: UPDATE_GET_DWELLINGPOINTS, dwellingPoints, isLoading });
    },
    getSpecialtyPoints: (agencyId) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/points/getspecialtypoints?agencyId=' + agencyId;
        const response = await fetch(url);

        const specialtyPoints = await response.json();

        dispatch({ type: UPDATE_GET_SPECIALTYPOINTS, specialtyPoints, isLoading });
    },
    getAutoPoints: (agencyId) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/points/getautopoints?agencyId=' + agencyId;
        const response = await fetch(url);

        const autoPoints = await response.json();

        dispatch({ type: UPDATE_GET_AUTOPOINTS, autoPoints, isLoading });
    },
    getLeadSourceAgentSummary: (agencyId, dateFrom, dateTo, typeQuoteLogLeadSourceId) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/QuoteLog/getleadsourceagentsummary?agencyId=' + agencyId + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&typequotelogleadsourceid=' + typeQuoteLogLeadSourceId;
        const response = await fetch(url);

        const leadSourceAgentSummary = await response.json();

        dispatch({ type: UPDATE_GET_LEADSOURCEAGENTSUMMARY, leadSourceAgentSummary, isLoading });
    },
    getSalesProducerGoals: (agencyId, monthDts) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/marketing/GetSalesProducerGoals?agencyId=' + agencyId + '&monthDts=' + monthDts
        const response = await fetch(url);

        const salesProducerGoals = await response.json();

        dispatch({ type: UPDATE_GET_SALESPRODUCERGOALS, salesProducerGoals, isLoading });
    },
    createEMCarrier: (agencyId, id, name) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        var data = JSON.stringify({ id: id, name: name, agencyId: agencyId });
        const url = config.baseUrl + '/api/ExpandedMarketplace/AddEMCarrier';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const emCarriers = await response.json();

        dispatch({ type: UPDATE_CREATE_EMCARRIER, emCarriers });
    },
    getEMCarriers: (agencyId) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/ExpandedMarketplace/GetEMCarriers?agencyId=' + agencyId
        const response = await fetch(url);

        const emCarriers = await response.json();

        dispatch({ type: UPDATE_GET_EMCARRIERS, emCarriers, isLoading });
    },
    createEMProduct: (agencyId, emCarrierId, productId, name, productType, points) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        var data = JSON.stringify({ name: name, agencyId: agencyId, emCarrierId: emCarrierId, productType: productType, points: points, id: productId });
        const url = config.baseUrl + '/api/ExpandedMarketplace/AddEMProduct';
        const response = await fetch(url, {
            method: 'POST',
            body: data,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const emProducts = await response.json();

        dispatch({ type: UPDATE_CREATE_EMPRODUCT, emProducts });
    },
    getEMProducts: (agencyId) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/ExpandedMarketplace/GetEMProducts?agencyId=' + agencyId
        const response = await fetch(url);

        const emProducts = await response.json();

        dispatch({ type: UPDATE_GET_EMPRODUCTS, emProducts, isLoading });
    },
    getEMProductCheckboxes: (agencyId) => async (dispatch) => {
        var isLoading = true;
        dispatch({ type: GET_CREATE_USER, isLoading });
        const url = config.baseUrl + '/api/ExpandedMarketplace/GetEMProductCheckboxes?agencyId=' + agencyId
        const response = await fetch(url);

        const emProductCheckboxes = await response.json();

        dispatch({ type: UPDATE_GET_EMPRODUCTCHECKBOXES, emProductCheckboxes, isLoading });
    },
}

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === UPDATE_CREATE_VENDOR) {
        return {
            ...state,
            vendors: action.vendors,
            isLoading: false
        };
    }
    if (action.type === UPDATE_UPDATE_VENDOR) {
        return {
            ...state,
            vendors: action.vendors,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_VENDORTYPE) {
        return {
            ...state,
            vendorTypes: action.vendorTypes,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_VENDORS) {
        return {
            ...state,
            vendors: action.vendors,
            isLoading: false
        };
    }
    if (action.type === UPDATE_CREATE_USER) {
        return {
            ...state,
            user: action.user,
            isLoading: false
        };
    }
    if (action.type === GET_CREATE_USER) {
        return {
            ...state,
            isLoading: true
        };
    }
    if (action.type === GET_CREATE_SERIOUSUPLOAD) {
        return {
            ...state,
            isSeriousLoading: true
        };
    }
    if (action.type === GET_CREATE_NEWBUSINESSUPLOAD) {
        return {
            ...state,
            isNewBusinessLoading: true
        };
    }
    if (action.type === UPDATE_LOGIN_USER) {
        return {
            ...state,
            user: action.user,
            isLoading: false
        };
    }
    if (action.type === UPDATE_CREATE_MARKETING) {
        return {
            ...state,
            marketing: action.marketing,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_MARKETING) {
        return {
            ...state,
            marketing: action.marketing,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_TELEMARKETING) {
        return {
            ...state,
            telemarketing: action.telemarketing,
            isLoading: false
        };
    }
    if (action.type === UPDATE_CREATE_TELEMARKETING) {
        return {
            ...state,
            telemarketing: action.telemarketing,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_LIVELEAD) {
        return {
            ...state,
            liveLead: action.liveLead,
            isLoading: false
        };
    }
    if (action.type === UPDATE_CREATE_LIVELEAD) {
        return {
            ...state,
            liveLead: action.liveLead,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_MAILER) {
        return {
            ...state,
            mailer: action.mailer,
            isLoading: false
        };
    }
    if (action.type === UPDATE_CREATE_MAILER) {
        return {
            ...state,
            mailer: action.mailer,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_SOCIALMEDIA) {
        return {
            ...state,
            socialMedia: action.socialMedia,
            isLoading: false
        };
    }
    if (action.type === UPDATE_CREATE_SOCIALMEDIA) {
        return {
            ...state,
            socialMedia: action.socialMedia,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_DATALEAD) {
        return {
            ...state,
            dataLead: action.dataLead,
            isLoading: false
        };
    }
    if (action.type === UPDATE_CREATE_DATALEAD) {
        return {
            ...state,
            dataLead: action.dataLead,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_AGEDLEAD) {
        return {
            ...state,
            agedLead: action.agedLead,
            isLoading: false
        };
    }
    if (action.type === UPDATE_CREATE_AGEDLEAD) {
        return {
            ...state,
            agedLead: action.agedLead,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_SALESAGENT) {
        return {
            ...state,
            salesAgent: action.salesAgent,
            isLoading: false
        };
    }
    if (action.type === UPDATE_CREATE_SALESAGENT) {
        return {
            ...state,
            salesAgent: action.salesAgent,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_AGENT) {
        return {
            ...state,
            agent: action.agent,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_SPECIALTY) {
        return {
            ...state,
            specialty: action.specialty,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_OBJECTION) {
        return {
            ...state,
            objection: action.objection,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_INELIGIBLE) {
        return {
            ...state,
            ineligible: action.ineligible,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_STATUS) {
        return {
            ...state,
            status: action.status,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_DWELLING) {
        return {
            ...state,
            dwelling: action.dwelling,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_LEADSOURCE) {
        return {
            ...state,
            leadSource: action.leadSource,
        };
    }
    if (action.type === UPDATE_CREATE_QUOTELOG) {
        return {
            ...state,
            quoteLog: action.quoteLog,
            error: '',
            isQuoteLogLoading: false
        };
    }
    if (action.type === UPDATE_GET_QUOTELOG) {
        return {
            ...state,
            quoteLog: action.quoteLog,
            isQuoteLogLoading: false
        };
    }
    if (action.type === UPDATE_CREATE_AGENCY) {
        return {
            ...state,
            agencyId: action.agencyId,
            isLoading: false
        };
    }
    if (action.type === UPDATE_CREATE_AGENT) {
        return {
            ...state,
            agent: action.agent,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_AGENCY) {
        return {
            ...state,
            agency: action.agency,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_QUOTELOGSUMMARY) {
        return {
            ...state,
            quoteLogSummary: action.quoteLogSummary,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_QUOTELOGVENDORSUMMARY) {
        return {
            ...state,
            quoteLogVendorSummary: action.quoteLogVendorSummary,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_USERAGENT) {
        return {
            ...state,
            userAgent: action.userAgent,
            isLoading: false
        };
    }
    if (action.type === UPDATE_CREATE_USERAGENT) {
        return {
            ...state,
            user: action.user,
            isLoading: false
        };
    }
    if (action.type === UPDATE_DELETE_AGENT) {
        return {
            ...state,
            agent: action.agent,
            isLoading: false
        };
    }
    if (action.type === UPDATE_HANDLE_ERROR) {
        return {
            ...state,
            error: action.errorMessage,
            isLoading: false
        };
    }
    if (action.type === UPDATE_CREATE_LEADSOURCE) {
        return {
            ...state,
            leadSource: action.leadSource,
            isLoading: false
        };
    }
    if (action.type === UPDATE_DELETE_QUOTELOG) {
        return {
            ...state,
            quoteLog: action.quoteLog,
            isQuoteLogLoading: false
        };
    }
    if (action.type === UPDATE_SEARCH_QUOTELOG) {
        return {
            ...state,
            quoteLog: action.quoteLog,
            isQuoteLogLoading: false
        };
    }
    if (action.type === UPDATE_CREATE_PAYMENT) {
        return {
            ...state,
            paymentId: action.paymentId,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_QUOTELOGZIPSUMMARY) {
        return {
            ...state,
            quoteLogZipSummary: action.quoteLogZipSummary,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_QUOTELOGDWELLINGS) {
        return {
            ...state,
            dwelling: action.quoteLogDwellings,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_QUOTELOGSPECIALTY) {
        return {
            ...state,
            specialty: action.quoteLogSpecialty,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_AGENTSTALKTIME) {
        return {
            ...state,
            agentsTalkTime: action.agentsTalkTime,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_FORGOTPASSWORD) {
        return {
            ...state,
            forgotPasswordResult: action.forgotPasswordResult,
            isLoading: false
        };
    }
    if (action.type === UPDATE_CREATE_SOLDQUOTELOG) {
        return {
            ...state,
            quoteLogSold: action.quoteLogSold,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_QUOTELOGDWELLINGSSOLD) {
        return {
            ...state,
            dwellingSold: action.quoteLogDwellingsSold,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_QUOTELOGSPECIALTYSOLD) {
        return {
            ...state,
            specialtySold: action.quoteLogSpecialtySold,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_QUOTELOGSOLD) {
        return {
            ...state,
            quoteLogSold: action.quoteLogSold,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_ONBOARDING) {
        return {
            ...state,
            onboarding: action.onboarding,
            isLoading: false
        };
    }
    if (action.type === UPDATE_DELETE_VENDOR) {
        return {
            ...state,
            vendors: action.vendors,
            isLoading: false
        };
    }
    if (action.type === UPDATE_QUOTELOG_LOADING) {
        return {
            ...state,
            isQuoteLogLoading: true
        };
    }
    if (action.type === UPDATE_GET_MARKETINGSUMMARY) {
        return {
            ...state,
            marketingSummary: action.marketingSummary,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_REPORTSUMMARY) {
        return {
            ...state,
            reportSummary: action.reportSummary,
            isQuoteLogLoading: false
        };
    }
    if (action.type === UPDATE_GET_VENDORSUMMARY) {
        return {
            ...state,
            vendorSummary: action.vendorSummary,
            isQuoteLogLoading: false
        };
    }
    if (action.type === UPDATE_UPDATE_ONBOARDING) {
        return {
            ...state,
            onboardingRecord: action.onboardingRecord,
            isLoading: false
        };
    }
    if (action.type === UPDATE_SEARCH_ONBOARDING) {
        return {
            ...state,
            onboarding: action.onboarding,
            isQuoteLogLoading: false
        };
    }
    if (action.type === UPDATE_CREATE_QUOTELOGSOLDQUOTELOG) {
        return {
            ...state,
            quoteLog: action.quoteLog,
            isQuoteLogLoading: false
        };
    }
    if (action.type === UPDATE_GET_TYPEVENDORTYPE) {
        return {
            ...state,
            typeVendorTypes: action.typeVendorTypes,
            isLoading: false
        };
    }
    if (action.type === UPDATE_CREATE_TYPEVENDORTYPE) {
        return {
            ...state,
            typeVendorTypes: action.typeVendorTypes,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_AGENTPREMIUMS) {
        return {
            ...state,
            agentPremiums: action.agentPremiums,
            isLoading: false
        };
    }
    if (action.type === UPDATE_CREATE_ZIPUPLOAD) {
        return {
            ...state,
            zipUpload: action.zipUpload,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_ZIPUPLOADS) {
        return {
            ...state,
            zipCounts: action.zipCounts,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_ZIPUPLOADFILENAMES) {
        return {
            ...state,
            zipUploadFilename: action.zipUploadFilename,
            isLoading: false
        };
    }
    if (action.type === UPDATE_DELETE_ZIPUPLOAD) {
        return {
            ...state,
            zipCounts: action.zipCounts,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_VENDORTYPESUMMARY) {
        return {
            ...state,
            vendorTypeSummary: action.vendorTypeSummary,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_POLICIESSUMMARY) {
        return {
            ...state,
            policiesSummary: action.policiesSummary,
            isActivityLoading: false
        };
    }
    if (action.type === UPDATE_GET_AVERAGEPREMIUM) {
        return {
            ...state,
            quoteLogAveragePremium: action.quoteLogAveragePremium,
            isAverageSummaryLoading: false
        };
    }
    if (action.type === UPDATE_GET_QUOTEAGENTSUMMARY) {
        return {
            ...state,
            quoteAgentSummary: action.quoteAgentSummary,
            isActivityLoading: false
        };
    }
    if (action.type === UPDATE_ACTIVITYSUMMARY_LOADING) {
        return {
            ...state,
            isActivityLoading: true
        };
    }
    if (action.type === UPDATE_GET_QUOTEAGENTSUMMARYBYAGENT) {
        return {
            ...state,
            quoteAgentSummaryByAgent: action.quoteAgentSummaryByAgent,
            isActivityLoading: false
        };
    }
    if (action.type === UPDATE_GET_CITYSTATE) {
        return {
            ...state,
            cityState: action.cityState,
            isActivityLoading: false
        };
    }
    if (action.type === UPDATE_CREATE_MARKETINGAGENT) {
        return {
            ...state,
            marketingAgent: action.marketingAgent,
            isActivityLoading: false
        };
    }
    if (action.type === UPDATE_GET_MARKETINGAGENT) {
        return {
            ...state,
            marketingAgent: action.marketingAgent,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_QUOTELOGDAILYITEMS) {
        return {
            ...state,
            dailyItems: action.dailyItems,
            isActivityLoading: false
        };
    }
    if (action.type === UPDATE_GET_TYPEVENDORTYPELEADSOURCE) {
        return {
            ...state,
            typeVendorTypesLeadSources: action.typeVendorTypesLeadSources,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_MARKETINGAGENTUSERS) {
        return {
            ...state,
            marketingAgentUsers: action.marketingAgentUsers,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_TOTALMARKETINGAGENTUSERS) {
        return {
            ...state,
            totalMarketingAgent: action.totalMarketingAgent,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_DASHBOARD) {
        return {
            ...state,
            dashboard: action.dashboard,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_TOTALSALESAGENTSUMMARY) {
        return {
            ...state,
            totalSalesAgentSummary: action.totalSalesAgentSummary,
            isActivityLoading: false
        };
    }
    if (action.type === UPDATE_GET_ACTUALMARKETINGSUMMARY) {
        return {
            ...state,
            actualMarketingSummary: action.actualMarketingSummary,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_DNQREPORT) {
        return {
            ...state,
            dnqReport: action.dnqReport,
            isQuoteLogLoading: false
        };
    }
    if (action.type === UPDATE_CREATE_SERIOUSUPLOAD) {
        return {
            ...state,
            seriousUpload: action.seriousUpload,
            isSeriousLoading: false
        };
    }
    if (action.type === UPDATE_GET_SERIOUSUPLOAD) {
        return {
            ...state,
            seriousUpload: action.seriousUpload,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_SERIOUSUPLOADFILES) {
        return {
            ...state,
            seriousUploadFiles: action.seriousUploadFiles,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_QUOTELOGSERIOUSUPLOAD) {
        return {
            ...state,
            quoteLogSeriousUploads: action.quoteLogSeriousUploads,
            isLoading: false
        };
    }
    if (action.type === UPDATE_UPDATE_VERIFYQUOTELOG) {
        return {
            ...state,
            singleQuoteLog: action.singleQuoteLog
        };
    }
    if (action.type === UPDATE_GET_SINGLEAGENT) {
        return {
            ...state,
            singleAgent: action.singleAgent
        };
    }
    if (action.type === UPDATE_GET_ALLAGENCIES) {
        return {
            ...state,
            agencies: action.agencies
        };
    }
    if (action.type === UPDATE_DELETE_SERIOUSUPLOADFILES) {
        return {
            ...state,
            seriousUploadFiles: action.seriousUploadFiles,
            isLoading: false
        };
    }
    if (action.type === UPDATE_CREATE_NEWBUSINESSUPLOAD) {
        return {
            ...state,
            newBusinessUpload: action.newBusinessUpload,
            isNewBusinessLoading: false
        };
    }
    if (action.type === UPDATE_GET_NEWBUSINESSUPLOAD) {
        return {
            ...state,
            newBusinessUpload: action.newBusinessUpload,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_NEWBUSINESSUPLOADFILES) {
        return {
            ...state,
            newBusinessUploadFiles: action.newBusinessUploadFiles,
            isLoading: false
        };
    }
    if (action.type === UPDATE_DELETE_NEWBUSINESSUPLOADFILES) {
        return {
            ...state,
            newBusinessUploadFiles: action.newBusinessUploadFiles,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_QUOTELOGNEWBUSINESSUPLOAD) {
        return {
            ...state,
            quoteLogNewBusinessUploads: action.quoteLogNewBusinessUploads,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_REFERRINGAGENT) {
        return {
            ...state,
            referringAgent: action.referringAgent
        };
    }
    if (action.type === UPDATE_CREATE_REFERRINGAGENT) {
        return {
            ...state,
            referringAgent: action.referringAgent
        };
    }
    if (action.type === UPDATE_GET_CURRENTCARRIERSUMMARY) {
        return {
            ...state,
            currentCarrierSummary: action.currentCarrierSummary,
            isQuoteLogLoading: false
        };
    }
    if (action.type === UPDATE_GET_MONTHLYWRITTENBUNDLES) {
        return {
            ...state,
            monthlyWrittenBundles: action.monthlyWrittenBundles,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_REFERRINGAGENTSUMMARY) {
        return {
            ...state,
            referringAgentSummary: action.referringAgentSummary,
            isQuoteLogLoading: false
        };
    }
    if (action.type === UPDATE_AVERAGESUMMARY_LOADING) {
        return {
            ...state,
            isAverageSummaryLoading: true
        };
    }
    if (action.type === UPDATE_GET_EDUCATIONSECTIONS) {
        return {
            ...state,
            educationSections: action.educationSections,
            isLoading: false
        };
    }
    if (action.type === UPDATE_CREATE_EDUCATIONSECTIONS) {
        return {
            ...state,
            educationSections: action.educationSections,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_EDUCATIONMATERIALS) {
        return {
            ...state,
            educationMaterials: action.educationMaterials,
            isLoading: false
        };
    }
    if (action.type === UPDATE_CREATE_EDUCATIONMATERIALS) {
        return {
            ...state,
            educationMaterials: action.educationMaterials,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_ACQUISITIONCOST) {
        return {
            ...state,
            acquisitionCost: action.acquisitionCost,
            isActivityLoading: false
        };
    }
    if (action.type === UPDATE_CREATE_TERMINATIONUPLOAD) {
        return {
            ...state,
            terminationUpload: action.terminationUpload,
            isTerminationLoading: false
        };
    }
    if (action.type === UPDATE_GET_TERMINATIONUPLOAD) {
        return {
            ...state,
            terminationUpload: action.terminationUpload,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_TERMINATIONUPLOADFILES) {
        return {
            ...state,
            terminationUploadFiles: action.terminationUploadFiles,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_QUOTELOGTERMINATIONUPLOAD) {
        return {
            ...state,
            quoteLogTerminationUploads: action.quoteLogTerminationUploads,
            isLoading: false
        };
    }
    if (action.type === UPDATE_DELETE_TERMINATIONUPLOADFILES) {
        return {
            ...state,
            terminationUploadFiles: action.terminationUploadFiles,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_AUTOPOINTS) {
        return {
            ...state,
            autoPoints: action.autoPoints,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_DWELLINGPOINTS) {
        return {
            ...state,
            dwellingPoints: action.dwellingPoints,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_SPECIALTYPOINTS) {
        return {
            ...state,
            specialtyPoints: action.specialtyPoints,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_LEADSOURCEAGENTSUMMARY) {
        return {
            ...state,
            leadSourceAgentSummary: action.leadSourceAgentSummary,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_SALESPRODUCERGOALS) {
        return {
            ...state,
            salesProducerGoals: action.salesProducerGoals,
            isLoading: false
        };
    }
    if (action.type === UPDATE_CREATE_EMCARRIER) {
        return {
            ...state,
            emCarriers: action.emCarriers,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_EMCARRIERS) {
        return {
            ...state,
            emCarriers: action.emCarriers,
            isLoading: false
        };
    }
    if (action.type === UPDATE_CREATE_EMPRODUCT) {
        return {
            ...state,
            emProducts: action.emProducts,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_EMPRODUCTS) {
        return {
            ...state,
            emProducts: action.emProducts,
            isLoading: false
        };
    }
    if (action.type === UPDATE_GET_EMPRODUCTCHECKBOXES) {
        return {
            ...state,
            emProductCheckboxes: action.emProductCheckboxes,
            isLoading: false
        };
    }
    return state;
}