import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Row, Col, InputNumber, Button } from 'antd'
import { SaveOutlined } from '@ant-design/icons';
import moment from 'moment';

class SalesAgent extends Component {
    constructor(props) {
      super(props);
        this.state = {
            topAgentSales: 10000,
            midAgentSales: 10000,
            lowAgentSales: 10000,
            topAgentNumber: 0,
            midAgentNumber: 0,
            lowAgentNumber: 0,
            topAgentCloseRatio: 0,
            midAgentCloseRatio: 0,
            lowAgentCloseRatio: 0,
            premiumPerItem: 1,
            itemsPerHousehold: 1,
            closingRatio: 1
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }
    componentDidMount(){
        const userId = localStorage.getItem('userId');
        const agencyId = localStorage.getItem('agencyId');
        this.props.getSalesAgent(userId);
        this.props.getMarketing(agencyId, moment().format("MMM YYYY"));
    }
    componentDidUpdate(prevProps){
        if(this.props.salesAgent != prevProps.salesAgent){
            this.setState({
                topAgentSales: this.props.salesAgent.topAgentSales,
                midAgentSales: this.props.salesAgent.midAgentSales,
                lowAgentSales: this.props.salesAgent.lowAgentSales,
                topAgentNumber: parseInt(this.props.salesAgent.topAgentNumber),
                midAgentNumber: parseInt(this.props.salesAgent.midAgentNumber),
                lowAgentNumber: parseInt(this.props.salesAgent.lowAgentNumber),
                topAgentCloseRatio: this.props.salesAgent.topAgentCloseRatio ? this.props.salesAgent.topAgentCloseRatio : 0,
                midAgentCloseRatio: this.props.salesAgent.midAgentCloseRatio ? this.props.salesAgent.midAgentCloseRatio : 0,
                lowAgentCloseRatio: this.props.salesAgent.lowAgentCloseRatio ? this.props.salesAgent.lowAgentCloseRatio : 0,
            });
        }
        if(this.props.marketing != prevProps.marketing){
            this.setState({
                premium: this.props.marketing.monthlyPremium,
                premiumPerItem: this.props.marketing.premiumPerItem,
                itemsPerHousehold: this.props.marketing.itemsPerHousehold,
                closingRatio: this.props.marketing.closedRatio
            });
        }
    }
    handleChange = (e, name) => {
        this.setState({[name]: e});
    }
    handleSave(e){
        const userId = localStorage.getItem('userId');
        const topAgentSales = this.state.topAgentSales;
        const midAgentSales = this.state.midAgentSales;
        const lowAgentSales = this.state.lowAgentSales;
        const topAgentNumber = this.state.topAgentNumber;
        const midAgentNumber = this.state.midAgentNumber;
        const lowAgentNumber = this.state.lowAgentNumber;
        const topAgentCloseRatio = this.state.topAgentCloseRatio;
        const midAgentCloseRatio = this.state.midAgentCloseRatio;
        const lowAgentCloseRatio = this.state.lowAgentCloseRatio;
        this.props.createSalesAgent(userId, topAgentSales, midAgentSales, lowAgentSales, topAgentNumber, midAgentNumber, lowAgentNumber, topAgentCloseRatio, midAgentCloseRatio, lowAgentCloseRatio);
    }
    render(){
        const topAgentSales_DailySales = this.state.topAgentSales / 20;
        const topAgentSales_MonthlyQuotedHouseholds = (this.state.topAgentSales / this.state.premiumPerItem) / this.state.itemsPerHousehold / (this.state.topAgentCloseRatio / 100);
        const topAgentSales_DailyQuotedHouseholds = topAgentSales_MonthlyQuotedHouseholds / 20;
        const topAgentSales_MonthlyWrittenItems = this.state.topAgentSales / this.state.premiumPerItem;
        const topAgentSales_DailyWrittenItems = topAgentSales_MonthlyWrittenItems / 20;
        const topAgentSales_MonthlyWrittenHousehold = topAgentSales_MonthlyWrittenItems / this.state.itemsPerHousehold;
        const topAgentSales_DailyWrittenHousehold = topAgentSales_MonthlyWrittenHousehold / 20;

        const midAgentSales_DailySales = this.state.midAgentSales / 20;
        const midAgentSales_MonthlyQuotedHouseholds = (this.state.midAgentSales / this.state.premiumPerItem) / this.state.itemsPerHousehold / (this.state.midAgentCloseRatio / 100);
        const midAgentSales_DailyQuotedHouseholds = midAgentSales_MonthlyQuotedHouseholds / 20;
        const midAgentSales_MonthlyWrittenItems = this.state.midAgentSales / this.state.premiumPerItem;
        const midAgentSales_DailyWrittenItems = midAgentSales_MonthlyWrittenItems / 20;
        const midAgentSales_MonthlyWrittenHousehold = midAgentSales_MonthlyWrittenItems / this.state.itemsPerHousehold;
        const midAgentSales_DailyWrittenHousehold = midAgentSales_MonthlyWrittenHousehold / 20;

        const lowAgentSales_DailySales = this.state.lowAgentSales / 20;
        const lowAgentSales_MonthlyQuotedHouseholds = (this.state.lowAgentSales / this.state.premiumPerItem) / this.state.itemsPerHousehold / (this.state.lowAgentCloseRatio / 100);
        const lowAgentSales_DailyQuotedHouseholds = lowAgentSales_MonthlyQuotedHouseholds / 20;
        const lowAgentSales_MonthlyWrittenItems = this.state.lowAgentSales / this.state.premiumPerItem;
        const lowAgentSales_DailyWrittenItems = lowAgentSales_MonthlyWrittenItems / 20;
        const lowAgentSales_MonthlyWrittenHousehold = lowAgentSales_MonthlyWrittenItems / this.state.itemsPerHousehold;
        const lowAgentSales_DailyWrittenHousehold = lowAgentSales_MonthlyWrittenHousehold / 20;

        const total_MonthlySales = (this.state.topAgentSales * this.state.topAgentNumber) + (this.state.midAgentSales * this.state.midAgentNumber) + (this.state.lowAgentSales * this.state.lowAgentNumber)
        const monthlySales_BackgroundColor = total_MonthlySales < this.props.monthlyPremium ? '#ffd8bf' : '#d9f7be';
        const total_DailySales = (topAgentSales_DailySales * this.state.topAgentNumber) + (midAgentSales_DailySales * this.state.midAgentNumber) + (lowAgentSales_DailySales * this.state.lowAgentNumber)
        const total_AgentNumber = (this.state.topAgentNumber + this.state.midAgentNumber + this.state.lowAgentNumber)
        const total_MonthlyQuotedHouseholds = (topAgentSales_MonthlyQuotedHouseholds * this.state.topAgentNumber) + (midAgentSales_MonthlyQuotedHouseholds * this.state.midAgentNumber) + (lowAgentSales_MonthlyQuotedHouseholds * this.state.lowAgentNumber)
        const total_DailyQuotedHouseholds = (topAgentSales_DailyQuotedHouseholds * this.state.topAgentNumber) + (midAgentSales_DailyQuotedHouseholds * this.state.midAgentNumber) + (lowAgentSales_DailyQuotedHouseholds * this.state.lowAgentNumber)
        const total_MonthlyWrittenItems = (topAgentSales_MonthlyWrittenItems * this.state.topAgentNumber) + (midAgentSales_MonthlyWrittenItems * this.state.midAgentNumber) + (lowAgentSales_MonthlyWrittenItems * this.state.lowAgentNumber)
        const total_DailyWrittenItems = (topAgentSales_DailyWrittenItems * this.state.topAgentNumber) + (midAgentSales_DailyWrittenItems * this.state.midAgentNumber) + (lowAgentSales_DailyWrittenItems * this.state.lowAgentNumber)
        const total_MonthlyWrittenHousehold = (topAgentSales_MonthlyWrittenHousehold * this.state.topAgentNumber) + (midAgentSales_MonthlyWrittenHousehold * this.state.midAgentNumber) + (lowAgentSales_MonthlyWrittenHousehold * this.state.lowAgentNumber)
        const total_DailyWrittenHousehold = (topAgentSales_DailyWrittenHousehold * this.state.topAgentNumber) + (midAgentSales_DailyWrittenHousehold * this.state.midAgentNumber) + (lowAgentSales_DailyWrittenHousehold * this.state.lowAgentNumber)
        const itemsRequired = this.props.marketing.monthlyPremium / this.props.marketing.premiumPerItem;
        const householdsNeeded = itemsRequired / this.props.marketing.itemsPerHousehold;
        const closingRatio = this.props.marketing.closedRatio / 100;
        const totalMonthly = householdsNeeded / closingRatio;
        console.log(total_DailyQuotedHouseholds);
        return(
            <div>
                <Row>
                    <Col span={4}>
                        <p style={{fontWeight: 'bold', border: 'solid 1px black'}}>
                            Monthly Premium Goal:<br />
                            {new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD"
                            }).format(this.props.marketing.monthlyPremium)}
                        </p>
                    </Col>
                    <Col span={4}>
                        <p style={{fontWeight: 'bold', border: 'solid 1px black'}}>
                            Average premium per item:<br />
                            {new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD"
                            }).format(this.props.marketing.premiumPerItem)}
                        </p>
                    </Col>
                    <Col span={4}>
                        <p style={{fontWeight: 'bold', border: 'solid 1px black'}}>
                          Average items per household: <br />
                            {this.props.marketing.itemsPerHousehold}
                        </p>
                    </Col>
                    <Col span={4}>
                        <p style={{fontWeight: 'bold', border: 'solid 1px black'}}>
                            Required Written Households: <br />
                            {Math.round(itemsRequired / this.props.marketing.itemsPerHousehold)}
                            
                        </p>
                    </Col>
                    <Col span={4}>
                        <p style={{fontWeight: 'bold', border: 'solid 1px black'}}>
                        Close ratio: <br />
                            {this.props.marketing.closedRatio}%
                        </p>
                    </Col>
                    <Col span={4}>
                        <p style={{fontWeight: 'bold', border: 'solid 1px black'}}>
                        Required Quoted Households: <br />
                            {Math.round(totalMonthly)}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <table style={{width: '100%', border: 'solid, 1px lightgrey'}}>
                            <thead style={{textAlign: 'center'}}>
                                <tr style={{backgroundColor: '#97E45D'}}>
                                    <th>SALES AGENT</th>
                                    <th>MONTHLY TOTAL</th>
                                    <th>TOP TIER SALES AGENT</th>
                                    <th>MID TIER SALES AGENT</th>
                                    <th>LOW TIER SALES AGENT</th>
                                </tr>
                            </thead>
                            <tbody style={{border: 'solid 1px lightgrey'}}>
                                <tr style={{borderBottom: 'solid 1px lightgrey'}}>
                                    <td style={{backgroundColor: "#d6e4ff"}}>MONTHLY SALES AGENT PREMIUM GOALS</td>
                                    <td style={{textAlign: 'center', backgroundColor: total_MonthlySales < this.props.marketing.monthlyPremium ? 'red' : '#d9f7be', fontWeight: 'bold'}}>
                                        {new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD"
                                        }).format(total_MonthlySales)}
                                    </td>
                                    <td style={{textAlign: 'center'}}>
                                        <InputNumber
                                            min={10000}
                                            max={150000}
                                            step={2500}
                                            style={{ marginLeft: 0 }}
                                            value={this.state.topAgentSales}
                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            onChange={e => this.handleChange(e, "topAgentSales")}
                                            onBlur={this.handleSave}
                                        />
                                    </td>
                                    <td style={{textAlign: 'center'}}>
                                        <InputNumber
                                            min={10000}
                                            max={150000}
                                            step={2500}
                                            style={{ marginLeft: 0 }}
                                            value={this.state.midAgentSales}
                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            onChange={e => this.handleChange(e, "midAgentSales")}
                                            onBlur={this.handleSave}
                                        />
                                    </td>
                                    <td style={{textAlign: 'center'}}>
                                        <InputNumber
                                            min={10000}
                                            max={150000}
                                            step={2500}
                                            style={{ marginLeft: 0 }}
                                            value={this.state.lowAgentSales}
                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            onChange={e => this.handleChange(e, "lowAgentSales")}
                                            onBlur={this.handleSave}
                                        />
                                    </td>
                                    {/* <td style={{textAlign: 'center'}}>
                                        {new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD"
                                        }).format(total_MonthlySales)}
                                    </td> */}
                                </tr>
                                <tr style={{borderBottom: 'solid 1px lightgrey'}}>
                                    <td style={{backgroundColor: "#d6e4ff"}}>DAILY SALES AGENT PREMIUM GOALS</td>
                                    <td style={{textAlign: 'center', fontWeight: 'bold'}}>
                                        {new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD"
                                        }).format(total_DailySales)}
                                    </td>
                                    <td style={{textAlign: 'center'}}>
                                        {new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD"
                                        }).format(topAgentSales_DailySales)}
                                    </td>
                                    <td style={{textAlign: 'center'}}>
                                        {new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD"
                                        }).format(midAgentSales_DailySales)}
                                    </td>
                                    <td style={{textAlign: 'center'}}>
                                        {new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD"
                                        }).format(lowAgentSales_DailySales)}
                                    </td>
                                    {/* <td style={{textAlign: 'center'}}>
                                        {new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD"
                                        }).format(total_DailySales)}
                                    </td> */}
                                </tr>
                                <tr style={{borderBottom: 'solid 1px lightgrey'}}>
                                    <td style={{backgroundColor: "#ccc"}}>NUMBER OF SALES AGENTS</td>
                                    <td style={{textAlign: 'center', fontWeight: 'bold'}}>{total_AgentNumber}</td>
                                    <td style={{textAlign: 'center'}}>
                                        <InputNumber
                                            min={0}
                                            max={15}
                                            step={1}
                                            style={{ marginLeft: 0, width: '15%' }}
                                            value={this.state.topAgentNumber}
                                            onChange={e => this.handleChange(e, "topAgentNumber")}
                                            onBlur={this.handleSave}
                                        />
                                    </td>
                                    <td style={{textAlign: 'center'}}>
                                        <InputNumber
                                            min={0}
                                            max={15}
                                            step={1}
                                            style={{ marginLeft: 0, width: '15%' }}
                                            value={this.state.midAgentNumber}
                                            onChange={e => this.handleChange(e, "midAgentNumber")}
                                            onBlur={this.handleSave}
                                        />
                                    </td>
                                    <td style={{textAlign: 'center'}}>
                                        <InputNumber
                                            min={0}
                                            max={15}
                                            step={1}
                                            style={{ marginLeft: 0, width: '15%' }}
                                            value={this.state.lowAgentNumber}
                                            onChange={e => this.handleChange(e, "lowAgentNumber")}
                                            onBlur={this.handleSave}
                                        />
                                    </td>
                                    {/* <td style={{textAlign: 'center'}}>{total_AgentNumber}</td> */}
                                </tr>
                                <tr style={{borderBottom: 'solid 1px lightgrey'}}>
                                    <td style={{backgroundColor: "#ccc"}}>SALES AGENT CLOSE RATIO</td>
                                    <td style={{textAlign: 'center', fontWeight: 'bold'}}></td>
                                    <td style={{textAlign: 'center'}}>
                                        <InputNumber
                                            min={0}
                                            max={75}
                                            step={.01}
                                            style={{ marginLeft: 16 }}
                                            value={this.state.topAgentCloseRatio}
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                            onChange={e => this.handleChange(e, "topAgentCloseRatio")}
                                            onBlur={this.handleSave}
                                        />
                                    </td>
                                    <td style={{textAlign: 'center'}}>
                                        <InputNumber
                                            min={0}
                                            max={75}
                                            step={.01}
                                            style={{ marginLeft: 16 }}
                                            value={this.state.midAgentCloseRatio}
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                            onChange={e => this.handleChange(e, "midAgentCloseRatio")}
                                            onBlur={this.handleSave}
                                        />
                                    </td>
                                    <td style={{textAlign: 'center'}}>
                                        <InputNumber
                                            min={0}
                                            max={75}
                                            step={.01}
                                            style={{ marginLeft: 16 }}
                                            value={this.state.lowAgentCloseRatio}
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                            onChange={e => this.handleChange(e, "lowAgentCloseRatio")}
                                            onBlur={this.handleSave}
                                        />
                                    </td>
                                </tr>
                                <tr style={{borderBottom: 'solid 1px lightgrey'}}>
                                    <td style={{backgroundColor: "#fff1b8"}}>MONTHLY QUOTED HOUSEHOLDS</td>
                                    <td style={{textAlign: 'center', fontWeight: 'bold'}}>
                                        {!isNaN(total_MonthlyQuotedHouseholds) ? Math.round(total_MonthlyQuotedHouseholds) : 0}
                                    </td>
                                    <td style={{textAlign: 'center'}}>{this.state.topAgentCloseRatio > 0 ? Math.round(topAgentSales_MonthlyQuotedHouseholds) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{this.state.midAgentCloseRatio > 0 ? Math.round(midAgentSales_MonthlyQuotedHouseholds) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{this.state.lowAgentCloseRatio > 0 ? Math.round(lowAgentSales_MonthlyQuotedHouseholds) : 0}</td>
                                    {/* <td style={{textAlign: 'center'}}>
                                        {Math.round(total_MonthlyQuotedHouseholds)}
                                    </td> */}
                                </tr>
                                <tr style={{borderBottom: 'solid 1px lightgrey'}}>
                                    <td style={{backgroundColor: "#fff1b8"}}>QUOTED DAILY HOUSEHOLDS (20 Work Days)</td>
                                    <td style={{textAlign: 'center', fontWeight: 'bold'}}>
                                        {!isNaN(total_DailyQuotedHouseholds) ? Math.round(total_DailyQuotedHouseholds) : 0}
                                    </td>
                                    <td style={{textAlign: 'center'}}>{this.state.topAgentCloseRatio > 0 ? Math.round(topAgentSales_DailyQuotedHouseholds) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{this.state.midAgentCloseRatio > 0 ? Math.round(midAgentSales_DailyQuotedHouseholds) : 0}</td>
                                    <td style={{textAlign: 'center'}}>{this.state.lowAgentCloseRatio > 0 ? Math.round(lowAgentSales_DailyQuotedHouseholds) : 0}</td>
                                    {/* <td style={{textAlign: 'center'}}>
                                        {Math.round(total_DailyQuotedHouseholds)}
                                    </td> */}
                                </tr>
                                <tr style={{borderBottom: 'solid 1px lightgrey'}}>
                                    <td style={{backgroundColor: "#ffd8bf"}}>MONTHLY WRITTEN ITEMS GOAL</td>
                                    <td style={{textAlign: 'center', fontWeight: 'bold'}}>{Math.round(total_MonthlyWrittenItems)}</td>
                                    <td style={{textAlign: 'center'}}>{Math.round(topAgentSales_MonthlyWrittenItems)}</td>
                                    <td style={{textAlign: 'center'}}>{Math.round(midAgentSales_MonthlyWrittenItems)}</td>
                                    <td style={{textAlign: 'center'}}>{Math.round(lowAgentSales_MonthlyWrittenItems)}</td>
                                    {/* <td style={{textAlign: 'center'}}>{Math.round(total_MonthlyWrittenItems)}</td> */}
                                </tr>
                                <tr style={{borderBottom: 'solid 1px lightgrey'}}>
                                    <td style={{backgroundColor: "#ffd8bf"}}>DAILY WRITTEN ITEMS GOAL</td>
                                    <td style={{textAlign: 'center', fontWeight: 'bold'}}>{Math.round(total_DailyWrittenItems)}</td>
                                    <td style={{textAlign: 'center'}}>{Number(topAgentSales_DailyWrittenItems).toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{Number(midAgentSales_DailyWrittenItems).toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{Number(lowAgentSales_DailyWrittenItems).toFixed(2)}</td>
                                    {/* <td style={{textAlign: 'center'}}>{Math.round(total_DailyWrittenItems)}</td> */}
                                </tr>
                                <tr style={{borderBottom: 'solid 1px lightgrey'}}>
                                    <td style={{backgroundColor: "violet"}}>MONTHLY WRITTEN HOUSEHOLDS GOAL</td>
                                    <td style={{textAlign: 'center', fontWeight: 'bold'}}>{Math.round(total_MonthlyWrittenHousehold)}</td>
                                    <td style={{textAlign: 'center'}}>{Math.round(topAgentSales_MonthlyWrittenHousehold)}</td>
                                    <td style={{textAlign: 'center'}}>{Math.round(midAgentSales_MonthlyWrittenHousehold)}</td>
                                    <td style={{textAlign: 'center'}}>{Math.round(lowAgentSales_MonthlyWrittenHousehold)}</td>
                                    {/* <td style={{textAlign: 'center'}}>{Math.round(total_MonthlyWrittenHousehold)}</td> */}
                                </tr>
                                <tr>
                                    <td style={{backgroundColor: "violet"}}>DAILY WRITTEN HOUSEHOLDS GOAL</td>
                                    <td style={{textAlign: 'center', fontWeight: 'bold'}}>{Math.round(total_DailyWrittenHousehold)}</td>
                                    <td style={{textAlign: 'center'}}>{Number(topAgentSales_DailyWrittenHousehold).toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{Number(midAgentSales_DailyWrittenHousehold).toFixed(2)}</td>
                                    <td style={{textAlign: 'center'}}>{Number(lowAgentSales_DailyWrittenHousehold).toFixed(2)}</td>
                                    {/* <td style={{textAlign: 'center'}}>{Math.round(total_DailyWrittenHousehold)}</td> */}
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <Row>
                        <Col span={24} style={{padding: 16}}>
                            {/* <Button type="primary" onClick={this.handleSave} style={{ position: "absolute", right: 0 }}>
                                <SaveOutlined />
                            </Button> */}
                        </Col>                           
                    </Row>
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(SalesAgent);