import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Card, Input, Row, Col, List, Typography, Select, Button, Radio, Checkbox, Modal } from 'antd';
import { DeleteOutlined, SaveOutlined, PlusCircleOutlined } from '@ant-design/icons';
const {Title, Text} = Typography
const {Option} = Select

class Agents extends Component {
    constructor(props) {
      super(props);
        this.state = {
            id: 0,
            firstName: '',
            email: '',
            dailyQuoteGoal: '',
            agencyId: this.props.agencyId,
            userName: '',
            status: "1",
            userId: 0,
            agentFilter: 'Active',
            producerNo: '',
            invalid: false,
            onlySelf: false,
            showModal: false,
            sendBragEmail: false
        }
        this.handleSave = this.handleSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleListClick = this.handleListClick.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.handleNewUser = this.handleNewUser.bind(this);
    }
    componentDidMount() {
        const agencyId = this.props.agencyId ? this.props.agencyId : localStorage.getItem('agencyId');
        this.props.getAgent(agencyId);
        this.props.getUserAgent("");
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handleBlur = (e) => {
        this.props.getUserAgent(e.target.value);
    }
    handleSave(event) {
        event.preventDefault();
        const id = this.state.id;
        const firstName = this.state.firstName;
        const email = this.state.email;
        const dailyQuoteGoal = 0; //this.state.dailyQuoteGoal;
        const agencyId = this.props.agencyId;
        const password = this.state.password;
        const userName = this.state.userName;
        const status = this.state.status;
        const userId = this.state.userId;
        const producerNo = this.state.producerNo;
        const onlySelf = this.state.onlySelf;
        const sendBragEmail = this.state.sendBragEmail;

        if(firstName != '' && email != '' && password != '' && userName != ''){
            this.props.createAgent(id, firstName, email, dailyQuoteGoal, agencyId, password, userName, status, userId, producerNo, onlySelf, sendBragEmail);

            this.setState({
                id: 0,
                firstName: '',
                email: '',
                dailyQuoteGoal: '',
                password: '',
                userName: '',
                status: "1",
                producerNo: '',
                invaid: false,
                onlySelf: false,
                showModal: false,
                sendBragEmail: false
            });
        } else {
            this.setState({invalid: true});
        }
        

        
    }
    handleDelete(event) {
        event.preventDefault();
        const id = this.state.id;
 
        this.props.deleteAgent(id);

        this.setState({
            id: 0,
            firstName: '',
            email: '',
            dailyQuoteGoal: ''
        })
    }
    handleListClick = (agent) => {
        this.setState({
            id: agent.agentId,
            firstName: agent.firstName, 
            email: agent.email, 
            dailyQuoteGoal: agent.dailyQuoteGoal,
            userName: agent.userName,
            status: agent.status,
            userId: agent.userId,
            password: agent.password,
            onlySelf: agent.onlySelf,
            producerNo: agent.producerNo,
            sendBragEmail: agent.sendBragEmail,
            showModal: true
        });

    }
    handleStatusChange = (e) => {
        console.log(e);
        this.setState({status: e.value});
    }
    handleRadioChange(e) {
        this.setState({agentFilter: e.target.value});
    }
    handleCheckChange(e) {

        this.setState({onlySelf: e.target.checked});
    }
    handleSebdBragEmailChange(e) {

        this.setState({sendBragEmail: e.target.checked});
    }
    handleNewUser(){
        this.setState({
            id: 0,
            firstName: '', 
            email: '', 
            dailyQuoteGoal: 0,
            userName: '',
            status: '',
            userId: 0,
            password: '',
            onlySelf: false,
            producerNo: '',
            showModal: true,
            sendBragEmail: false
        });

    }
    render() {
        console.log(this.props.userAgent);
        const agents = this.state.agentFilter == "Active" ? this.props.agent.filter(a => a.status != "5") : this.props.agent.filter(a => a.status == "5");
        return (
            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={4} style={{paddingLeft: 16}}>
                            <Button shape="round" type="primary" size="lg" onClick={this.handleNewUser} style={{ marginBottom: 16 }}>
                                <PlusCircleOutlined /> Add New Agent
                            </Button>
                        </Col>
                        <Col md={4} style={{display: 'flex', justifyContent: 'end'}}>
                            <Radio.Group size="small" defaultValue="Active" buttonStyle="solid" onChange={this.handleRadioChange} value={this.state.agentFilter}>
                                <Radio.Button value="Active">Active</Radio.Button>
                                <Radio.Button value="Inactive">Inactive</Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8} style={{paddingLeft: 16}}>
                            {this.props.agent &&
                                agents.map(a =>
                                    <Card title={a.firstName} style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '10px 5px 5px #ccc', marginBottom: 10 }} extra={<Button onClick={() => this.handleListClick(a)}>Edit</Button>}>
                                        <Row>
                                            <Col md={12}>
                                                <Text>{a.email}</Text>
                                            </Col>
                                        </Row>
                                    </Card>
                                )
                            }
                            {/* <List header={
                                <Row>
                                    <Col md={8}><Title level={4}>Users</Title></Col>
                                    <Col md={8} style={{textAlign: 'center'}}>
                                        <Radio.Group size="small" defaultValue="Active" buttonStyle="solid" onChange={this.handleRadioChange} value={this.state.agentFilter}>
                                            <Radio.Button value="Active">Active</Radio.Button>
                                            <Radio.Button value="Inactive">Inactive</Radio.Button>
                                        </Radio.Group>
                                    </Col>
                                    <Col md={8}>
                                        <Button type="primary" size="small" shape="round" style={{position: 'absolute', right: 0, fontWeight: 'bold'}} onClick={this.handleNewUser}><PlusCircleOutlined /> New User</Button>
                                    </Col>
                                </Row>
                                } itemLayout="horizontal" dataSource={this.state.agentFilter == "Active" ? this.props.agent.filter(a => a.status != "5") : this.props.agent.filter(a => a.status == "5")} renderItem={item => (
                                <List.Item actions={[<a key="list-loadmore-edit" onClick={() => this.handleListClick(item)}>edit</a>]}>
                                    <List.Item.Meta 
                                        title={item.firstName}
                                        description={item.email}
                                    />
                                </List.Item>
                            )} />  */}
                        </Col>
                        <Modal width={1000}
                            title="Sales Agents"
                            visible={this.state.showModal}
                            onCancel={() => this.setState({showModal: false})}
                            footer={[
                            <Button shape="round" type="primary" onClick={this.handleSave} style={{ marginTop: 16 }}>
                                <SaveOutlined /> {this.state.id == '' ? 'Submit' : 'Update'}
                            </Button>,
                            <Button shape="round" key="cancel" type="danger" onClick={() => this.setState({showModal: false})}>
                                Cancel
                            </Button>,
                        ]}>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label htmlFor="dts">First Name <span style={{color: 'Red'}}>*</span></label><br />
                                        <Input value={this.state.firstName} name='firstName' onChange={e => this.handleChange(e)}  />
                                    </div>
                                    <div>
                                        <label htmlFor="phone">Email <span style={{color: 'Red'}}>*</span></label><br />
                                        <Input value={this.state.email} name='email' onChange={e => this.handleChange(e)} onBlur={e => this.handleBlur(e)}  />
                                        {
                                        (this.props.userAgent == undefined) ?
                                            <span></span>
                                            :
                                            (this.props.userAgent.id > 0) ?
                                            <Text type="danger" id="invalid">Email is already registered. {this.props.userAgent.id}</Text>
                                            :
                                            <span></span>
                                        }
                                    </div>
                                    <div>
                                        <label htmlFor="producerNo">Producer Number</label><br />
                                        <Input value={this.state.producerNo} name='producerNo' onChange={e => this.handleChange(e)}  />
                                    </div>
                                    <div>
                                        <label htmlFor="phone">User Name <span style={{color: 'Red'}}>*</span></label><br />
                                        <Input value={this.state.userName} name='userName' onChange={e => this.handleChange(e)}  />
                                    </div>
                                    <div>
                                        <label htmlFor="phone">Password <span style={{color: 'Red'}}>*</span></label><br />
                                        <Input value={this.state.password} name='password' onChange={e => this.handleChange(e)}  />
                                    </div>
                                    <div>
                                        <label htmlFor="phone">Status</label><br />
                                        <Select
                                            labelInValue
                                            style={{ width: '100%' }}
                                            placeholder="Please select"
                                            name="status"
                                            value={{ value: this.state.status.toString() }}
                                            onChange={(e) => this.handleStatusChange(e)}>
                                            <Option key={2} value="2">User</Option>
                                            <Option key={1} value="1">Super User</Option>
                                            <Option key={4} value="4">Administrator</Option>
                                            <Option key={5} value="5">Inactive</Option>
                                        </Select>
                                    </div>
                                    <div>
                                        <Checkbox checked={this.state.onlySelf} onChange={(e) => this.handleCheckChange(e)}>
                                            Only See Self Data 
                                        </Checkbox>
                                        <Checkbox checked={this.state.sendBragEmail} onChange={(e) => this.handleSebdBragEmailChange(e)}>
                                            Send Humble Brag Email
                                        </Checkbox>
                                    </div>
                                    {
                                    (this.state.invalid == true) ?
                                        <Text type="danger" id="invalid">Required fields.</Text>
                                        :
                                        <span></span>
                                    }
                                </Col>
                            </Row>
                        </Modal>
                    </Row>
                </Col>
            </Row>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Agents);