import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { actionCreators } from '../store/humbleActions';
import { DownloadOutlined } from '@ant-design/icons';
import { List, Typography, Row, Col, Button, Table, Select, DatePicker, Space } from 'antd';
import moment from 'moment';
const { Title } = Typography;
const {Option} = Select;
const { RangePicker } = DatePicker;

class ZipUploadReport extends Component {
    constructor(props) {
      super(props);
        this.state = {
           columnHeaders: [],
           zipCounts: [],
           selectedLeadSource: '',
           valid: false,
           dateTo: moment().format("01/01/2030"),
           dateFrom: moment().format("01/01/2020"),
           fileName: ''
        }
        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
        this.handleSelectedLeadSourceChange = this.handleSelectedLeadSourceChange.bind(this);
        this.handleListClick = this.handleListClick.bind(this);
    }
    componentDidMount(){
        const userId = localStorage.getItem('userId');
        const agencyId = localStorage.getItem('agencyId');
        this.props.getLeadSource(agencyId);
        this.props.getZipUploads(agencyId, moment('01/01/2020').format("MM/DD/YYYY"), moment('01/01/2030').format("MM/DD/YYYY"));
        this.props.getZipUploadFilenames(agencyId, moment('01/01/2020').format("MM/DD/YYYY"), moment('01/01/2030').format("MM/DD/YYYY"));
    }
    componentDidUpdate(prevProps){
        if(prevProps.zipCounts != this.props.zipCounts)
        {
            const agencyId = localStorage.getItem('agencyId');
            this.props.getZipUploadFilenames(agencyId, moment(this.state.dateFrom).format("MM/DD/YYYY"), moment(this.state.dateTo).format("MM/DD/YYYY"));
        }
        console.log(this.props.zipUpload);
        if(this.props.zipUpload != prevProps.zipUpload)
        {
            const agencyId = localStorage.getItem('agencyId');
            this.props.getZipUploads(agencyId, moment(this.state.dateFrom).format("MM/DD/YYYY"), moment(this.state.dateTo).format("MM/DD/YYYY"));
            this.props.getZipUploadFilenames(agencyId, moment(this.state.dateFrom).format("MM/DD/YYYY"), moment(this.state.dateTo).format("MM/DD/YYYY"));
        }
    }
    handleSelectedLeadSourceChange(value){
        this.setState({selectedLeadSource: value, valid: true});
    }
    handleDateRangeChange(dates, dateStrings) {
        const dateFrom = moment(dateStrings[0]).format("MM/DD/YYYY");
        const dateTo = moment(dateStrings[1]).format("MM/DD/YYYY");
        const agencyId = localStorage.getItem('agencyId');
        if(dateFrom != ''){
            this.props.getZipUploads(agencyId, dateFrom, dateTo)
            this.props.getZipUploadFilenames(agencyId, dateFrom, dateTo);
        }
        this.setState({
            dateFrom: dateFrom,
            dateTo: dateTo, filtered: true, filteredVendorSummary: []
        });
    }
    handleListClick = (zipUpload) => {
        const dateFrom = moment(this.state.dateFrom).format("MM/DD/YYYY");
        const dateTo = moment(this.state.dateTo).format("MM/DD/YYYY");
        const agencyId = localStorage.getItem('agencyId'); 
        const monthDts = moment(zipUpload.monthDts).format("MM/DD/YYYY");;
        const fileName = zipUpload.fileName;
        this.props.deleteZipUpload(agencyId, dateFrom, dateTo, monthDts, fileName);
        this.props.getZipUploadFilenames(agencyId, moment(this.state.dateFrom).format("MM/DD/YYYY"), moment(this.state.dateTo).format("MM/DD/YYYY"));
    }
    exportToCSV = (e) => {
        e.preventDefault();
        var data, filename, link;
        data = this.props.zipCounts;
        var csv = this.convertArrayOfObjectsToCSV(data);
        if (csv == null)
          return;
    
        filename = 'ZipCodeUpload' + this.state.dateFrom + '-' + this.state.dateTo + '.csv';
    
        var blob = new Blob([csv], {type: "text/csv;charset=utf-8;"});
    
        if (navigator.msSaveBlob)
        { // IE 10+
          navigator.msSaveBlob(blob, filename)
        }
        else
        {
          var link = document.createElement("a");
          if (link.download !== undefined)
          {
            // feature detection, Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style = "visibility:hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
    
        //console.log(arrOrders);
    }
    
    convertArrayOfObjectsToCSV(args) {
        var result, ctr, keys, columnDelimiter, lineDelimiter, data;
    
        data = args || null;
        if (data == null || !data.length) {
            return null;
        }
    
        columnDelimiter = args.columnDelimiter || ',';
        lineDelimiter = args.lineDelimiter || '\n';
    
        keys = Object.keys(data[0]);
    
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;
    
        data.forEach(function(item) {
            ctr = 0;
            keys.forEach(function(key) {
                //if(key != 'costPerLead' && key != 'children' && key != 'key'){
                    if (ctr > 0) result += columnDelimiter;
                    result += item[key]
                    ctr++;
                //}
            });
            result += lineDelimiter;
        });
    
        return result;
    };
    render() {
        const zipColumns = [
            {
                title: 'Zip Code',
                dataIndex: 'zip',
                key: 'zip',
                sorter: (a, b) => ('' + a.zip).localeCompare(b.zip),
            },
            {
                title: 'Count',
                dataIndex: 'count',
                key: 'count',
                sorter: (a, b) => a.count - b.count,
            },
        ]
        return (
            <div>
                <Row>
                    <Space>
                        <RangePicker picker="month" onChange={this.handleDateRangeChange} />
                        <Select
                            style={{ width: '100%' }}
                            dropdownStyle={{ minWidth: "20%" }}
                            placeholder="Please Select Lead Source"
                            name="leadSource"
                            onChange={this.handleSelectedLeadSourceChange}>
                            {
                                this.props.leadSource.map(a => 
                                    <Option key={a.id} value={a.id}>{a.name}</Option>
                                )
                            }
                        </Select>
                        <Button shape="round" onClick={this.exportToCSV} style={{position: 'absolute', right: 0}}>
                            <DownloadOutlined /> Export
                        </Button>
                    </Space>
                </Row>
                <Row>
                    <Col md={18}>
                        <Table dataSource={this.props.zipCounts} columns={zipColumns} pagination={false}></Table>
                    </Col>
                    <Col md={6} style={{paddingLeft: 10}}>
                        <List header={
                            <Row>
                                <Col span={12}>
                                    <Title level={3}>Sources</Title>
                                </Col>
                            </Row>} itemLayout="horizontal" dataSource={this.props.zipUploadFilename} renderItem={item => (
                            <List.Item actions={[<a style={{color: 'Red'}} key="list-loadmore-edit" onClick={() => this.handleListClick(item)}>delete</a>]}>
                                <List.Item.Meta 
                                title={item.fileName} 
                                description={moment(item.monthDts).format('MM/yyyy')} />
                            </List.Item>
                        )} /> 
                    </Col>
                </Row>
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ZipUploadReport);