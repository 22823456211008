import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Card, Input, Row, Col, List, Typography, Select, Button, Radio, Checkbox, message, Upload } from 'antd';
import type { UploadProps } from 'antd';
import { DeleteOutlined, SaveOutlined, PlusCircleOutlined, PlayCircleOutlined, UploadOutlined, FileTextOutlined, EditOutlined, FolderAddOutlined, CloseOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import "video-react/dist/video-react.css";
import { Player, BigPlayButton, ControlBar } from 'video-react';
import EducationMaterials from './EducationMaterials'
const {Title, Text} = Typography
const {Option} = Select
const { Dragger } = Upload;

class UploadEducation extends Component {
    constructor(props) {
      super(props);
        this.state = {
            educationSection: '',
            selectedEducationId: 0,
            newFolder: false
        }
        this.handleSourceChange = this.handleSourceChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleAddEducationSection = this.handleAddEducationSection.bind(this);
        this.handleUploadFile = this.handleUploadFile.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onDragEndSection = this.onDragEndSection.bind(this);
        this.handleRenameEducationSection = this.handleRenameEducationSection.bind(this);
        this.handleDeleteEducationSection = this.handleDeleteEducationSection.bind(this);
    }

    componentDidMount() {
        const agencyId = localStorage.getItem('agencyId');
        this.props.getEducationSections(agencyId);
        this.props.getEducationMaterials(agencyId);
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handleAddEducationSection() {
        const agencyId = localStorage.getItem('agencyId');
        this.props.createEducationSections(agencyId, this.state.educationSection);
        this.setState({newFolder: false, educationSection: ''});
    }

    handleSourceChange(e) {
        this.setState({videoSrc: e})
        const userId = localStorage.getItem('userId');
    }
    handleUploadFile(file, educationSectionId) {
        console.log(file);
        const agencyId = localStorage.getItem('agencyId');
        this.props.createEducationMaterials(agencyId, educationSectionId, file);
    }
    onDragEnd = ({ source, destination, draggableId}) => {
        if (destination == undefined) {
            return;
        }
        if(destination.index == source.index){
            return;
        }
        const agencyId = localStorage.getItem('agencyId');
        console.log(destination); 
        this.props.updateEducationMaterials(agencyId, draggableId, destination.droppableId, source.index, destination.index);
        return null
    }
    onDragEndSection = ({ source, destination, draggableId}) => {
        if (destination == undefined) {
            return;
        }
        if(destination.index == source.index){
            return;
        }
        const agencyId = localStorage.getItem('agencyId');
        console.log(destination); 
        this.props.updateEducationSections(agencyId, draggableId, source.index, destination.index);
    }
    handleRenameEducationSection(){
        const agencyId = localStorage.getItem('agencyId');
        const sectionId = this.state.selectedEducationId;
        const name = this.state.educationSection;
        this.props.renameEducationSection(sectionId, agencyId, name);
        this.setState({selectedEducationId: 0, educationSection: '', newFolder: false})
    }
    handleDeleteEducationSection(){
        const agencyId = localStorage.getItem('agencyId');
        const sectionId = this.state.selectedEducationId;
        const name = this.state.educationSection;
        this.props.deleteEducationSection(sectionId, agencyId, name);
        this.setState({selectedEducationId: 0, educationSection: ''})
    }
    render() {
        const props = {
            name: 'file',
            multiple: false,
            showUploadList: false,
            beforeUpload: (file) => {
                console.log(file);
                const agencyId = localStorage.getItem('agencyId');
                this.props.createEducationMaterials(agencyId, 0, file);
                return false;
            },
        };
        return (
            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={18} style={{paddingLeft: 16}}>
                            <div>
                                <Row>
                                    <Col span={24}>
                                        <Button size="large" style={{ float: 'left', marginTop: 5 }} type="primary" shape="round" onClick={() => this.setState({newFolder: true, selectedEducationId: 0, educationSection: ''})}>
                                            <FolderAddOutlined /> Create New Folder
                                        </Button>
                                    </Col>
                                </Row>
                                {
                                    this.state.newFolder &&
                                    <Row>
                                        <Col span={24} style={{padding: 20, marginTop: 20, border: 'solid 1px #1890ff', borderColor: '#1890ff', borderRadius: "20px", boxShadow: '10px 5px 5px #ccc', marginBottom: 10}}>
                                            <label htmlFor="educationSection">Folder Name</label>
                                            <Input value={this.state.educationSection} name='educationSection' onChange={e => this.handleChange(e)} />
                                            {this.state.selectedEducationId == 0 ?
                                            <div>
                                                <Button style={{ float: 'left', marginTop: 5 }} type="primary" shape="round" onClick={() => this.handleAddEducationSection()}>
                                                    <SaveOutlined /> Save
                                                </Button>
                                                <Button style={{ float: 'right', marginTop: 5 }} shape="round" onClick={() => this.setState({newFolder: false})}>
                                                    <CloseOutlined /> Close
                                                </Button>
                                            </div>
                                            :
                                            <div>
                                                <Button style={{ float: 'left', marginTop: 5 }} type="primary" shape="round" onClick={() => this.handleRenameEducationSection()}>
                                                    <SaveOutlined /> Update
                                                </Button>
                                                <Button style={{ float: 'left', marginTop: 5, marginLeft: 10 }} shape="round" onClick={() => this.setState({selectedEducationId: 0, educationSection: ''})}>
                                                    <CloseOutlined /> Cancel
                                                </Button>
                                                <Button style={{ position: 'absolute', right: 5, marginTop: 5}} type="danger" shape="round" onClick={() => this.handleDeleteEducationSection()}>
                                                    <DeleteOutlined /> Delete Folder
                                                </Button>
                                            </div>
                                            }
                                        </Col>
                                    </Row>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={18} style={{paddingLeft: 16}}>
                                <DragDropContext onDragEnd={this.onDragEndSection}>
                                    <Droppable droppableId="droppable">
                                        {(providedS, snapshot) => (
                                            <div {...providedS.droppableProps} ref={providedS.innerRef}>
                                                {this.props.educationSections &&
                                                this.props.educationSections.map((e, index) =>
                                                    <Draggable key={e.id} draggableId={e.id.toString()} index={index}>
                                                        {(providedS, snapshot) => (
                                                            <div ref={providedS.innerRef} {...providedS.draggableProps} >
                                                                <Row key={e.id} style={{marginTop: 10}}>
                                                                    <Col span={24}>
                                                                        <Card title={
                                                                            <div {...providedS.dragHandleProps} style={{display: 'flex', justifyContent: 'space-between'}}>
                                                                                {e.name}
                                                                                <Button shape="circle" icon={<EditOutlined />} onClick={() => this.setState({selectedEducationId: e.id, educationSection: e.name, newFolder: true})} />
                                                                            </div>
                                                                            } style={{borderColor: '#1890ff', borderRadius: "20px", boxShadow: '10px 5px 5px #ccc', marginBottom: 10 }}>
                                                                            <EducationMaterials educationSectionId={e.id} />
                                                                        </Card>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )}
                                                {providedS.placeholder}
                                            </div>
                                        )}
                                        
                                    </Droppable>
                                </DragDropContext>
                        </Col>
                        {/* <Col span={16} style={{paddingLeft: 16}}>
                            <Player src={this.state.videoSrc} autoPlay>
                                <ControlBar autoHide={false}  />
                            </Player>
                        </Col> */}
                    </Row>
                </Col>
            </Row>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(UploadEducation);