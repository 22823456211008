import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom'
import { actionCreators } from '../store/humbleActions';
import { Card, Input, Row, Col, Select, Button, Collapse, Typography } from 'antd';
import Agents from './Agents'
const {Option} = Select;
const { Panel } = Collapse;
const {Title} = Typography;

class Agency extends Component {
    constructor(props) {
      super(props);
        this.state = {
            name: '',
            phone: '',
            address: '',
            address2: '',
            city: '',
            state:'', 
            zip: '',
            agencyId: '',
            loading: false
        }
        this.handleSave = this.handleSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount(){
        const userId = localStorage.getItem('userId');
        const agencyId = localStorage.getItem('agencyId');
        //this.props.getAgency(userId);
        this.setState({loading: true, agencyId: agencyId})
    }
    componentDidUpdate(prevProps){
        if(this.props.agency != prevProps.agency){
            this.setState({
                name: this.props.agency.name,
                phone: this.props.agency.phone,
                address: this.props.agency.address,
                address2: this.props.agency.address2,
                city: this.props.agency.city,
                state: this.props.agency.state,
                zip: this.props.agency.zip,
                agencyId: this.props.agency.id
            });
            //localStorage.setItem('agencyId', this.props.agency.id);
        }
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handleStateChange = (e) => {
        this.setState({
            state: e    
        });
    }
    handleSave(event) {
        event.preventDefault();
        const userId = localStorage.getItem('userId');
        const name = this.state.name;
        const phone = this.state.phone;
        const address = this.state.address;
        const address2 = this.state.address2;
        const city = this.state.city;
        const state = this.state.state;
        const zip = this.state.zip;       
        
        this.props.createAgency(name, phone, address, address2, city, state, zip, userId);

        this.setState({loading: true})
    }
    renderRedirect = () => {
        let path = 'quotelog';
        this.props.history.push(path);
    }
    render() {
        const stateList = require('../data/states.json');
        return (
            <div>
            {/* <Row>
                <Col span={24}>
                    <Card title="Agency Profile" id="divRegister" headStyle={{ backgroundColor: '#97E45D', color: '#fff' }}>
                        <Row>
                            <Col span={12}>
                                <div>
                                    <label htmlFor="name">Agency Name</label><br />
                                    <Input value={this.state.name} name='name' onChange={e => this.handleChange(e)}  />
                                </div>
                                <div>
                                    <label htmlFor="phone">Phone</label><br />
                                    <Input value={this.state.phone} name='phone' onChange={e => this.handleChange(e)}  />
                                </div>
                                <div>
                                    <label htmlFor="address">Address</label><br />
                                    <Input value={this.state.address} name='address' onChange={e => this.handleChange(e)} />
                                </div>
                                <div>
                                    <label htmlFor="address2">Address 2</label><br />
                                    <Input value={this.state.address2} name='address2' onChange={e => this.handleChange(e)} />
                                </div>
                                <Row>
                                    <Col span={8} style={{paddingRight: 10}}>
                                        <label htmlFor="spend">City</label><br />
                                        <Input name='city' value={this.state.city} onChange={e => this.handleChange(e)} />
                                    </Col>
                                    <Col span={8} style={{paddingRight: 10}}>
                                        <label htmlFor="spend">State</label><br />
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Please select"
                                            name="ineligible"
                                            value={this.state.state}
                                            onChange={(e) => this.handleStateChange(e)}>
                                            {
                                                stateList.map(d => 
                                                    <Option key={d.abbreviation}>{d.name}</Option>
                                                )
                                            }
                                        </Select>     
                                    </Col>
                                    <Col span={8}>
                                        <label htmlFor="spend">Zip</label><br />
                                        <Input name='zip' value={this.state.zip} onChange={e => this.handleChange(e)} />
                                    </Col>
                                </Row> 
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <div>
                                    <Button type="primary" onClick={this.handleSave} style={{ marginTop: 16 }}>
                                        <Icon type="save" /> Submit
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row> */}
            <Agents agencyId={this.state.agencyId} />
            {/* {(this.state.agencyId) && 
            <Collapse>
                <Panel header={<Title level={4}>AGENTS</Title>} key="2" style={{backgroundColor: "#fff"}}>
                    <Row>
                        
                    </Row>
                </Panel>
            </Collapse>
            } */}
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Agency);