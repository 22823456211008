import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Collapse, Row, Col, Card, Slider, InputNumber, Typography, Button, Select, List, Progress, Space, DatePicker, Spin } from 'antd'
import { RingProgress, Line, Column, DualAxes } from '@ant-design/plots';

import ReactPlayer from 'react-player'
import moment from 'moment';
const { Title, Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

class AgentGoals extends Component {
    constructor(props) {
      super(props);
        this.state = {
        premium: 0,
        premiumPerItem: 0,
        premiumPerPolicy: 0,
        premiumPerHousehold: 0,
        itemsRequired: 0,
        itemsPerHousehold: 0,
        householdsNeeded: 0,
        closingRatio: 0,
        closingRatioPolicy: 0,
        closingRatioHousehold: 0,
        totalMonthly: 0,
        writtenItems: 0,
        loaded: false,
        itemsPerPolicy: 0,
        agent: 'All',
        agentId: 0,
        quotedBundleRatio: 0,
        writtenBundleRatio: 0,
        monthDts: moment().format("MMM YYYY"),
        selectedDate: moment().startOf('month').format("MM/DD/YYYY")
        };
        this.handleSave = this.handleSave.bind(this);
        this.handleAgentChange = this.handleAgentChange.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
    }
    componentDidMount(){
        const userId = localStorage.getItem("userId");
        const agencyId = localStorage.getItem("agencyId");
        this.props.getAgent(agencyId);
        this.setState({loaded: true});
        if(this.props.viewOnly){
            if(this.props.loggedInAgent != 'All'){
                const agent = this.props.loggedInAgent;
                const agentId = this.props.loggedInAgentId;
                this.setState({agent: agent, agentId: agentId});
                this.props.getMarketingAgent(agentId, moment().format("MMM YYYY"));
                this.props.getSalesAgentSummary(agencyId, moment().startOf('month').format("MM/DD/YYYY"), moment().endOf('month').format("MM/DD/YYYY"), agent);
                var threeMonthsAgo = moment().subtract(3, 'months').calendar(); 
                this.props.getMonthlyWrittenBundles(agencyId, moment().startOf('month').format("MM/DD/YYYY"), moment(threeMonthsAgo).endOf('month').format("MM/DD/YYYY"), agent);
            } else {
                this.props.getTotalMarketingAgentUsers(agencyId, moment().format("MMM YYYY"));
                this.props.getTotalSalesAgentSummary(agencyId, moment().startOf('month').format("MM/DD/YYYY"), moment().endOf('month').format("MM/DD/YYYY"));
                this.setState({agent: 'All', agentId: 0});
            }
        } else {
            this.props.getTotalMarketingAgentUsers(agencyId, moment().format("MMM YYYY"));
            this.props.getTotalSalesAgentSummary(agencyId, moment().startOf('month').format("MM/DD/YYYY"), moment().endOf('month').format("MM/DD/YYYY"));
        }
    }
    componentDidUpdate(prevProps, prevState){
        if(this.props.marketingAgent != prevProps.marketingAgent){
            this.setState({
                premium: this.props.marketingAgent.monthlyPremium,
                premiumPerItem: this.props.marketingAgent.premiumPerItem,
                premiumPerPolicy: this.props.marketingAgent.premiumPerPolicy,
                premiumPerHousehold: this.props.marketingAgent.premiumPerHousehold,
                itemsPerHousehold: this.props.marketingAgent.itemsPerHousehold,
                closingRatio: this.props.marketingAgent.closedRatio,
                closingRatioPerPolicy: this.props.marketingAgent.closedRatioPerPolicy,
                closingRatioPerHousehold: this.props.marketingAgent.closedRatioPerHousehold,
                itemsPerPolicy: this.props.marketingAgent.itemsPerPolicy,
                quotedBundleRatio: this.props.marketingAgent.quotedBundleRatio,
                writtenBundleRatio: this.props.marketingAgent.writtenBundleRatio
            });
            const agencyId = localStorage.getItem("agencyId");
            //this.props.getTotalMarketingAgentUsers(agencyId);
        }
    }
    handleAgentChange = (e) => {
        console.log(e);
        const agencyId = localStorage.getItem("agencyId");
        const monthDts = this.state.monthDts;
        const selectedDate = this.state.selectedDate;
        if(e != 'All'){
            const agent = e;
            const agentId = this.props.agent.find(a => a.firstName == e).agentId;
            this.setState({agent: e, agentId: agentId});
            this.props.getMarketingAgent(agentId, monthDts);
            console.log(selectedDate);
            this.props.getSalesAgentSummary(agencyId, moment(selectedDate).startOf('month').format("MM/DD/YYYY"), moment(selectedDate).endOf('month').format("MM/DD/YYYY"), agent);
            var threeMonthsAgo = moment().subtract(3, 'months').calendar(); 
            this.props.getMonthlyWrittenBundles(agencyId, moment(threeMonthsAgo).startOf('month').format("MM/DD/YYYY"), moment().endOf('month').format("MM/DD/YYYY"), agent);
        } else {
            this.props.getTotalMarketingAgentUsers(agencyId, monthDts);
            this.props.getTotalSalesAgentSummary(agencyId, moment(selectedDate).startOf('month').format("MM/DD/YYYY"), moment(selectedDate).endOf('month').format("MM/DD/YYYY"));
            this.setState({agent: 'All', agentId: 0});
        }
        
    }
    onPremiumChange = value => {
        this.setState({
          premium: value,
        });
    };
    onPremiumPerItemChange = value => {
        this.setState({
            premiumPerItem: value,
        });
    };
    onPremiumPerPolicyChange = value => {
        this.setState({
            premiumPerPolicy: value,
        });
    };
    onPremiumPerHouseholdChange = value => {
        this.setState({
            premiumPerHousehold: value,
        });
    };
    onPerItemPolicyChange = value => {
        this.setState({
            itemsPerPolicy: value,
        });
    };
    onItemsPerHouseholdChange = value => {
        this.setState({
            itemsPerHousehold: value,
        });
    };
    onClosingRatioChange = value => {
        this.setState({
            closingRatio: value,
        });
    };
    onClosingRatioPolicyChange = value => {
        this.setState({
            closingRatioPerPolicy: value,
        });
    };
    onClosingRatioHouseholdChange = value => {
        this.setState({
            closingRatioPerHousehold: value,
        });
    };
    onQuotedBundleRatioChange = value => {
        this.setState({
            quotedBundleRatio: value,
        });
    };
    onWrittenBundleRatioChange = value => {
        this.setState({
            writtenBundleRatio: value,
        });
    };
    onHouseholdsNeededChange = value => {
        this.setState({
            householdsNeeded: value,
        });
    };
    handleSave(event) {
        const agencyId = localStorage.getItem("agencyId");
        const agentId = this.state.agentId;
        const premium = this.state.premium;
        const premiumPerItem = this.state.premiumPerItem;
        const itemsPerHousehold = this.state.itemsPerHousehold;
        const closingRatio = this.state.closingRatio;
        const closingRatioPerPolicy = this.state.closingRatioPerPolicy;
        const closingRatioPerHousehold = this.state.closingRatioPerHousehold;
        const itemsPerPolicy = this.state.itemsPerPolicy;
        const premiumPerPolicy = this.state.premiumPerPolicy;
        const premiumPerHousehold = this.state.premiumPerHousehold;
        const quotedBundleRatio = this.state.quotedBundleRatio;
        const writtenBundleRatio = this.state.writtenBundleRatio;
        const monthDts = this.state.monthDts;
 
        this.props.createMarketingAgent(agencyId, agentId, premium, premiumPerItem, itemsPerHousehold, closingRatio, itemsPerPolicy, premiumPerPolicy, premiumPerHousehold, closingRatioPerPolicy, closingRatioPerHousehold, quotedBundleRatio, writtenBundleRatio, monthDts);
        
    }
    onMonthChange(date, dateString){
        if(dateString != ''){
            const userId = localStorage.getItem("userId");
            const agencyId = localStorage.getItem("agencyId");
            const agent = this.state.agent;
            const agentId = this.state.agentId;
            this.setState({monthDts: dateString, selectedDate: date});
            if(agent != 'All'){
                this.props.getMarketingAgent(agentId, dateString);
                this.props.getSalesAgentSummary(agencyId, moment(date).startOf('month').format("MM/DD/YYYY"), moment(date).endOf('month').format("MM/DD/YYYY"), agent);
            } else {
                this.props.getTotalMarketingAgentUsers(agencyId, dateString);
                this.props.getTotalSalesAgentSummary(agencyId, moment(date).startOf('month').format("MM/DD/YYYY"), moment(date).endOf('month').format("MM/DD/YYYY"));
            }
            
        }else{
            this.setState({monthDts: moment().format("MMM YYYY")});
        }
    }
    render() {
        const totalAgentMonthly = this.state.agent == 'All' && this.props.totalMarketingAgent.length > 0 ? this.props.totalMarketingAgent[0] : [];
        const premium = this.state.agent == 'All' ? totalAgentMonthly.monthlyPremium : this.state.premium;
        const premiumPerItem = this.state.agent == 'All' ? totalAgentMonthly.premiumPerItem : this.state.premiumPerItem;
        //const premiumPerPolicy = this.state.agent == 'All' ? 0 : this.state.premiumPerPolicy;
        //const premiumPerHousehold = this.state.agent == 'All' ? 0 : this.state.premiumPerHousehold;
        const itemsPerHousehold = this.state.agent == 'All' ? totalAgentMonthly.itemsPerHousehold : this.state.itemsPerHousehold;
        const closingRatio = this.state.agent == 'All' ? parseFloat(totalAgentMonthly.closedRatio).toFixed(2) : this.state.closingRatio;
        const closingRatioPerPolicy = this.state.agent == 'All' ? parseFloat(totalAgentMonthly.closedRatioPerPolicy).toFixed(2) : this.state.closingRatioPerPolicy;
        const closingRatioPerHousehold = this.state.agent == 'All' ? parseFloat(totalAgentMonthly.closedRatioPerHousehold).toFixed(2) : this.state.closingRatioPerHousehold;
        const itemsPerPolicy = this.state.agent == 'All' ? totalAgentMonthly.itemsPerPolicy : this.state.itemsPerPolicy != null ? this.state.itemsPerPolicy : 0;
        const quotedBundleRatio = this.state.agent == 'All' ? parseFloat(totalAgentMonthly.quotedBundleRatio).toFixed(2) : this.state.quotedBundleRatio;
        const writtenBundleRatio = this.state.agent == 'All' ? parseFloat(totalAgentMonthly.writtenBundleRatio).toFixed(2) : this.state.writtenBundleRatio;

        const premiumPerPolicy = premiumPerItem * itemsPerPolicy
        const premiumPerHousehold = premiumPerItem * itemsPerHousehold

        const writtenItems = premiumPerItem > 0 ? premium / premiumPerItem : 0;
        const writtenPolicies = premiumPerPolicy > 0 ? premium / premiumPerPolicy : 0;
        const writtenHouseholds = premiumPerHousehold > 0 ? premium / premiumPerHousehold : 0;

        const closedRatio = closingRatio / 100;
        const closingRatioPolicies = closingRatioPerPolicy / 100;
        const closingRatioHouseholds = closingRatioPerHousehold / 100;

        const quotedItems = closingRatio > 0 ? writtenItems / (closingRatio / 100) : 0;
        const quotedPolicies = closingRatioPolicies > 0 ? writtenPolicies / closingRatioPolicies : 0;
        const quotedHousehold = closingRatioHouseholds > 0 ? writtenHouseholds / closingRatioHouseholds : 0;
        const configBar = {
            data: this.props.marketingAgent,
            isGroup: true,
            yField: 'premiumPerItem',
            seriesField: 'premiumPerPolicy',
            
        };
        
        var agentMonthly = [];
        if(this.state.agent != 'All' & this.props.quoteAgentSummaryByAgent.length > 0) {
            agentMonthly = this.props.quoteAgentSummaryByAgent[0];
        } else if(this.state.agent == 'All' & this.props.totalSalesAgentSummary.length > 0) {
            agentMonthly = this.props.totalSalesAgentSummary[0];
        };
        const agentAvgPerItem = agentMonthly.writtenPremium > 0 ? agentMonthly.writtenPremium / agentMonthly.writtenItems : 0;
        const agentAvgPerPolicy = agentMonthly.writtenPremium > 0 ? agentMonthly.writtenPremium / agentMonthly.writtenPolicies : 0;
        const agentAvgPerHousehold = agentMonthly.writtenPremium > 0 ? agentMonthly.writtenPremium / agentMonthly.writtenHouseholds : 0;
        
        const agentAvgItemsPerPolicy = agentMonthly.writtenItems > 0 ? agentMonthly.writtenItems / agentMonthly.writtenPolicies : 0;
        const agentAvgItemsPerHousehold = agentMonthly.writtenItems > 0 ? agentMonthly.writtenItems / agentMonthly.writtenHouseholds : 0;

        const agentClosePerItem = agentMonthly.quotedItems > 0 ? agentMonthly.writtenItems / agentMonthly.quotedItems : 0;
        const agentClosePerPolicy = agentMonthly.quotedPolicies > 0 ? agentMonthly.writtenPolicies / agentMonthly.quotedPolicies : 0;
        const agentClosePerHouseholds = agentMonthly.count > 0 ? agentMonthly.writtenHouseholds / agentMonthly.count : 0;

        const businessDays = this.state.agent == 'All' ? totalAgentMonthly.businessDays : agentMonthly.businessDays;
        console.log(businessDays + " " + premium);
        const writtenItemsTrending = agentMonthly.count > 0 ? (agentMonthly.writtenItems / agentMonthly.businessDaysSoFar) * agentMonthly.businessDays : 0;
        const writtenPoliciesTrending = agentMonthly.count > 0 ? (agentMonthly.writtenPolicies / agentMonthly.businessDaysSoFar) * agentMonthly.businessDays : 0;
        const writtenHouseholdsTrending = agentMonthly.count > 0 ? (agentMonthly.writtenHouseholds / agentMonthly.businessDaysSoFar) * agentMonthly.businessDays : 0;

        const quotedItemsTrending = agentMonthly.count > 0 ? (agentMonthly.quotedItems / agentMonthly.businessDaysSoFar) * agentMonthly.businessDays : 0;
        const quotedPoliciesTrending = agentMonthly.count > 0 ? (agentMonthly.quotedPolicies / agentMonthly.businessDaysSoFar) * agentMonthly.businessDays : 0;
        const quotedHouseholdsTrending = agentMonthly.count > 0 ? (agentMonthly.count / agentMonthly.businessDaysSoFar) * agentMonthly.businessDays : 0;

        const quotedBundles = agentMonthly.count > 0 ? agentMonthly.quotedBundles / agentMonthly.count : 0;
        const writtenBundles = agentMonthly.writtenHouseholds > 0 ?  agentMonthly.writtenBundles / agentMonthly.writtenHouseholds : 0;

        const dailyQuotedBundles = agentMonthly.quotedBundles / agentMonthly.businessDaysSoFar;
        const dailyWrittenBundles = agentMonthly.writtenBundles / agentMonthly.businessDaysSoFar;
        const data = this.props.monthlyWrittenBundles ? this.props.monthlyWrittenBundles : [];
        const config = {
            data,
            xField: 'bindMonth',
            yField: 'writtenBundleRate',
            point: {
              shapeField: 'square',
              sizeField: 4,
            },
            interaction: {
              tooltip: {
                marker: false,
              },
            },
            style: {
              lineWidth: 2,
            },
          };
        return(
            <div>
                <Row>
                    <Col xs={24} sm={24} lg={24} style={{paddingBottom: 20}}>
                        <Card title="SALES PRODUCER GOALS" headStyle={{ backgroundColor: '#3D76DD', fontSize: 30, textAlign: 'center', color: "#fff" }}>
                            <Row>
                                <Col xs={12} sm={12} lg={12}>
                                {!this.props.viewOnly && !this.props.isActivityLoading &&
                                    <Row>
                                        <Col md={12} style={{paddingBottom: 10}}>
                                            <Space>
                                                <div>
                                                    <strong>PRODUCER:</strong>
                                                    <Select disabled={this.state.isSold && this.state.id}
                                                        visible={!this.props.viewOnly}
                                                        style={{ width: '100%' }}
                                                        placeholder="Please select"
                                                        name="agent"
                                                        value={this.state.agent}
                                                        onChange={(e) => this.handleAgentChange(e)}>
                                                            <Option key='All'>All</Option>
                                                            {    
                                                            this.props.agent.filter(a => a.status != "5").map(a => 
                                                                <Option key={a.firstName}>{a.firstName}</Option>
                                                            )
                                                        }
                                                    </Select>
                                                </div>
                                                <div style={{marginLeft: 20}}>
                                                    <strong>MONTH:</strong><br />
                                                    <DatePicker onChange={this.onMonthChange} picker="month" format="MMM YYYY" value={moment(this.state.monthDts, "MMM YYYY")} />
                                                </div>
                                            </Space>
                                        </Col>
                                    </Row>
                                    }
                                    <Row>
                                    {this.props.isActivityLoading == true ?
                                        <Spin tip="Loading Your Data..."></Spin>
                                    :
                                        <Col span={24}>
                                            <Card title="MONTHLY" headStyle={{ backgroundColor: '#8B8000', color: '#fff', fontSize: '32px', textAlign: 'center' }} style={{borderColor: '#8B8000', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc', marginTop: '10px'}}>
                                            <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc'}}>
                                                <Row>
                                                    <Col md={5}>
                                                    </Col>
                                                    {/* <Col md={10}>
                                                    </Col> */}
                                                    <Col md={3} style={{textAlign: 'center'}}>
                                                        GOAL
                                                    </Col>
                                                    <Col md={3} style={{textAlign: 'center'}}>
                                                        ACTUAL
                                                    </Col>
                                                    <Col md={3} style={{textAlign: 'center'}}>
                                                        TRENDING
                                                    </Col>
                                                </Row>
                                                <Row style={{paddingBottom: 10}}> 
                                                    <Col md={5}>
                                                        <label style={{fontSize: 18}}>Premium</label>
                                                    </Col>
                                                    {/* <Col md={5}>
                                                        <Slider
                                                            min={0}
                                                            max={750000}
                                                            step={5000}
                                                            onChange={this.onPremiumChange}
                                                            value={premium}
                                                            //onBlur={this.handleSave}
                                                        />
                                                    </Col> */}
                                                    <Col md={3}>
                                                        <InputNumber 
                                                            min={0}
                                                            max={2000000}
                                                            step={5000}
                                                            style={{ marginLeft: 5, width: '95%' }}
                                                            value={premium}
                                                            formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                            onChange={this.onPremiumChange}
                                                            //onBlur={this.handleSave}
                                                        />
                                                    </Col>
                                                    <Col md={3}>
                                                        <InputNumber disabled
                                                            min={0}
                                                            max={2000000}
                                                            step={1}
                                                            style={{ marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000' }}
                                                            // formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            // parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                            value={agentMonthly.length == 0 ? 0 : new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(agentMonthly.writtenPremium)}
                                                        />
                                                    </Col>
                                                    <Col md={3}>
                                                        <InputNumber disabled
                                                            min={0}
                                                            max={2000000}
                                                            step={1}
                                                            status={agentMonthly.trending < premium ? 'error' : 'success'}
                                                            style={{ marginLeft: 5, width: '95%', color: '#fff', backgroundColor: agentMonthly.trending < premium ? 'red' : 'green' }}
                                                            // formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            // parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                            value={agentMonthly.length == 0 ? 0 : new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(agentMonthly.trending)}
                                                        />
                                                    </Col>
                                                    {/* <Col md={8}>
                                                        <Column {...configBar} />
                                                    </Col> */}
                                                </Row>
                                                <div>
                                                    <Row>
                                                        <Col md={5}>
                                                            <label style={{fontSize: 18}}>Premium Per Item</label>
                                                        </Col>
                                                        {/* <Col md={10}>
                                                            <Slider
                                                                min={0}
                                                                max={7500}
                                                                step={1}
                                                                onChange={this.onPremiumPerItemChange}
                                                                value={premiumPerItem}
                                                                //onBlur={this.handleSave}
                                                            />
                                                        </Col> */}
                                                        <Col md={3}>
                                                            <InputNumber
                                                                min={0}
                                                                max={7500}
                                                                step={1}
                                                                style={{  marginLeft: 5, width: '95%' }}
                                                                value={parseFloat(premiumPerItem).toFixed(0)}
                                                                onChange={this.onPremiumPerItemChange}
                                                                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                                //onBlur={this.handleSave}
                                                            />
                                                        </Col>
                                                        <Col md={3}>
                                                            <InputNumber disabled
                                                                min={0}
                                                                max={7500}
                                                                step={1}
                                                                status={agentAvgPerItem < premiumPerItem ? 'error' : 'success'}
                                                                style={{  marginLeft: 5, width: '95%', color: '#fff',  backgroundColor: agentAvgPerItem < premiumPerItem ? 'red' : 'green' }}
                                                                value={new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(agentAvgPerItem.toFixed(2))}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={5}>
                                                            <label style={{fontSize: 18}}>Premium Per Policy</label>
                                                        </Col>
                                                        {/* <Col md={10}>
                                                            <Slider disabled
                                                                min={100}
                                                                max={7500}
                                                                step={1}
                                                                onChange={this.onPremiumPerPolicyChange}
                                                                value={premiumPerPolicy}
                                                                //onBlur={this.handleSave}
                                                            />
                                                        </Col> */}
                                                        <Col md={3}>
                                                            <InputNumber disabled
                                                                min={100}
                                                                max={7500}
                                                                step={1}
                                                                style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000' }}
                                                                value={new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(premiumPerPolicy)}//{premiumPerPolicy}
                                                                //formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                //parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                                onChange={this.onPremiumPerPolicyChange}
                                                                //onBlur={this.handleSave}
                                                            />
                                                        </Col>
                                                        <Col md={3}>
                                                            <InputNumber disabled
                                                                min={0}
                                                                max={500000}
                                                                step={1}
                                                                style={{  marginLeft: 5, width: '95%', color: '#fff',  backgroundColor: agentAvgPerPolicy < premiumPerPolicy ? 'red' : 'green' }}
                                                                value={new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(agentAvgPerPolicy)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={5}>
                                                            <label style={{fontSize: 18}}>Premium Per Household</label>
                                                        </Col>
                                                        {/* <Col md={10}>
                                                            <Slider disabled
                                                                min={100}
                                                                max={7500}
                                                                step={1}
                                                                onChange={this.onPremiumPerHouseholdChange}
                                                                value={premiumPerHousehold}
                                                                //onBlur={this.handleSave}
                                                            />
                                                        </Col> */}
                                                        <Col md={3}>
                                                            <InputNumber disabled
                                                                min={100}
                                                                max={7500}
                                                                step={1}
                                                                style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000' }}
                                                                value={new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(premiumPerHousehold)}//{premiumPerHousehold}
                                                                //formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                //parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                                onChange={this.onPremiumPerHouseholdChange}
                                                                //onBlur={this.handleSave}
                                                            />
                                                        </Col>
                                                        <Col md={3}>
                                                            <InputNumber disabled
                                                                min={0}
                                                                max={500000}
                                                                step={1}
                                                                style={{  marginLeft: 5, width: '95%', color: '#fff',  backgroundColor: agentAvgPerHousehold < premiumPerHousehold ? 'red' : 'green' }}
                                                                value={new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(agentAvgPerHousehold)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Card>
                                            <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc', marginTop: '10px'}}>
                                                <Row>
                                                    <Col md={5}>
                                                    </Col>
                                                    {/* <Col md={10}>
                                                    </Col> */}
                                                    <Col md={3} style={{textAlign: 'center'}}>
                                                        GOAL
                                                    </Col>
                                                    <Col md={3} style={{textAlign: 'center'}}>
                                                        ACTUAL
                                                    </Col>
                                                </Row>
                                                <Row style={{paddingTop: 10}}>
                                                    <Col md={5}>
                                                        <label style={{fontSize: 18}}>Items Per Policy</label>
                                                    </Col>
                                                    {/* <Col md={10}>
                                                        <Slider
                                                            min={0}
                                                            max={10}
                                                            step={.01}
                                                            onChange={this.onPerItemPolicyChange}
                                                            value={itemsPerPolicy}
                                                            //onBlur={this.handleSave}
                                                        />
                                                    </Col> */}
                                                    <Col md={3}>
                                                        <InputNumber
                                                            min={0}
                                                            max={10}
                                                            step={.01}
                                                            style={{  marginLeft: 5, width: '95%' }}
                                                            value={parseFloat(itemsPerPolicy).toFixed(2)}
                                                            
                                                            onChange={this.onPerItemPolicyChange}
                                                            //onBlur={this.handleSave}
                                                        />
                                                    </Col>
                                                    <Col md={3}>
                                                        <InputNumber disabled
                                                            min={0}
                                                            max={500000}
                                                            step={1}
                                                            style={{  marginLeft: 5, width: '95%', color: '#fff',  backgroundColor: agentAvgItemsPerPolicy < itemsPerPolicy ? 'red' : 'green' }}
                                                            value={agentAvgItemsPerPolicy.toFixed(2)}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={5}>
                                                        <label style={{fontSize: 18}}>Items Per Household</label>
                                                    </Col>
                                                    {/* <Col md={10}>
                                                        <Slider
                                                            min={0}
                                                            max={10}
                                                            step={.01}
                                                            onChange={this.onItemsPerHouseholdChange}
                                                            value={itemsPerHousehold}
                                                            //onBlur={this.handleSave}
                                                        />
                                                    </Col> */}
                                                    <Col md={3}>
                                                        <InputNumber
                                                            min={0}
                                                            max={10}
                                                            step={.01}
                                                            style={{  marginLeft: 5, width: '95%' }}
                                                            value={parseFloat(itemsPerHousehold).toFixed(2)}
                                                            onChange={this.onItemsPerHouseholdChange}
                                                            //onBlur={this.handleSave}
                                                        />
                                                    </Col>
                                                    <Col md={3}>
                                                        <InputNumber disabled
                                                            min={0}
                                                            max={500000}
                                                            step={1}
                                                            style={{  marginLeft: 5, width: '95%', color: '#fff',  backgroundColor: agentAvgItemsPerHousehold < itemsPerHousehold ? 'red' : 'green' }}
                                                            value={agentAvgItemsPerHousehold.toFixed(2)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Card>
                                            <Card  style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc', marginTop: '10px'}}>
                                                <Row>
                                                    <Col md={5}>
                                                    </Col>
                                                    {/* <Col md={10}>
                                                    </Col> */}
                                                    <Col md={3} style={{textAlign: 'center'}}>
                                                        GOAL
                                                    </Col>
                                                    <Col md={3} style={{textAlign: 'center'}}>
                                                        ACTUAL
                                                    </Col>
                                                </Row>
                                                <Row style={{paddingTop: 10}}>
                                                    <Col md={5}>
                                                        <label style={{fontSize: 18}}>Close Ratio Per Item</label>
                                                    </Col>
                                                    {/* <Col md={10}>
                                                        <Slider
                                                            min={0}
                                                            max={75}
                                                            step={.01}
                                                            onChange={this.onClosingRatioChange}
                                                            value={closingRatio}
                                                            //onBlur={this.handleSave}
                                                        />
                                                    </Col> */}
                                                    <Col md={3}>
                                                        <InputNumber
                                                            min={0}
                                                            max={75}
                                                            step={.01}
                                                            style={{  marginLeft: 5, width: '95%' }}
                                                            value={closingRatio}
                                                            formatter={value => `${value}%`}
                                                            parser={value => value.replace('%', '')}
                                                            onChange={this.onClosingRatioChange}
                                                            //onBlur={this.handleSave}
                                                        />
                                                    </Col>
                                                    <Col md={3}>
                                                        <InputNumber disabled
                                                            min={0}
                                                            max={500000}
                                                            step={1}
                                                            style={{  marginLeft: 5, width: '95%', color: '#fff',  backgroundColor: new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(agentClosePerItem) < closingRatio ? 'red' : 'green' }}
                                                            value={new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(agentClosePerItem)}
                                                            // formatter={value => `${value}%`}
                                                            // parser={value => value.replace('%', '')}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={5}>
                                                        <label style={{fontSize: 18}}>Close Ratio Per Policy</label>
                                                    </Col>
                                                    {/* <Col md={10}>
                                                        <Slider
                                                            min={0}
                                                            max={75}
                                                            step={.01}
                                                            onChange={this.onClosingRatioPolicyChange}
                                                            value={closingRatioPerPolicy}
                                                            //onBlur={this.handleSave}
                                                        />
                                                    </Col> */}
                                                    <Col md={3}>
                                                        <InputNumber
                                                            min={0}
                                                            max={75}
                                                            step={.01}
                                                            style={{  marginLeft: 5, width: '95%' }}
                                                            value={closingRatioPerPolicy}
                                                            formatter={value => `${value}%`}
                                                            parser={value => value.replace('%', '')}
                                                            onChange={this.onClosingRatioPolicyChange}
                                                            //onBlur={this.handleSave}
                                                        />
                                                    </Col>
                                                    <Col md={3}>
                                                        <InputNumber disabled
                                                            min={0}
                                                            max={500000}
                                                            step={1}
                                                            style={{  marginLeft: 5, width: '95%', color: '#fff',  backgroundColor: new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(agentClosePerPolicy) < closingRatioPerPolicy ? 'red' : 'green' }}
                                                            value={new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(agentClosePerPolicy)}
                                                            // formatter={value => `${value}%`}
                                                            // parser={value => value.replace('%', '')}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row style={{paddingBottom: 10}}>
                                                    <Col md={5}>
                                                        <label style={{fontSize: 18}}>Close Ratio Per Household</label>
                                                    </Col>
                                                    {/* <Col md={10}>
                                                        <Slider
                                                            min={0}
                                                            max={75}
                                                            step={.01}
                                                            onChange={this.onClosingRatioHouseholdChange}
                                                            value={closingRatioPerHousehold}
                                                            //onBlur={this.handleSave}
                                                        />
                                                    </Col> */}
                                                    <Col md={3}>
                                                        <InputNumber
                                                            min={0}
                                                            max={75}
                                                            step={.01}
                                                            style={{  marginLeft: 5, width: '95%' }}
                                                            value={closingRatioPerHousehold}
                                                            formatter={value => `${value}%`}
                                                            parser={value => value.replace('%', '')}
                                                            onChange={this.onClosingRatioHouseholdChange}
                                                            //onBlur={this.handleSave}
                                                        />
                                                    </Col>
                                                    <Col md={3}>
                                                        <InputNumber disabled
                                                            min={0}
                                                            max={500000}
                                                            step={1}
                                                            style={{  marginLeft: 5, width: '95%', color: '#fff',  backgroundColor: (agentClosePerHouseholds * 100) < closingRatioPerHousehold ? 'red' : 'green' }}
                                                            value={new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(agentClosePerHouseholds)}
                                                            // formatter={value => `${value}%`}
                                                            // parser={value => value.replace('%', '')}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Card>
                                            <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc', marginTop: '10px'}}>
                                                <Row>
                                                    <Col md={5}>
                                                    </Col>
                                                    {/* <Col md={10}>
                                                    </Col> */}
                                                    <Col md={3} style={{textAlign: 'center'}}>
                                                        GOAL
                                                    </Col>
                                                    <Col md={3} style={{textAlign: 'center'}}>
                                                        ACTUAL
                                                    </Col>
                                                </Row>
                                                <Row style={{paddingTop: 10}}>
                                                    <Col md={5}>
                                                        <label style={{fontSize: 18}}>Quoted Bundle Ratio</label>
                                                    </Col>
                                                    <Col md={3}>
                                                        <InputNumber
                                                            min={0}
                                                            max={100}
                                                            step={.01}
                                                            style={{  marginLeft: 5, width: '95%' }}
                                                            value={quotedBundleRatio}
                                                            formatter={value => `${value}%`}
                                                            parser={value => value.replace('%', '')}
                                                            onChange={this.onQuotedBundleRatioChange}
                                                        />
                                                    </Col>
                                                    <Col md={3}>
                                                        <InputNumber disabled
                                                            min={0}
                                                            max={100}
                                                            step={.01}
                                                            style={{  marginLeft: 5, width: '95%', color: '#fff',  backgroundColor: quotedBundleRatio > new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(quotedBundles) ? 'red' : 'green' }}
                                                            value={new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(quotedBundles)}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={5}>
                                                        <label style={{fontSize: 18}}>Written Bundle Ratio</label>
                                                    </Col>
                                                    <Col md={3}>
                                                        <InputNumber
                                                            min={0}
                                                            max={100}
                                                            step={.01}
                                                            style={{  marginLeft: 5, width: '95%' }}
                                                            value={writtenBundleRatio}
                                                            formatter={value => `${value}%`}
                                                            parser={value => value.replace('%', '')}
                                                            onChange={this.onWrittenBundleRatioChange}
                                                        />
                                                    </Col>
                                                    <Col md={3}>
                                                        <InputNumber disabled
                                                            min={0}
                                                            max={100}
                                                            step={.01}
                                                            style={{  marginLeft: 5, width: '95%', color: '#fff',  backgroundColor: writtenBundleRatio > new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(writtenBundles) ? 'red' : 'green' }}
                                                            value={new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(writtenBundles)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Card>
                                            <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc', marginTop: '10px'}}>
                                                <Row>
                                                    <Col md={5}>
                                                    </Col>
                                                    {/* <Col md={10}>
                                                    </Col> */}
                                                    <Col md={3} style={{textAlign: 'center'}}>
                                                        GOAL
                                                    </Col>
                                                    <Col md={3} style={{textAlign: 'center'}}>
                                                        ACTUAL
                                                    </Col>
                                                    <Col md={3} style={{textAlign: 'center'}}>
                                                        TRENDING
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={5}>
                                                        <label style={{fontSize: 18}}>Quoted Items</label>
                                                    </Col>
                                                    {/* <Col md={10}>
                                                        <Slider disabled
                                                            min={0}
                                                            max={10000}
                                                            step={1}
                                                            value={Math.round(quotedItems)}
                                                        />
                                                    </Col> */}
                                                    <Col md={3}>
                                                        <InputNumber disabled
                                                            min={0}
                                                            max={10000}
                                                            step={1}
                                                            style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000' }}
                                                            value={Math.round(quotedItems)}
                                                        />
                                                    </Col>
                                                    <Col md={3}>
                                                        <InputNumber disabled
                                                            min={0}
                                                            max={500000}
                                                            step={1}
                                                            style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000' }}
                                                            value={agentMonthly.quotedItems}
                                                        />
                                                    </Col>
                                                    <Col md={3}>
                                                        <InputNumber disabled
                                                            min={0}
                                                            max={500000}
                                                            step={1}
                                                            style={{ marginLeft: 5, width: '95%', color: '#fff',  backgroundColor: quotedItemsTrending < quotedItems ? 'red' : 'green' }}
                                                            value={quotedItemsTrending.toFixed(2)}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={5}>
                                                        <label style={{fontSize: 18}}>Quoted Policies</label>
                                                    </Col>
                                                    {/* <Col md={10}>
                                                        <Slider disabled
                                                            min={0}
                                                            max={10000}
                                                            step={1}
                                                            value={Math.round(quotedPolicies)}
                                                        />
                                                    </Col> */}
                                                    <Col md={3}>
                                                        <InputNumber disabled
                                                            min={0}
                                                            max={10000}
                                                            step={1}
                                                            style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000' }}
                                                            value={Math.round(quotedPolicies)}
                                                        />
                                                    </Col>
                                                    <Col md={3}>
                                                        <InputNumber disabled
                                                            min={0}
                                                            max={500000}
                                                            step={1}
                                                            style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000' }}
                                                            value={agentMonthly.quotedPolicies}
                                                        />
                                                    </Col>
                                                    <Col md={3}>
                                                        <InputNumber disabled
                                                            min={0}
                                                            max={500000}
                                                            step={1}
                                                            style={{  marginLeft: 5, width: '95%', color: '#fff',  backgroundColor: quotedPoliciesTrending < quotedPolicies ? 'red' : 'green'}}
                                                            value={quotedPoliciesTrending.toFixed(2)}
                                                        />
                                                    </Col>
                                                </Row>
                                                
                                                <Row>
                                                    <Col md={5}>
                                                        <label style={{fontSize: 18}}>Quoted Households</label>
                                                    </Col>
                                                    {/* <Col md={10}>
                                                        <Slider disabled
                                                            min={0}
                                                            max={10000}
                                                            step={1}
                                                            value={Math.round(quotedHousehold)}
                                                        />
                                                    </Col> */}
                                                    <Col md={3}>
                                                        <InputNumber disabled
                                                            min={0}
                                                            max={10000}
                                                            step={1}
                                                            style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000' }}
                                                            value={Math.round(quotedHousehold)}
                                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                        />
                                                    </Col>
                                                    <Col md={3}>
                                                        <InputNumber disabled
                                                            min={0}
                                                            max={500000}
                                                            step={1}
                                                            style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000' }}
                                                            value={agentMonthly.count}
                                                        />
                                                    </Col>
                                                    <Col md={3}>
                                                        <InputNumber disabled
                                                            min={0}
                                                            max={500000}
                                                            step={1}
                                                            style={{  marginLeft: 5, width: '95%', color: '#fff',  backgroundColor: quotedHouseholdsTrending < quotedHousehold ? 'red' : 'green' }}
                                                            value={quotedHouseholdsTrending.toFixed(2)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Card>
                                            <Card  style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc', marginTop: '10px'}}>
                                                <Row>
                                                    <Col md={5}>
                                                    </Col>
                                                    {/* <Col md={10}>
                                                    </Col> */}
                                                    <Col md={3} style={{textAlign: 'center'}}>
                                                        GOAL
                                                    </Col>
                                                    <Col md={3} style={{textAlign: 'center'}}>
                                                        ACTUAL
                                                    </Col>
                                                    <Col md={3} style={{textAlign: 'center'}}>
                                                        TRENDING
                                                    </Col>
                                                </Row>
                                                <Row style={{paddingTop: 10}}>
                                                    <Col md={5}>
                                                        <label style={{fontSize: 18}}>Written Items</label>
                                                    </Col>
                                                    {/* <Col md={10}>
                                                        <Slider disabled
                                                            min={0}
                                                            max={10000}
                                                            step={1}
                                                            value={Math.round(writtenItems)}
                                                        />
                                                    </Col> */}
                                                    <Col md={3}>
                                                        <InputNumber disabled
                                                            min={0}
                                                            max={10000}
                                                            step={1}
                                                            style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000'}}
                                                            value={Math.round(writtenItems)}
                                                        />
                                                    </Col>
                                                    <Col md={3}>
                                                        <InputNumber disabled
                                                            min={0}
                                                            max={500000}
                                                            step={1}
                                                            style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000' }}
                                                            value={agentMonthly.writtenItems}
                                                        />
                                                    </Col>
                                                    <Col md={3}>
                                                        <InputNumber disabled
                                                            min={0}
                                                            max={500000}
                                                            step={1}
                                                            style={{  marginLeft: 5, width: '95%', color: '#fff',  backgroundColor: writtenItemsTrending < writtenItems ? 'red' : 'green' }}
                                                            value={writtenItemsTrending.toFixed(2)}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={5}>
                                                        <label style={{fontSize: 18}}>Written Policies</label>
                                                    </Col>
                                                    {/* <Col md={10}>
                                                        <Slider disabled
                                                            min={0}
                                                            max={10000}
                                                            step={1}
                                                            value={Math.round(writtenPolicies)}
                                                        />
                                                    </Col> */}
                                                    <Col md={3}>
                                                        <InputNumber disabled
                                                            min={0}
                                                            max={10000}
                                                            step={1}
                                                            style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000'}}
                                                            value={Math.round(writtenPolicies)}
                                                        />
                                                    </Col>
                                                    <Col md={3}>
                                                        <InputNumber disabled
                                                            min={0}
                                                            max={500000}
                                                            step={1}
                                                            style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000' }}
                                                            value={agentMonthly.writtenPolicies}
                                                        />
                                                    </Col>
                                                    <Col md={3}>
                                                        <InputNumber disabled
                                                            min={0}
                                                            max={500000}
                                                            step={1}
                                                            style={{  marginLeft: 5, width: '95%', color: '#fff',  backgroundColor: writtenPoliciesTrending < writtenPolicies ? 'red' : 'green'}}
                                                            value={writtenPoliciesTrending.toFixed(2)}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row style={{paddingBottom: 10}}>
                                                    <Col md={5}>
                                                        <label style={{fontSize: 18}}>Written Households</label>
                                                    </Col>
                                                    {/* <Col md={10}>
                                                        <Slider disabled
                                                            min={0}
                                                            max={10000}
                                                            step={1}
                                                            value={Math.round(writtenHouseholds)}
                                                        />
                                                    </Col> */}
                                                    <Col md={3}>
                                                        <InputNumber disabled
                                                            min={0}
                                                            max={10000}
                                                            step={1}
                                                            style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000' }}
                                                            value={Math.round(writtenHouseholds)}
                                                        />
                                                    </Col>
                                                    <Col md={3}>
                                                        <InputNumber disabled
                                                            min={0}
                                                            max={500000}
                                                            step={1}
                                                            style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000' }}
                                                            value={agentMonthly.writtenHouseholds}
                                                        />
                                                    </Col>
                                                    <Col md={3}>
                                                        <InputNumber disabled
                                                            min={0}
                                                            max={500000}
                                                            step={1}
                                                            style={{  marginLeft: 5, width: '95%', color: '#fff',  backgroundColor: writtenHouseholdsTrending < writtenHouseholds ? 'red' : 'green' }}
                                                            value={writtenHouseholdsTrending.toFixed(2)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Card>
                                            </Card>
                                            <Row>
                                                <Col xs={24} sm={24} lg={24}>
                                                <Card title="DAILY" headStyle={{ backgroundColor: '#FCD12A', color: '#fff', fontSize: '32px', textAlign: 'center' }} style={{borderColor: '#FCD12A', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc', marginTop: '10px'}}>
                                                <Row>
                                                    <Col md={5}>
                                                    </Col>
                                                    {/* <Col md={10}>
                                                    </Col> */}
                                                    <Col md={3} style={{textAlign: 'center'}}>
                                                        GOAL
                                                    </Col>
                                                    <Col md={3} style={{textAlign: 'center'}}>
                                                        ACTUAL
                                                    </Col>
                                                </Row>
                                                <Card  style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc', marginTop: '10px'}}>
                                                    <Row style={{paddingBottom: 10}}> 
                                                        <Col md={5}>
                                                            <label style={{fontSize: 18}}>Premium</label>
                                                        </Col>
                                                        {/* <Col md={10}>
                                                            <Slider disabled
                                                                min={100}
                                                                max={10000}
                                                                step={5} 
                                                                value={businessDays == undefined ? 0 : premium / businessDays}
                                                            />
                                                        </Col> */}
                                                        <Col md={3}>
                                                            <InputNumber disabled
                                                                min={10000}
                                                                max={50000}
                                                                step={5000}
                                                                style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000' }}
                                                                value={businessDays == undefined ? 0 : new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(premium / businessDays)}
                                                                // formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                // parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                            />
                                                        </Col>
                                                        <Col md={3}>
                                                            <InputNumber disabled
                                                                min={0}
                                                                max={50000}
                                                                step={1}
                                                                style={{  fontWeight: 'bold', marginLeft: 5, width: '95%', backgroundColor: (premium / businessDays) > (agentMonthly.writtenPremium / agentMonthly.businessDaysSoFar) ? 'red' : 'green', color: '#fff' }}
                                                                // formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                // parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                                value={agentMonthly.businessDaysSoFar == undefined ? 0 : new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(agentMonthly.writtenPremium / agentMonthly.businessDaysSoFar)}
                                                            />
                                                        </Col>
                                                        {/* <Col md={3}>
                                                            <InputNumber disabled
                                                                min={0}
                                                                max={500000}
                                                                step={1}
                                                                style={{ marginLeft: 5, width: '95%', color: '#fff',  backgroundColor: (agentMonthly.trending / businessDays) < (premium / businessDays) ? 'red' : 'green'  }}
                                                                // formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                // parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                                value={businessDays == undefined ? 0 : new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(agentMonthly.trending / businessDays)}
                                                            />
                                                        </Col> */}
                                                    </Row>
                                                    </Card>
                                                    <Card  style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc', marginTop: '10px'}}>
                                                    
                                                    <Row>
                                                        <Col md={5}>
                                                            <label style={{fontSize: 18}}>Quoted Items</label>
                                                        </Col>
                                                        {/* <Col md={10}>
                                                            <Slider disabled
                                                                min={0}
                                                                max={10000}
                                                                step={1}
                                                                value={(businessDays == undefined ? 0 : quotedItems / businessDays).toFixed(2)}
                                                            />
                                                        </Col> */}
                                                        <Col md={3}>
                                                            <InputNumber disabled
                                                                min={0}
                                                                max={10000}
                                                                step={1}
                                                                style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000' }}
                                                                value={businessDays == undefined ? 0 : quotedItems != null ? (quotedItems / businessDays).toFixed(2) : 0 }
                                                            />
                                                        </Col>
                                                        <Col md={3}>
                                                            <InputNumber disabled
                                                                min={0}
                                                                max={500000}
                                                                step={1}
                                                                style={{  marginLeft: 5, width: '95%', backgroundColor: (quotedItems / businessDays) > (agentMonthly.quotedItems / agentMonthly.businessDaysSoFar) ? 'red' : 'green', color: '#fff' }}
                                                                value={agentMonthly.businessDaysSoFar == undefined ? 0 : agentMonthly.quotedItems > 0 ? (agentMonthly.quotedItems / agentMonthly.businessDaysSoFar).toFixed(2) : 0 }
                                                            />
                                                        </Col>
                                                        {/* <Col md={3}>
                                                            <InputNumber disabled
                                                                min={0}
                                                                max={500000}
                                                                step={1}
                                                                style={{  marginLeft: 5, width: '95%', color: '#fff',  backgroundColor: (quotedItemsTrending / businessDays) < (quotedItems / businessDays) ? 'red' : 'green' }}
                                                                value={businessDays == undefined ? 0 : (quotedItemsTrending / businessDays).toFixed(2)}
                                                            />
                                                        </Col> */}
                                                    </Row>
                                                    <Row>
                                                        <Col md={5}>
                                                            <label style={{fontSize: 18}}>Quoted Policies</label>
                                                        </Col>
                                                        {/* <Col md={10}>
                                                            <Slider disabled
                                                                min={0}
                                                                max={10000}
                                                                step={1}
                                                                value={businessDays == undefined ? 0 : (quotedPolicies / businessDays).toFixed(2)}
                                                            />
                                                        </Col> */}
                                                        <Col md={3}>
                                                            <InputNumber disabled
                                                                min={0}
                                                                max={10000}
                                                                step={1}
                                                                style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000' }}
                                                                value={businessDays == undefined ? 0 : (quotedPolicies / businessDays).toFixed(2)}
                                                            />
                                                        </Col>
                                                        <Col md={3}>
                                                            <InputNumber disabled
                                                                min={0}
                                                                max={500000}
                                                                step={1}
                                                                style={{  marginLeft: 5, width: '95%', backgroundColor: (quotedPolicies / businessDays) > (agentMonthly.quotedPolicies / agentMonthly.businessDaysSoFar) ? 'red' : 'green', color: '#fff' }}
                                                                value={agentMonthly.businessDaysSoFar == undefined ? 0 : (agentMonthly.quotedPolicies / agentMonthly.businessDaysSoFar).toFixed(2)}
                                                            />
                                                        </Col>
                                                        {/* <Col md={3}>
                                                            <InputNumber disabled
                                                                min={0}
                                                                max={500000}
                                                                step={1}
                                                                style={{  marginLeft: 5, width: '95%', color: '#fff',  backgroundColor: (quotedPoliciesTrending / businessDays) < (quotedPolicies / businessDays) ? 'red' : 'green' }}
                                                                value={businessDays == undefined ? 0 : (quotedPoliciesTrending / businessDays).toFixed(2)}
                                                            />
                                                        </Col> */}
                                                    </Row>
                                                    <Row>
                                                        <Col md={5}>
                                                            <label style={{fontSize: 18}}>Quoted Households</label>
                                                        </Col>
                                                        {/* <Col md={10}>
                                                            <Slider disabled
                                                                min={0}
                                                                max={10000}
                                                                step={1}
                                                                value={businessDays == undefined ? 0 : (quotedHousehold / businessDays).toFixed(2)}
                                                            />
                                                        </Col> */}
                                                        <Col md={3}>
                                                            <InputNumber disabled
                                                                min={0}
                                                                max={10000}
                                                                step={1}
                                                                style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000' }}
                                                                value={businessDays == undefined ? 0 : (quotedHousehold / businessDays).toFixed(2)}
                                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                            />
                                                        </Col>
                                                        <Col md={3}>
                                                            <InputNumber disabled
                                                                min={0}
                                                                max={500000}
                                                                step={1}
                                                                style={{  marginLeft: 5, width: '95%', backgroundColor: (quotedHousehold / businessDays) > (agentMonthly.count / agentMonthly.businessDaysSoFar) ? 'red' : 'green', color: '#fff' }}
                                                                value={agentMonthly.businessDaysSoFar == undefined ? 0 : (agentMonthly.count / agentMonthly.businessDaysSoFar).toFixed(2)}
                                                            />
                                                        </Col>
                                                        {/* <Col md={3}>
                                                            <InputNumber disabled
                                                                min={0}
                                                                max={500000}
                                                                step={1}
                                                                style={{  marginLeft: 5, width: '95%', color: '#fff',  backgroundColor: (quotedHouseholdsTrending / businessDays) < (quotedHousehold / businessDays) ? 'red' : 'green' }}
                                                                value={businessDays == undefined ? 0 : (quotedHouseholdsTrending / businessDays).toFixed(2)}
                                                            />
                                                        </Col> */}
                                                    </Row>
                                                </Card>
                                                <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc', marginTop: '10px'}}>
                                                    <Row>
                                                        <Col md={5}>
                                                            <label style={{fontSize: 18}}>Written Items</label>
                                                        </Col>
                                                        {/* <Col md={10}>
                                                            <Slider disabled
                                                                min={0}
                                                                max={10000}
                                                                step={1}
                                                                value={businessDays == undefined ? 0 : (writtenItems / businessDays).toFixed(2)}
                                                            />
                                                        </Col> */}
                                                        <Col md={3}>
                                                            <InputNumber disabled
                                                                min={0}
                                                                max={10000}
                                                                step={1}
                                                                style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000'}}
                                                                value={businessDays == undefined ? 0 : (writtenItems / businessDays).toFixed(2)}
                                                            />
                                                        </Col>
                                                        <Col md={3}>
                                                            <InputNumber disabled
                                                                min={0}
                                                                max={500000}
                                                                step={1}
                                                                style={{  marginLeft: 5, width: '95%', backgroundColor: (writtenItems / businessDays) > (agentMonthly.writtenItems / agentMonthly.businessDaysSoFar) ? 'red' : 'green', color: '#fff' }}
                                                                value={agentMonthly.businessDaysSoFar == undefined ? 0 : (agentMonthly.writtenItems / agentMonthly.businessDaysSoFar).toFixed(2)}
                                                            />
                                                        </Col>
                                                        {/* <Col md={3}>
                                                            <InputNumber disabled
                                                                min={0}
                                                                max={500000}
                                                                step={1}
                                                                style={{  marginLeft: 5, width: '95%', color: '#fff',  backgroundColor: (writtenItemsTrending / businessDays) < (writtenItems / businessDays) ? 'red' : 'green' }}
                                                                value={businessDays == undefined ? 0 : (writtenItemsTrending / businessDays).toFixed(2)}
                                                            />
                                                        </Col> */}
                                                    </Row>
                                                    <Row>
                                                        <Col md={5}>
                                                            <label style={{fontSize: 18}}>Written Policies</label>
                                                        </Col>
                                                        {/* <Col md={10}>
                                                            <Slider disabled
                                                                min={0}
                                                                max={10000}
                                                                step={1}
                                                                value={businessDays == undefined ? 0 : (writtenPolicies / businessDays).toFixed(2)}
                                                            />
                                                        </Col> */}
                                                        <Col md={3}>
                                                            <InputNumber disabled
                                                                min={0}
                                                                max={10000}
                                                                step={1}
                                                                style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000'}}
                                                                value={businessDays == undefined ? 0 : (writtenPolicies/ businessDays).toFixed(2)}
                                                            />
                                                        </Col>
                                                        <Col md={3}>
                                                            <InputNumber disabled
                                                                min={0}
                                                                max={500000}
                                                                step={1}
                                                                style={{  marginLeft: 5, width: '95%', backgroundColor: (writtenPolicies/ businessDays) > (agentMonthly.writtenPolicies / agentMonthly.businessDaysSoFar) ? 'red' : 'green', color: '#fff' }}
                                                                value={agentMonthly.businessDaysSoFar == undefined ? 0 : (agentMonthly.writtenPolicies / agentMonthly.businessDaysSoFar).toFixed(2)}
                                                            />
                                                        </Col>
                                                        {/* <Col md={3}>
                                                            <InputNumber disabled
                                                                min={0}
                                                                max={500000}
                                                                step={1}
                                                                style={{  marginLeft: 5, width: '95%', color: '#fff',  backgroundColor: (writtenPoliciesTrending / businessDays) < (writtenPolicies / businessDays) ? 'red' : 'green' }}
                                                                value={businessDays == undefined ? 0 : (writtenPoliciesTrending / businessDays).toFixed(2)}
                                                            />
                                                        </Col> */}
                                                    </Row>
                                                    <Row>
                                                        <Col md={5}>
                                                            <label style={{fontSize: 18}}>Written Households</label>
                                                        </Col>
                                                        {/* <Col md={10}>
                                                            <Slider disabled
                                                                min={0}
                                                                max={10000}
                                                                step={1}
                                                                value={businessDays == undefined ? 0 : (writtenHouseholds / businessDays).toFixed(2)}
                                                            />
                                                        </Col> */}
                                                        <Col md={3}>
                                                            <InputNumber disabled
                                                                min={0}
                                                                max={10000}
                                                                step={1}
                                                                style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000' }}
                                                                value={businessDays == undefined ? 0 : (writtenHouseholds / businessDays).toFixed(2)}
                                                            />
                                                        </Col>
                                                        <Col md={3}>
                                                            <InputNumber disabled
                                                                min={0}
                                                                max={500000}
                                                                step={1}
                                                                style={{  marginLeft: 5, width: '95%', backgroundColor: (writtenHouseholds / businessDays) > (agentMonthly.writtenHouseholds / agentMonthly.businessDaysSoFar) ? 'red' : 'green', color: '#fff' }}
                                                                value={agentMonthly.businessDaysSoFar == undefined ? 0 : (agentMonthly.writtenHouseholds / agentMonthly.businessDaysSoFar).toFixed(2)}
                                                            />
                                                        </Col>
                                                        {/* <Col md={3}>
                                                            <InputNumber disabled
                                                                min={0}
                                                                max={500000}
                                                                step={1}
                                                                style={{  marginLeft: 5, width: '95%', color: '#fff',  backgroundColor: (writtenHouseholdsTrending / businessDays) < (writtenHouseholds / businessDays) ? 'red' : 'green' }}
                                                                value={businessDays == undefined ? 0 : (writtenHouseholdsTrending / businessDays).toFixed(2)}
                                                            />
                                                        </Col> */}
                                                    </Row>
                                                    </Card>
                                                    <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc', marginTop: '10px'}}>
                                                        <Row>
                                                            <Col md={5}>
                                                                <label style={{fontSize: 18}}>Quoted Bundle Ratio</label>
                                                            </Col>
                                                            <Col md={3}>
                                                                <InputNumber disabled
                                                                    min={0}
                                                                    max={10000}
                                                                    step={1}
                                                                    formatter={value => `${value}%`}
                                                                    parser={value => value.replace('%', '')}
                                                                    style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000'}}
                                                                    value={quotedBundleRatio}
                                                                />
                                                            </Col>
                                                            <Col md={3}>
                                                                <InputNumber disabled
                                                                    min={0}
                                                                    max={500000}
                                                                    step={1}
                                                                    style={{  marginLeft: 5, width: '95%', backgroundColor: this.state.quotedBundleRatio > new Intl.NumberFormat("en-US", {
                                                                        style: "percent",
                                                                        maximumFractionDigits: 2
                                                                        }).format(dailyQuotedBundles / (agentMonthly.count / agentMonthly.businessDaysSoFar)) ? 'red' : 'green', color: '#fff' }}
                                                                    value={businessDays == undefined ? 0 : new Intl.NumberFormat("en-US", {
                                                                        style: "percent",
                                                                        maximumFractionDigits: 2
                                                                        }).format(dailyQuotedBundles > 0 ? dailyQuotedBundles / (agentMonthly.count / agentMonthly.businessDaysSoFar) : 0 )}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={5}>
                                                                <label style={{fontSize: 18}}>Written Bundle Ratio</label>
                                                            </Col>
                                                            <Col md={3}>
                                                                <InputNumber disabled
                                                                    min={0}
                                                                    max={10000}
                                                                    step={1}
                                                                    formatter={value => `${value}%`}
                                                                    parser={value => value.replace('%', '')}
                                                                    style={{  marginLeft: 5, width: '95%', backgroundColor: '#e6f7ff', color: '#000'}}
                                                                    value={writtenBundleRatio}
                                                                />
                                                            </Col>
                                                            <Col md={3}>
                                                                <InputNumber disabled
                                                                    min={0}
                                                                    max={500000}
                                                                    step={1}
                                                                    style={{  marginLeft: 5, width: '95%', backgroundColor: this.state.writtenBundleRatio > new Intl.NumberFormat("en-US", {
                                                                        style: "percent",
                                                                        maximumFractionDigits: 2
                                                                        }).format(dailyWrittenBundles / (agentMonthly.writtenHouseholds / agentMonthly.businessDaysSoFar)) ? 'red' : 'green', color: '#fff' }}
                                                                    value={businessDays == undefined ? 0 : new Intl.NumberFormat("en-US", {
                                                                        style: "percent",
                                                                        maximumFractionDigits: 2
                                                                        }).format(dailyWrittenBundles > 0 ? dailyWrittenBundles / (agentMonthly.writtenHouseholds / agentMonthly.businessDaysSoFar) : 0)}
                                                                />
                                                            </Col>
                                                            <Col md={13} style={{display: 'none'}}>
                                                                <Line height={100} {...config} />
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <Row style={{paddingBottom: 20}}>
                                                <Col span={24}>
                                                    {!this.props.viewOnly ? <Button disabled={this.state.agent == 'All' ? true : false} style={{ position: 'absolute', right: 0 }} type="primary" shape="round" size='large' onClick={() => this.handleSave()}>SAVE / UPDATE</Button> : <span></span>}
                                                </Col>
                                            </Row>
                                            
                                        </Col>
                                        
    }
                                    </Row>                           
                                </Col>                   
                            </Row>
    
                        </Card>
                    </Col>
                </Row>
                
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(AgentGoals);