import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Card, Input, Row, Col, List, Typography, Select, Button, Radio, Checkbox, message, Modal } from 'antd';
import type { UploadProps } from 'antd';
import { DeleteOutlined, SaveOutlined, PlusCircleOutlined, PlayCircleOutlined, UploadOutlined, FileTextOutlined, EditOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import "video-react/dist/video-react.css";
import { Player, BigPlayButton, ControlBar } from 'video-react';
import EducationMaterials from './EducationMaterials'
const {Title, Text} = Typography
const {Option} = Select

class AgencyEduMaterials extends Component {
    constructor(props) {
      super(props);
        this.state = {
            educationSection: '',
            selectedEducationId: 0,
            showPlayerModal: false,
            videoSrc: ''
        }
    }

    componentDidMount() {
        const agencyId = localStorage.getItem('agencyId');
        this.props.getEducationSections(agencyId);
        this.props.getEducationMaterials(agencyId);
    }

    render() {
        return (
            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={18} style={{paddingLeft: 16}}>
                            {
                            this.props.educationSections &&
                            this.props.educationSections.map((e, index) =>
                                <Row key={e.id} style={{marginTop: 10}}>
                                    <Col span={24}>
                                        <Card title={e.name} style={{borderColor: '#1890ff', borderRadius: "20px", boxShadow: '10px 5px 5px #ccc', marginBottom: 10 }}>
                                            {
                                            this.props.educationMaterials.filter(m => m.educationSectionId == e.id).map((m, index) => (
                                                <div style={{marginTop: 10, padding: 10}}>
                                                    {m.fileName.includes(".mp4") || m.fileName.includes(".mov") ?
                                                    <Button style={{fontSize: 18}} type="link" name={m.fileName} onClick={e => this.setState({showPlayerModal: true, videoSrc: m.url})}><PlayCircleOutlined />  {m.fileName}</Button>
                                                    :
                                                    <Button style={{fontSize: 18}} type="link" target="_blank" href={m.url}><FileTextOutlined /> {m.fileName}</Button>
                                                    }
                                                </div>
                                            ))}
                                        </Card>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                </Col>
                <Modal 
                    width="75%"
                    title="The Humble 100"
                    visible={this.state.showPlayerModal}
                    onCancel={() => this.setState({showPlayerModal: false})}
                    afterClose={() => this.player.pause()}
                    footer={[
                        <Button key="closeAdded" type="primary" onClick={() => this.setState({showPlayerModal: false})}>
                            Close
                        </Button>,
                    ]}>
                    <Player ref={(player) => { this.player = player }} src={this.state.videoSrc} autoPlay>
                        <ControlBar autoHide={false}  />
                    </Player>
                </Modal>
            </Row>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(AgencyEduMaterials);