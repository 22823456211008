import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { RingProgress, Line, Column, DualAxes } from '@ant-design/plots';
import { actionCreators } from '../store/humbleActions';
import { LineChartOutlined, FunnelPlotOutlined, BarChartOutlined } from '@ant-design/icons';
import { List, Typography, Row, Col, Avatar, DatePicker, Cascader, Space, Button, Select, Radio, Spin, Tooltip } from 'antd';
import moment from 'moment';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { SHOW_CHILD } = Cascader;
const { Option } = Select;

class Graphs extends Component {
    constructor(props) {
      super(props);
        this.state = {
            dateTo: moment().endOf('month').format("MM/DD/YYYY"),
            dateFrom: moment().startOf('month').format("MM/DD/YYYY"),
            leadSourceList: '',
            agent: null,
            typeReport: localStorage.getItem('dailyTypeReport') != null ? localStorage.getItem('dailyTypeReport') : "households",
            typeGraph: localStorage.getItem('dailyTypeGraph') != null ? localStorage.getItem('dailyTypeGraph') : "line",
            selectedValues: []
        }
        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.handleSelectedAgentChange = this.handleSelectedAgentChange.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.handleGraphChange = this.handleGraphChange.bind(this);
    }
    
    componentDidMount(){
        const userId = localStorage.getItem('userId');
        const agencyId = localStorage.getItem('agencyId');
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo;
        this.props.getAgent(agencyId);
        const typeReport = this.state.typeReport;
        this.props.getQuoteLogDailyItems(agencyId, dateFrom, dateTo, '', '', typeReport);
        this.props.getTypeVendorTypeLeadSources(agencyId);
    }
    handleDateRangeChange(dates, dateStrings) {
        const dateFrom = dateStrings[0];
        const dateTo = dateStrings[1];
        const agencyId = localStorage.getItem('agencyId');
        const agent = this.state.agent == null ? '' : this.state.agent;
        const leadSourceList = this.state.leadSourceList;
        const typeReport = this.state.typeReport;
        if(dateFrom != ''){
            this.props.getQuoteLogDailyItems(agencyId, dateFrom, dateTo, agent, leadSourceList, typeReport);
        }
        this.setState({
            dateFrom: dateFrom,
            dateTo: dateTo
        });
    }
    handleFilter() {
        // const dateFrom = this.state.dateFrom;
        // const dateTo = this.state.dateTo;
        // const agencyId = localStorage.getItem('agencyId');
        // const agent = this.state.agent;
        // const leadSourceList = this.state.leadSourceList;
        // const typeReport = this.state.typeReport;
        // if(dateFrom != ''){
        //     this.props.getQuoteLogDailyItems(agencyId, dateFrom, dateTo, agent, leadSourceList, typeReport);
        // }
        // this.setState({
        //     dateFrom: dateFrom,
        //     dateTo: dateTo
        // });
        const userId = localStorage.getItem('userId');
        const agencyId = localStorage.getItem('agencyId');
        const dateFrom = moment().startOf('month').format("MM/DD/YYYY");
        const dateTo = moment().endOf('month').format("MM/DD/YYYY");
        const typeReport = this.state.typeReport;
        this.props.getQuoteLogDailyItems(agencyId, dateFrom, dateTo, '', '', typeReport);
        this.setState({dateFrom: dateFrom, dateTo: dateTo, agent: null, leadSourceList: '', selectedValues: []});
    }
    handleSelectedAgentChange(value){
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo;
        const agencyId = localStorage.getItem('agencyId');
        const agent = value;
        const leadSourceList = this.state.leadSourceList;
        const typeReport = this.state.typeReport;
        if(dateFrom != ''){
            this.props.getQuoteLogDailyItems(agencyId, dateFrom, dateTo, agent, leadSourceList, typeReport);
        }
        this.setState({agent: value })
    }
    handleRadioChange(e){
        localStorage.setItem('dailyTypeReport', e.target.value);
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo;
        const agencyId = localStorage.getItem('agencyId');
        const agent = this.state.agent == null ? '' : this.state.agent;
        const leadSourceList = this.state.leadSourceList;
        if(dateFrom != ''){
            this.props.getQuoteLogDailyItems(agencyId, dateFrom, dateTo, agent, leadSourceList, e.target.value);
        }
        this.setState({typeReport: e.target.value })
    }
    handleGraphChange(selectedGraph){
        this.setState({typeGraph: selectedGraph});
        localStorage.setItem('dailyTypeGraph', selectedGraph);
    }
    render() {
        const config = {
            data: this.props.dailyItems,
            isGroup: true,
            xField: 'dts',
            yField: 'items',
            seriesField: 'typeItems',
            
        };
        const onChange = (value: string[][]) => {
            var leadSourceList = '';
            
            for (var i=0; i < value.length; i++){
                if(value[i].length > 1){
                    leadSourceList += leadSourceList == '' ? value[i][1] : ',' + value[i][1];
                } else {
                    var fullList = this.props.typeVendorTypesLeadSources.filter(l => l.value == value[i][0]);
                    var children = fullList[0].children;
                    for (var x=0; x < children.length; x++){
                        leadSourceList += leadSourceList == '' ? children[x].value : ',' + children[x].value;
                    }
                }
            }
            const dateFrom = this.state.dateFrom;
            const dateTo = this.state.dateTo;
            const agencyId = localStorage.getItem('agencyId');
            const agent = this.state.agent == null ? '' : this.state.agent;
            const typeReport = this.state.typeReport;
            if(dateFrom != ''){
                this.props.getQuoteLogDailyItems(agencyId, dateFrom, dateTo, agent, leadSourceList, typeReport);
            }
            this.setState({leadSourceList: leadSourceList, selectedValues: value});
        };
        return (
            <div>
                <Row>
                    <Col span={24}>
                        <Title level={3}>Graph Reports</Title>
                    </Col>
                </Row>
                {this.props.isActivityLoading ?
                <div style={{textAlign: 'center'}}> <Spin></Spin><p><Title level={3}>Loading report.  Please do not click the refresh button...</Title></p></div>:
                <div> 
                <Row>
                    <Col span={6}>
                        <RangePicker format={'MM/DD/YYYY'} allowEmpty={true} style={{ width: '90%' }}
                            ranges={{
                                Today: [moment(), moment()],
                                'This Week': [moment().startOf('week'), moment().endOf('week')],
                                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            }}
                            defaultValue={[moment().startOf('month'), moment().endOf('month')]}
                            value={this.state.dateFrom == null ? [] : [moment(this.state.dateFrom), moment(this.state.dateTo)]}
                            onChange={this.handleDateRangeChange}
                        />
                    </Col>
                    <Col span={6}>
                        <Cascader
                            style={{ width: '90%' }}
                            options={this.props.typeVendorTypesLeadSources}
                            onChange={onChange}
                            multiple
                            maxTagCount="responsive"
                            placeholder={<strong style={{color: 'black'}}>Select Lead Source</strong>}
                            value={this.state.selectedValues}
                        />
                    </Col>
                    <Col span={6}>
                        <Select
                            virtual={false}
                            style={{ width: '90%' }}
                            dropdownStyle={{ minWidth: "20%" }}
                            placeholder={<strong style={{color: 'black'}}>Select Agent</strong>}
                            name="agents"
                            value={this.state.agent}
                            onChange={this.handleSelectedAgentChange}>
                                <Option key='' value=''></Option>
                            {
                                this.props.agent.filter(a => a.status != "5").map(a => 
                                    <Option key={a.firstName} value={a.firstName}>{a.firstName}</Option>
                                )
                            }
                        </Select>
                    </Col>
                    <Col span={2}>
                        <Button shape="round" onClick={() => this.handleFilter()}>
                            <FunnelPlotOutlined /> Clear Filter
                        </Button>

                    </Col>
                </Row>
                <Row style={{paddingTop: 15}} >
                    <Col span={12}>
                        <Radio.Group defaultValue="items" buttonStyle="solid" onChange={this.handleRadioChange} value={this.state.typeReport}>
                            <Radio.Button value="items">Items</Radio.Button>
                            <Radio.Button value="policies">Policies</Radio.Button>
                            <Radio.Button value="households">Households</Radio.Button>
                        </Radio.Group>
                    </Col>
                    <Col span={12}>
                        <Space style={{position: 'absolute', right: 2}}>
                            <Tooltip title="Line Graph">
                                <Button type="primary" shape="circle" icon={<LineChartOutlined />} onClick={() => this.handleGraphChange("line")} name="line" />
                            </Tooltip>
                            <Tooltip title="Bar Graph">
                                <Button type="primary" shape="circle" icon={<BarChartOutlined />} onClick={() => this.handleGraphChange("bar")} name="bar" />
                            </Tooltip>
                        </Space>
                    </Col>
                </Row>
                    {this.state.typeGraph == 'line'?
                        <Row style={{paddingTop: 20}}>
                            <Col span={20} offset={2}>
                                <Line {...config} />
                            </Col>
                        </Row> 
                    :
                        <Row style={{paddingTop: 20}}>
                            <Col span={20} offset={2}>
                                <Column {...config} />
                            </Col>
                        </Row>
                    }
                </div>
                }
            </div>
        );
    }
    
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Graphs);