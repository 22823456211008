import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Row, Col, List, Typography, Space, Table, InputNumber, DatePicker, Spin, Button } from 'antd';
import moment from 'moment';
const {Title} = Typography;
const { RangePicker } = DatePicker;

class Reports extends Component {
    constructor(props) {
        super(props);
          this.state = {
              date: moment().format("MM/DD/YYYY"),
              loading: false,
              dateTo: moment().format("MM/DD/YYYY"),
              dateFrom: moment().format("MM/DD/YYYY"),
              agents: []
          }
          this.handleDateChange = this.handleDateChange.bind(this);
          this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
    }
    componentDidMount() {
        this.setState({loading: true})
        const agencyId = localStorage.getItem('agencyId');
        this.props.getAgent(agencyId);
    }

    handleDateChange(date, dateString) {
        this.setState({
            date: date
        });
        const agencyId = localStorage.getItem('agencyId');
       
    }
    
    handleDateRangeChange(dates, dateStrings) {
        const dateFrom = dateStrings[0];
        const dateTo = dateStrings[1];
        const agencyId = localStorage.getItem('agencyId');
        
        this.setState({
            dateFrom: dateFrom,
            dateTo: dateTo 
        });
        console.log(dateStrings);
    }
    render() {
        // function getAgentData = (agentId) => {
        //     const agencyId = localStorage.getItem("agencyId");
        //     this.props.getQuoteAgentSummaryByAgent(agencyId, moment().startOf('month').format("MM/DD/YYYY"), moment().endOf('month').format("MM/DD/YYYY"), agentId);
        // }
        const agencyId = localStorage.getItem("agencyId");
        //this.props.agent.filter(a => a.status != "5").map(a => this.props.getQuoteAgentSummaryByAgent(agencyId, moment().startOf('month').format("MM/DD/YYYY"), moment().endOf('month').format("MM/DD/YYYY"), a.agentId));
        return (
            <Row>
                <Col span={24}>
                    <table>
                        <thead>
                            <tr>
                                <td><strong>Agent</strong></td>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.agent.filter(a => a.status != "5").map((a) => (
                                <tr>
                                    <td>{a.firstName} {this.props.getQuoteAgentSummaryByAgent(agencyId, moment().startOf('month').format("MM/DD/YYYY"), moment().endOf('month').format("MM/DD/YYYY"), a.agentId)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Col>
            </Row>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Reports);