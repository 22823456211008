import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Card, Input, Row, Col, List, Typography, Select, Button, Radio, Checkbox, Modal, Drawer, Space, InputNumber } from 'antd';
import { DeleteOutlined, SaveOutlined, PlusCircleOutlined, RightOutlined } from '@ant-design/icons';
const {Title, Text} = Typography
const {Option} = Select

class ExpandedMarketplace extends Component {
    constructor(props) {
      super(props);
        this.state = {
            open: false,
            carrierId: 0,
            carrierName: '',
            openProduct: false,
            selectedCarrier: '',
            productType: '',
            productName: '',
            productPoints: 0,
            productId: 0
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.openProductDrawer = this.openProductDrawer.bind(this);
        this.openCarrierDrawer = this.openCarrierDrawer.bind(this);
        this.handleProductTypeChange = this.handleProductTypeChange.bind(this);
        this.handleProductSave = this.handleProductSave.bind(this);
        this.openProductDrawerUpdate = this.openProductDrawerUpdate.bind(this);
        this.openCarrierDrawerUpdate = this.openCarrierDrawerUpdate.bind(this);
        this.closeProductDrawer = this.closeProductDrawer.bind(this);
    }
    componentDidMount() {
        const agencyId = localStorage.getItem('agencyId');
        this.props.getEMCarriers(agencyId);
        this.props.getEMProducts(agencyId);
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handlePointsChange = value => {
        this.setState({productPoints: value});
    }
    handleSave(){
        const agencyId = localStorage.getItem('agencyId');
        const carrierName = this.state.carrierName;
        const carrierId = this.state.carrierId;
        this.props.createEMCarrier(agencyId, carrierId, carrierName);
        this.setState({carrierName: '', carrierId: 0});
    }
    openCarrierDrawer() {
        this.setState({selectedCarrier: '', carrierName: '', open: true, carrierId: 0});
    }
    openProductDrawer(selectedCarrier) {
        this.setState({selectedCarrier: selectedCarrier, openProduct: !this.state.openProduct});
    }
    openProductDrawerUpdate(selectedCarrier, selectedProduct) {
        this.setState({selectedCarrier: selectedCarrier, openProduct: !this.state.openProduct, productName: selectedProduct.name, productType: selectedProduct.productType, productPoints: selectedProduct.points, productId: selectedProduct.id});
    }
    openCarrierDrawerUpdate(selectedCarrier) {
        this.setState({selectedCarrier: selectedCarrier, carrierName: selectedCarrier.name, carrierId: selectedCarrier.id, open: true});
    }
    handleProductTypeChange = (e) => {
        this.setState({productType: e.value});
    }
    handleProductSave(selectedCarrier){
        const agencyId = localStorage.getItem('agencyId');
        const carrierId = selectedCarrier.id;
        const productName = this.state.productName;
        const productType = this.state.productType;
        const points = this.state.productPoints;
        const productId = this.state.productId;
        this.props.createEMProduct(agencyId, carrierId, productId, productName, productType, points);
        this.setState({productName: '', productType: '', productPoints: 0, productId: 0});
    }
    closeProductDrawer(){
        this.setState({openProduct: false, productName: '', productType: '', productPoints: 0, productId: 0});
    }

    render() {
        const products = this.props.emProducts;
        return (
            <div>
            <Button size="large" shape="round" type="primary" onClick={() => this.openCarrierDrawer()} icon={<PlusCircleOutlined />}>
                New carrier
            </Button>
            <div>
                {
                    this.props.emCarriers.map(c => {
                        return <Card key={c.id} title={<div onClick={() => this.openCarrierDrawerUpdate(c)} style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>{c.name}<RightOutlined style={{marginTop: 10, color: '#3D76DD'}} /></div>} style={{borderColor: '#1890ff', borderRadius: "20px", boxShadow: '10px 5px 5px #ccc', marginTop: 10 }}>
                            <Button shape="round" type="primary" onClick={() => this.openProductDrawer(c)} icon={<PlusCircleOutlined />}>
                                Add Products
                            </Button>
                            {products.filter(p => p.emCarrierId == c.id).length > 0 &&
                            <Row style={{marginTop: 10}}>
                                <Col span={8}>
                                    <Title level={4}>Dwelling</Title>
                                    {products.filter(p => p.emCarrierId == c.id & p.productType == "Dwelling").map(product => {
                                        return <div key={product.id} onClick={() => this.openProductDrawerUpdate(c, product)} style={{cursor: 'pointer', border: 'solid 1px #3D76DD', borderRadius: "20px", boxShadow: '10px 5px 5px #ccc', marginTop: 10, padding: 10, width: '90%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    <Space direction="vertical">
                                                        <Text style={{fontWeight: 'bold'}}>{product.name}</Text>
                                                        <Text>Points: {product.points}</Text>
                                                    </Space>
                                                    <RightOutlined style={{marginTop: 10, color: '#3D76DD'}} />
                                                </div>
                                    })}
                                </Col> 
                                <Col span={8}>
                                    <Title level={4}>Auto</Title>
                                    {products.filter(p => p.emCarrierId == c.id & p.productType == "Auto").map(product => {
                                        return <div key={product.id} onClick={() => this.openProductDrawerUpdate(c, product)} style={{cursor: 'pointer', border: 'solid 1px #3D76DD', borderRadius: "20px", boxShadow: '10px 5px 5px #ccc', marginTop: 10, padding: 10, width: '90%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    <Space direction="vertical">
                                                        <Text style={{fontWeight: 'bold'}}>{product.name}</Text>
                                                        <Text>Points: {product.points}</Text>
                                                    </Space>
                                                    <RightOutlined style={{marginTop: 10, color: '#3D76DD'}} />
                                                </div>
                                    })}
                                </Col> 
                                <Col span={8}>
                                    <Title level={4}>Specialty</Title>
                                    {products.filter(p => p.emCarrierId == c.id & p.productType == "Specialty").map(product => {
                                        return <div key={product.id} onClick={() => this.openProductDrawerUpdate(c, product)} style={{cursor: 'pointer', border: 'solid 1px #3D76DD', borderRadius: "20px", boxShadow: '10px 5px 5px #ccc', marginTop: 10, padding: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                                                    <Space direction="vertical">
                                                        <Text style={{fontWeight: 'bold'}}>{product.name}</Text>
                                                        <Text>Points: {product.points}</Text>
                                                    </Space>
                                                    <RightOutlined style={{marginTop: 10, color: '#3D76DD'}} />
                                                </div>
                                    })}
                                </Col>
                            </Row>
                            }
                        </Card>   
                    })
                }
            </div>
            <Drawer
                title={this.state.carrierId == 0 ? "Create a new carrier" : "Update carrier"}
                width={720}
                onClose={() => this.setState({open: false})}
                open={this.state.open}
                styles={{
                body: {
                    paddingBottom: 80,
                },
                }}
                extra={
                <Space>
                    <Button shape="round" onClick={() => this.setState({open: false})}>Cancel</Button>
                    <Button shape="round" onClick={this.handleSave} type="primary">
                        Submit
                    </Button>
                </Space>
                }>
                    <div>
                        <label htmlFor="dts">Carrier Name <span style={{color: 'Red'}}>*</span></label><br />
                        <Input value={this.state.carrierName} name='carrierName' onChange={e => this.handleChange(e)}  />
                    </div>
            </Drawer>
            <Drawer
                title={"Add Products for " + this.state.selectedCarrier.name}
                width={720}
                onClose={this.closeProductDrawer}
                open={this.state.openProduct}
                styles={{
                body: {
                    paddingBottom: 80,
                },
                }}
                extra={
                <Space>
                    <Button shape="round" onClick={this.closeProductDrawer}>Cancel</Button>
                    <Button shape="round" onClick={() => this.handleProductSave(this.state.selectedCarrier)} type="primary">
                        Submit
                    </Button>
                </Space>
                }>
                    <div>
                        <label htmlFor="dts">Product Name</label><br />
                        <Input value={this.state.productName} name='productName' onChange={e => this.handleChange(e)}  />
                        <label htmlFor="dts">Product Type</label><br />
                        <Select
                            labelInValue
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            name="productType"
                            value={{ value: this.state.productType.toString() }}
                            onChange={(e) => this.handleProductTypeChange(e)}>
                            <Option key={2} value="Dwelling">Dwelling</Option>
                            <Option key={1} value="Auto">Auto</Option>
                            <Option key={4} value="Specialty">Specialty</Option>
                        </Select>
                        <label htmlFor="dts">Points</label><br />
                        <InputNumber
                            name="productPoints"
                            min={0}
                            max={100}
                            step={1}
                            value={this.state.productPoints}
                            onChange={this.handlePointsChange}
                        />
                    </div>
            </Drawer>
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ExpandedMarketplace);