import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Row, Col, Checkbox, Typography, Space, Table, Modal, DatePicker, Spin, Button, Select, Anchor, Input, Radio, Cascader } from 'antd';
import { SearchOutlined, DownloadOutlined, FunnelPlotOutlined } from '@ant-design/icons';
import moment from 'moment';
const {Title, Text} = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Link } = Anchor;

class ZipCodeSummary extends Component {
    constructor(props) {
        super(props);
          this.state = {
            dateTo: moment().endOf('month').format("MM/DD/YYYY"),
            dateFrom: moment().startOf('month').format("MM/DD/YYYY"),
            selectedLeadSource: [],
            modalVisible: false,
            filteredZipSummary: [],
            numberFrom: 0,
            numberTo: 0,
            searchFilter: 'zip',
            leadSourceList: '',
            selectedValues: [],
            agent: null
          }
          this.myRef = React.createRef();
          this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
          this.handlePanelChange = this.handlePanelChange.bind(this);
          this.handleSelectedLeadSourceChange = this.handleSelectedLeadSourceChange.bind(this);
          this.handleReset = this.handleReset.bind(this);
          this.handleChange = this.handleChange.bind(this);
          this.handleFilterWrittenPremium = this.handleFilterWrittenPremium.bind(this);
          this.exportToCSV = this.exportToCSV.bind(this);
          this.handleRadioChange = this.handleRadioChange.bind(this);
          this.handleClearFilters = this.handleClearFilters.bind(this);
          this.handleSelectedAgentChange = this.handleSelectedAgentChange.bind(this);
    }
    componentDidMount() {
        const agencyId = localStorage.getItem('agencyId');
        if(agencyId == null){
            this.props.history.push('/');
        }
        const dateFrom = moment().startOf('month').format("MM/DD/YYYY");
        const dateTo = moment().endOf('month').format("MM/DD/YYYY");
        this.props.getAgent(agencyId);
        this.props.getTypeVendorTypes(agencyId);
        this.props.getLeadSource(agencyId);
        this.props.getTypeVendorTypeLeadSources(agencyId);
        this.props.getQuoteLogZipSummary(agencyId, dateFrom, dateTo, '', '');
    }
    componentDidUpdate(prevProps){

    }
    handleSelectedAgentChange(value){
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo;
        const agencyId = localStorage.getItem('agencyId');
        const leadSourceList = this.state.leadSourceList; 
        if(this.state.searchFilter == 'zip'){
            this.props.getQuoteLogZipSummary(agencyId, dateFrom, dateTo, leadSourceList, value);
        } else if(this.state.searchFilter == 'state'){
            this.props.getQuoteLogStateSummary(agencyId, dateFrom, dateTo, leadSourceList, value);
        } else if(this.state.searchFilter == 'city'){
            this.props.getQuoteLogCitySummary(agencyId, dateFrom, dateTo, leadSourceList, value);
        }
        this.setState({agent: value});
    }
    handleDateRangeChange(dates, dateStrings) {
        const dateFrom = dateStrings[0];
        const dateTo = dateStrings[1];
        const agencyId = localStorage.getItem('agencyId');
        const leadSourceList = this.state.leadSourceList;
        const searchFilter = this.searchFilter;
        const agent = this.state.agent == null ? "" : this.state.agent;
        if(dateFrom != ''){
            //this.props.getQuoteLogZipSummary(agencyId, dateFrom, dateTo, selectedLeadSource);
            if(this.state.searchFilter == 'zip'){
                this.props.getQuoteLogZipSummary(agencyId, dateFrom, dateTo, leadSourceList, agent);
            } else if(this.state.searchFilter == 'state'){
                this.props.getQuoteLogStateSummary(agencyId, dateFrom, dateTo, leadSourceList, agent);
            } else if(this.state.searchFilter == 'city'){
                this.props.getQuoteLogCitySummary(agencyId, dateFrom, dateTo, leadSourceList, agent);
            }
        }
        this.setState({
            dateFrom: dateFrom,
            dateTo: dateTo
        });
        console.log(dateStrings);
    }
    handlePanelChange = (open) => {
        if(open){
            this.setState({dateFrom: null, dateTo: null})
        }
    }
    handleOpenModal = () => {
        this.setState({ modalVisible: true });
    }
    handleCloseModal = () => {
        this.setState({
            modalVisible: false,
        });
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo;
        const agencyId = localStorage.getItem('agencyId');
        const selectedLeadSource = this.state.selectedLeadSource;
        this.props.getQuoteLogZipSummary(agencyId, dateFrom, dateTo, selectedLeadSource);
        console.log(this.state.selectedLeadSource);
    }
    handleSelectedLeadSourceChange(value){
        this.setState({selectedLeadSource: value});
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo;
        const agencyId = localStorage.getItem('agencyId');
        //const selectedLeadSource = this.state.selectedLeadSource;
        this.props.getQuoteLogZipSummary(agencyId, dateFrom, dateTo, value);
    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleTableSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleTableSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#97E45D' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
    });
    handleTableSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
    };
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handleFilterWrittenPremium = () => {
        const numberFrom = this.state.numberFrom;
        const numberTo = this.state.numberTo;
        var summary = this.props.quoteLogZipSummary.filter(z => z.writtenPremium >= numberFrom && z.writtenPremium <= numberTo);
        this.setState({filteredZipSummary: summary});
    }
    exportToCSV = (e) => {
        e.preventDefault();
        var data, filename, link;
        data = this.state.filteredZipSummary.length == 0 ? this.props.quoteLogZipSummary : this.state.filteredZipSummary;
        var csv = this.convertArrayOfObjectsToCSV(data);
        if (csv == null)
          return;
    
        filename = 'ZipCodeSummary' + this.state.dateFrom + '-' + this.state.dateTo + '.csv';
    
        var blob = new Blob([csv], {type: "text/csv;charset=utf-8;"});
    
        if (navigator.msSaveBlob)
        { // IE 10+
          navigator.msSaveBlob(blob, filename)
        }
        else
        {
          var link = document.createElement("a");
          if (link.download !== undefined)
          {
            // feature detection, Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style = "visibility:hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
    
        //console.log(arrOrders);
      }
    
      convertArrayOfObjectsToCSV(args) {
        var result, ctr, keys, columnDelimiter, lineDelimiter, data;
    
        data = args || null;
        if (data == null || !data.length) {
            return null;
        }
    
        columnDelimiter = args.columnDelimiter || ',';
        lineDelimiter = args.lineDelimiter || '\n';
    
        keys = Object.keys(data[0]);
    
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;
    
        data.forEach(function(item) {
            ctr = 0;
            keys.forEach(function(key) {
                //if(key != 'costPerLead' && key != 'children' && key != 'key'){
                    if (ctr > 0) result += columnDelimiter;
                    result += item[key]
                    ctr++;
                //}
            });
            result += lineDelimiter;
        });
    
        return result;
    }
    // handleRadioChange = () => {
    //     const dateFrom = this.state.dateFrom;
    //     const dateTo = this.state.dateTo;
    //     const agencyId = localStorage.getItem('agencyId');
    //     this.props.getQuoteLogZipSummary(agencyId, dateFrom, dateTo, '');
    // }
    handleRadioChange = (e) => {
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo;
        const agencyId = localStorage.getItem('agencyId');
        const leadSourceList = this.state.leadSourceList;
        const agent = this.state.agent == null ? "" : this.state.agent;
        this.setState({
            searchFilter: e.target.value
        });
        console.log(agent);
        if(e.target.value == 'zip'){
            this.props.getQuoteLogZipSummary(agencyId, dateFrom, dateTo, leadSourceList, agent);
        } else if(e.target.value == 'state'){
            this.props.getQuoteLogStateSummary(agencyId, dateFrom, dateTo, leadSourceList, agent);
        } else if(e.target.value == 'city'){
            this.props.getQuoteLogCitySummary(agencyId, dateFrom, dateTo, leadSourceList, agent);
        }
    }
    handleClearFilters(){
        const agencyId = localStorage.getItem('agencyId');
        const dateFrom = moment().startOf('month').format("MM/DD/YYYY");
        const dateTo = moment().endOf('month').format("MM/DD/YYYY");
        this.props.getQuoteLogZipSummary(agencyId, dateFrom, dateTo, '', '');
        this.setState({selectedValues: [], dateTo: dateTo, dateFrom: dateFrom, leadSourceList: '', agent: null});
    }
    render() {
        const states = [];
        const cities = [];
        const filterState = [];
        const filterCity = [];
        const zipSummary = this.state.filteredZipSummary.length == 0 ? this.props.quoteLogZipSummary : this.state.filteredZipSummary;
        let totalQuotedHouseholds = 0;
        let totalWrittenHouseholds = 0;
        let totalWrittenItems = 0;
        let totalQuotedItems = 0;
        let totalWrittenPremium = 0;
        let totalQuotedPolicies = 0;
        let totalWrittenPolicies = 0;
        if(zipSummary != undefined) {
            zipSummary.forEach(({ quotedHouseholds, writtenHouseholds, writtenItems, quotedItems, writtenPremium, quotedPolicies, state, city, writtenPolicies }) => {
                totalQuotedHouseholds += quotedHouseholds;
                totalWrittenHouseholds += writtenHouseholds;
                totalWrittenItems += writtenItems;
                totalQuotedItems += quotedItems;
                totalWrittenPremium += writtenPremium;
                totalQuotedPolicies += quotedPolicies;
                totalWrittenPolicies += writtenPolicies
                states.push({state});
                cities.push({city});
            });
            const uniqueStates = [...new Set(states.map((item) => item.state))];
            uniqueStates.map (s =>
                filterState.push({
                    text: s,
                    value: s
                })
            );
            const uniqueCities = [...new Set(cities.map((item) => item.city))];
            uniqueCities.map (c =>
                filterCity.push({
                    text: c,
                    value: c
                })
            );
        }
        const agencyId = localStorage.getItem('agencyId');
        const summaryZipColumns = [
            {
                title: 'Zip',
                dataIndex: 'zip',
                sorter: (a, b) => ('' + a.zip).localeCompare(b.zip),
                ...this.getColumnSearchProps('zip'),
                //render: text => <span>{text != null & agencyId == 1168673106 ? text.substring(0, 2) + "***" : text}</span>
            },
            {
                title: 'State',
                dataIndex: 'state',
                sorter: (a, b) => ('' + a.state).localeCompare(b.state),
                //...this.getColumnSearchProps('state'),
                filters: filterState,
                filterMultiple: true,
                //filteredValue: this.state.filteredInfo ? this.state.filteredInfo.agent : [],
                onFilter: (value, record) => record.state.indexOf(value) === 0,
            },
            {
                title: 'City',
                dataIndex: 'city',
                sorter: (a, b) => ('' + a.city).localeCompare(b.city),
                //...this.getColumnSearchProps('state'),
                filters: filterCity,
                filterMultiple: true,
                //filteredValue: this.state.filteredInfo ? this.state.filteredInfo.agent : [],
                onFilter: (value, record) => record.city.indexOf(value) === 0,
                //render: text => <span>{agencyId == 1168673106 ? "******" : text}</span>
            },
            {
                title: 'Quoted Households',
                dataIndex: 'quotedHouseholds',
                align: 'center',
                key: 'quotedHouseholds',
                sorter: (a, b) => a.quotedHouseholds - b.quotedHouseholds
            },
            {
                title: 'Quoted Policies',
                dataIndex: 'quotedPolicies',
                align: 'center',
                key: 'quotedPolicies',
                sorter: (a, b) => a.quotedPolicies - b.quotedPolicies
            },
            {
                title: 'Quoted Items',
                dataIndex: 'quotedItems',
                align: 'center',
                key: 'quotedItems',
                sorter: (a, b) => a.quotedItems - b.quotedItems
            },
            {
                title: 'Written Households',
                dataIndex: 'writtenHouseholds',
                align: 'center',
                key: 'writtenHouseholds',
                sorter: (a, b) => a.writtenHouseholds - b.writtenHouseholds
            },
            {
                title: 'Written Policies',
                dataIndex: 'writtenPolicies',
                align: 'center',
                key: 'writtenPolicies',
                sorter: (a, b) => a.writtenPolicies - b.writtenPolicies
            },
            {
                title: 'Written Items',
                dataIndex: 'writtenItems',
                align: 'center',
                key: 'writtenItems',
                sorter: (a, b) => a.writtenItems - b.writtenItems
            },
            {
                title: 'Written Premium',
                dataIndex: 'writtenPremium',
                render: text => <span style={{textAlign: 'center'}}>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(text)}</span>,
                align: 'center',
                sorter: (a, b) => a.writtenPremium - b.writtenPremium,
            },
            {
                title: 'Close Ratio',
                dataIndex: 'closeRatio',
                align: 'center',
                sorter: (a, b) => parseFloat(a.closeRatio) - parseFloat(b.closeRatio)
            },
            {
                title: 'Written Bundle Ratio',
                dataIndex: 'writtenBundlesRate',
                align: 'center',
                sorter: (a, b) => parseFloat(a.closeRatio) - parseFloat(b.closeRatio)
            },
            {
                title: 'Avg Dwelling Ins Score',
                dataIndex: 'avgInsuranceScore',
                align: 'center',
                key: 'avgInsuranceScore',
                sorter: (a, b) => a.avgInsuranceScore - b.avgInsuranceScore
            },
            {
                title: 'Avg Auto Ins Score',
                dataIndex: 'avgInsuranceAutoScore',
                align: 'center',
                key: 'avgInsuranceAutoScore',
                sorter: (a, b) => a.avgInsuranceScore - b.avgInsuranceScore
            },
            {
                title: 'DNQ Policies',
                dataIndex: 'dnqPolicies',
                align: 'center',
                key: 'dnqPolicies',
                sorter: (a, b) => a.dnqPolicies - b.dnqPolicies
            },
            {
                title: 'DNQ Percent',
                dataIndex: 'dnqPercent',
                align: 'center',
                sorter: (a, b) => parseFloat(a.dnqPercent) - parseFloat(b.dnqPercent)
            },
        ];
            const summaryTable = (
                <table width='100%'>
                    <tr style={{backgroundColor: '#3D76DD', fontWeight: 'bold'}}>
                        <th></th>
                        <td style={{textAlign: 'center'}} key="totalCount">
                            <Text style={{color: '#fff'}}>Quoted Households</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="totalQuotedPolicies">
                            <Text style={{color: '#fff'}}>Quoted Policies</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="totalQuotedItems">
                            <Text style={{color: '#fff'}}>Quoted Items</Text>
                        </td>
                        
                        <td style={{textAlign: 'center'}} key="totalWrittenHouseholds">
                            <Text style={{color: '#fff'}}>Written Households</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="totalWrittenPolicies">
                            <Text style={{color: '#fff'}}>Written Policies</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="totalWrittenItems">
                            <Text style={{color: '#fff'}}>Written Items</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="totalWrittenPremium">
                            <Text style={{color: '#fff'}}>Written Premium</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="totalCloseRate">
                            <Text style={{color: '#fff'}}>Close Rate</Text>
                        </td>
                    </tr>
                    <tr ref={this.myRef} key="totalRow" style={{fontWeight: 'bold'}}>
                        <th>Total</th>
                        <td style={{textAlign: 'center'}} key="totalCount">
                            <Text>{totalQuotedHouseholds}</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="totalQuotedPolicies">
                            <Text>{totalQuotedPolicies}</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="totalQuotedItems">
                            <Text>{totalQuotedItems}</Text>
                        </td>
                        
                        <td style={{textAlign: 'center'}} key="totalWrittenHouseholds">
                            <Text>{totalWrittenHouseholds}</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="totalWrittenPolicies">
                            <Text>{totalWrittenPolicies}</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="totalWrittenItems">
                            <Text>{totalWrittenItems}</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="totalWrittenPremium">
                            <Text>{new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD"
                            }).format(totalWrittenPremium)}</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="totalCloseRate">
                            <Text>
                            {new Intl.NumberFormat("en-US", {
                                style: "percent",
                                maximumFractionDigits: 2
                            }).format(totalWrittenPolicies / totalQuotedPolicies)}
                            </Text>
                        </td>
                    </tr>
                </table>
        )
        function xml2json(srcDOM){
            let children = [...srcDOM.children];
          
            // base case for recursion.
            if (!children.length) {
              return srcDOM.innerHTML;
            }
          
            // initializing object to be returned.
            let jsonResult = {};
          
            for (let child of children) {
              // checking is child has siblings of same name.
              let childIsArray =
                children.filter((eachChild) => eachChild.nodeName === child.nodeName)
                  .length > 1;
          
              // if child is array, save the values as array, 
              // else as strings.
              if (childIsArray) {
                if (jsonResult[child.nodeName] === undefined) {
                  jsonResult[child.nodeName] = [xml2json(child)];
                } else {
                  jsonResult[child.nodeName].push(xml2json(child));
                }
              } else {
                jsonResult[child.nodeName] = xml2json(child);
              }
            }
          
            return jsonResult;
        }
        const onChange = (value: string[][]) => {
            var leadSourceList = '';
            
            for (var i=0; i < value.length; i++){
                if(value[i].length > 1){
                    leadSourceList += leadSourceList == '' ? value[i][1] : ',' + value[i][1];
                } else {
                    var fullList = this.props.typeVendorTypesLeadSources.filter(l => l.value == value[i][0]);
                    var children = fullList[0].children;
                    for (var x=0; x < children.length; x++){
                        leadSourceList += leadSourceList == '' ? children[x].value : ',' + children[x].value;
                    }
                }
            }
            const dateFrom = this.state.dateFrom;
            const dateTo = this.state.dateTo;
            const agent = this.state.agent == null ? "" : this.state.agent;
            //this.props.getQuoteLogZipSummary(agencyId, dateFrom, dateTo, leadSourceList);
            if(this.state.searchFilter == 'zip'){
                this.props.getQuoteLogZipSummary(agencyId, dateFrom, dateTo, leadSourceList, agent);
            } else if(this.state.searchFilter == 'state'){
                this.props.getQuoteLogStateSummary(agencyId, dateFrom, dateTo, leadSourceList, agent);
            } else if(this.state.searchFilter == 'city'){
                this.props.getQuoteLogCitySummary(agencyId, dateFrom, dateTo, leadSourceList, agent);
            }
            this.setState({leadSourceList: leadSourceList, selectedValues: value});
        };
        return (
            <div>
                <Row>
                    <Col md={24}>
                        <Title level={3}>ZIP CODE SUMMARY</Title>
                    </Col>
                </Row>
                {this.props.isLoading ?<div style={{textAlign: 'center'}}> <Spin></Spin><p><Title level={3}>Loading report.  Please do not click the refresh button...</Title></p></div>:
                <div>
                <Row>
                    <Col span={4}>
                        <RangePicker format={'MM/DD/YYYY'} allowEmpty={true}
                            ranges={{
                                Today: [moment(), moment()],
                                'This Week': [moment().startOf('week'), moment().endOf('week')],
                                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            }}
                            defaultValue={[moment().startOf('month'), moment().endOf('month')]}
                            value={this.state.dateFrom == null ? [] : [moment(this.state.dateFrom), moment(this.state.dateTo)]}
                            onChange={this.handleDateRangeChange}
                            onOpenChange={this.handlePanelChange}
                        />
                    </Col>
                    <Col span={4}>
                        <Cascader
                            style={{ width: '90%' }}
                            options={this.props.typeVendorTypesLeadSources}
                            onChange={onChange}
                            multiple
                            maxTagCount="responsive"
                            placeholder={<strong style={{color: 'black'}}>Select Lead Source</strong>}
                            value={this.state.selectedValues}
                        />
                    </Col>
                    <Col sm={4}>
                        <Select
                            virtual={false}
                            style={{ width: '90%' }}
                            dropdownStyle={{ minWidth: "20%" }}
                            placeholder={<strong style={{color: 'black'}}>Select Agent</strong>}
                            name="agents"
                            value={this.state.agent}
                            onChange={this.handleSelectedAgentChange}>
                                <Option key='' value=''></Option>
                            {
                                this.props.agent.filter(a => a.status != "5").map(a => 
                                    <Option key={a.firstName} value={a.firstName}>{a.firstName}</Option>
                                )
                            }
                        </Select>
                    </Col>
                    <Col sm={4}>
                        <Button shape="round" onClick={() => this.handleClearFilters()} style={{color: this.state.filtered ? 'Blue' : 'Black'}}>
                            <FunnelPlotOutlined /> Clear Filters
                        </Button>
                    </Col>
                    <Col span={4}>
                        <Button shape="round" onClick={this.exportToCSV} style={{position: 'absolute', right: 0}}>
                            <DownloadOutlined /> Export
                        </Button>
                    </Col>
                </Row>
                <Row style={{paddingTop: 10}}>
                    <Col>
                        <Radio.Group defaultValue="zip" buttonStyle="solid" onChange={this.handleRadioChange} value={this.state.searchFilter}>
                            <Radio.Button value="zip">Zip Code</Radio.Button>
                            <Radio.Button value="city">City</Radio.Button>
                            <Radio.Button value="state">State</Radio.Button>
                        </Radio.Group>
                    </Col>
                </Row>
                <Row style={{paddingTop: 1}}>
                    <Col md={24}>
                        <p>{summaryTable}</p>
                        <Table dataSource={zipSummary} columns={summaryZipColumns} pagination={false}></Table>
                    </Col>
                </Row>
                <Modal 
                    width="75%"
                    visible={this.state.modalVisible}
                    title="SELECT VENDORS"
                    onOk={this.handleCloseModal}
                    onCancel={this.handleCloseModal}
                    footer={[
                        <Row style={{paddingBottom: 10}} >
                            <Col sm={12}>
                                <Button style={{ float: 'left' }} type="primary" shape="round" onClick={this.handleCloseModal}>
                                    OK
                                </Button>
                            </Col>
                        </Row>,
                    ]}>
                        <Checkbox.Group style={{ width: '100%' }} onChange={this.handleSelectedLeadSourceChange}>
                            <Row>
                                {this.props.leadSource.map(a => 
                                    <Col span={8} key={a.id}>
                                        <Checkbox value={a.name}>{a.name}</Checkbox>
                                    </Col>
                                )}
                            </Row>
                        </Checkbox.Group>
                </Modal>
                </div>}
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ZipCodeSummary);