import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Row, Col, List, Typography, Space, Table, InputNumber, DatePicker, Spin, Button } from 'antd';
import moment from 'moment';
const {Title} = Typography;
const { RangePicker } = DatePicker;

class VendorZipCodeSummary extends Component {
    constructor(props) {
        super(props);
          this.state = {

          }
          this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
    }
    componentDidMount() {
        const agencyId = localStorage.getItem('agencyId');
        const dateFrom = moment().startOf('month').format("MM/DD/YYYY");
        const dateTo = moment().endOf('month').format("MM/DD/YYYY");
        this.props.getQuoteLogVendorZipSummary(agencyId, dateFrom, dateTo);
    }
    componentDidUpdate(prevProps){
        
    }
    handleDateRangeChange(dates, dateStrings) {
        const dateFrom = dateStrings[0];
        const dateTo = dateStrings[1];
        const agencyId = localStorage.getItem('agencyId');
        if(dateFrom != ''){
            this.props.getQuoteLogVendorZipSummary(agencyId, dateFrom, dateTo);
        }
        this.setState({
            dateFrom: dateFrom,
            dateTo: dateTo 
        });
        console.log(dateStrings);
    }
    render() {
        
        const summaryVendorZipColumns = [
            {
                title: 'Vendor',
                dataIndex: 'vendor',
            },
            {
                title: 'Quoted Prospects',
                dataIndex: 'count',
                render: text => <span style={{textAlign: 'center'}}>{text}</span>,
            },
            {
                title: 'Written Households',
                dataIndex: 'writtenHouseholds',
            },
            {
                title: 'Close Ratio',
                dataIndex: 'closeRatio',
            },
        ];
        
        return (
            <Row>
                <Col span={24}>
                    <Space direction='vertical' style={{width: '100%', paddingTop: 10}}>
                        <Title level={3}>VENDOR ZIP CODE SUMMARY</Title>
                        <RangePicker format={'MM/DD/YYYY'} allowEmpty={true}
                            ranges={{
                                Today: [moment(), moment()],
                                'This Week': [moment().startOf('week'), moment().endOf('week')],
                                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            }}
                            //defaultValue={[moment().startOf('week'), moment().endOf('week')]}
                            onChange={this.handleDateRangeChange}
                        />
                        {this.props.isLoading ?<div style={{textAlign: 'center'}}> <Spin tip='Loading report...' ></Spin> </div>:
                        <Table dataSource={this.props.quoteLogVendorSummary.zipVendorSummary} columns={summaryVendorZipColumns} pagination={{ pageSize: 50 }}></Table>}
                    </Space>
                </Col>
            </Row>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(VendorZipCodeSummary);