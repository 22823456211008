import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Select, Table, Row, DatePicker, Col, Space, Button, Radio, Input, InputNumber, Typography, Modal, Spin } from 'antd';
import { DownOutlined, UpOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import LeadSourceAgentSummary from './LeadSourceAgentSummary'
const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const { Search } = Input;

class CostAcquisition extends Component {
    constructor(props) {
        super(props);
          this.state = {
            dateFrom: moment().startOf('month').format("MM/DD/YYYY"),
            dateTo: moment().endOf('month').format("MM/DD/YYYY"),
            monthDts: moment().format("MMM YYYY"),
            showModal: false
        };
        this.onMonthChange = this.onMonthChange.bind(this);
        this.handleBucketChange = this.handleBucketChange.bind(this);
        this.handleSpendChange = this.handleSpendChange.bind(this);
        this.handleSelectedLeadSource = this.handleSelectedLeadSource.bind(this);
    }
    componentDidMount() {
        const agencyId = localStorage.getItem('agencyId');
        
        this.props.getCostAcquisition(agencyId, this.props.dateFrom, this.props.dateTo, this.props.monthDts);
    }
    componentDidUpdate(prevProps){
        if(this.props.monthDts != prevProps.monthDts){
            const agencyId = localStorage.getItem('agencyId');
            this.props.getCostAcquisition(agencyId, this.props.dateFrom, this.props.dateTo, this.props.monthDts);
        }
    };
    onMonthChange(date, dateString){
        if(dateString != ''){
            console.log(date);
            const userId = localStorage.getItem("userId");
            const agencyId = localStorage.getItem("agencyId");
            this.setState({monthDts: dateString, selectedDate: date});
            
            const dateTo = moment(date).endOf('month').format("MM/DD/YYYY");
            const dateFrom = moment(date).startOf('month').format("MM/DD/YYYY");
            this.props.getCostAcquisition(agencyId, dateFrom, dateTo, dateString);
        }else{
            this.setState({monthDts: moment().format("MMM YYYY")});
        }
    }
    handleBucketChange(selectedBucket, record){
        
        console.log(selectedBucket);
        console.log(record);
        record.marketingBucket = selectedBucket;
        console.log(record);
    }
    handleSpendChange(spend, record){
        console.log(record);
        //this.setState({isSaving: true});
        record.actualSpend = spend.target.value.replace('$', '');
        console.log(record);
        const agencyId = localStorage.getItem('agencyId');
        const userId = localStorage.getItem("userId");
        if(record.marketingBucket == "Telemarketing"){
            this.props.createTelemarketing(
                agencyId, 
                userId, 
                record.name, 
                record.typeQuoteLogLeadSourceId, 
                0, 
                0, 
                0, 
                0,
                0, 
                0, 
                0, 
                0, 
                0, 
                0, 
                0, 
                0, 
                0, 
                this.state.monthDts, 
                0, 
                0, 
                0, 
                0, 
                0, 
                0,
                record.actualSpend,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0);
        }
        //this.setState({isSaving: false});
    }
    handleSelectedLeadSource(typeQuoteLogLeadSourceId, actualHouseholdCost) {
        this.setState({selectedTypeQuoteLogLeadSourceId: typeQuoteLogLeadSourceId, showModal: true, actualHouseholdCost: actualHouseholdCost })
    }
    render(){
        const dateFormat = 'MM/DD/YYYY';
        const columns = [
            {
                title: 'Lead Source',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Marketing Bucket',
                dataIndex: 'marketingBucket',
                key: 'action',
                // render: (text, record) => 
                // <Select size="sm"
                //     style={{ width: '100%' }}
                //     placeholder="Please select"
                //     name="marketingBucket"
                //     value={text}
                //     onChange={(e) => this.handleBucketChange(e, record)}>
                //         <option key=""></option>
                //         <option key="Telemarketing">Telemarketing</option>
                //         <option key="Live Call Transfer">Live Call Transfer</option>
                //         <option key="Mailers">Mailers</option>
                //         <option key="Social Media">Social Media</option>
                //         <option key="Live Web Lead">Live Web Lead</option>
                //         <option key="Aged Leads">Aged Leads</option>
                // </Select>,
            },
            {
                title: 'Spend',
                dataIndex: 'actualSpend',
                key: 'actualSpend',
                align: 'center',
                render: text => new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(text)
                // render: (text, record) => <InputNumber value={text} min={0} max={999999} step={1} style={{ width: '100%', marginLeft: 10 }} formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                // parser={value => value.replace(/\$\s?|(,*)/g, '')} onBlur={(e) => this.handleSpendChange(e, record)} />,
            },
            {
                title: 'Written Premium',
                dataIndex: 'writtenPremium',
                key: 'writtenPremium',
                align: 'center',
                render: text => new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(text),
            },
            {
                title: 'Cost per Quoted Household',
                dataIndex: 'actualHouseholdCost',
                key: 'actualHouseholdCost',
                align: 'center',
                render: text => new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(text),
            },
            {
                title: 'Cost per Quoted Policy',
                dataIndex: 'actualPolicyCost',
                key: 'actualPolicyCost',
                align: 'center',
                render: text => new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(text),
            },
            {
                title: 'Cost per Quoted Item',
                dataIndex: 'actualItemCost',
                key: 'actualItemCost',
                align: 'center',
                render: text => new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(text),
            },
            {
                title: 'Household Acq. Cost',
                dataIndex: 'actualHouseholdAcquistionCost',
                key: 'actualHouseholdAcquistionCost',
                align: 'center',
                render: text => new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(text),
            },
            {
                title: 'Policy Acq. Cost',
                dataIndex: 'actualPolicyAcquistionCost',
                key: 'actualPolicyAcquistionCost',
                align: 'center',
                render: text => new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(text),
            },
            {
                title: 'Item Acq. Cost',
                dataIndex: 'actualItemAcquistionCost',
                key: 'actualItemAcquistionCost',
                align: 'center',
                render: text => new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(text),
            },
          ];
        return(
            <div>
                {/* <Row style={{paddingBottom: 5}}>
                    <Col md={8}>
                        <strong>Filter month</strong><br />
                        <DatePicker onChange={this.onMonthChange} picker="month" format="MMM YYYY" value={moment(this.state.monthDts, "MMM YYYY")}  />
                    </Col>
                </Row> */}
                <Row>
                    <Col span={24}>
                    {//this.state.isSaving == false &&
                        //<Table dataSource={this.props.acquisitionCost} columns={columns} pagination={{ pageSize: 50 }} />
                    }
                    </Col>
                </Row>
                <Row>{this.props.isActivityLoading ?<div style={{textAlign: 'center'}}> <Spin></Spin><p><Title level={3}>Loading report.  Please do not click the refresh button...</Title></p></div>:
                    <Col span={24}>
                        <table style={{width: '100%'}}>
                            <thead style={{backgroundColor: '#3D76DD', fontWeight: 'bold', textAlign: 'center', color: '#fff'}}>
                                <tr>
                                    <td>Lead Source</td>
                                    <td>Marketing Bucket</td>
                                    <td>Spend</td>
                                    <td>Written Premium</td>
                                    <td>Cost per Quoted Household</td>
                                    <td>Cost per Quoted Policy</td>
                                    <td>Cost per Quoted Item</td>
                                    <td>Household Acq. Cost</td>
                                    <td>Policy Acq. Cost</td>
                                    <td>Item Acq. Cost</td>
                                </tr>
                            </thead>
                            <tbody>
                            {this.props.acquisitionCost.map(a => 
                                <tr style={{textAlign: 'center', borderBottom: 'solid 1px #ccc', height: 50}}>
                                    <td style={{fontWeight: 'bold', cursor: 'pointer', display: 'flex', justifyContent: 'space-between', marginTop: 12, marginRight: 10}} 
                                    onClick={() => this.handleSelectedLeadSource(a.typeQuoteLogLeadSourceId, a.actualHouseholdCost)}>
                                        <Text>{a.name}</Text>
                                        <RightOutlined />
                                    </td>
                                    <td style={{textAlign: 'left'}}>{a.marketingBucket}</td>
                                    <td>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(a.actualSpend)}</td>
                                    <td>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(a.writtenPremium)}</td>
                                    <td>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(a.actualHouseholdCost)}</td>
                                    <td>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(a.actualPolicyCost)}</td>
                                    <td>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(a.actualItemCost)}</td>
                                    <td>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(a.actualHouseholdAcquistionCost)}</td>
                                    <td>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(a.actualPolicyAcquistionCost)}</td>
                                    <td>{new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(a.actualItemAcquistionCost)}</td>
                                </tr>      
                            )}
                            </tbody>
                        </table>
                    </Col>
                }
                </Row>
                <Modal 
                    width="75%"
                    title="Lead Source Agent Summary"
                    visible={this.state.showModal}
                    onCancel={() => this.setState({showModal: false})}
                    footer={[
                        <Button key="close" type="primary" onClick={() => this.setState({showModal: false})}>
                            Close
                        </Button>,
                    ]}>
                    <LeadSourceAgentSummary dateTo={this.props.dateTo} dateFrom={this.props.dateFrom} typeQuoteLogLeadSourceId={this.state.selectedTypeQuoteLogLeadSourceId} actualHouseholdCost={this.state.actualHouseholdCost} />
                </Modal>
            </div>
        )
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(CostAcquisition);