import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Card, Button, Input, Col, Row, Select, Typography } from 'antd'
// import SquarePaymentForm, {
//     CreditCardNumberInput,
//     CreditCardExpirationDateInput,
//     CreditCardPostalCodeInput,
//     CreditCardCVVInput,
//     CreditCardSubmitButton
//   } from 'react-square-payment-form'
//   import 'react-square-payment-form/lib/default.css'

const { Meta } = Card;
const { Option } = Select;
const { Text } = Typography;

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            firstName: '',
            lastName: '',
            company: '',
            phone: '',
            address: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            email: '',
            loading: false,
            password: '',
            subscriptionId: '',
            errorMessages: []
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount(){
        if(this.props.location.search.includes("subscriptionId")){
            const query = new URLSearchParams(this.props.location.search).get('subscriptionId');
            this.setState({subscriptionId: query});
            console.log(query);
        }else{
            this.props.history.push('/');
        }
        
    }
    componentDidUpdate(prevProps){
        if(this.props.user != prevProps.user) {
            localStorage.setItem('userId', this.props.user.id);
            localStorage.setItem('status', this.props.user.status);
            localStorage.setItem('agencyId', this.props.user.agencyId);
            this.props.history.push('/agency');
        }
        // if(this.props.paymentId != prevProps.paymentId){
        //    if(this.props.paymentId != ''){
        //         const userName = this.state.userName;
        //         const firstName = this.state.firstName;
        //         const lastName = this.state.lastName;
        //         const email = this.state.email;
        //         const company = this.state.company;
        //         const password = this.state.password;
        //         const phone = this.state.phone;
        //         const address = this.state.address;
        //         const address2 = this.state.address2;
        //         const city = this.state.city;
        //         const state = this.state.state;
        //         const zip = this.state.zip;
        //         const paymentId = this.props.paymentId;
        //         const status = this.props.amount == 20 ? 2 : this.props.amount == 25 ? 3 : 4;
        //         this.props.createUser(userName, firstName, lastName, email, password, company, phone, address, address2, city, state, zip, paymentId, status);
        //     }
        // }
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleStateChange = (e) => {
        this.setState({
            state: e    
        });
    }
    handleBlur = (e) => {
        this.props.getUserAgent(e.target.value);
    }
    handleSubmit(event) {
        const userName = this.state.userName;
        const firstName = this.state.firstName;
        const lastName = this.state.lastName;
        const email = this.state.email;
        const company = this.state.company;
        const password = this.state.password;
        const phone = this.state.phone;
        const address = this.state.address;
        const address2 = this.state.address2;
        const city = this.state.city;
        const state = this.state.state;
        const zip = this.state.zip;      
        const paymentId = this.state.subscriptionId;

        this.props.createUser(userName, firstName, lastName, email, password, company, phone, address, address2, city, state, zip, paymentId, 4);

        this.setState({
            userName: '',
            firstName: '',
            lastName: '',
            company: '',
            phone: '',
            address: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            email: '',
            loading: false,
            password: '',
            errorMessages: []});
       
    }
    cardNonceResponseReceived = (errors, nonce, cardData, buyerVerificationToken) => {
        // if (errors) {
        //   this.setState({ errorMessages: errors.map(error => error.message) })
        //   return
        // }
    
        // this.setState({ errorMessages: [] })
        //alert("nonce created: " + nonce + ", buyerVerificationToken: " + buyerVerificationToken)
        //this.props.makePayment(nonce, this.props.amount);
        // const firstName = this.state.firstName;
        // const lastName = this.state.lastName;
        // const address = this.state.address;
        // const city = this.state.city;
        // const state = this.state.state;
        // const zip = this.state.zip;
        // const shipFirstName = this.state.shipFirstName;
        // const shipLastName = this.state.shipLastName;
        // const shipAddress = this.state.shipAddress;
        // const shipCity = this.state.shipCity;
        // const shipState = this.state.shipState;
        // const shipZip = this.state.shipZip;
        // const email = this.state.email;
        // const phone = this.state.phone;
        // const athleteFirstName = this.state.athleteFirstName;
        // const athleteLastName = this.state.athleteLastName;
        // const coachName = this.state.coachName;
        // const teamName = this.state.teamName;
        // this.setState({loading: true});
        // this.props.makeSquarePayment(nonce, this.state.totalAmount, email, firstName, lastName, address, city, state, zip, phone, this.props.cart, shipFirstName, shipLastName, shipAddress, shipCity, shipState, shipZip, athleteFirstName, athleteLastName, teamName, coachName);
        // this.setState({loading: false});
      }
    render() {  
        const stateList = require('../data/states.json');

        return (
            <div>
                <Row style={{paddingBottom: 30}} >
                    <Col sm={12} offset={6} style={{textAlign: 'center'}}>
                        <img src="humblelogo.png" alt="Logo" style={{float: "left", height: 65}} />
                    </Col>
                </Row>
                <Row>
                    <Col span={12} offset={6}>
                        <Card title="Register" headStyle={{ backgroundColor: '#3D76DD', color: '#fff'}} style={{borderRadius: "20px",overflow: "hidden"}}>
                            <Row>
                                <Col span={24}>
                                    <Meta description={
                                        <div>
                                            <div>
                                                <label htmlFor="firstName">First Name</label><br />
                                                <Input size="large" placeholder="First Name" name="firstName" onChange={e => this.handleChange(e)} />
                                            </div>
                                            <div>
                                                <label htmlFor="lastName">Last Name</label><br />
                                                <Input size="large" placeholder="Last Name" name="lastName" onChange={e => this.handleChange(e)} />
                                            </div>
                                            <div>
                                                <label htmlFor="company">Company</label><br />
                                                <Input size="large" placeholder="Company" name="company" onChange={e => this.handleChange(e)} />
                                            </div>
                                            <div>
                                                <label htmlFor="phone">Phone</label><br />
                                                <Input size="large" value={this.state.phone} name='phone' onChange={e => this.handleChange(e)}  />
                                            </div>
                                            <div>
                                                <label htmlFor="address">Address</label><br />
                                                <Input size="large" value={this.state.address} name='address' onChange={e => this.handleChange(e)} />
                                            </div>
                                            <div>
                                                <label htmlFor="address2">Address 2</label><br />
                                                <Input size="large" value={this.state.address2} name='address2' onChange={e => this.handleChange(e)} />
                                            </div>
                                            <Row>
                                                <Col span={8} style={{paddingRight: 10}}>
                                                    <label htmlFor="spend">City</label><br />
                                                    <Input size="large" name='city' value={this.state.city} onChange={e => this.handleChange(e)} />
                                                </Col>
                                                <Col span={8} style={{paddingRight: 10}}>
                                                    <label htmlFor="spend">State</label><br />
                                                    <Select size="large"
                                                        style={{ width: '100%' }}
                                                        placeholder="Please select"
                                                        name="ineligible"
                                                        value={this.state.state}
                                                        onChange={(e) => this.handleStateChange(e)}>
                                                        {
                                                            stateList.map(d => 
                                                                <Option key={d.abbreviation}>{d.name}</Option>
                                                            )
                                                        }
                                                    </Select>     
                                                </Col>
                                                <Col span={8}>
                                                    <label htmlFor="spend">Zip</label><br />
                                                    <Input size="large" name='zip' value={this.state.zip} onChange={e => this.handleChange(e)} />
                                                </Col>
                                            </Row> 
                                            <div>
                                                <label htmlFor="email">Email</label><br />
                                                <Input size="large" placeholder="Email" name="email" onChange={e => this.handleChange(e)} onBlur={e => this.handleBlur(e)} />
                                                
                                                {
                                                    (this.props.userAgent == undefined) ?
                                                        <span></span>
                                                        :
                                                        (this.props.userAgent.id > 0) ?
                                                        <Text type="danger" id="invalid">Email is already registered. {this.props.userAgent.id}</Text>
                                                        :
                                                        <span></span>
                                                }
                                                
                                            </div>
                                            <div>
                                                <label htmlFor="password">Password</label><br />
                                                <Input.Password size="large" placeholder="Password" name="password" onChange={e => this.handleChange(e)} />
                                            </div>
                                        </div>
                                } />
                                </Col>
                            </Row>
                            {/* <Row style={{paddingTop: 10, display: 'none'}}>
                                <Col span-={24}>
                                    <Card title="Payment Information">
                                        <SquarePaymentForm
                                            sandbox={false}
                                            applicationId='sq0idp-0i7SEUdkD1TqZIWWnZPR5A'
                                            locationId='03VDAZTFAG620'
                                            givenName={this.state.firstName}
                                            familyName= {this.state.lastName}
                                            email={this.state.email}
                                            amount={this.props.amount}
                                            cardNonceResponseReceived={this.cardNonceResponseReceived}
                                            //createVerificationDetails={this.createVerificationDetails}
                                        >
                                            <fieldset className="sq-fieldset">
                                                <Row style={{paddingBottom: 10}}>
                                                    <Col md={24}>
                                                       <strong>AMOUNT: ${this.props.amount} / MO</strong>
                                                    </Col>
                                                </Row>
                                                <CreditCardNumberInput />
                                                <div className="sq-form-third">
                                                <CreditCardExpirationDateInput />
                                                </div>

                                                <div className="sq-form-third">
                                                <CreditCardPostalCodeInput />
                                                </div>

                                                <div className="sq-form-third">
                                                <CreditCardCVVInput />
                                                </div>
                                            </fieldset>

                                            <CreditCardSubmitButton>
                                                Register
                                            </CreditCardSubmitButton>
                                        </SquarePaymentForm>
                        
                                        <div className="sq-error-message">
                                            {this.state.errorMessages.map(errorMessage =>
                                            <li key={`sq-error-${errorMessage}`}>{errorMessage}</li>
                                            )}
                                        </div>
                                    </Card>
                                </Col>
                            </Row> */}
                            <Row style={{paddingTop: '10px'}}>
                                <Col>
                                    <Button disabled={this.props.isLoading} shape="round" type='primary' onClick={e => this.handleSubmit(e)}> {this.props.isLoading == true ? 'Please Wait' : 'Submit'}
                                    </Button>
                                    <p><Text type="danger" id="invalid">{this.props.error}</Text></p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Register);