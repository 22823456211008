import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Row, Col, Table, Collapse, Typography, Modal, Spin, DatePicker, Select, Button, Checkbox, Space, Cascader } from 'antd';
import { FunnelPlotOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
const { Panel } = Collapse;
const { Text, Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

class DNQReport extends Component {
    constructor(props) {
      super(props);
        this.state = {
            agent: null,
            dateTo: moment().endOf('month').format("MM/DD/YYYY"),
            dateFrom: moment().startOf('month').format("MM/DD/YYYY"),
            selectedAgents: null,
            modalVisible: false,
            filteredVendorSummary: [],
            summary: '',
            filtered: false,
            selectedLeadSource: null,
            arrLeadSource: [],
            selectedValues: []
        }
        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
        this.handleAgentChange = this.handleAgentChange.bind(this);
        this.handleSelectedAgentChange = this.handleSelectedAgentChange.bind(this);
        this.handleLeadSourceChange = this.handleLeadSourceChange.bind(this);
        this.handleClearFilters = this.handleClearFilters.bind(this);
        this.myRef = React.createRef();
        this.exportToCSV = this.exportToCSV.bind(this);
        this.convertArrayOfObjectsToCSV = this.convertArrayOfObjectsToCSV.bind(this);
        //this.handleFilter = this.handleFilter.bind(this);
    }
    componentDidMount() {
        const agencyId = localStorage.getItem('agencyId');
        if(agencyId == null){
            this.props.history.push('/');
        }
        const dateFrom = moment().startOf('month').format("MM/DD/YYYY");
        const dateTo = moment().endOf('month').format("MM/DD/YYYY");
        this.props.getAgent(agencyId);
        this.props.getLeadSource(agencyId);
        this.props.getDNQReport(agencyId, dateFrom, dateTo, '');
        this.props.getVendorTypes();
        this.props.getTypeVendorTypes(agencyId);
        this.props.getTypeVendorTypeLeadSources(agencyId);
    }

    handleDateRangeChange(dates, dateStrings) {
        const dateFrom = dateStrings[0];
        const dateTo = dateStrings[1];
        const agencyId = localStorage.getItem('agencyId');
        const agent = this.state.agent == null ? '' : this.state.agent;
        if(dateFrom != ''){
            this.props.getDNQReport(agencyId, dateFrom, dateTo, agent);
        }
        this.setState({
            dateFrom: dateFrom,
            dateTo: dateTo, filtered: true, filteredVendorSummary: []
        });
    }

    handleAgentChange = (e) => {
        this.setState({agent: e});
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo;
        const agencyId = localStorage.getItem('agencyId');
        this.props.getDNQReport(agencyId, dateFrom, dateTo, e);
    }

    handleOpenModal = () => {
        this.setState({ modalVisible: true });
    }

    handleCloseModal = () => {
        this.setState({
            modalVisible: false,
        });
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo;
        const agencyId = localStorage.getItem('agencyId');
        const selectedAgents = this.state.selectedAgents;
        this.props.getDNQReport(agencyId, dateFrom, dateTo, selectedAgents);
    }

    handleSelectedAgentChange(value){
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo;
        const agencyId = localStorage.getItem('agencyId');
        this.props.getDNQReport(agencyId, dateFrom, dateTo, value);
        this.setState({agent: value, filtered: true, filteredVendorSummary: []});
    }

    handlePanelChange = (open) => {
        if(open){
            this.setState({dateFrom: null, dateTo: null})
        }
    }

    handleLeadSourceChange(value){
        var leadSourceList = '';
        var arrLeadSource = [];
        for (var i=0; i < value.length; i++){
            if(value[i].length > 1){
                leadSourceList += leadSourceList == '' ? value[i][1] : ',' + value[i][1];
                arrLeadSource.push(value[i][1]);
            } else {
                var fullList = this.props.typeVendorTypesLeadSources.filter(l => l.value == value[i][0]);
                var children = fullList[0].children;
                for (var x=0; x < children.length; x++){
                    leadSourceList += leadSourceList == '' ? children[x].value : ',' + children[x].value;
                    arrLeadSource.push(children[x].value);
                }
            }
        }
        const filteredSummary = this.props.dnqReport.filter((el) => {
            return arrLeadSource.some((f) => {
                return f == el.typeQuoteLogLeadSourceId;
            });
        });
        console.log(arrLeadSource);
        this.setState({filteredVendorSummary: filteredSummary, filtered: true, arrLeadSource: arrLeadSource, selectedValues: value});
    }
    handleClearFilters(){
        const agencyId = localStorage.getItem('agencyId');
        const dateFrom = moment().startOf('month').format("MM/DD/YYYY");
        const dateTo = moment().endOf('month').format("MM/DD/YYYY");
        this.props.getDNQReport(agencyId, dateFrom, dateTo, '');
        
        this.setState({filteredVendorSummary: [], dateFrom: dateFrom, dateTo: dateTo, filtered: false, agent: '', arrLeadSource: [], selectedValues: []});
    }
    exportToCSV = (e) => {
        e.preventDefault();
        var data, filename, link;
        data = this.state.selectedLeadSource == null ? this.props.dnqReport : this.props.dnqReport.filter(v => v.vendorType == this.state.selectedLeadSource)
        var csv = this.convertArrayOfObjectsToCSV(data, this.props.leadSource);
        if (csv == null)
          return;
    
        filename = 'DNQReport' + this.state.dateFrom + '-' + this.state.dateTo + '.csv';
    
        var blob = new Blob([csv], {type: "text/csv;charset=utf-8;"});
    
        if (navigator.msSaveBlob)
        { // IE 10+
          navigator.msSaveBlob(blob, filename)
        }
        else
        {
          var link = document.createElement("a");
          if (link.download !== undefined)
          {
            // feature detection, Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style = "visibility:hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
    
        //console.log(arrOrders);
      }
    
    convertArrayOfObjectsToCSV = (args, leadSource) => {
        var result, ctr, keys, columnDelimiter, lineDelimiter, data;
    
        data = args || null;
        if (data == null || !data.length) {
            return null;
        }
    
        columnDelimiter = args.columnDelimiter || ',';
        lineDelimiter = args.lineDelimiter || '\n';
    
        keys = Object.keys(data[0]);
    
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;
    
        data.forEach(function(item) {
            ctr = 0;
            keys.forEach(function(key) {
                if (ctr > 0) result += columnDelimiter;
                if(key == 'vendor'){
                    var leadSourceName = leadSource.filter(l => l.id == item[key]).length == 0 ? item[key] : leadSource.find(l => l.id == item[key]).name
                    result += leadSourceName;
                }else{
                    result += item[key];
                }
                ctr++;
            });
            result += lineDelimiter;
        });
    
        return result;
    };

    render() {
        const filterLeadSource = [];
        this.props.leadSource.filter(l => l.inactive == false).map (ls =>
            filterLeadSource.push({
                text: ls.name,
                value: ls.id
            })
        );
        
        const dnqReport = this.state.arrLeadSource.length > 0 ? this.props.dnqReport.filter((el) => {
            return this.state.arrLeadSource.some((f) => {
                return f == el.typeQuoteLogLeadSourceId;
            });
        }) : this.props.dnqReport;

        let totalQuotedPolicies = 0;
        let totalDNQForQuote = 0;
        let totalHomeAutoDNQ = 0;
        let totalAutoHomeDNQ = 0;
        console.log(dnqReport);
        dnqReport &&
            dnqReport.forEach(({ quotedPolicies, dnqForQuote, homeAutoDNQ, autoHomeDNQ }) => {
                totalQuotedPolicies += quotedPolicies;
                totalDNQForQuote += dnqForQuote;
                totalHomeAutoDNQ += homeAutoDNQ;
                totalAutoHomeDNQ += autoHomeDNQ;
            });
            
            const summaryTable = (
                <table width='100%'>
                    <tr style={{backgroundColor: '#3D76DD', fontWeight: 'bold'}}>
                        <th></th>
                        <td style={{textAlign: 'center'}} key="totalQuotedPoliciesHeader">
                            <Text style={{color: '#fff'}}>Quoted Policies</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="totalDNQForQuoteHeader">
                            <Text style={{color: '#fff'}}>Total Policies DNQ</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="percentDNQForQuoteHeader">
                            <Text style={{color: '#fff'}}>Percentage DNQ</Text>
                        </td>
                        {/* <td style={{textAlign: 'center'}} key="totalHomeAutoDNQHeader">
                            <Text style={{color: '#fff'}}>Quoted Dwelling - Auto DNQ</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="percentHomeAutoDNQHeader">
                            <Text style={{color: '#fff'}}>Percent</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="totalAutoHomeDNQHeader">
                            <Text style={{color: '#fff'}}>Quoted Auto - Dwelling DNQ</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="percentAutoHomeDNQHeader">
                            <Text style={{color: '#fff'}}>Percent</Text>
                        </td> */}
                    </tr>
                    <tr ref={this.myRef} key="totalRow" style={{fontWeight: 'bold'}}>
                        <th>Total</th>
                        <td style={{textAlign: 'center'}} key="totalQuotedPolicies">
                            <Text>{totalQuotedPolicies}</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="totalDNQForQuote">
                            <Text>{totalDNQForQuote}</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="percentDNQForQuote">
                            <Text>{Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(totalDNQForQuote / totalQuotedPolicies)}</Text>
                        </td>
                        {/* <td style={{textAlign: 'center'}} key="totalHomeAutoDNQ">
                            <Text>{totalHomeAutoDNQ}</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="percentHomeAutoDNQ">
                            <Text>{Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(totalHomeAutoDNQ / totalQuotedPolicies)}</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="totalAutoHomeDNQ">
                            <Text>{totalAutoHomeDNQ}</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="percentAutoHomeDNQ">
                            <Text>{Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(totalAutoHomeDNQ / totalQuotedPolicies)}</Text>
                        </td> */}
                    </tr>
                </table>
        )
        const dnqReportColumns = [
            {
                title: 'Lead Source',
                dataIndex: 'leadSource',
                key: 'vendor',
                sorter: (a, b) => ('' + a.vendor).localeCompare(b.vendor),
                //render: (text, record) => this.props.leadSource.filter(l => l.id == text).length == 0 ? text : this.props.leadSource.find(l => l.id == text).name,
                filters: filterLeadSource,
                filterMultiple: true,
                onFilter: (value, record) => record.vendor.indexOf(value) === 0,
            },
            {
                title: 'Marketing Bucket',
                dataIndex: 'type',
                key: 'vendorType',
                sorter: (a, b) => ('' + a.vendorType).localeCompare(b.vendorType),
            },
            {
                title: 'Total Quoted Policies',
                dataIndex: 'quotedPolicies',
                key: 'quotedPolicies',
                align: 'center',
                sorter: (a, b) => a.quotedPolicies - b.quotedPolicies,
            },
            {
                title: 'Total Policies DNQ',
                dataIndex: 'dnqForQuote',
                key: 'dnqForQuote',
                align: 'center',
                sorter: (a, b) => a.dnqForQuote - b.dnqForQuote,
            },
            {
                title: 'Percentage DNQ',
                dataIndex: 'dnqForQuote',
                key: 'dnqForQuotePercent',
                align: 'center',
                render: (text, record) => new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(text / record.quotedPolicies),
                //sorter: (a, b) => a.dnqForQuote - b.dnqForQuote,
            },
            // {
            //     title: 'Quoted Dwelling - Auto DNQ',
            //     dataIndex: 'homeAutoDNQ',
            //     key: 'homeAutoDNQ',
            //     align: 'center',
            //     sorter: (a, b) => a.homeAutoDNQ - b.homeAutoDNQ,
            // },
            // {
            //     title: 'Percent',
            //     dataIndex: 'homeAutoDNQ',
            //     key: 'homeAutoDNQPercent',
            //     align: 'center',
            //     render: (text, record) => new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(text / record.quotedPolicies),
            //     //sorter: (a, b) => a.dnqForQuote - b.dnqForQuote,
            // },
            // {
            //     title: 'Quoted Auto - Dwelling DNQ',
            //     dataIndex: 'autoHomeDNQ',
            //     key: 'autoHomeDNQ',
            //     align: 'center',
            //     sorter: (a, b) => a.autoHomeDNQ - b.autoHomeDNQ,
            // },
            // {
            //     title: 'Percent',
            //     dataIndex: 'autoHomeDNQ',
            //     key: 'autoHomeDNQPercent',
            //     align: 'center',
            //     render: (text, record) => new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(text / record.quotedPolicies),
            //     //sorter: (a, b) => a.dnqForQuote - b.dnqForQuote,
            // },
        ];
        
        return (
            <div>
                <Row>
                    <Col sm={8}>
                        <Title level={3}>DNQ Report</Title>
                    </Col>
                </Row>
                {this.props.isQuoteLogLoading ?<div style={{textAlign: 'center'}}> <Spin></Spin><p><Title level={3}>Loading report.  Please do not click the refresh button...</Title></p></div>:
                <div>
                <Row>
                    <Col sm={5}>
                        <RangePicker format={'MM/DD/YYYY'} allowEmpty={true}
                            ranges={{
                                Today: [moment(), moment()],
                                'This Week': [moment().startOf('week'), moment().endOf('week')],
                                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            }}
                            defaultValue={[moment().startOf('month'), moment().endOf('month')]}
                            value={this.state.dateFrom == null ? [] : [moment(this.state.dateFrom), moment(this.state.dateTo)]}
                            onChange={this.handleDateRangeChange}
                            onOpenChange={this.handlePanelChange}
                        />
                    </Col>
                    <Col sm={5}>
                        <Select
                            virtual={false}
                            style={{ width: '90%' }}
                            dropdownStyle={{ minWidth: "20%" }}
                            placeholder={<strong style={{color: 'black'}}>Select Agent</strong>}
                            name="agents"
                            value={this.state.agent}
                            onChange={this.handleSelectedAgentChange}>
                                <Option key='' value=''></Option>
                            {
                                this.props.agent.filter(a => a.status != "5").map(a => 
                                    <Option key={a.firstName} value={a.firstName}>{a.firstName}</Option>
                                )
                            }
                        </Select>
                    </Col>
                    <Col sm={5}>
                        <Cascader
                            style={{ width: '90%' }}
                            options={this.props.typeVendorTypesLeadSources}
                            onChange={this.handleLeadSourceChange}
                            multiple
                            maxTagCount="responsive"
                            placeholder={<strong style={{color: 'black'}}>Select Lead Source</strong>}
                            value={this.state.selectedValues}
                        />
                    </Col>
                    <Col sm={5}>
                        <Button shape="round" onClick={() => this.handleClearFilters()} style={{color: this.state.filtered ? 'Blue' : 'Black'}}>
                            <FunnelPlotOutlined /> Clear Filters
                        </Button>
                    </Col>
                    <Col sm={4}>
                        <Button shape="round" onClick={this.exportToCSV} style={{position: 'absolute', right: 0}}>
                            <DownloadOutlined /> Export
                        </Button>
                    </Col>
                </Row>
                
                <Row style={{paddingTop: 10}}>
                    <Col span={24}>
                        {summaryTable}
                        <Table dataSource={dnqReport} columns={dnqReportColumns} pagination={false}></Table>
                    </Col>
                </Row>
                </div>}
        </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(DNQReport);                   