import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Card, Input, InputNumber, Row, Col, Typography, Select, Button, DatePicker, Table, Collapse, Radio, Space, Modal, Checkbox, Spin, Alert } from 'antd';
import { SearchOutlined, SaveOutlined, DeleteOutlined, PlusCircleOutlined, StarFilled, EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import SoldQuoteLog from './SoldQuoteLog';
import ActivitySummary from './ActivitySummary';
const { Search } = Input;
const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Text } = Typography;


class QuoteLogEntry extends Component {
    constructor(props) {
      super(props);
        this.state = {
            id: 0,
            date: moment().format("MM/DD/YYYY"),
            agent: '',
            status: '',
            originalStatus: '',
            firstName: '',
            lastName: '',
            leadSource: '',
            dwelling: [],
            auto: 0,
            specialty: [],
            objection: 'N/A',
            ineligible: 'N/A',
            notes: '',
            dateFrom: this.props.dateFrom,
            dateTo: this.props.dateTo,
            tablePageSize: 50,
            searchFilter: 'date',
            openSearch: false,
            zip: '',
            invalid: false,
            visible: false,
            selectedDwelling: [],
            selectedSpecialty: [],
            selectedDwellingCnt: [],
            selectedSpecialtyCnt: [],
            dwellingCheckboxes: [],
            dwellingSoldCheckboxes: [],
            specialtyCheckboxes: [],
            specialtySoldCheckboxes: [],
            emProductCheckboxes: [],
            emProductSoldCheckboxes: [],
            soldEffectiveDate: moment().format("MM/DD/YYYY"),
            soldBindDate: moment().format("MM/DD/YYYY"),
            soldAuto: -1,
            soldCommercialAuto: 0,
            autoPremium: '',
            quoteLogSoldId: 0,
            isSold: false,
            soldBundle: false,
            autoVisible: false,
            commercialAutoVisible: false,
            commercialAuto: 0,
            commercialAutoPremium: '',
            typeVendorTypeId: 0,
            isZipValid: true,
            selfGenerated: false,
            insuranceScore: 0,
            currentCarrier: "",
            insuranceScoreAuto: 0,
            currentCarrierAuto: "",
            showIneligible: false,
            agentId: 0,
            editable: true,
            referringAgentId: 0,
            bundle: false,
            invalidModalVisible: false,
            requote: false,
            productFilter: 'Allstate',
            selectedCarrier: ''
        }
        this.handleAgentChange = this.handleAgentChange.bind(this);
        this.handleLeadSourceChange = this.handleLeadSourceChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleObjectionChange = this.handleObjectionChange.bind(this);
        this.handleIneligibleChange = this.handleIneligibleChange.bind(this);
        this.handleDwellingChange = this.handleDwellingChange.bind(this);
        this.handleSpecialtyChange = this.handleSpecialtyChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleNumberChange = this.handleNumberChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleDwellingNumberChange = this.handleDwellingNumberChange.bind(this);
        this.handleSpecialtyNumberChange = this.handleSpecialtyNumberChange.bind(this);
        this.handleDwellingSoldChange = this.handleDwellingSoldChange.bind(this);
        this.handleSpecialtySoldChange = this.handleSpecialtySoldChange.bind(this);
        this.handleSoldChange = this.handleSoldChange.bind(this);
        this.handleChooseAuto = this.handleChooseAuto.bind(this);
        this.handleChooseCommercialAuto = this.handleChooseCommercialAuto.bind(this);
        this.handleCheckZipCode = this.handleCheckZipCode.bind(this);
        this.handleInsuranceScoreChange = this.handleInsuranceScoreChange.bind(this);
        this.handleSelfGeneratedChange = this.handleSelfGeneratedChange.bind(this);
        this.handleCurrentCarrierChange = this.handleCurrentCarrierChange.bind(this);
        this.handleInsuranceScoreAutoChange = this.handleInsuranceScoreAutoChange.bind(this);
        this.handleCurrentCarrierAutoChange = this.handleCurrentCarrierAutoChange.bind(this);
        this.handleReferringAgentChange = this.handleReferringAgentChange.bind(this);
        this.handleRequoteChange = this.handleRequoteChange.bind(this);
        this.handleProductsRadioChange = this.handleProductsRadioChange.bind(this);
        this.handleCarrierChange = this.handleCarrierChange.bind(this);
        this.handleEMProductChange = this.handleEMProductChange.bind(this);
        this.handleEMProductNumberChange = this.handleEMProductNumberChange.bind(this);
    }
    
    componentDidMount() {
        const agencyId = localStorage.getItem('agencyId');
        this.props.getAgent(agencyId);
        this.props.getLeadSource(agencyId);
        this.props.getSpecialty();
        this.props.getObjection();
        this.props.getStatus();
        this.props.getDwelling();
        this.props.getIneligible();
        this.props.getReferringAgent(agencyId);
        this.props.getTypeVendorTypes(agencyId);
        this.props.getEMCarriers(agencyId);
        this.props.getEMProductCheckboxes(agencyId);
    }
    componentDidUpdate(prevProps){
        if(this.props.visible != prevProps.visible){
            console.log(this.props.selectedQuoteLog);
            this.setState({visible: this.props.visible})
            if(this.props.selectedQuoteLog.id != undefined && this.props.visible == true){
                var quoteLog = this.props.selectedQuoteLog
                console.log("referring agent:" + quoteLog.referringAgentId)
                this.setState({
                    id: quoteLog.id,
                    date: moment(quoteLog.dts).format("MM/DD/YYYY"),
                    agent: quoteLog.agent,
                    agentId: quoteLog.agentId,
                    originalStatus: quoteLog.status, 
                    status: quoteLog.status,
                    firstName: quoteLog.firstName,
                    lastName: quoteLog.lastName,
                    leadSource: isNaN(quoteLog.leadSource) || quoteLog.leadSource == '' ? quoteLog.leadSource : this.props.leadSource.find(l => l.id == quoteLog.leadSource).name,
                    auto: quoteLog.auto,
                    autoVisible: quoteLog.auto > 0,
                    commercialAutoVisible: quoteLog.commercialAuto > 0,
                    commercialAuto: quoteLog.commercialAuto,
                    objection: quoteLog.objection,
                    ineligible: quoteLog.ineligible,
                    notes: quoteLog.notes,
                    zip: quoteLog.zip,
                    typeVendorTypeId: !isNaN(quoteLog.leadSource) & quoteLog.leadSource != ''  && this.props.leadSource.find(l => l.id == quoteLog.typeQuoteLogLeadSourceId).vendorType,
                    selfGenerated: quoteLog.selfGenerated,
                    insuranceScore: quoteLog.insuranceScore,
                    currentCarrier: quoteLog.currentCarrier,
                    insuranceScoreAuto: quoteLog.insuranceScoreAuto,
                    currentCarrierAuto: quoteLog.currentCarrierAuto,
                    referringAgentId: quoteLog.referringAgentId,
                    bundle: quoteLog.bundle,
                    requote: quoteLog.requote
                });
                if(quoteLog.id > 0){
                    this.props.getQuoteLogDwellings(quoteLog.id);
                    this.props.getQuoteLogSpecialty(quoteLog.id);
                }else{
                    this.setState({dwellingCheckboxes: quoteLog.dwelling, specialtyCheckboxes: quoteLog.specialty});
                }
                if(quoteLog.status == 'SOLD') {// && (agencyId == '1168673106' || agencyId == '1246366855' || agencyId == '1677401557')) {
                    this.props.getQuoteLogSold(quoteLog.id);
                }else{
                    if(quoteLog.id > 0){
                        this.setState(
                        {
                            specialtyCheckboxes: [],
                            dwellingCheckboxes: [],
                            effectiveDate: moment().format("MM/DD/YYYY"),
                            bindDate: moment().format("MM/DD/YYYY"),
                            soldAuto: 0,
                            autoPremium: '',
                            quoteLogSoldId: 0,
                            isSold: false,
                            editable: true,
                        });
                    }
                }
            }
        }
        if(this.props.dwelling != prevProps.dwelling){
            this.setState({dwellingCheckboxes: this.props.dwelling});
        }
        if(this.props.specialty != prevProps.specialty){
            this.setState({specialtyCheckboxes: this.props.specialty});
        }
        if(this.props.emProductCheckboxes != prevProps.emProductCheckboxes){
            this.setState({emProductCheckboxes: this.props.emProductCheckboxes});
        }
        if(this.props.quoteLogSold != prevProps.quoteLogSold && this.props.visible == true){
            this.setState(
                {
                    //specialtyCheckboxes: this.props.quoteLogSold.specialtySoldCheckboxes,
                    //dwellingCheckboxes: this.props.quoteLogSold.dwellingSoldCheckboxes,
                    specialtySoldCheckboxes: this.props.quoteLogSold.specialtySoldCheckboxes,
                    dwellingSoldCheckboxes: this.props.quoteLogSold.dwellingSoldCheckboxes,
                    effectiveDate: this.props.quoteLogSold.effectiveDate,
                    bindDate: this.props.quoteLogSold.bindDate,
                    soldAuto: this.props.quoteLogSold.auto,
                    autoPremium: this.props.quoteLogSold.autoPremium,
                    quoteLogSoldId: this.props.quoteLogSold.id,
                    soldBundle: this.props.quoteLogSold.bundle,
                    isSold: true,
                    editable: false,
                    soldCommercialAuto: this.props.quoteLogSold.commercialuto,
                    commercialAutoPremium: this.props.quoteLogSold.commercialAutoPremium,
                });
        }
    }
    handleStateChange = (e) => {
        this.setState({state: e});
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handleVendorTypeChange = (e) => {
        this.setState({vendorType: e});
    }
    handleNumberChange = (e, name) => {
        this.setState({
            [name]: e
        })
    }
    handleDateChange(date, dateString) {
        this.setState({
            date: date == null ? moment().format("MM/DD/YYYY") : date
        });
    }
    handleAgentChange = (e, option) => {
        this.setState({agent: e, agentId: option.key});
    }
    handleReferringAgentChange = (e) => {
        console.log(e);
        this.setState({referringAgentId: e});
    }
    handleLeadSourceChange(id) {
        console.log(id);
        var leadSource = this.props.leadSource.filter(l => l.id == id);
        console.log(leadSource);
        this.setState({leadSource: leadSource[0].name, typeVendorTypeId: leadSource[0].vendorType, bundle: leadSource[0].autoBundle, soldBundle: leadSource[0].autoBundle});
    }
    handleStatusChange = (e) => {
        const agencyId = localStorage.getItem('agencyId');
        this.setState({status: e});
        if(e == 'SOLD'){
            this.setState({isSold: true, soldAuto: this.props.auto});
        //&& this.isQuoteLogValid(e)) {
             
        // } else if (e == 'SOLD' && !this.isQuoteLogValid(e)){
        //     this.setState({isSold: false, editable: true, invalid: true, status: "Warm"});
        } else {
             this.setState({isSold: false, editable: true});
        }
        
        if(this.state.originalStatus == 'SOLD' && e != 'SOLD')
        {
            this.setState({originalStatus: e})
        }
    }
    handleObjectionChange = (e) => {
        this.setState({objection: e});
        if(e == "ALL LINES DNQ" || e == "QUOTED DWELLING - ( AUTO DNQ )" || e == "QUOTED AUTO - ( DWELLING DNQ )"){
            this.setState({showIneligible: true, ineligible: "INCIDENTS"});
        }
        else{
            this.setState({showIneligible: false, ineligible: "N/A"});
        }
    }
    handleIneligibleChange = (e) => {
        this.setState({ineligible: e});
    }
    handleSpecialtyChange = (e) => {
        const data = this.state.specialtyCheckboxes;
        const { value, checked } = e.target;
        const updatedData = data.map(group => {
        if (group.id === value) {
            return {
            ...group,
            checked: group.checked === checked ? null : checked,
            cnt: 1
            };
        } else {
            return group;
        }
        });

        this.setState({ specialtyCheckboxes: updatedData }, () => console.log(this.state.specialtyCheckboxes));
    }
    handleSpecialtyNumberChange = (e, id) => {
        const data = this.state.specialtyCheckboxes;
        const updatedData = data.map(group => {
        if (group.id === id) {
            return {
            ...group,
            cnt: e
            };
        } else {
            return group;
        }
        });

        this.setState({ specialtyCheckboxes: updatedData }, () => console.log(this.state.specialtyCheckboxes));
    }
    handleDwellingChange = (e) => {
        const data = this.state.dwellingCheckboxes;
        const { value, checked } = e.target;
        const updatedData = data.map(group => {
        if (group.id === value) {
            return {
            ...group,
            checked: group.checked === checked ? null : checked,
            cnt: 1
            };
        } else {
            return group;
        }
        });

        this.setState({ dwellingCheckboxes: updatedData }, () => console.log(this.state.dwellingCheckboxes));
    }
    handleDwellingSoldChange = (e) => {
        this.setState({ dwellingSoldCheckboxes: e }, () => console.log(this.state.dwellingSoldCheckboxes));
    }
    handleSpecialtySoldChange = (e) => {
        this.setState({ specialtySoldCheckboxes: e }, () => console.log(this.state.specialtySoldCheckboxes));
    }
    handleDwellingNumberChange = (e, id) => {
        const data = this.state.dwellingCheckboxes;
        const updatedData = data.map(group => {
        if (group.id === id) {
            return {
            ...group,
            cnt: e
            };
        } else {
            return group;
        }
        });

        this.setState({ dwellingCheckboxes: updatedData }, () => console.log(this.state.dwellingCheckboxes));
    }
    clearDwellingCheckboxes = () => {
        const data = this.state.dwellingCheckboxes;
        const updatedData = data.map(group => {
        return {
            ...group,
            checked: false,
            cnt: 0,
            };
        });
        this.setState({ dwellingCheckboxes: updatedData }, () => console.log(this.state.dwellingCheckboxes));
    }
    clearSpecialtyCheckboxes = () => {
        const data = this.state.specialtyCheckboxes;
        const updatedData = data.map(group => {
        return {
            ...group,
            checked: false,
            cnt: 0
            };
        });
        this.setState({ specialtyCheckboxes: updatedData }, () => console.log(this.state.specialtyCheckboxes));
    }
    clearDwellingSoldCheckboxes = () => {
        const data = this.state.dwellingSoldCheckboxes;
        const updatedData = data.map(group => {
        return {
            ...group,
            checked: false,
            cnt: 0,
            premium: ''
            };
        });
        this.setState({ dwellingSoldCheckboxes: updatedData }, () => console.log(this.state.dwellingSoldCheckboxes));
    }
    clearSpecialtySoldCheckboxes = () => {
        const data = this.state.specialtySoldCheckboxes;
        const updatedData = data.map(group => {
        return {
            ...group,
            checked: false,
            cnt: 0,
            premium: ''
            };
        });
        this.setState({ specialtySoldCheckboxes: updatedData }, () => console.log(this.state.specialtySoldCheckboxes));
    }
    handleEMProductChange = (e) => {
        console.log(e);
        const data = this.state.emProductCheckboxes;
        const { value, checked } = e.target;
        const updatedData = data.map(group => {
        if (group.emProductId === value) {
            return {
            ...group,
            checked: group.checked === checked ? null : checked,
            cnt: 1
            };
        } else {
            return group;
        }
        });

        this.setState({ emProductCheckboxes: updatedData }, () => console.log(this.state.emProductCheckboxes));
    }
    handleEMProductNumberChange = (e, id) => {
        const data = this.state.emProductCheckboxes;
        const updatedData = data.map(group => {
        if (group.emProductId === id) {
            return {
            ...group,
            cnt: e
            };
        } else {
            return group;
        }
        });

        this.setState({ emProductCheckboxes: updatedData }, () => console.log(this.state.emProductCheckboxes));
    }
    handleSave(event) {
        event.preventDefault();
        const id = this.state.id;
        const date = this.state.date;
        const agent = this.state.agent;
        const agentId = this.state.agentId;
        const status = this.state.status;
        const firstName = this.state.firstName;
        const lastName = this.state.lastName;
        const leadSource = this.state.leadSource;
        const dwelling = this.state.dwellingCheckboxes;
        const auto = this.state.auto;
        const commercialAuto = this.state.commercialAuto;
        const specialty = this.state.specialtyCheckboxes;
        const objection = this.state.objection;
        const ineligible = this.state.ineligible;
        const notes = this.state.notes;
        const agencyId = localStorage.getItem('agencyId');
        const dateFrom = this.props.dateFrom;
        const dateTo = this.props.dateTo;
        const zip = this.state.zip;
        const dwellingCnt = this.state.dwellingCheckboxes.filter(s => s.checked).length;
        const autoCnt = this.state.auto > 0 ? 1 : 0;
        const specialtyCnt = this.state.specialtyCheckboxes.filter(s => s.checked).length;
        const reportFilter = this.props.type;
        const typeQuoteLogLeadSourceId = this.props.leadSource.find(l => l.name == leadSource).id;
        const insuranceScore = this.state.insuranceScore;
        const selfGenerated = this.state.selfGenerated;
        const currentCarrier = this.state.currentCarrier;
        const insuranceScoreAuto = this.state.insuranceScoreAuto;
        const currentCarrierAuto = this.state.currentCarrierAuto;
        const referringAgentId = this.state.referringAgentId;
        const emProducts = this.state.emProductCheckboxes;
        var bundle = this.state.bundle;
        const requote = this.state.requote;

        const totalProducts = dwellingCnt + specialtyCnt + autoCnt
        if(dwellingCnt > 1 || specialtyCnt > 1 || totalProducts > 1){
            bundle = 1;
        }
        if(!this.isQuoteLogValid()){
            this.setState({invalid: true, invalidModalVisible: true});
        }
        else{
            if(this.state.isSold){
                const quoteLogId = this.state.id;
                const quoteLogSoldId = this.state.quoteLogSoldId;
                const effectiveDate = this.state.soldEffectiveDate;
                const bindDate = this.state.soldBindDate;
                const soldAuto = this.state.soldAuto;
                const soldCommercialAuto = this.state.soldCommercialAuto;
                const autoPremium = this.state.autoPremium;
                const commercialAutoPremium = this.state.commercialAutoPremium;
                const soldDwelling = this.state.dwellingSoldCheckboxes;
                const soldSpecialty = this.state.specialtySoldCheckboxes;
                const soldDwellingCnt = this.state.dwellingSoldCheckboxes.filter(s => s.checked).length;
                const soldAutoCnt = this.state.soldAuto > 0 ? 1 : 0;
                const soldCommericalAutoCnt = this.state.soldCommercialAuto > 0 ? 1 : 0;
                const soldSpecialtyCnt = this.state.specialtySoldCheckboxes.filter(s => s.checked).length;
                var soldBundle = 0;
                var invalid = false;
                const soldTotalProducts = soldDwellingCnt + soldSpecialtyCnt + soldAutoCnt + soldCommericalAutoCnt
                if(soldDwellingCnt > 1 || soldSpecialtyCnt > 1 || soldTotalProducts > 1){
                    soldBundle = 1;
                }
                else{
                    soldBundle = this.state.soldBundle;
                }
                
                const soldDwellingPremium = this.state.dwellingSoldCheckboxes.filter(s => s.checked);
                var soldDwellingPremiumCnt = 0
                soldDwellingPremium.map(dw => {
                    if(dw.premium == undefined || dw.premium == 0){
                        soldDwellingPremiumCnt += 1;
                    }
                });
                const soldSpecialtyPremium = this.state.specialtySoldCheckboxes.filter(s => s.checked);
                var soldSpecialtyPremiumCnt = 0
                soldSpecialtyPremium.map(sp => {
                    if(sp.premium == undefined || sp.premium == 0){
                        soldSpecialtyPremiumCnt += 1;
                    }
                });
                const soldAutoPremiumCnt = this.state.soldAuto > 0 & autoPremium == 0 ? 1 : 0;
                const soldCommercialAutoPremiumCnt = this.state.soldCommercialAuto > 0 & commercialAutoPremium == 0 ? 1 : 0;
                
                if(soldDwellingPremiumCnt == 0 && soldAutoPremiumCnt == 0 && soldSpecialtyPremiumCnt == 0 && soldTotalProducts > 0 && soldCommercialAutoPremiumCnt == 0){
                    if(this.state.id > 0){
                        this.props.createQuoteLog(id, date, agent, 'SOLD', firstName, lastName, leadSource, dwelling, auto, specialty, objection, ineligible, notes, agencyId, dateFrom, dateTo, zip, bundle, reportFilter, typeQuoteLogLeadSourceId, commercialAuto, insuranceScore, selfGenerated, currentCarrier, insuranceScoreAuto, currentCarrierAuto, agentId, referringAgentId, requote);

                        this.props.createSoldQuoteLog(quoteLogSoldId, quoteLogId, bindDate, effectiveDate, soldAuto, autoPremium, soldDwelling, soldSpecialty, soldBundle, soldCommercialAuto, commercialAutoPremium);
                    }
                    else{
                        this.props.addQuoteLogSoldQuoteLog(bindDate, effectiveDate, soldAuto, autoPremium, soldDwelling, soldSpecialty, soldBundle, date, agent, 'SOLD', firstName, lastName, leadSource, dwelling, auto, specialty, objection, ineligible, notes, agencyId, dateFrom, dateTo, zip, bundle, reportFilter, commercialAuto, soldCommercialAuto, commercialAutoPremium, typeQuoteLogLeadSourceId, insuranceScore, selfGenerated, currentCarrier, insuranceScoreAuto, currentCarrierAuto, agentId, referringAgentId, requote);
                    }
                } else {
                    invalid = true;
                    this.setState({invalid: true});
                }
            } else {
                this.props.createQuoteLog(id, date, agent, status, firstName, lastName, leadSource, dwelling, auto, specialty, objection, ineligible, notes, agencyId, dateFrom, dateTo, zip, bundle, reportFilter, typeQuoteLogLeadSourceId, commercialAuto, insuranceScore, selfGenerated, currentCarrier, insuranceScoreAuto, currentCarrierAuto, agentId, referringAgentId, requote, emProducts);
            }
            if(!invalid){
                this.setState({
                    id: 0,
                    date: moment().format("MM/DD/YYYY"),
                    agent: '',
                    agentId: 0,
                    status: '',
                    firstName: '',
                    lastName: '',
                    leadSource: '',
                    //dwellingCheckboxes: [],
                    auto: 0,
                    //specialtyCheckboxes: [],
                    objection: 'N/A',
                    ineligible: 'N/A',
                    notes: '',
                    zip: '',
                    invalid: false,
                    visible: false,
                    isSold: false,
                    editable: true,
                    quoteLogSoldId: 0,
                    soldEffectiveDate: moment().format("MM/DD/YYYY"),
                    soldBindDate: moment().format("MM/DD/YYYY"),
                    soldAuto: 0,
                    autoPremium: 0,
                    soldBundle: false,
                    commercialAuto: 0,
                    soldCommercialAuto: 0,
                    commercialAutoPremium: '',
                    currentCarrier: "",
                    insuranceScore: 0,
                    currentCarrierAuto: "",
                    insuranceScoreAuto: 0,
                    showIneligible: false,
                    selfGenerated: false,
                    requote: false,
                    referringAgentId: 0,
                    bundle: false
                });
                this.clearDwellingCheckboxes();
                this.clearSpecialtyCheckboxes();
                this.clearDwellingSoldCheckboxes();
                this.clearSpecialtySoldCheckboxes();
                this.props.onVisibleChange(status);
            }
        }
    }
    handleDelete(event) {
        event.preventDefault();
        const id = this.state.id;
        const dateFrom = this.props.dateFrom;
        const dateTo = this.props.dateTo;
        const agencyId = localStorage.getItem('agencyId');

        this.props.deleteQuoteLog(id, dateFrom, dateTo, agencyId);

        this.setState({
            id: 0,
            date: moment().format("MM/DD/YYYY"),
            agent: '',
            status: '',
            firstName: '',
            lastName: '',
            leadSource: '',
            //dwellingCheckboxes: [],
            auto: 0,
            //specialtyCheckboxes: [],
            objection: '',
            ineligible: '',
            notes: '',
            zip: '',
            invalid: false,
            visible: false,
            isSold: false,
            editable: true,
            quoteLogSoldId: 0,
            soldEffectiveDate: moment().format("MM/DD/YYYY"),
            soldBindDate: moment().format("MM/DD/YYYY"),
            soldAuto: 0,
            autoPremium: 0,
            soldBundle: false,
            commercialAuto: 0,
            soldCommercialAuto: 0,
            commercialAutoPremium: '',
            referringAgentId: 0,
            bundle: false,
            requote: false
        });
        this.clearDwellingCheckboxes();
        this.clearSpecialtyCheckboxes();
        this.clearDwellingSoldCheckboxes();
        this.clearSpecialtySoldCheckboxes();
        this.props.onVisibleChange("deleted");
    }
    handleOpenModal = () => {
        this.setState({ visible: true });
        this.props.onVisibleChange("opened");
        console.log('ID: ' + this.state.id);
    }
    handleCloseModal = () => {
        this.setState({
            id: 0,
            date: moment().format("MM/DD/YYYY"),
            agent: '',
            status: '',
            firstName: '',
            lastName: '',
            leadSource: '',
            //dwellingCheckboxes: [],
            auto: 0,
            //specialtyCheckboxes: [],
            objection: '',
            ineligible: '',
            notes: '',
            zip: '',
            invalid: false,
            visible: false,
            isSold: false,
            editable: true,
            quoteLogSoldId: 0,
            soldEffectiveDate: moment().format("MM/DD/YYYY"),
            soldBindDate: moment().format("MM/DD/YYYY"),
            soldAuto: 0,
            autoPremium: 0,
            soldBundle: false,
            commercialAuto: 0,
            soldCommercialAuto: 0,
            commercialAutoPremium: '',
            referringAgentId: 0,
            bundle: false,
            requote: false
        });
        this.clearDwellingCheckboxes();
        this.clearSpecialtyCheckboxes();
        this.clearDwellingSoldCheckboxes();
        this.clearSpecialtySoldCheckboxes();
        this.props.onVisibleChange("closed");
    } 
    
    handleSoldChange(name, e) {
        this.setState({[name]: e});
        //this.setState({soldAuto: e.auto, soldAutoPremium: e.premiumAuto, soldBindDate: e.bindDate, soldEffectiveDate: e.effectiveDate})
    }
    handleBundleChange(e) {
        console.log(e.target.checked);
        this.setState({bundle: e.target.checked});
    }
    isQuoteLogValid(s){
        const id = this.state.id;
        const date = this.state.date;
        const agent = this.state.agent;
        const status = this.state.status === undefined ? s : this.state.status;
        const firstName = this.state.firstName;
        const lastName = this.state.lastName;
        const leadSource = this.state.leadSource;
        const dwelling = this.state.dwellingCheckboxes.filter(s => s.checked).length;
        const auto = isNaN(this.state.auto) ? 0 : this.state.auto;
        const specialty = this.state.specialtyCheckboxes.filter(s => s.checked).length;
        const objection = this.state.objection;
        const ineligible = this.state.ineligible;
        const notes = this.state.notes;
        const agencyId = localStorage.getItem('agencyId');
        const dateFrom = this.props.dateFrom;
        const dateTo = this.props.dateTo;
        const zip = this.state.zip;
        const commercialAuto = this.state.commercialAuto;
        const emProducts = this.state.emProductCheckboxes.filter(s => s.checked).length;
        const totalProducts = dwelling + specialty + auto + commercialAuto + emProducts
        
        if(date === undefined | agent === undefined | status === undefined | firstName === undefined | lastName === undefined | leadSource === undefined | objection === undefined | ineligible === undefined | zip === undefined 
            | date === "" | agent === "" | status === "" | firstName === "" | lastName === "" | leadSource === ""| objection === "" | ineligible === "" | zip === "" | zip.length > 5 | zip.length < 5 | totalProducts == 0){
             return false;
        }
        else{
            return true;
        }
    }
    handleChooseAuto(e){
        this.setState({autoVisible: e.target.checked, auto: e.target.checked == true ? 1 : 0 });
    }
    handleChooseCommercialAuto(e){
        this.setState({commercialAutoVisible: e.target.checked, commercialAuto: e.target.checked == true ? 1 : 0 });
    }
    handleCheckZipCode(e){
        if(e.target.value.length > 5 | e.target.value.length < 5){
            this.setState({isZipValid: false})
        } else {
            this.setState({isZipValid: true});
        } 
    }
    handleInsuranceScoreChange = (e) => {
        this.setState({insuranceScore: e});
    }
    handleCurrentCarrierChange = (e) => {
        this.setState({currentCarrier: e});
    }
    handleInsuranceScoreAutoChange = (e) => {
        this.setState({insuranceScoreAuto: e});
    }
    handleCurrentCarrierAutoChange = (e) => {
        this.setState({currentCarrierAuto: e});
    }
    handleSelfGeneratedChange(e) {
        this.setState({selfGenerated: e.target.checked});
    }
    handleRequoteChange(e) {
        this.setState({requote: e.target.checked});
    }
    handleProductsRadioChange(e) {
        this.setState({productFilter: e.target.value})
    }
    handleCarrierChange = (e) => {
        this.setState({selectedCarrier: e});
    }
    render() {
        const insCarriersList = require('../data/inscarriers.json');
        const filterLeadSource = [];

        this.props.leadSource.map (ls =>
            filterLeadSource.push({
                text: ls.name,
                value: ls.name
            })
        );
        const userStatus = localStorage.getItem('status');
        const insuranceScores = [];
        for(let i=0; i <= 99; i++){
            insuranceScores.push(i);
        }
        var showBundle = true;
        const dwellingCnt = this.state.dwellingCheckboxes.filter(s => s.checked).length;
        const autoCnt = this.state.auto > 0 ? 1 : 0;
        const specialtyCnt = this.state.specialtyCheckboxes.filter(s => s.checked).length;
        const totalProducts = dwellingCnt + specialtyCnt + autoCnt
        if(dwellingCnt > 1 || specialtyCnt > 1 || totalProducts > 1){
            showBundle = false;
        }
        return (
            <div>
            
                <Row>
                    <Col span={4} style={{fontWeight: 'bold'}}>
                        <Button shape="round" type='primary' size='large' onClick={() => this.handleOpenModal()} style={{fontWeight: 'bold', overflow: "hidden", boxShadow: '5px 5px 5px #ccc', display: this.props.hideButton ? 'none' : 'block' }}><PlusCircleOutlined /> ADD QUOTED PROSPECTS</Button>
                    </Col>
                </Row>
                <Modal
                    width="75%"
                    visible={this.state.visible}
                    title="ADD QUOTED PROSPECT"
                    onOk={this.handleOk}
                    onCancel={this.handleCloseModal}
                    footer={[
                        <Row style={{paddingBottom: 10}}>
                            <Col sm={8}>
                                <Button style={{ float: 'left', fontWeight: 'bold' }} type="primary" shape="round" onClick={this.handleSave}>
                                    <SaveOutlined /> Submit
                                </Button>
                                <p><Text style={{ float: 'left' }} type="danger" id="error">{this.props.error}</Text></p>
                                {
                                    (this.state.invalid == true) &&
                                        <p><Text style={{ float: 'left' }} type="danger" id="invalid">One or more required fields are blank.  Please try again.</Text></p>
                                }
                            </Col>
                            <Col sm={8} style={{textAlign: 'center'}}>
                            {
                                (this.state.id > 0 && this.state.isSold) &&
                                    <Button style={{ fontWeight: 'bold' }} type="primary" shape="round" onClick={() => this.setState({editable: true})}>
                                        <EditOutlined /> Edit
                                    </Button>
                            }
                            </Col>
                            <Col sm={8}>
                            {
                                (userStatus == 4) && 
                                    (this.state.id > 0) && 
                                        <Button shape="round" type="danger" onClick={this.handleDelete} style={{fontWeight: 'bold'}}>
                                            <DeleteOutlined /> Delete
                                        </Button>
                            }
                            </Col>
                        </Row>,
                    ]}>
                    <Row>
                        <Col sm={6} style={{paddingRight: 5}}>
                            <div>
                                <label htmlFor="dts">Quote Date<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                <DatePicker onChange={(e) => this.handleDateChange(e)} format={'MM/DD/YYYY'} style={{width: '100%'}} defaultValue={moment(this.state.date, 'MM/DD/YYYY')} value={moment(this.state.date, 'MM/DD/YYYY')} disabled={!this.state.editable} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={8} style={{paddingRight: 5}}>
                            <div>
                                <label htmlFor="agent">Agent<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                <Select disabled={!this.state.editable}
                                    virtual={false}
                                    style={{ width: '100%' }}
                                    placeholder="Please select"
                                    name="agent"
                                    value={this.state.agent}
                                    onChange={this.handleAgentChange}>
                                    {
                                        this.props.agent.filter(a => a.status != "5").map(a => 
                                            <Option key={a.agentId} value={a.firstName}>{a.firstName}</Option>
                                        )
                                    }
                                    
                                </Select>     
                            </div>
                        </Col>
                        <Col sm={8} style={{paddingRight: 5}}>
                            <div>
                                <label htmlFor="state">Lead Source<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                <Select disabled={!this.state.editable}
                                    virtual={false}
                                    style={{ width: '100%' }}
                                    dropdownStyle={{ minWidth: "20%" }}
                                    placeholder="Please select"
                                    name="leadSource"
                                    value={this.state.leadSource}
                                    onChange={this.handleLeadSourceChange}>
                                    {
                                        this.props.leadSource.filter(ls => ls.inactive == false).map(ls => 
                                            <Option key={ls.id} value={ls.id}>{ls.name}</Option>
                                        )
                                    }
                                </Select>                    
                            </div>
                        </Col>
                        <Col sm={8}>
                            <div>
                                <label htmlFor="referringAgent">Referring Agent<span style={{color: 'red', fontSize: 'large'}}></span></label><br />
                                <Select disabled={!this.state.editable}
                                    virtual={false}
                                    style={{ width: '100%' }}
                                    dropdownStyle={{ minWidth: "20%" }}
                                    placeholder="Please select"
                                    name="referringAgent"
                                    value={this.state.referringAgentId}
                                    onChange={this.handleReferringAgentChange}>
                                        <Option key="0" value={0}> </Option>
                                    {
                                        
                                        this.props.referringAgent.filter(a => !a.inactive).map(a => 
                                            <Option key={a.id} value={a.id}>{a.name}</Option>
                                        )
                                    }
                                    
                                </Select>     
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={10} style={{paddingRight: 5}}>
                            <div>
                                <label htmlFor="firstName">First Name<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                <Input value={this.state.firstName} name='firstName' onChange={e => this.handleChange(e)} />
                            </div>
                        </Col>
                        <Col sm={10} style={{paddingRight: 5}}>
                            <div>
                                <label htmlFor="lastName">Last Name<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                <Input value={this.state.lastName} name='lastName' onChange={e => this.handleChange(e)} />
                            </div>
                        </Col>
                        <Col span={4} style={{paddingRight: 5}}>
                            <div>
                                <label htmlFor="zip">Zip<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                <Input type="number" value={this.state.zip} name='zip' onChange={e => this.handleChange(e)} onBlur={e => this.handleCheckZipCode(e)} maxLength="5" minLength="5" />
                                {this.state.isZipValid == false ?
                                    <Alert message="Zip Code must be 5 characters" type="error" visible={!this.state.isZipValid}></Alert>
                                    :
                                    ""
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={24} style={{paddingRight: 5, paddingTop: 10}}>
                            <Card size="small" title={<span>Products <small>(select at least one product)</small><span style={{color: 'red', fontSize: 'large'}}>*</span></span>}>
                                <Row style={{marginBottom: 10}}>
                                    <Col>
                                        <Radio.Group size="small" defaultValue="Allstate" buttonStyle="solid" onChange={this.handleProductsRadioChange} value={this.state.productFilter}>
                                            <Radio.Button value="Allstate">Allstate</Radio.Button>
                                            <Radio.Button value="EM">Expanded Marketplace</Radio.Button>
                                        </Radio.Group>
                                    </Col>
                                </Row>
                                {this.state.productFilter == 'Allstate' ? 
                                <Row>
                                    <Col sm={8} style={{paddingRight: 5}}>
                                        <div>
                                            <label htmlFor="state">Dwelling<small>(select all that apply)</small></label><br />
                                            {
                                                this.state.dwellingCheckboxes.map(d => 
                                                    <Row key={d.id}>
                                                        <Col>
                                                            <Checkbox value={d.id} onChange={(e) => this.handleDwellingChange(e)} checked={d.checked}>{d.name}</Checkbox>
                                                        </Col>
                                                        <Col>
                                                        {d.checked & d.name != "N/A" ? 
                                                            <InputNumber type="number" size='small' min={1} max={15} step={1} style={{width: '100%'}} value={d.cnt} onChange={e => this.handleDwellingNumberChange(e, d.id)} />
                                                            :
                                                            <span></span>
                                                        }
                                                        </Col>
                                                    </Row>
                                                )
                                            }
                                        </div>
                                        <div style={{display: this.state.dwellingCheckboxes.filter(s => s.checked).length == 0 ? 'none' : 'block'}}>
                                            <div>
                                                <label htmlFor="insuranceScore">Dwelling Insurance Score</label><br />
                                                <Select disabled={!this.state.editable}
                                                    style={{ width: '75%' }}
                                                    dropdownStyle={{ minWidth: "20%" }}
                                                    placeholder="Please select"
                                                    name="insuranceScore"
                                                    value={this.state.insuranceScore}
                                                    onChange={(e) => this.handleInsuranceScoreChange(e)}>
                                                    {
                                                        insuranceScores.map(i => 
                                                            <Option key={i}>{i}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </div>
                                            <div>
                                                <label htmlFor="insCarrier">Current Dwelling Carrier</label><br />
                                                <Select disabled={!this.state.editable}
                                                    showSearch
                                                    virtual={false}
                                                    style={{ width: '75%' }}
                                                    dropdownStyle={{ minWidth: "20%" }}
                                                    placeholder="Please select"
                                                    name="currentCarrier"
                                                    value={this.state.currentCarrier}
                                                    filterOption={(input, option) =>
                                                        (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    onChange={(e) => this.handleCurrentCarrierChange(e)}>
                                                    {
                                                        insCarriersList.sort().map(i => 
                                                            <Option key={i}>{i}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={8} style={{paddingRight: 5}}>
                                        <Row>
                                            <Col>
                                                <Checkbox checked={this.state.autoVisible} onChange={(e) => this.handleChooseAuto(e)}>Auto</Checkbox><br />
                                                {this.state.autoVisible ?
                                                <InputNumber type="number" min={0} max={15} step={1} value={this.state.auto} onChange={e => this.handleNumberChange(e, 'auto')} style={{width: '75%'}} />
                                                :
                                                <span></span>
                                                }
                                            </Col>
                                            
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Checkbox checked={this.state.commercialAutoVisible} onChange={(e) => this.handleChooseCommercialAuto(e)}>Commercial Auto</Checkbox><br />
                                                {this.state.commercialAutoVisible ?
                                                <InputNumber type="number" min={0} max={15} step={1} value={this.state.commercialAuto} onChange={e => this.handleNumberChange(e, 'commercialAuto')} style={{width: '75%'}}  />
                                                :
                                                <span></span>
                                                }
                                            </Col>
                                        </Row>
                                        <Row style={{display: this.state.autoVisible | this.state.commercialAutoVisible ? 'block' : 'none'}}>
                                            <Col>
                                                <div>
                                                    <label htmlFor="insuranceScoreAuto">Auto Insurance Score</label><br />
                                                    <Select disabled={!this.state.editable}
                                                        
                                                        style={{ width: '75%' }}
                                                        dropdownStyle={{ minWidth: "20%" }}
                                                        placeholder="Please select"
                                                        name="insuranceScoreAuto"
                                                        value={this.state.insuranceScoreAuto}
                                                        onChange={(e) => this.handleInsuranceScoreAutoChange(e)}>
                                                        {
                                                            insuranceScores.map(i => 
                                                                <Option key={i}>{i}</Option>
                                                            )
                                                        }
                                                    </Select>
                                                </div>
                                                <div>
                                                    <label htmlFor="insCarrierAuto">Current Auto Carrier</label><br />
                                                    <Select disabled={!this.state.editable}
                                                        showSearch
                                                        virtual={false}
                                                        style={{ width: '75%' }}
                                                        dropdownStyle={{ minWidth: "20%" }}
                                                        placeholder="Please select"
                                                        name="currentCarrierAuto"
                                                        value={this.state.currentCarrierAuto}
                                                        filterOption={(input, option) =>
                                                            (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        onChange={(e) => this.handleCurrentCarrierAutoChange(e)}>
                                                        {
                                                            insCarriersList.sort().map(i => 
                                                                <Option key={i}>{i}</Option>
                                                            )
                                                        }
                                                        
                                                    </Select>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={8} style={{paddingRight: 5}}>
                                        <div>
                                            <label htmlFor="specialty">Specialty<small>(select all that apply)</small></label><br />
                                            {
                                                this.state.specialtyCheckboxes.map(d => 
                                                    <Row key={d.id}>
                                                        <Col>
                                                            <Checkbox value={d.id} onChange={(e) => this.handleSpecialtyChange(e)} checked={d.checked}>{d.name}</Checkbox>
                                                        </Col>
                                                        <Col>
                                                            {d.checked & d.name != "N/A" ? 
                                                            <InputNumber size='small' type="number" min={1} max={10} step={1} style={{width: '100%'}} value={d.cnt} onChange={e => this.handleSpecialtyNumberChange(e, d.id)} />
                                                            :
                                                            <span></span>
                                                            }
                                                        </Col>
                                                    </Row>
                                                )
                                            }
                                        </div>
                                    </Col>
                                </Row>
                                :
                                <div>
                                <Row>
                                    <Col>
                                        <label htmlFor="agent">Carrier</label><br />
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Please select"
                                            name="emCarrier"
                                            value={this.state.emCarrier}
                                            onChange={(e) => this.handleCarrierChange(e)}>
                                            {
                                                this.props.emCarriers.map(c => 
                                                    <Option key={c.id}>{c.name}</Option>
                                                )
                                            }
                                        </Select>
                                    </Col>
                                </Row>
                                {
                                    this.state.emProductCheckboxes.filter(p => p.emCarrierId == this.state.selectedCarrier).length > 0 &&
                                    <Row style={{marginTop: 10}}>
                                        <Col span={8}>
                                            <label htmlFor="state">Dwelling<small>(select all that apply)</small></label><br />
                                            {this.state.emProductCheckboxes.filter(p => p.emCarrierId == this.state.selectedCarrier & p.productType == "Dwelling").map(product => {
                                                return <Row key={product.emProductId}>
                                                            <Col>
                                                                <Checkbox value={product.emProductId} onChange={(e) => this.handleEMProductChange(e)} checked={product.checked}>{product.name}</Checkbox>
                                                            </Col>
                                                            <Col>
                                                                {product.checked ? 
                                                                <InputNumber size='small' type="number" min={1} max={10} step={1} style={{width: '100%'}} value={product.cnt} onChange={e => this.handleEMProductNumberChange(e, product.emProductId)} />
                                                                :
                                                                <span></span>
                                                                }
                                                            </Col>
                                                        </Row>
                                            })}
                                        </Col> 
                                        <Col span={8}>
                                            <label htmlFor="state">Auto<small>(select all that apply)</small></label><br />
                                            {this.state.emProductCheckboxes.filter(p => p.emCarrierId == this.state.selectedCarrier & p.productType == "Auto").map(product => {
                                                return  <Row key={product.emProductId}>
                                                            <Col>
                                                                <Checkbox value={product.emProductId} onChange={(e) => this.handleEMProductChange(e)} checked={product.checked}>{product.name}</Checkbox>
                                                            </Col>
                                                            <Col>
                                                                {product.checked ? 
                                                                <InputNumber size='small' type="number" min={1} max={10} step={1} style={{width: '100%'}} value={product.cnt} onChange={e => this.handleEMProductNumberChange(e, product.emProductId)} />
                                                                :
                                                                <span></span>
                                                                }
                                                            </Col>
                                                        </Row>
                                            })}
                                        </Col> 
                                        <Col span={8}>
                                            <label htmlFor="specialty">Specialty<small>(select all that apply)</small></label><br />
                                            {this.state.emProductCheckboxes.filter(p => p.emCarrierId == this.state.selectedCarrier & p.productType == "Specialty").map(product => {
                                                return  <Row key={product.emProductId}>
                                                            <Col>
                                                                <Checkbox value={product.emProductId} onChange={(e) => this.handleEMProductChange(e)} checked={product.checked}>{product.name}</Checkbox>
                                                            </Col>
                                                            <Col>
                                                                {product.checked ? 
                                                                <InputNumber size='small' type="number" min={1} max={10} step={1} style={{width: '100%'}} value={product.cnt} onChange={e => this.handleEMProductNumberChange(e, product.emProductId)} />
                                                                :
                                                                <span></span>
                                                                }
                                                            </Col>
                                                        </Row>
                                            })}
                                        </Col>
                                    </Row>
                                    }
                                    </div>
                                }
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={8} style={{paddingRight: 5}}>
                            <div>
                                <label htmlFor="agent">Status<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                <Select //disabled={this.state.isSold}
                                    style={{ width: '100%' }}
                                    placeholder="Please select"
                                    name="status"
                                    value={this.state.status}
                                    onChange={(e) => this.handleStatusChange(e)}>
                                    {
                                        this.props.status.map(a => 
                                            <Option key={a.name}>{a.name}</Option>
                                        )
                                    }
                                </Select>     
                            </div>
                        </Col>
                        <Col sm={8} style={{paddingRight: 5}}>
                            <div>
                                <label htmlFor="objection">Objection<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                <Select disabled={!this.state.editable}
                                    virtual={false}
                                    style={{ width: '100%' }}
                                    dropdownStyle={{ minWidth: "20%" }}
                                    placeholder="Please select"
                                    name="objection"
                                    value={this.state.objection}
                                    onChange={(e) => this.handleObjectionChange(e)}>
                                    {
                                        this.props.objection.map(d => 
                                            <Option key={d.name}>{d.name}</Option>
                                        )
                                    }
                                </Select>                    
                            </div>
                        </Col>
                        {this.state.showIneligible && 
                        <Col sm={8} style={{paddingRight: 5}}>
                            <div>
                                <label htmlFor="ineligible">Ineligible<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                <Select disabled={!this.state.editable}
                                    style={{ width: '100%' }}
                                    dropdownStyle={{ minWidth: "20%" }}
                                    placeholder="Please select"
                                    name="ineligible"
                                    value={this.state.ineligible}
                                    onChange={(e) => this.handleIneligibleChange(e)}>
                                    {
                                        this.props.ineligible.filter(i => i.name != "N/A").map(d => 
                                            <Option key={d.name}>{d.name}</Option>
                                        )
                                    }
                                </Select>      
                            </div>
                        </Col>
                        }
                        
                    </Row>
                    <Row style={{paddingTop: 10}}>
                        <Col sm={4} style={{paddingRight: 5}}>
                            <Checkbox checked={this.state.selfGenerated} name="selfGenerated" onChange={(e) => this.handleSelfGeneratedChange(e)}>Self Generated?</Checkbox>
                        </Col>
                        <Col sm={4} style={{paddingRight: 5}}>
                            {showBundle == true && <Checkbox checked={this.state.bundle} name="bundle" onChange={(e) => this.handleBundleChange(e)}>Bundle?</Checkbox>}
                        </Col>
                        <Col sm={4} style={{paddingRight: 5}}>
                            <Checkbox checked={this.state.requote} name="requote" onChange={(e) => this.handleRequoteChange(e)}>Requote / Aged Lead</Checkbox>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={24} style={{paddingRight: 5}}>
                            <div>
                                <label htmlFor="spend">Notes</label><br />
                                <TextArea rows={2} name='notes' value={this.state.notes} onChange={e => this.handleChange(e)} />
                            </div>  
                        </Col>
                    </Row>
                    {this.state.isSold &&
                        <SoldQuoteLog 
                            dwellingCheckboxes={this.state.dwellingCheckboxes} 
                            specialtyCheckboxes={this.state.specialtyCheckboxes} 
                            dwellingSoldCheckboxes={this.state.dwellingSoldCheckboxes} 
                            specialtySoldCheckboxes={this.state.specialtySoldCheckboxes} 
                            auto={this.state.originalStatus == 'SOLD' ? this.state.soldAuto : this.state.auto} 
                            autoPremium={this.state.autoPremium} effectiveDate={this.state.effectiveDate}
                            bindDate={this.state.bindDate} 
                            onDwellingSoldChange={this.handleDwellingSoldChange} 
                            onSpecialtySoldChange={this.handleSpecialtySoldChange} 
                            onSoldChange={this.handleSoldChange} 
                            originalStatus={this.state.originalStatus} 
                            quoteDate={this.state.date} 
                            soldBundle={this.state.soldBundle} 
                            soldCommercialAuto={this.state.soldCommercialAuto ? this.state.soldCommercialAuto : this.state.commercialAuto} 
                            commercialAutoPremium={this.state.commercialAutoPremium}
                            typeVendorTypeId={this.state.typeVendorTypeId} />
                    }
                </Modal>
                <Modal 
                    width="25%"
                    visible={this.state.invalidModalVisible}
                    title="MISSING FIELD"
                    onOk={() => this.setState({invalidModalVisible: false})}
                    onCancel={() => this.setState({invalidModalVisible: false})}
                    footer={[
                        <Row style={{paddingBottom: 10}} >
                            <Col sm={12}>
                                <Button style={{ float: 'left' }} type="primary" shape="round" onClick={() => this.setState({invalidModalVisible: false})}>
                                    OK
                                </Button>
                            </Col>
                        </Row>,
                    ]}>
                        <Text style={{ float: 'left', fontSize: 16 }} type="danger" id="invalid">One or more required fields are blank.  Please try again.</Text>
                </Modal>
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(QuoteLogEntry);