import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { actionCreators } from '../store/humbleActions';
import { UploadOutlined } from '@ant-design/icons';
import { Upload, Typography, Row, Col, Button, Table, Select, DatePicker, Space } from 'antd';
import moment from 'moment';
import ZipUploadReport from './ZipUploadReport'
const { Title } = Typography;
const {Option} = Select;

class ZipUpload extends Component {
    constructor(props) {
      super(props);
        this.state = {
           columnHeaders: [],
           zipCounts: [],
           selectedLeadSource: '',
           valid: false,
           monthDts: '',
           fileName: '',
           openUpload: false,
           refreshReport: false
        }
        this.setCsvFile = this.setCsvFile.bind(this);
        this.handleSelectedLeadSourceChange = this.handleSelectedLeadSourceChange.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
        this.onUploadClick = this.onUploadClick.bind(this);
    }
    componentDidMount(){
        const userId = localStorage.getItem('userId');
        const agencyId = localStorage.getItem('agencyId');
        this.props.getLeadSource(agencyId);
    }
    handleSelectedLeadSourceChange(value){
        const valid = this.state.monthDts != '' && value != '';
        this.setState({selectedLeadSource: value, valid: valid});
    }
    onMonthChange(date, dateString){
        const valid = this.state.selectedLeadSource != '' && dateString != '';
        if(dateString != ''){
            this.setState({monthDts: dateString, valid: valid});
        }else{
            this.setState({monthDts: moment().format("MMM YYYY"), valid: valid});
        }
    }
    onUploadClick(){
        const openUpload = this.state.openUpload;
        this.setState({openUpload: !openUpload, refreshReport: false, monthDts: '', selectedLeadSource: ''});
    }
    setCsvFile(file){
        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target.result;
            const delim = ',';
            const headers = text.slice(0,text.indexOf('\n')).split(delim);
            const rows = text.slice(text.indexOf('\n')+1).split('\n');
            var zipCodeHeader = '';
            const newArray = rows.map( row => {
                const values = row.split(delim);
                const eachObject = headers.reduce((obj, header, i) => {
                    if(header.toLowerCase().includes("zip")){
                        zipCodeHeader = header;
                        if(values[i] != undefined){
                            obj["Zip"] = values[i].split('-')[0];
                        }
                    }else{
                        obj[header] = values[i];
                    }
                    return obj;
                }, {})
                return eachObject;
            });

            var counts = newArray.reduce((p, c) => {
                var name = c.Zip;
                if (!p.hasOwnProperty(name)) {
                  p[name] = 0;
                }
                p[name]++;
                return p;
            }, {});
              
            console.log(counts);
            var countsExtended = Object.keys(counts).map(k => {
                return {zip: k, count: counts[k]}; });

            const monthDts = this.state.monthDts;
            const fileName = file.name;
            const typeQuoteLogLeadSourceId = this.state.selectedLeadSource;
            const agencyId = localStorage.getItem('agencyId'); 

            this.props.createZipUpload(monthDts, fileName, typeQuoteLogLeadSourceId, agencyId, countsExtended);
            this.setState({columnHeaders: headers, zipCounts: countsExtended.sort(function (a, b) {return b.count - a.count;}), fileName: file.name, refreshReport: true, openUpload: false});
        }
        
        reader.readAsText(file);
        return false;
    }
    
    render() {
        const zipColumns = [
            {
                title: 'Zip Code',
                dataIndex: 'zip',
                key: 'zip',
                sorter: (a, b) => ('' + a.zip).localeCompare(b.zip),
            },
            {
                title: 'Count',
                dataIndex: 'count',
                key: 'count',
                sorter: (a, b) => a.count - b.count,
            },
        ]
        return (
            <div>
                <Row>
                    <Col>
                        <Button icon={<UploadOutlined />} type='primary' shape="round" onClick={this.onUploadClick}>Upload New Report</Button>
                    </Col>
                </Row>
                <Row style={{display: this.state.openUpload ? 'block' : 'none', paddingTop: 5}}>
                    <Col md={24} style={{border: 'solid 1px #CCC', padding: 10}}>
                        <Space>
                            <DatePicker onChange={this.onMonthChange} picker="month" format="MMM YYYY"  />
                            <Select
                                style={{ width: '100%' }}
                                dropdownStyle={{ minWidth: "20%" }}
                                placeholder="Please Select Lead Source"
                                name="agents"
                                onChange={this.handleSelectedLeadSourceChange}>
                                {
                                    this.props.leadSource.map(a => 
                                        <Option key={a.id} value={a.id}>{a.name}</Option>
                                    )
                                }
                            </Select>
                            <Upload
                                    accept='.csv'
                                    id='csvFile'
                                    beforeUpload={file => {
                                        this.setCsvFile(file);
                                        return false;
                                    }}
                            >
                                <Button icon={<UploadOutlined />} disabled={!this.state.valid} type='primary' shape="round">Choose File</Button>
                            </Upload>
                        </Space>
                    </Col>
                </Row>
                <Row style={{paddingTop: 20}}>
                    <Col md={24}>
                        <ZipUploadReport refreshReport={this.state.refreshReport} />
                        {/* <Table dataSource={this.state.zipCounts} columns={zipColumns} pagination={false}></Table> */}
                    </Col>
                </Row>
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ZipUpload);