import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom'
import { Collapse, Row, Col, Card, Slider, InputNumber, Typography, Button, Space, Modal, Image} from 'antd'
import { ArrowRightOutlined, CheckCircleOutlined } from '@ant-design/icons'
import Agreement from './Agreement';
const { Text, Title } = Typography;

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            acceptChecks: 0,
            matches: window.matchMedia("(min-width: 768px)").matches
        };
        this.handleClose = this.handleClose.bind(this);
        this.handleAcceptClicks = this.handleAcceptClicks.bind(this);
    }
    componentDidMount() {
        const handler = e => this.setState({matches: e.matches});
        window.matchMedia("(min-width: 768px)").addEventListener('change', handler);
    }
    handleClose() {
        this.setState({showModal: false})
    }
    handleAcceptClicks(num) {
        console.log(num);
        // var acceptChecks = this.state.acceptChecks;
        // acceptChecks = e.target.checked ? acceptChecks + 1 : acceptChecks - 1;
        // console.log(acceptChecks);
        this.setState({acceptChecks: num});
    }
    render() {
        return (
            <div>
                {/* <Row>
                    <Col xs={{ span: 22, offset: 1 }} lg={{ span: 12, offset: 6 }}>
                        <Row style={{display: 'flex', justifyContent: 'space-between'}}>
                            <Col>
                                <Button type="link" size="large" style={{fontSize: 20}}><Link to={'/Login'} >HOME</Link></Button>
                            </Col>
                            <Col>
                                <img src="Studio-Project.png" alt="Logo" style={{height: 150}} />
                            </Col>
                            <Col>
                                <Button type="link" size="large" style={{fontSize: 20}}><Link to={'/Login'} >LOGIN</Link></Button>
                            </Col>
                        </Row>
                        <Col lg={{ span: 12, offset: 6 }} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Row>
                                <Col style={{textAlign: 'center'}}>
                                    <Title style={{fontSize: 50, marginTop: 30}}>STOP Believing Your Feelings and START Making Data Driven Decisions</Title>
                                    {!this.state.matches && <Button shape="round" size="large" style={{backgroundColor: '#fff', boxShadow: '5px 5px 5px black', fontWeight: 'bold', color: 'black', marginBottom: 10}} href='https://bit.ly/THE-HUMBLE-SIGNUP' target="_blank">SCHEDULE YOUR CONSULTATION</Button>}
                                    <Title level={3}>THE Humble 100: A Proven Data Analytics System That Helped Ryan Chao Organically Grow His Agency From $0 to $27M in 4 Years.</Title>
                                    <Space direction="vertical" style={{marginTop: 20}}>
                                        <Button shape="round" size="large" style={{backgroundColor: '#97E45D', boxShadow: '5px 5px 5px black', fontWeight: 'bold'}} onClick={() => this.setState({showModal: true})}>PURCHASE SUBSCRIPTION</Button>
                                        <Button shape="round" size="large" style={{backgroundColor: '#fff', boxShadow: '5px 5px 5px black', fontWeight: 'bold', color: 'black'}} href='https://bit.ly/THE-HUMBLE-SIGNUP' target="_blank">SCHEDULE YOUR CONSULTATION</Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                </Row> */}
                {/* <Row>
                    <Col xs={{ span: 22, offset: 1 }} lg={{ span: 12, offset: 6 }}>
                        <Row>
                            <Col span={12}>
                                <img src="humblelogo.png" alt="Logo" style={{float: "left", height: 65}} />
                            </Col>
                            <Col span={12} style={{position: 'absolute', right: 10}}>
                                <Button type="link" size="large" style={{fontSize: 20}}><Link to={'/Login'} >LOGIN</Link></Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                
                                <Row>
                                    <Col style={{textAlign: 'center'}}>
                                        <Title level={3} style={{color: '#3D76DD'}}>If you're tired of inconsistent marketing results, struggling to track your sales performance, or wishing you could determine your best performing leads, the THEhumble100 is for you.</Title>
                                        {!this.state.matches && <Button shape="round" size="large" style={{backgroundColor: '#fff', boxShadow: '5px 5px 5px black', fontWeight: 'bold', color: 'black', marginBottom: 10}} href='https://bit.ly/THE-HUMBLE-SIGNUP' target="_blank">SCHEDULE YOUR CONSULTATION</Button>}
                                        <Title level={1}>It's Time to Make <span style={{color: 'blue'}}>DATA DRIVEN Decisions!</span></Title>
                                        <Text style={{fontSize: 16, fontWeight: 'bold'}}>THEhumble100.net: A Proven Data Analytics System That Helped Ryan Chao Organically Grow His Agency From $0 to $10.6M in Four Years!</Text>
                                    
                                        <Space direction="vertical" style={{marginTop: 20}}>
                                            <Button shape="round" size="large" style={{backgroundColor: '#97E45D', boxShadow: '5px 5px 5px black', fontWeight: 'bold'}} onClick={() => this.setState({showModal: true})}>PURCHASE SUBSCRIPTION</Button>
                                            <Button shape="round" size="large" style={{backgroundColor: '#fff', boxShadow: '5px 5px 5px black', fontWeight: 'bold', color: 'black'}} href='https://bit.ly/THE-HUMBLE-SIGNUP' target="_blank">SCHEDULE YOUR CONSULTATION</Button>
                                        </Space>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Image width={500} src="3297225.jpg" preview={false} />
                            </Col>
                        </Row>
                        <Row style={{padding: 20}}>
                            <Col span={24} style={{borderTop: 'solid 1px blue', borderBottom: 'solid 1px blue', paddingBottom: 20}}>
                                <Row>
                                    <Col span={24} style={{textAlign: 'center'}}>
                                        <Title level={2} style={{color: '#3D76DD'}}>Features and Benefits of THEhumble100.net</Title>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} flex={3} style={{textAlign: 'center'}}>
                                        <Space direction="vertical" style={{width: 350 }}>
                                            <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '10px 5px 5px #ccc' }}>
                                                <Title level={5} className="App-link"> Create a Successful Marketing Plan</Title>
                                            </Card>
                                            <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '10px 5px 5px #ccc' }}>
                                                <Title level={5}>Track Marketing Spend</Title>
                                            </Card>
                                            <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '10px 5px 5px #ccc' }}>
                                                <Title level={5}>Create Sales Agent Goals</Title>
                                            </Card>
                                            <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '10px 5px 5px #ccc' }}>
                                                <Title level={5}>Track Acquisition Costs</Title>
                                            </Card>
                                            <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '10px 5px 5px #ccc' }}>
                                                <Title level={5}>Know Your ROI</Title>
                                            </Card>
                                        </Space>
                                    </Col>
                                    <Col lg={12} flex={2} style={{textAlign: 'center'}}>
                                        <Space direction="vertical" style={{width: 350 }}>
                                            <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '10px 5px 5px #ccc'}}>
                                                <Title level={5}>Track Close Ratios</Title>
                                            </Card>
                                            <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '10px 5px 5px #ccc' }}>
                                                <Title level={5}>Track Sales Agent Performance</Title>
                                            </Card>
                                            <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '10px 5px 5px #ccc' }}>
                                                <Title level={5}>Lead Source Tracking</Title>
                                            </Card>
                                            <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '10px 5px 5px #ccc' }}>
                                                <Title level={5}>Monthly Group Coaching</Title>
                                            </Card>
                                            <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '10px 5px 5px #ccc' }}>
                                                <Title level={5}>Leaderboard Gamification</Title>
                                            </Card>
                                        </Space>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row> */}

            <Row>
                <Col xs={{ span: 20, offset: 2 }} lg={{ span: 12, offset: 6 }}>
                    <Row>
                        <Col span={12}>
                            <img src="humblelogo.png" alt="Logo" style={{float: "left", height: 65}} />
                        </Col>
                        <Col span={12} style={{position: 'absolute', right: 10}}>
                            <Button type="link" size="large" style={{fontSize: 20}}><Link to={'/Login'} >LOGIN</Link></Button>
                        </Col>
                    </Row>
                    <Row style={{paddingTop: 10}}>
                        <Col span={24} style={{textAlign: 'center' }}>
                            <Title level={3} style={{color: '#3D76DD'}}>If you're tired of inconsistent marketing results, struggling to track your sales performance, or wishing you could determine your best performing leads, the THEhumble100 is for you.</Title>
                            {!this.state.matches && <Button shape="round" size="large" style={{backgroundColor: '#fff', boxShadow: '5px 5px 5px black', fontWeight: 'bold', color: 'black', marginBottom: 10}} href='https://bit.ly/THE-HUMBLE-SIGNUP' target="_blank">SCHEDULE YOUR CONSULTATION</Button>}
                        </Col>
                    </Row>
                    <Row style={{backgroundColor: '#3D76DD', padding: 20}}>
                        <Col flex={3} lg={14}>
                            <Title level={1} style={{color: '#fff'}}>It's Time to Make DATA DRIVEN Decisions!</Title>
                            <Text style={{color: '#fff', fontSize: 16}}>THEhumble100.net: A Proven Data Analytics System That Helped Ryan Chao Organically Grow His Agency From $0 to $10.6M in Four Years!</Text>
                        </Col>
                        <Col flex={2} lg={10} style={{marginTop: 50, textAlign: 'center'}}>
                            <Space direction="vertical">
                                <Button shape="round" size="large" style={{backgroundColor: '#97E45D', boxShadow: '5px 5px 5px black', fontWeight: 'bold'}} onClick={() => this.setState({showModal: true})}>PURCHASE SUBSCRIPTION</Button>
                                
                                <Button shape="round" size="large" style={{backgroundColor: '#fff', boxShadow: '5px 5px 5px black', fontWeight: 'bold', color: 'black'}} href='https://bit.ly/THE-HUMBLE-SIGNUP' target="_blank">SCHEDULE YOUR CONSULTATION</Button>
                            </Space>
                        </Col>
                    </Row>
                    <Row style={{padding: 20}}>
                        <Col span={24} style={{borderTop: 'solid 1px blue', borderBottom: 'solid 1px blue', paddingBottom: 20}}>
                            <Row>
                                <Col span={24} style={{textAlign: 'center'}}>
                                    <Title level={2} style={{color: '#3D76DD'}}>Features and Benefits of THEhumble100.net</Title>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} flex={3} style={{textAlign: 'center'}}>
                                    <Space direction="vertical" style={{width: 350 }}>
                                        <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '10px 5px 5px #ccc' }}>
                                            <Title level={5} className="App-link"> Create a Successful Marketing Plan</Title>
                                        </Card>
                                        <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '10px 5px 5px #ccc' }}>
                                            <Title level={5}>Track Marketing Spend</Title>
                                        </Card>
                                        <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '10px 5px 5px #ccc' }}>
                                            <Title level={5}>Create Sales Agent Goals</Title>
                                        </Card>
                                        <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '10px 5px 5px #ccc' }}>
                                            <Title level={5}>Track Acquisition Costs</Title>
                                        </Card>
                                        <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '10px 5px 5px #ccc' }}>
                                            <Title level={5}>Know Your ROI</Title>
                                        </Card>
                                    </Space>
                                </Col>
                                <Col lg={12} flex={2} style={{textAlign: 'center'}}>
                                    <Space direction="vertical" style={{width: 350 }}>
                                        <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '10px 5px 5px #ccc'}}>
                                            <Title level={5}>Track Close Ratios</Title>
                                        </Card>
                                        <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '10px 5px 5px #ccc' }}>
                                            <Title level={5}>Track Sales Agent Performance</Title>
                                        </Card>
                                        <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '10px 5px 5px #ccc' }}>
                                            <Title level={5}>Lead Source Tracking</Title>
                                        </Card>
                                        <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '10px 5px 5px #ccc' }}>
                                            <Title level={5}>Monthly Group Coaching</Title>
                                        </Card>
                                        <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '10px 5px 5px #ccc' }}>
                                            <Title level={5}>Leaderboard Gamification</Title>
                                        </Card>
                                    </Space>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{textAlign: 'center'}}>
                            <Text>Book a consultation today and learn how THEhumble100 can help you know your numbers!</Text>
                        </Col>
                    </Row>
                    <Row style={{paddingTop: 10}}>
                        <Col span={24} style={{textAlign: 'center'}}>
                            <Button size="large" style={{backgroundColor: '#3D76DD', color: '#fff', boxShadow: '10px 5px 5px #ccc', fontWeight: 'bold'}} shape="round" type="success" href='https://bit.ly/THE-HUMBLE-SIGNUP' target="_blank">SCHEDULE YOUR CONSULTATION</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Modal width={1000}
                title="Service Agreement"
                visible={this.state.showModal}
                onOk={this.handleClose}
                onCancel={this.handleClose}
                footer={[
                <Button key="back" href='https://square.link/u/ZAZQGw5t' target="_blank" disabled={this.state.acceptChecks < 3 ? true : false} onClick={this.handleClose}>
                    Submit
                </Button>,
                <Button key="submit" type="primary" onClick={this.handleClose}>
                    Cancel
                </Button>,
                ]}>
            <Agreement onAcceptClick={this.handleAcceptClicks} />
          </Modal>
          </div>  
        );
    }
}
export default Home;