import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Card, Input, Row, Col, List, Typography, Select, Button, Radio, Checkbox } from 'antd';
import { DeleteOutlined, SaveOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Agents from './Agents'
const {Title, Text} = Typography
const {Option} = Select

class Admin extends Component {
    constructor(props) {
      super(props);
        this.state = {
            id: 0,
            firstName: '',
            email: '',
            dailyQuoteGoal: '',
            agencyId: this.props.agencyId,
            userName: '',
            status: "1",
            userId: 0,
            agentFilter: 'Active',
            producerNo: '',
            invalid: false,
            onlySelf: false,
            selectedAgency: ''
        }
        
        this.handleListClick = this.handleListClick.bind(this);
        
    }
    componentDidMount() {
        const agencyId = localStorage.getItem('agencyId');
        console.log(agencyId);
        if(agencyId != "1168673106"){
            this.props.history.push('/');
        } else {
            this.props.getAllAgencies();
        }
    }
    
    handleListClick = (agent) => {
        this.setState({
            id: agent.agentId,
            firstName: agent.firstName, 
            email: agent.email, 
            dailyQuoteGoal: agent.dailyQuoteGoal,
            userName: agent.userName,
            status: agent.status,
            userId: agent.userId,
            password: agent.password,
            onlySelf: agent.onlySelf,
            producerNo: agent.producerNo
        });
    }
    
    render() {
        return (
            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={8} style={{paddingLeft: 16}}>
                            {
                                this.props.agencies &&
                                    this.props.agencies.map(agency =>
                                        <Card title={<Title level={3}>{agency.name}</Title>} style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '10px 5px 5px #ccc', marginBottom: 10 }} extra={<Button onClick={() => this.setState({selectedAgency: agency.id})}>More</Button>}>
                                            <Row>
                                                <Col md={12}>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Text>{agency.address}</Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Text>{agency.city + ', ' + agency.state + ' ' + agency.zip}</Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Text><strong>Phone:</strong> {agency.phone}</Text>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={12}>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Text style={{fontWeight: 'bold'}}>Agency Owner</Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Text>{agency.agencyOwner.firstName + ' ' + agency.agencyOwner.lastName}</Text>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Text>{agency.agencyOwner.email}</Text>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    )
                            }
                        </Col>
                        <Col span={12} style={{paddingLeft: 16}}>
                            {this.state.selectedAgency != 0 &&
                                <Agents agencyId={this.state.selectedAgency} />
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Admin);