import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Card, Input, Row, Col, List, Typography, Select, Button, Checkbox, Radio, InputNumber } from 'antd';
import { DeleteOutlined, SaveOutlined, EditOutlined } from '@ant-design/icons';
const {Title, Text} = Typography
const {Option} = Select

class ProductPoints extends Component {
    constructor(props) {
      super(props);
        this.state = {
            id: 0,
            agencyId: this.props.agencyId,
            name: '',
            inactive: false,
            inactiveFilter: 'Active',
            autoPoints: 0,
            commAutoPoints: 0,
            autoPointsId: 0,
            commAutoPointsId: 0
        }
        this.handleSave = this.handleSave.bind(this);
        this.handleAutoChange = this.handleAutoChange.bind(this);
        this.handleCommAutoChange = this.handleCommAutoChange.bind(this);
    }
    componentDidMount() {
        const agencyId = localStorage.getItem('agencyId');
        this.props.getSpecialtyPoints(agencyId);
        this.props.getDwellingPoints(agencyId);
        this.props.getAutoPoints(agencyId);
    }
    componentDidUpdate(prevProps){
        if(this.props.autoPoints != prevProps.autoPoints){
            var autoPoints = this.props.autoPoints.filter(a => a.typeAuto == "AUTO").length > 0 ? this.props.autoPoints.filter(a => a.typeAuto == "AUTO")[0].points : 0;
            var commAutoPoints = this.props.autoPoints.filter(a => a.typeAuto == "COMM AUTO").length > 0 ? this.props.autoPoints.filter(a => a.typeAuto == "COMM AUTO")[0].points : 0;
            var autoPointsId = this.props.autoPoints.filter(a => a.typeAuto == "AUTO").length > 0 ? this.props.autoPoints.filter(a => a.typeAuto == "AUTO")[0].id : 0;
            var commAutoPointsId = this.props.autoPoints.filter(a => a.typeAuto == "COMM AUTO").length > 0 ? this.props.autoPoints.filter(a => a.typeAuto == "COMM AUTO")[0].id : 0;
            this.setState({
                autoPoints: autoPoints,
                commAutoPoints: commAutoPoints,
                autoPointsId: autoPointsId,
                commAutoPointsId: commAutoPointsId,
            });
        }
    }
    handleAutoChange = value => {
        this.setState({
            "autoPoints": value
        });
    }
    handleCommAutoChange = value => {
        this.setState({
            "commAutoPoints": value
        });
    }
    handleSave(productType, product, points, typeId, pointsId) {
        const agencyId = localStorage.getItem('agencyId');
        console.log(productType + " : " + product + " : " + points + " : " + typeId)
        const id = pointsId == null ? 0 : pointsId;
        if(productType == "dwelling"){
            this.props.updateDwellingPoints(id, typeId, points, agencyId);
        } else if(productType == "specialty"){
            this.props.updateSpecialtyPoints(id, typeId, points, agencyId);
        } else if(productType == "auto"){
            this.props.updateAutoPoints(id, typeId, points, agencyId);
        }
    }
    
    render() {
        return (
            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={16} style={{paddingLeft: 16}}>
                            <Card title="ADD POINTS TO DWELLING PRODUCTS" headStyle={{ backgroundColor: '#3D76DD', color: '#fff' }} style={{borderColor: '#3D76DD', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc', marginTop: '10px'}}>
                                {this.props.dwellingPoints.map(d => 
                                    <Row style={{marginBottom: 10}}>
                                        <Col md={5}>
                                            {d.name}
                                        </Col>
                                        <Col md={5}>
                                            <InputNumber
                                                min={0}
                                                max={100}
                                                step={1}
                                                style={{ marginLeft: 16 }}
                                                defaultValue={d.points}
                                                onBlur={(e) => this.handleSave("dwelling", d.name, e.target.value, d.typeQuoteLogDwellingId, d.id)}
                                            />
                                        </Col>
                                    </Row>
                                )}
                            </Card>
                        </Col>
                    </Row>
                    <Row style={{marginTop: 20}}>
                        <Col span={16} style={{paddingLeft: 16}}>
                            <Card title="ADD POINTS TO SPECIALTY PRODUCTS" headStyle={{ backgroundColor: '#3D76DD', color: '#fff' }} style={{borderColor: '#3D76DD', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc', marginTop: '10px'}}>
                                {this.props.specialtyPoints.map(d => 
                                <Row style={{marginBottom: 10}}>
                                    <Col md={5}>
                                        {d.name}
                                    </Col>
                                    <Col md={5}>
                                        <InputNumber
                                            min={0}
                                            max={100}
                                            step={1}
                                            style={{ marginLeft: 16 }}
                                            defaultValue={d.points}
                                            onBlur={(e) => this.handleSave("specialty", d.name, e.target.value, d.typeQuoteLogSpecialtyId, d.id)}
                                        />
                                    </Col>
                                </Row>
                                )}
                            </Card>
                        </Col>
                    </Row>
                    <Row style={{marginTop: 20}}>
                        <Col span={16} style={{paddingLeft: 16}}>
                            <Card title="ADD POINTS TO AUTO" headStyle={{ backgroundColor: '#3D76DD', color: '#fff' }} style={{borderColor: '#3D76DD', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc', marginTop: '10px'}}>
                                <Row style={{marginBottom: 10}}>
                                    <Col md={5}>
                                        AUTO
                                    </Col>
                                    <Col md={5}>
                                        <InputNumber
                                            name="autoPoints"
                                            min={0}
                                            max={100}
                                            step={1}
                                            style={{ marginLeft: 16 }}
                                            value={this.state.autoPoints}
                                            onChange={this.handleAutoChange}
                                            onBlur={(e) => this.handleSave("auto", "AUTO", e.target.value, "AUTO", this.state.autoPointsId)}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{marginBottom: 10}}>
                                    <Col md={5}>
                                        COMM AUTO
                                    </Col>
                                    <Col md={5}>
                                        <InputNumber
                                            name="commAutoPoints"
                                            min={0}
                                            max={100}
                                            step={1}
                                            style={{ marginLeft: 16 }}
                                            value={this.state.commAutoPoints}
                                            onChange={this.handleCommAutoChange}
                                            onBlur={(e) => this.handleSave("auto", "COMM AUTO", e.target.value, "COMM AUTO", this.state.commAutoPointsId)}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ProductPoints);