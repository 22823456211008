import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Card, Button, Input, Col, Row, Select, Typography, Checkbox } from 'antd'
import { CheckSquareOutlined } from '@ant-design/icons';
const { Meta } = Card;
const { Option } = Select;
const { Text, Title } = Typography;

class Agreement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            acceptChecks: 0
        };
        this.handleCheckChange = this.handleCheckChange.bind(this);
    }
    componentDidMount(){
    }
    componentDidUpdate(prevProps){
    }
    
    handleSubmit(event) {
        
    }
    handleCheckChange(e) {
        console.log(e.target.checked);
        var acceptChecks = this.state.acceptChecks;
        acceptChecks = e.target.checked ? acceptChecks + 1 : acceptChecks - 1;
        console.log(acceptChecks);
        this.setState({acceptChecks: acceptChecks});
        this.props.onAcceptClick(acceptChecks);
    }
    render() {  
        return (
            <div>
            <Row>
                <Col>
                    <Row>
                        <Col md={24} style={{textAlign: 'center'}}>
                            <Title level={4}>THEhumble100 Service Agreement</Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24} style={{fontSize: '16px'}}>
                            <p>Welcome to THEhumble100! Our platform is designed to elevate your agency's performance by allowing you to know your numbers. From tracking marketing spend and acquisition costs to understanding ROI and monitoring close ratios, you'll gain crucial insights to make well-informed decisions. </p>
                            <p>To complete your registration, please provide the requested information and select your preferred plan. </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24}>
                            <Title level={4}>THEhumble100 Data Analytics Plan - $79/month</Title> 
                            <p style={{fontSize: '16px'}}><i>Upgrade to advanced insights and guidance:</i></p>
                            <div style={{marginLeft: '50px'}}>
                                <Row style={{fontSize: '16px'}}>
                                    <Col md={12}>
                                        <CheckSquareOutlined style={{color: 'green', fontSize: '20px'}} /> Dashboard
                                    </Col>
                                    <Col md={12}>
                                        <CheckSquareOutlined style={{color: 'green', fontSize: '20px'}} /> Marketing Performance Report
                                    </Col>
                                </Row>
                                <Row style={{fontSize: '16px'}}>
                                    <Col md={12}>
                                        <CheckSquareOutlined style={{color: 'green', fontSize: '20px'}} /> Settings
                                    </Col>
                                    <Col md={12}>
                                        <CheckSquareOutlined style={{color: 'green', fontSize: '20px'}} /> Marketing Goals
                                    </Col>
                                </Row>
                                <Row style={{fontSize: '16px'}}>
                                    <Col md={12}>
                                        <CheckSquareOutlined style={{color: 'green', fontSize: '20px'}} /> Activity Log
                                    </Col>
                                    <Col md={12}>
                                        <CheckSquareOutlined style={{color: 'green', fontSize: '20px'}} /> Monthly 1-hr Group Consult (not 1-on-1)
                                    </Col>
                                </Row>
                                <Row style={{fontSize: '16px'}}>
                                    <Col md={12}>
                                        <CheckSquareOutlined style={{color: 'green', fontSize: '20px'}} /> Reports
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <div style={{marginLeft: '50px', marginTop: '10px'}}>
                        <Row style={{fontSize: '16px'}}>
                            <Col md={24}>
                                <p><i>Reports Include:</i></p>
                            </Col>
                        </Row>
                        <div style={{marginLeft: '50px'}}>
                            <Row style={{fontSize: '16px'}}>
                                <Col md={12}>
                                    <CheckSquareOutlined style={{color: 'purple', fontSize: '20px'}} />Lead Source Performance
                                </Col>
                                <Col md={12}>
                                    <CheckSquareOutlined style={{color: 'purple', fontSize: '20px'}} />Product Activity Summary
                                </Col>
                            </Row>
                            <Row style={{fontSize: '16px'}}>
                                <Col md={12}>
                                    <CheckSquareOutlined style={{color: 'purple', fontSize: '20px'}} />Zip Code Summary
                                </Col>
                                <Col md={12}>
                                    <CheckSquareOutlined style={{color: 'purple', fontSize: '20px'}} />Graphs and Charts
                                </Col>
                            </Row>
                            <Row style={{fontSize: '16px'}}>
                                <Col md={12}>
                                    <CheckSquareOutlined style={{color: 'purple', fontSize: '20px'}} />Zip Code Upload
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <Row style={{fontSize: '16px', marginTop: '10px'}}>
                        <Col md={24} style={{border: 'solid 1px #3D76DD', borderRadius: "20px",overflow: "hidden", padding: '10px'}}>
                            <p style={{fontWeight: 'bold'}}>Agreement:</p>
                            <p>By clicking “Accept” below, you acknowledge that you have read and agreed to the terms of the selected plan and our Privacy Policy.</p>
                            <Checkbox onChange={(e) => this.handleCheckChange(e)}>ACCEPT</Checkbox>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Title level={4}>TH100 CONSULTING EFT PAYMENT AUTHORIZATION</Title>
                    <div style={{border: 'solid 1px #3D76DD', borderRadius: "20px",overflow: "hidden", padding: '10px'}}>
                    <Title level={5}>AUTHORIZATION:</Title>
                        <p>By Clicking “Accept”, you are authorizing TH100 Consulting LLC to create an EFT via Credit Card on your behalf for the sole purpose to pre-pay for THEhumble100 services with monthly recurring payment terms.  You are agreeing that you are the Authorized Representative or Owner of the Credit Card.</p>
                        <p>By clicking “Accept”, you are authorizing TH100 Consulting LLC’s  SQUARE Payment Processor to charge your credit card on a monthly recurring basis. </p>
                        <Checkbox onChange={this.handleCheckChange}>ACCEPT</Checkbox>
                    </div>
                    <Title level={5}>Data Security</Title>
                    <p>The security of your Personal Information is very important to us. We maintain organizational, technical, and administrative measures designed to protect Personal Information within our organization against unauthorized access, destruction, loss, alteration or misuse, consistent with applicable regulatory requirements and applicable published security standards. Your Personal Information is only accessible to a limited number of personnel who need access to the information to perform their duties.</p>
                    <p>However, no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially reasonable means to protect your Personal Information, we cannot guarantee absolute security. When you provide us with financial information, we encrypt the transmission of that information by using industry practices as outlined by the Payment Card Industry Data Security Standard (PCI DSS).</p>
                    <Title level={5}>TH100 Consulting LLC Privacy Policy:</Title>
                    <p>How We Use Payment Information</p>
                    <p>We will use EFT Payment Information for the following purposes:</p>
                    <p>To process payments for services rendered and completed by TH100 Consulting LLC.  All payment information is kept in an encrypted file that is accessible only by authorized personnel within the organization.  Payment information will not be shared outside of TH100 Consulting LLC, or with unauthorized personnel within the organization.</p>
                    <Title level={5}>THEhumble100 WEBSITE TERMS OF USE</Title>
                    <p style={{textDecoration: 'underline'}}>Acceptance of the Terms of Use</p>
                    <p>Welcome to our Website! The Website is made available by TH100 Consulting, LLC, a Georgia limited liability company ("THEhumble100," "we" or "us"). The following terms and conditions, together with any documents they incorporate by reference (collectively, these "Terms of Use"), govern your access to and use of www.thehumble100.net, our software, mobile applications, or any products hosted by or on behalf of THEhumble100, including without limitation any content, functionality and services offered on or through such websites, software, mobile applications, or services (collectively, the "Website"), whether as a guest or a registered user. Please read the Terms of Use carefully before you start to use the Website. By using the Website, you accept and agree to be bound and abide by these Terms of Use and our Privacy Policy, found at www.thehumble100.net/privacypolicy, incorporated herein by reference. If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use the Website.</p>
                    <p>The Website is offered and available to users that are Licensed Property and Casualty Insurance Agents and reside in the United States or any of its territories or possessions. By using the Website, you represent and warrant that you meet all of the eligibility requirements contained herein. If you do not meet all of these requirements, you must not access or use the Website.</p>
                    <p style={{textDecoration: 'underline'}}>Changes to the Terms of Use</p>
                    <p>We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them, and apply to all access to and use of the Website thereafter. Your continued use of the Website following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this each time you access the Website so you are aware of any changes, as they are binding on you.</p>
                    <p style={{textDecoration: 'underline'}}>Accessing the Website and Account Security</p>
                    <p>We reserve the right to withdraw or amend the Website, and any service or material we provide on or through the Website, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Website is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Website, or the entire Website, to users, including registered users.</p>
                    <ul>You are responsible for:
                        <li>Making all arrangements necessary for you to have access to the Website.</li>
                        <li>Ensuring that all persons who access the Website through your internet connection are aware of these Terms of Use and comply with them.</li>
                    </ul>
                    <p>To access the Website or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Website that all the information you provide on the Website is correct, current, and complete. You agree that all information you provide to register with, use the Website or otherwise, including but not limited to through the use of any interactive features on the Website which enable the uploading and extraction of information from PDF files, is governed by our Privacy Policy, and you consent to all actions we take with respect to the information you provide, consistent with our Privacy Policy.</p>
                    <p>If you choose, or are provided with, a user name, password or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to the Website or portions of it using your user name, password or other security information. You agree to notify us immediately of any unauthorized access to or use of your user name or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.</p>
                    <p>We have the right to disable any user name, password or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms of Use.</p>
                    <p style={{textDecoration: 'underline'}}>Intellectual Property Rights</p>
                    <p>The Website and its entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by TH100 CONSULTING LLC, its licensors or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.</p>
                    <ul>These Terms of Use permit you to use the Website as limited by TH100 CONSULTING LLC. You must not reproduce, distribute, modify, create derivative works of, publicly display, republish, download, store or transmit any of the material on the Website, except as follows:</ul>
                    <li>Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.</li>
                    <li>You may store files that are automatically cached by your Web browser for display enhancement purposes.</li> 
                    <li>If we provide desktop, mobile or other applications for download, you may download a single copy to your computer or mobile device solely for the purpose provided, provided you agree to be bound by our end user license agreement for such applications.</li>
                    <ul>You must not: 
                        <li>Modify copies of any materials from this site.</li>
                        <li>Use any illustrations, photographs, video or audio sequences or any graphics separately from the accompanying text.</li>
                        <li>Delete or alter any copyright, trademark or other proprietary rights notices from copies of materials from this site.</li>
                    </ul>
                    <p>You must not access or use for any commercial purposes any part of the Website or any services or materials available through the Website, except as may be provided by THEhumble100.</p>
                    <p>If you print, copy, modify, download or otherwise use or provide any other person with access to any part of the Website in breach of the Terms of Use, your right to use the Website will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title or interest in or to the Website or any content on the Website is transferred to you, and all rights not expressly granted are reserved by TH100 CONSULTING LLC or its licensors, as applicable. Any use of the Website not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark and other laws.</p>
                    <p style={{textDecoration: 'underline'}}>Trademarks</p>
                    <p>The THEhumble100 name, the THEhumble100 logo, and all related names, logos, product and service names, designs and slogans are trademarks of THEhumble100 or its affiliates or licensors. You must not use such marks without the prior written permission of THEhumble100. All other names, logos, product and service names, designs and slogans on the Website are the trademarks of their respective owners.</p>
                    <p style={{textDecoration: 'underline'}}>Prohibited Uses</p>
                    <p>You may use the Website only for lawful purposes and in accordance with these Terms of Use or as otherwise provided by TH100 CONSULTING LLC in writing. You agree not to use the Website:</p>
                    <ul>
                        <li>In any way that violates any applicable federal, state, local or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries).</li>
                        <li>For the purpose of exploiting, harming or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information or otherwise.</li>
                        <li>To send, knowingly receive, upload, download, use or re-use any material which does not comply with the Content Standards set out in these Terms of Use.</li>
                        <li>To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail", "chain letter" or "spam" or any other similar solicitation.</li> 
                        <li>To impersonate or attempt to impersonate THEhumble100, a THEhumble100 employee, another user or any other person or entity (including, without limitation, by using e-mail addresses, or screen names associated with any of the foregoing).</li>
                        <li>To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Website, or which, as determined by us, may harm THEhumble100 or users of the Website or expose them to liability.</li>
                        <p>Additionally, you agree not to:</p>
                        <li>Use the Website in any manner that could disable, overburden, damage, or impair the site or interfere with any other party's use of the Website, including their ability to engage in real time activities through the Website. </li>
                        <li>Use any robot, spider or other automatic device, process or means to access the Website for any purpose, including monitoring or copying any of the material on the Website.</li>
                        <li>Use any manual process to monitor or copy any of the material on the Website or for any other unauthorized purpose without our prior written consent.</li>
                        <li>Use any device, software or routine that interferes with the proper working of the Website.</li>
                        <li>Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful.</li> 
                        <li>Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Website, the server on which the Website is stored, or any server, computer or database connected to the Website.</li> 
                        <li>Attack the Website via a denial-of-service attack or a distributed denial-of-service attack.</li>
                        <li>Otherwise attempt to interfere with the proper working of the Website.</li>
                    </ul> 
                    <p style={{textDecoration: 'underline'}}>User Contributions</p>
                    <p>The Website may contain profiles, forums, dashboards, and other interactive features (collectively, "Interactive Services") that allow users to post, submit, upload, publish, display or transmit to other users or other persons (hereinafter, "post") content or materials (collectively, "User Contributions") on or through the Website.</p>
                    <p>All User Contributions must comply with the Content Standards set out in these Terms of Use.</p>
                    <p>Any User Contribution you post to the site will be considered non-confidential and nonproprietary. By providing any User Contribution on the Website, you grant us and our affiliates and service providers, and each of their and our respective licensees, successors and assigns the right to use, reproduce, modify, perform, display, distribute and otherwise disclose to third parties any such material for any purpose.</p>
                    <ul>You represent and warrant that:
                        <li>You own or control all rights in and to the User Contributions and have the right to grant the license granted above to us and our affiliates and service providers, and each of their and our respective licensees, successors and assigns.</li>
                        <li>All of your User Contributions do and will comply with these Terms of Use.</li>
                    </ul>
                    <p>You understand and acknowledge that you are responsible for any User Contributions you submit or contribute, and you, not TH100 CONSULTING LLC, have full responsibility for such content, including its legality, reliability, accuracy and appropriateness.</p>
                    <p>We are not responsible, or liable to any third party, for the content or accuracy of any User Contributions posted by you or any other user of the Website.</p>
                    <p>Monitoring and Enforcement; Termination </p>
                    <ul>We have the right to: 
                        <li>Remove or refuse to post any User Contributions for any or no reason at our sole discretion.</li>
                        <li>Take any action with respect to any User Contribution that we deem necessary or appropriate in our sole discretion, including if we believe that such User Contribution violates the Terms of Use, including the Content Standards, infringes any intellectual property right or other right of any person or entity, threatens the personal safety of users of the Website or the public or could create liability for THEhumble100. </li>
                        <li>Disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy.</li>
                        <li>Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Website.</li>
                        <li>Terminate or suspend your access to all or part of the Website for any or no reason, including without limitation, any violation of these Terms of Use.</li>
                    </ul>    
                    <p>Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Website. YOU WAIVE AND HOLD HARMLESS TH100 CONSULTING LLC AND ITS AFFILIATES, LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.</p>
                    <p>However, we do not undertake to review material before it is posted on the Website, and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications or content provided by any user or third party. We have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section.</p>
                    <p style={{textDecoration: 'underline'}}>Content Standards</p>
                    <p>These content standards apply to any and all User Contributions and use of Interactive Services. User Contributions must in their entirety comply with all applicable federal, state, local and international laws and regulations. Without limiting the foregoing, User Contributions must not:</p>
                    <ul>
                        <li>Contain any material which is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory or otherwise objectionable.</li>
                        <li>Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.</li>
                        <li>Infringe any patent, trademark, trade secret, copyright or other intellectual property or other rights of any other person.</li> 
                        <li>Violate the legal rights (including without limitation contractual rights and the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Use and our Privacy Policy.</li> 
                        <li>Be likely to deceive any person.</li> 
                        <li>Promote any illegal activity, or advocate, promote or assist any unlawful act.</li> 
                        <li>Cause annoyance, inconvenience or needless anxiety or be likely to upset, embarrass, alarm or annoy any other person.</li> 
                        <li>Impersonate any person, or misrepresent your identity or affiliation with any person or organization.</li> 
                        <li>Give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not the case.</li>
                    </ul>
                    <p style={{textDecoration: 'underline'}}>Copyright Infringement</p>
                    <p>If you believe that any User Contributions violate your copyright, please see our Copyright Policy below for instructions on sending us a notice of copyright infringement. It is the policy of TH100 CONSULTING LLC to terminate the user accounts of repeat infringers.</p>
                    <p>We treat claims of copyright infringement with utmost seriousness. We will address notices of alleged copyright infringement that adhere to relevant legal requirements. If you believe that any materials accessible on or from the Website violate your copyright, you can request the removal of those materials (or access to them) from the Website by sending a written notification to the address provided for such requests. In accordance with the Online Copyright Infringement Liability Limitation Act of the Digital Millennium Copyright Act (17 U.S.C. § 512) ("DMCA"), the written notice (the "DMCA Notice") must include substantially the following: </p>
                    <ul>
                        <li>Your physical or electronic signature.</li>
                        <li>Identification of the copyrighted work you believe to have been infringed or, if the claim involves multiple works on the Website, a representative list of such works. </li>
                        <li>Identification of the material you believe to be infringing in a sufficiently precise manner to allow us to locate that material.</li>
                        <li>Adequate information by which we can contact you (including your name, postal address, telephone number and, if available, e-mail address).</li> 
                        <li>A statement that you have a good faith belief that use of the copyrighted material is not authorized by the copyright owner, its agent or the law.</li>
                        <li>A statement that the information in the written notice is accurate.</li>
                        <li>A statement, under penalty of perjury, that you are authorized to act on behalf of the copyright owner. </li>
                    </ul>
                    <p>
                        Send DMCA Notices to:<br />
                        1080 Holcomb Bridge Rd Bldg 200130<br />
                        Roswell, Georgia 30076<br />
                        sales@th100consulting.com
                    </p>
                    <p style={{textDecoration: 'underline'}}>Reliance on Information Posted</p> 
                    <p>THE INFORMATION PRESENTED ON OR THROUGH THE WEBSITE IS MADE AVAILABLE SOLELY FOR GENERAL INFORMATION PURPOSES. WE DO NOT WARRANT THE ACCURACY, COMPLETENESS, USEFULNESS, OR SUITABILITY OF THIS INFORMATION, INCLUDING WITHOUT LIMITATION THE WEBSITE’S SUITABILITY OR UNSUITABILITY FOR A PARTICULAR USE OR ENVIRONMENT INCLUDING WITHOUT LIMITATION FOR USE WITHIN THE INSURANCE INDUSTRY. ANY RELIANCE YOU PLACE ON SUCH INFORMATION IS STRICTLY AT YOUR OWN RISK. WE DISCLAIM ALL LIABILITY AND RESPONSIBILITY ARISING FROM ANY RELIANCE PLACED ON SUCH MATERIALS BY YOU OR ANY OTHER VISITOR TO THE WEBSITE, OR BY ANYONE WHO MAY BE INFORMED OF ANY OF ITS CONTENTS. WE ARE NOT RESPONSIBLE, OR LIABLE TO YOU OR ANY THIRD PARTY, FOR THE CONTENT OR ACCURACY OF ANY MATERIALS PROVIDED BY ANY THIRD PARTIES.</p>
                    <p>Changes to the Website</p>
                    <p>We may update the content on the Website from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the Website may be out of date at any given time, and we are under no obligation to update such material.</p>
                    <p>Information About You and Your Visits to the Website</p> 
                    <p>All information we collect on the Website is subject to our Privacy Policy. By using the Website, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy. </p>
                    <p>Online Purchases and Other Terms and Conditions </p>
                    <p>All purchases through our site or other transactions for the sale or licensing of goods or services formed through the Website or as a result of visits made by you are governed by the applicable TH100 CONSULTING LLC  License Agreement (“Terms of Sale”), which are hereby incorporated into these Terms of Use. Additional terms and conditions may also apply to specific portions, services or features of the Website. All such additional terms and conditions are hereby incorporated by this reference into these Terms of Use. </p>
                    <p>Linking to the Website and Social Media Features</p>
                    <p>You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part without our express written consent. </p>
                    <p>The Website may provide certain social media features that enable you to:</p> 
                    <ul>
                        <li>Link from your own or certain third-party websites to certain content on the Website.</li>
                        <li>Send e-mails or other communications with certain content, or links to certain content, on the Website.</li> 
                        <li>Cause limited portions of content on the Website to be displayed or appear to be displayed on your own or certain third-party websites.</li>
                        <li>You may use these features solely as they are provided by us, and solely with respect to the content they are displayed with and otherwise in accordance with any additional terms and conditions we provide with respect to such features. Subject to the foregoing, you must not:</li>
                        <li>Otherwise take any action with respect to the materials on the Website that is inconsistent with any other provision of these Terms of Use.</li>
                    </ul>
                    <p>The website from which you are linking, or on which you make certain content accessible, must comply in all respects with the Content Standards set out in these Terms of Use.</p>
                    <p>You agree to cooperate with us in causing any unauthorized framing or linking immediately to cease. We reserve the right to withdraw linking permission without notice. </p>
                    <p>We may disable all or any social media features and any links at any time without notice at our discretion. </p>
                    <div style={{border: 'solid 1px #3D76DD', borderRadius: "20px",overflow: "hidden", padding: '10px'}}>
                        <Checkbox onChange={this.handleCheckChange}>ACCEPT</Checkbox>
                    </div>
                </Col>
            </Row>
            </div>
        );
    }
}
export default connect(
    state => state.action
)(Agreement);