import React, { Component, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Card, Input, Row, Col, List, Typography, Select, Button, Radio, Checkbox, message, Upload, Spin } from 'antd';
import type { UploadProps } from 'antd';
import { DeleteOutlined, SaveOutlined, PlusCircleOutlined, PlayCircleOutlined, UploadOutlined, FileTextOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import "video-react/dist/video-react.css";
import { Player, BigPlayButton, ControlBar } from 'video-react';
const {Title, Text} = Typography
const {Option} = Select
const { Dragger } = Upload;

class UploadMaterials extends Component {
    constructor(props) {
      super(props);
        this.state = {
            educationSection: '',
            uploadingSectionId: 0
        }
        this.handleUploadFile = this.handleUploadFile.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.handleDeleteMaterial = this.handleDeleteMaterial.bind(this);
         
    }
    
    componentDidMount() {
        const agencyId = localStorage.getItem('agencyId');
        this.props.getEducationMaterials(agencyId);
    }
    handleUploadFile(file, educationSectionId) {
        this.setState({uploadingSectionId: educationSectionId});
        const agencyId = localStorage.getItem('agencyId');
        this.props.createEducationMaterials(agencyId, educationSectionId, file);
        return false;
    }
    onDragEnd = ({ source, destination, draggableId}) => {
        if (destination == undefined) {
            return;
        }
        if(destination.index == source.index){
            return;
        }
        const agencyId = localStorage.getItem('agencyId');
        console.log(destination); 
        this.props.updateEducationMaterials(agencyId, draggableId, destination.droppableId, source.index, destination.index);
    }
    handleDeleteMaterial(materialId) {
        const agencyId = localStorage.getItem('agencyId');
        this.props.deleteEducationMaterial(agencyId, materialId);
    }
    
    render() {
        const props = {
            name: 'file',
            multiple: false,
            showUploadList: false,
            beforeUpload: (file) => {
                console.log(file);
                const agencyId = localStorage.getItem('agencyId');
                this.props.createEducationMaterials(agencyId, 0, file);
                return false;
            },
        };
        const uploadingSectionId = this.state.uploadingSectionId;
        console.log(this.props.uploadingEducationSectionId);
        return (
            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={18} style={{paddingLeft: 16}}>
                            <Row>
                                <Col span={24}>
                                    <Dragger name="file" multiple={false} beforeUpload={(file) => this.handleUploadFile(file, this.props.educationSectionId)} onChange={(info) => console.log(info)} showUploadList={false}>
                                        <p className="ant-upload-drag-icon">
                                        <UploadOutlined />
                                        </p>
                                        <p className="ant-upload-text">Click or drag video and document files to this area to upload</p>
                                    </Dragger>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                {
                                    this.props.educationMaterials &&
                                        <DragDropContext onDragEnd={this.onDragEnd}>
                                            <Droppable droppableId={this.props.educationSectionId.toString()}>
                                            {(providedM, snapshot) => (
                                                <div {...providedM.droppableProps} ref={providedM.innerRef}>
                                                    {this.props.educationMaterials.filter(m => m.educationSectionId == this.props.educationSectionId).map((m, index) => (
                                                        <Draggable key={m.id} draggableId={m.id.toString()} index={index}>
                                                        {(providedM, snapshot) => (
                                                            <div ref={providedM.innerRef} {...providedM.draggableProps} {...providedM.dragHandleProps}>
                                                                <div style={{backgroundColor: snapshot.isDragging ? '#f1f1f1' : '#fff', marginTop: 20, padding: 10, display: 'flex', justifyContent: 'space-between', border: 'solid 1px #3D76DD', borderRadius: "10px"}}>
                                                                    <a style={{fontSize: 18}} target="_blank" href={m.url}>{m.fileName.includes(".mp4") || m.fileName.includes(".mov") ? <PlayCircleOutlined /> : <FileTextOutlined />} {m.fileName}</a>
                                                                    <Button shape="circle" icon={<DeleteOutlined />} onClick={() => this.handleDeleteMaterial(m.id)} />
                                                                </div>
                                                            </div>
                                                        )}
                                                        </Draggable>
                                                    ))}
                                                    {providedM.placeholder}
                                                </div>
                                            )}
                                            </Droppable>
                                        </DragDropContext>
                                }
                                {
                                    this.props.isLoading ?
                                    <div style={{textAlign: 'center'}}><Spin></Spin><p><Text>Uploading File...</Text></p></div>
                                    :
                                    <div></div>
                                }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(UploadMaterials);