import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Row, Col, Checkbox, Typography, Space, Table, Modal, DatePicker, Spin, Button, Select, Anchor, Input, Cascader } from 'antd';
import { SearchOutlined, DownloadOutlined, FunnelPlotOutlined } from '@ant-design/icons';
import moment from 'moment';
import { pick, groupBy, map, first } from "lodash";
const {Title, Text} = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

class ReferringAgentSummary extends Component {
    constructor(props) {
        super(props);
          this.state = {
            dateTo: moment().endOf('month').format("MM/DD/YYYY"),
            dateFrom: moment().startOf('month').format("MM/DD/YYYY"),
            selectedAgent: null,
            modalVisible: false,
            filteredPolicySummary: [],
            numberFrom: 0,
            numberTo: 0,
            leadSourceList: '',
            selectedValues: [],
            selectedReferringAgent: null,
            referringAgents: null
          }
          this.myRef = React.createRef();
          this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
          this.handleReset = this.handleReset.bind(this);
          this.handleChange = this.handleChange.bind(this);
          this.exportToCSV = this.exportToCSV.bind(this);
          this.handleSelectedAgentChange = this.handleSelectedAgentChange.bind(this);
          this.handleClearFilters = this.handleClearFilters.bind(this);
          this.handleReferringAgentChange = this.handleReferringAgentChange.bind(this);
    }
    componentDidMount() {
        const agencyId = localStorage.getItem('agencyId');
        const dateFrom = moment().startOf('month').format("MM/DD/YYYY");
        const dateTo = moment().endOf('month').format("MM/DD/YYYY");
        this.props.getAgent(agencyId);
        this.props.getReferringAgent(agencyId);
        this.props.getTypeVendorTypeLeadSources(agencyId);
        this.props.getReferringAgentSummary(agencyId, dateFrom, dateTo);
    }
    componentDidUpdate(prevProps){

    }
    handleDateRangeChange(dates, dateStrings) {
        const dateFrom = dateStrings[0];
        const dateTo = dateStrings[1];
        const agencyId = localStorage.getItem('agencyId');
        const selectedAgent = this.state.selectedAgent == null ? '' : this.state.selectedAgent;
        const referringAgent = this.state.selectedReferringAgent;
        if(dateFrom != ''){
            this.props.getReferringAgentSummary(agencyId, dateFrom, dateTo);
        }
        this.setState({
            dateFrom: dateFrom,
            dateTo: dateTo 
        });
    }
    handleOpenModal = () => {
        this.setState({ modalVisible: true });
    }
    handleSelectedAgentChange(value){
        var agent = value == 'None' ? '' : value;
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo;
        const agencyId = localStorage.getItem('agencyId');
        const referringAgent = this.state.selectedReferringAgent;
        this.props.getReferringAgentSummary(agencyId, dateFrom, dateTo);
        this.setState({selectedAgent: agent});
    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleTableSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleTableSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#97E45D' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
    });
    handleTableSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
    };
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    
    exportToCSV = (e) => {
        e.preventDefault();
        var data, filename, link;
        data = this.props.referringAgentSummary;
        var csv = this.convertArrayOfObjectsToCSV(data);
        if (csv == null)
          return;
    
        filename = 'ReferringAgentSummary' + this.state.dateFrom + '-' + this.state.dateTo + '.csv';
    
        var blob = new Blob([csv], {type: "text/csv;charset=utf-8;"});
    
        if (navigator.msSaveBlob)
        { // IE 10+
          navigator.msSaveBlob(blob, filename)
        }
        else
        {
          var link = document.createElement("a");
          if (link.download !== undefined)
          {
            // feature detection, Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style = "visibility:hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      }
    
      convertArrayOfObjectsToCSV(args) {
        var result, ctr, keys, columnDelimiter, lineDelimiter, data;
    
        data = args || null;
        if (data == null || !data.length) {
            return null;
        }
    
        columnDelimiter = args.columnDelimiter || ',';
        lineDelimiter = args.lineDelimiter || '\n';
    
        keys = Object.keys(data[0]);
    
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;
    
        data.forEach(function(item) {
            ctr = 0;
            keys.forEach(function(key) {
                    if (ctr > 0) result += columnDelimiter;
                    result += item[key]
                    ctr++;
            });
            result += lineDelimiter;
        });
        return result;
    };
    handleClearFilters(){
        const agencyId = localStorage.getItem('agencyId');
        const dateFrom = moment().startOf('month').format("MM/DD/YYYY");
        const dateTo = moment().endOf('month').format("MM/DD/YYYY");
        this.props.getReferringAgentSummary(agencyId, dateFrom, dateTo);
        this.setState({selectedValues: [], dateTo: dateTo, dateFrom: dateFrom, selectedReferringAgent: null, selectedAgent: null, referringAgents: null});
    }
    handleReferringAgentChange = (e) => {
        console.log(e);
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo;
        const agencyId = localStorage.getItem('agencyId');
        const agent = this.state.selectedAgent;
        //this.props.getReferringAgentSummary(agencyId, dateFrom, dateTo);
        this.setState({selectedReferringAgent: e});
    }
    render() {
       
        const summary = this.props.referringAgentSummary; //this.state.selectedReferringAgent == null ? this.props.referringAgentSummary : this.props.referringAgentSummary.filter(r => r.referringAgent == this.state.selectedReferringAgent);
        let totalWrittenItems = 0;
        let totalQuotedItems = 0;
        let totalWrittenPolicies = 0;
        let totalQuotedPolicies = 0;
        let totalPremium = 0;
        summary &&
            summary.forEach(({ writtenItems, quotedItems, quotedPolicies, writtenPolicies, premium }) => {
                totalWrittenItems += writtenItems;
                totalQuotedItems += quotedItems;
                totalWrittenPolicies += writtenPolicies;
                totalQuotedPolicies += quotedPolicies;
                totalPremium += premium;
            });
            
        const summaryTable = (
            <table width='100%'>
                <tr style={{backgroundColor: '#3D76DD', fontWeight: 'bold'}}>
                    <th></th>
                    <td style={{textAlign: 'center'}} key="totalQuotedItems">
                        <Text style={{color: '#fff'}}>Quoted Items</Text>
                    </td>
                    <td style={{textAlign: 'center'}} key="totalWrittenItems">
                        <Text style={{color: '#fff'}}>Written Items</Text>
                    </td>
                    <td style={{textAlign: 'center'}} key="totalQuotedPolicies">
                        <Text style={{color: '#fff'}}>Quoted Policies</Text>
                    </td>
                    <td style={{textAlign: 'center'}} key="totalWrittenPolicies">
                        <Text style={{color: '#fff'}}>Written Policies</Text>
                    </td>
                    <td style={{textAlign: 'center'}} key="totalCloseRate">
                        <Text style={{color: '#fff'}}>Close Rate</Text>
                    </td>
                    <td style={{textAlign: 'center'}} key="totalWrittenPremium">
                        <Text style={{color: '#fff'}}>Written Premium</Text>
                    </td>
                </tr>
                <tr ref={this.myRef} key="totalRow" style={{fontWeight: 'bold'}}>
                    <th>Total</th>
                    
                    <td style={{textAlign: 'center'}} key="totalQuotedItems">
                        <Text>{totalQuotedItems}</Text>
                    </td>
                    <td style={{textAlign: 'center'}} key="totalWrittenItems">
                        <Text>{totalWrittenItems}</Text>
                    </td>
                    <td style={{textAlign: 'center'}} key="totalQuotedPolicies">
                        <Text>{totalQuotedPolicies}</Text>
                    </td>
                    <td style={{textAlign: 'center'}} key="totalWrittenPolicies">
                        <Text>{totalWrittenPolicies}</Text>
                    </td>
                    <td style={{textAlign: 'center'}} key="totalCloseRate">
                        <Text>
                        {new Intl.NumberFormat("en-US", {
                            style: "percent",
                            maximumFractionDigits: 2
                        }).format(totalWrittenPolicies / totalQuotedPolicies)}
                        </Text>
                    </td>
                    <td style={{textAlign: 'center'}} key="totalWrittenPremium">
                        <Text>{new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD"
                        }).format(totalPremium)}</Text>
                    </td>
                </tr>
            </table>
        )
        const onChange = (value: string[][]) => {
            var leadSourceList = '';
            
            for (var i=0; i < value.length; i++){
                if(value[i].length > 1){
                    leadSourceList += leadSourceList == '' ? value[i][1] : ',' + value[i][1];
                } else {
                    var fullList = this.props.typeVendorTypesLeadSources.filter(l => l.value == value[i][0]);
                    var children = fullList[0].children;
                    for (var x=0; x < children.length; x++){
                        leadSourceList += leadSourceList == '' ? children[x].value : ',' + children[x].value;
                    }
                }
            }
            const agencyId = localStorage.getItem('agencyId');
            const dateFrom = this.state.dateFrom;
            const dateTo = this.state.dateTo;
            const agent = this.state.selectedAgent == null ? '' : this.state.selectedAgent;
            this.props.getQuoteLogPoliciesSummary(agencyId, dateFrom, dateTo, agent, leadSourceList);
            this.setState({leadSourceList: leadSourceList, selectedValues: value});
        };
        const onReferringAgentChange = (value: string[][], label: string[][]) => {
            var referringAgentList = [];
            console.log(label);
            for (var i=0; i < label.length; i++){
                if(value[i].length == 1){
                    //referringAgentList += referringAgentList == '' ? label[i][0].label : ',' + label[i][0].label;
                    referringAgentList.push(label[i][0].label);
                } 
                //else {
                //     var fullList = this.props.typeVendorTypesLeadSources.filter(l => l.value == value[i][0]);
                //     var children = fullList[0].children;
                //     for (var x=0; x < children.length; x++){
                //         leadSourceList += leadSourceList == '' ? children[x].value : ',' + children[x].value;
                //     }
                // }
            }
            console.log(referringAgentList);
            const agencyId = localStorage.getItem('agencyId');
            const dateFrom = this.state.dateFrom;
            const dateTo = this.state.dateTo;
            // const agent = this.state.selectedAgent == null ? '' : this.state.selectedAgent;
            // this.props.getQuoteLogPoliciesSummary(agencyId, dateFrom, dateTo, agent, leadSourceList);
            this.setState({selectedReferringAgent: value, referringAgents: referringAgentList});
        };
        let desired_output = (summary) => {
            let unique_values = [
                ...new Set(summary.map((element) => element.referringAgent)),
            ];
            return unique_values;
        };
        var referringAgentList = this.props.referringAgent.filter(r => r.inactive == false).map(a => ({ value: a.id, label: a.name }));
        
        const distinctReferringAgent = this.state.referringAgents == null ? desired_output(summary) : this.state.referringAgents;
        //const casReferringAgent = referringAgentList(this.props.selectedReferringAgent);
        return (
            <div>
                <Row>
                    <Col md={24}>
                        <Title level={3}>REFERRING AGENT SUMMARY</Title>
                    </Col>
                </Row>
                {this.props.isQuoteLogLoading ?<div style={{textAlign: 'center'}}> <Spin></Spin><p><Title level={3}>Loading report.  Please do not click the refresh button...</Title></p></div>:
                <div>
                <Row>
                    <Col span={5}>
                        <RangePicker format={'MM/DD/YYYY'} allowEmpty={true}
                            style={{ width: '90%' }}
                            ranges={{
                                Today: [moment(), moment()],
                                'This Week': [moment().startOf('week'), moment().endOf('week')],
                                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            }}
                            defaultValue={[moment().startOf('month'), moment().endOf('month')]}
                            value={this.state.dateFrom == null ? [] : [moment(this.state.dateFrom), moment(this.state.dateTo)]}
                            onChange={this.handleDateRangeChange}
                            onOpenChange={this.handlePanelChange}
                        />
                    </Col>
                    {/* <Col span={5}>
                        <Cascader
                            style={{ width: '90%' }}
                            options={this.props.typeVendorTypesLeadSources}
                            onChange={onChange}
                            multiple
                            maxTagCount="responsive"
                            placeholder={<strong style={{color: 'black'}}>Select Lead Source</strong>}
                            value={this.state.selectedValues}
                        />
                    </Col> */}
                    {/* <Col span={4}>
                        <Cascader
                            style={{ width: '90%' }}
                            options={this.props.typeVendorTypesLeadSources}
                            onChange={onChange}
                            multiple
                            maxTagCount="responsive"
                            placeholder="Select Lead Source"
                            value={this.state.selectedValues}
                        />
                    </Col> */}
                    <Col sm={5}>
                        <Cascader
                            style={{ width: '90%' }}
                            options={referringAgentList}
                            onChange={onReferringAgentChange}
                            multiple
                            maxTagCount="responsive"
                            placeholder={<strong style={{color: 'black'}}>Select Referring Agent</strong>}
                            value={this.state.selectedReferringAgent}
                        />
                        {/* <Select
                            mode="multiple"
                            style={{ width: '90%' }}
                            dropdownStyle={{ minWidth: "20%" }}
                            placeholder={<strong style={{color: 'black'}}>Select Referring Agent</strong>}
                            name="referringAgent"
                            value={this.state.selectedReferringAgent}
                            onChange={this.handleReferringAgentChange}>
                                <Option key="" value="">{"All"}</Option>
                            {
                                this.props.referringAgent.filter(a => !a.inactive).map(a => 
                                    <Option key={a.id} value={a.name}>{a.name}</Option>
                                )
                            }
                            
                        </Select> */}
                    </Col>
                    <Col sm={5}>
                        <Button shape="round" onClick={() => this.handleClearFilters()} style={{color: this.state.filtered ? 'Blue' : 'Black'}}>
                            <FunnelPlotOutlined /> Clear Filters
                        </Button>
                    </Col>
                    <Col span={9}>
                        <Button shape="round" onClick={this.exportToCSV} style={{position: 'absolute', right: 0}}>
                            <DownloadOutlined /> Export
                        </Button>
                    </Col>
                </Row>
                <Row style={{paddingTop: 10}}>
                    <Col md={24}>
                        {summaryTable}
                    </Col>
                </Row>
                <Row>
                    <Col md={24}>
                        <table style={{width: '100%'}}>
                            <thead>
                                <tr style={{backgroundColor: '#3D76DD', fontWeight: 'bold', color: '#fff', textAlign: 'center'}}>
                                    <td></td>
                                    <td>Quoted Households</td>
                                    <td>Written Households</td>
                                    <td>Quoted Items</td>
                                    <td>Written Items</td>
                                    <td>Quoted Policies</td>
                                    <td>Written Policies</td>
                                    <td>Close Rate</td>
                                    <td>Writtem Premium</td>
                                </tr>
                            </thead>
                            
                            {distinctReferringAgent.map(dc => {
                                let totalRAQuotedHouseholds = 0;
                                let totalRAWrittenHouseholds = 0;
                                let totalRAQuotedItems = 0;
                                let totalRAWrittenItems = 0;
                                let totalRAQuotedPolicies = 0;
                                let totalRAWrittenPolicies = 0;
                                let totalRAPremium = 0;
                                return <tbody><tr key={dc}><td colSpan={9} style={{borderTop: 'solid 1px #ccc', backgroundColor: '#ccc', fontSize: 18}}><strong>{dc}</strong></td></tr>
                                {
                                summary.filter(s => s.referringAgent == dc).map(c => {
                                    totalRAQuotedHouseholds += c.quotedHouseholds;
                                    totalRAWrittenHouseholds += c.writtenHouseholds;
                                    totalRAQuotedItems += c.quotedItems;
                                    totalRAWrittenItems += c.writtenItems;
                                    totalRAQuotedPolicies += c.quotedPolicies;
                                    totalRAWrittenPolicies += c.writtenPolicies;
                                    totalRAPremium += c.premium;
                                    return <tr>
                                                <td>{c.leadSource}</td>
                                                <td style={{textAlign: 'center'}}>{c.quotedHouseholds}</td>
                                                <td style={{textAlign: 'center'}}>{c.writtenHouseholds}</td>
                                                <td style={{textAlign: 'center'}}>{c.quotedItems}</td>
                                                <td style={{textAlign: 'center'}}>{c.writtenItems}</td>
                                                <td style={{textAlign: 'center'}}>{c.quotedPolicies}</td>
                                                <td style={{textAlign: 'center'}}>{c.writtenPolicies}</td>
                                                <td style={{textAlign: 'center'}}>{new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(c.writtenPolicies / c.quotedPolicies)}</td>
                                                <td style={{textAlign: 'center'}}>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(c.premium)}</td>
                                            </tr>
                                })}
                                    <tr style={{backgroundColor: '#ebf1fb'}}>
                                        <td><strong>Total</strong></td>
                                        <td style={{textAlign: 'center'}}>{totalRAQuotedHouseholds}</td>
                                        <td style={{textAlign: 'center'}}>{totalRAWrittenHouseholds}</td>
                                        <td style={{textAlign: 'center'}}>{totalRAQuotedItems}</td>
                                        <td style={{textAlign: 'center'}}>{totalRAWrittenItems}</td>
                                        <td style={{textAlign: 'center'}}>{totalRAQuotedPolicies}</td>
                                        <td style={{textAlign: 'center'}}>{totalRAWrittenPolicies}</td>
                                        <td style={{textAlign: 'center'}}>{new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(totalRAWrittenPolicies / totalRAQuotedPolicies)}</td>
                                        <td style={{textAlign: 'center'}}>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(totalRAPremium)}</td>
                                    </tr>
                                </tbody>})
                    }</table>
                    </Col>
                </Row>
                <Modal 
                    width="75%"
                    visible={this.state.modalVisible}
                    title="SELECT VENDORS"
                    onOk={this.handleCloseModal}
                    onCancel={this.handleCloseModal}
                    footer={[
                        <Row style={{paddingBottom: 10}} >
                            <Col sm={12}>
                                <Button style={{ float: 'left' }} type="primary" shape="round" onClick={this.handleCloseModal}>
                                    OK
                                </Button>
                            </Col>
                        </Row>,
                    ]}>
                        <Checkbox.Group style={{ width: '100%' }} onChange={this.handleSelectedLeadSourceChange}>
                            <Row>
                                {this.props.leadSource.map(a => 
                                    <Col span={8} key={a.id}>
                                        <Checkbox value={a.name}>{a.name}</Checkbox>
                                    </Col>
                                )}
                            </Row>
                        </Checkbox.Group>
                </Modal>
                </div>}
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ReferringAgentSummary);