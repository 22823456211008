import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Row, Col, InputNumber, Typography, Select, Button, Input, List, DatePicker, Cascader, Radio } from 'antd'
import { SaveOutlined } from '@ant-design/icons';
import moment from 'moment'
const { Title, Text } = Typography;
const {Option} = Select

class Mailer extends Component {
    constructor(props) {
      super(props);
        this.state = {
            mailerMonthlyCount: 0,
            mailerResponseRate: 0,
            mailerQuoteRatio: 0,
            mailerCloseRatio: 0,
            mailerPerHousehold: 0,
            mailerResponseRateCalc: 0,
            mailerQuoteRatioCalc: 0,
            mailerCloseRatioCalc: 0,
            mailerTotalPremium: 0,
            mailerMonthlyCost: 0,
            mailerMonthlyCostCalc: 0,
            mailerCostWrittenItems: 0,
            actualMailerMonthlyCount: 0,
            actualMailerResponseRate: 0,
            actualMailerQuoteRatio: 0,
            actualMailerMonthlyCost: 0,
            name: '',
            selectedLeadSourceId: '',
            monthDts: moment().format("MMM YYYY"),
            leadSourceFilter: 'Active',
        };
        this.handleSave = this.handleSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
    }
    componentDidMount() {
        const userId = localStorage.getItem('userId');
        const agencyId = localStorage.getItem('agencyId');
        this.props.getTypeVendorTypeLeadSources(agencyId);
        const dateTo = moment().endOf('month').format("MM/DD/YYYY");
        const dateFrom = moment().startOf('month').format("MM/DD/YYYY");
        this.props.getMailer(agencyId, moment().format("MMM YYYY"));
        this.props.getLeadSource(agencyId);
    }
    componentDidUpdate(prevProps){
        if(prevProps.mailer != this.props.mailer){
            this.props.onMailerTotal();
        }
    }
    onMailerMonthlyCountChange = value => {
        this.setState({
            mailerMonthlyCount: value
        });
    }
    onMailerResponseRateChange = value => {
        this.setState({
            mailerResponseRate: value
        });
    }
    onMailerQuoteRatioChange = value => {
        this.setState({
            mailerQuoteRatio: value
        });
    }
    onMailerCloseRatioChange = value => {
        this.setState({
            mailerCloseRatio: value
        });
    }
    onMailerMonthlyCostChange = value => {
        this.setState({
            mailerMonthlyCost: value
        });
    }
    onActualMailerMonthlyCountChange = value => {
        this.setState({
            actualMailerMonthlyCount: value
        });
    }
    onActualMailerResponseRateChange = value => {
        this.setState({
            actualMailerResponseRate: value
        });
    }
    onActualMailerQuoteRatioChange = value => {
        this.setState({
            actualMailerQuoteRatio: value
        });
    }
    onActualMailerMonthlyCostChange = value => {
        this.setState({
            actualMailerMonthlyCost: value
        });
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    onMonthChange(date, dateString) {
        const agencyId = localStorage.getItem("agencyId");
        if(dateString != ''){
            const dateTo = moment(date).endOf('month').format("MM/DD/YYYY");
            const dateFrom = moment(date).startOf('month').format("MM/DD/YYYY");
            this.props.getVendorSummary(agencyId, dateFrom, dateTo, '');
            this.setState({
                monthDts: dateString
            });
            this.props.getMailer(agencyId, moment(date).format("MMM YYYY"));
        }
        else{
            this.setState({
                monthDts: moment().format("MMM YYYY")
            });
        } 
    };
    handleListClick = (mailer) => {
        var leadSource = this.props.vendorSummary.filter(v => v.vendor == mailer.typeQuoteLogLeadSourceId);
        var leadSourceType = this.props.typeVendorTypesLeadSources.filter(c => c.label == leadSource[0].vendorType);
        var selectedValues = mailer.typeQuoteLogLeadSourceId;
        this.setState({
            id: mailer.id,
            name: mailer.name,
            selectedValues: selectedValues,
            selectedLeadSourceId: mailer.typeQuoteLogLeadSourceId,
            vendor: String(mailer.vendorId),
            mailerMonthlyCount: mailer.monthlyCount, 
            mailerResponseRate: mailer.responseRate, 
            mailerQuoteRatio: mailer.quoteRatio,
            actualMailerMonthlyCount: mailer.actualMonthlyCount, 
            actualMailerResponseRate: mailer.actualResponseRate, 
            actualMailerQuoteRatio: mailer.actualQuoteRatio, 
            mailerCloseRatio: mailer.closeRatio,
            mailerMonthlyCost: mailer.monthlyCost,
            actualMailerMonthlyCost: mailer.actualMonthlyCost,
            monthDts: mailer.monthDts
        });
    }
    handleSave(e) {
        e.preventDefault()
        const userId = localStorage.getItem("userId");
        const agencyId = localStorage.getItem("agencyId");
        const typeQuoteLogLeadSourceId = this.state.selectedLeadSourceId;
        const vendorId = 0;
        const name = this.state.name;
        const monthlyCount = this.state.mailerMonthlyCount;
        const responseRate = this.state.mailerResponseRate;
        const quoteRatio = this.state.mailerQuoteRatio;
        const actualMonthlyCount = this.state.actualMailerMonthlyCount;
        const actualResponseRate = this.state.actualMailerResponseRate;
        const actualQuoteRatio = this.state.actualMailerQuoteRatio;
        const closeRatio = this.state.mailerCloseRatio;
        const monthlyCost = this.state.mailerMonthlyCost;
        const actualMonthlyCost = this.state.actualMailerMonthlyCost;
        const monthDts = this.state.monthDts;
        const premiumPerItem = this.props.premiumPerItem;

        const mailerResponseRateCalc = this.state.mailerMonthlyCount * (this.state.mailerResponseRate / 100);
        const mailerQuoteRatioCalc = mailerResponseRateCalc * (this.state.mailerQuoteRatio / 100);
        const mailerCloseRatioCalc = mailerQuoteRatioCalc * (this.state.mailerCloseRatio / 100);
        const mailerQuotedItems = this.props.itemsPerHousehold * mailerQuoteRatioCalc;
        const mailerPerHousehold = mailerQuotedItems / this.props.itemsPerHousehold;
        const mailerQuotedPolicies = mailerQuotedItems / this.props.itemsPerPolicy;

        const mailerSoldItems = mailerQuotedItems * (this.state.mailerCloseRatio / 100);
        const mailerSoldHousehold = mailerPerHousehold * (this.state.mailerCloseRatio / 100);
        const mailerSoldPolicies = mailerQuotedPolicies * (this.state.mailerCloseRatio / 100);

        const mailerTotalPremium = mailerSoldItems * this.props.premiumPerItem;

        const filterLeadSource = this.props.vendorSummary ? this.props.vendorSummary.filter(v => v.vendor == this.state.selectedLeadSourceId) : [];
        const actualMailerQuotedItems = filterLeadSource.length > 0 ? filterLeadSource[0].quotedItems : 0;
        const actualMailerPerHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].quotedHouseholds : 0;
        const actualMailerQuotedPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].quotedPolicies : 0;

        const actualMailerSoldItems = filterLeadSource.length > 0 ? filterLeadSource[0].writtenItems : 0;
        const actualMailerSoldHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].writtenHouseholds : 0;
        const actualMailerSoldPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].writtenPolicies : 0;

        const actualMailerMonthlyCostCalc = this.state.actualMailerMonthlyCost * 1;
        const actualMailerMonthlyCostWrittenItems = actualMailerMonthlyCostCalc / actualMailerSoldItems;
        const actualMailerMonthlyHousehold = actualMailerMonthlyCostCalc / actualMailerSoldHousehold;
        const actualMailerMonthlyPolicy = actualMailerMonthlyCostCalc / actualMailerSoldPolicies;

        const mailerMonthlyCostCalc = this.state.mailerMonthlyCost * 1;
        const mailerMonthlyCostWrittenItems = mailerMonthlyCostCalc / mailerSoldItems;
        const mailerMonthlyHousehold = mailerMonthlyCostCalc / mailerSoldHousehold;
        const mailerMonthlyPolicy = mailerMonthlyCostCalc / mailerSoldPolicies;

        this.props.createMailer(
            userId, 
            agencyId,
            typeQuoteLogLeadSourceId,
            name, 
            vendorId, 
            monthlyCount, 
            responseRate, 
            quoteRatio, 
            actualMonthlyCount, 
            actualResponseRate, 
            actualQuoteRatio, 
            closeRatio, 
            monthlyCost, 
            actualMonthlyCost, 
            mailerPerHousehold, 
            mailerQuotedPolicies, 
            mailerQuotedItems, 
            mailerSoldHousehold, 
            mailerSoldPolicies, 
            mailerSoldItems, 
            monthDts, 
            mailerTotalPremium,
            premiumPerItem,
            mailerMonthlyCostWrittenItems,
            mailerMonthlyHousehold,
            mailerMonthlyPolicy,
            actualMailerMonthlyCostWrittenItems,
            actualMailerMonthlyHousehold,
            actualMailerMonthlyPolicy);
            
            this.setState({
                selectedLeadSourceId: 0, 
                mailerMonthlyCount: 0,
                mailerResponseRate: 0,
                mailerQuoteRatio: 0,
                mailerCloseRatio: 0,
                mailerPerHousehold: 0,
                mailerResponseRateCalc: 0,
                mailerQuoteRatioCalc: 0,
                mailerCloseRatioCalc: 0,
                mailerTotalPremium: 0,
                mailerMonthlyCost: 0,
                mailerMonthlyCostCalc: 0,
                mailerCostWrittenItems: 0,
                actualMailerMonthlyCount: 0,
                actualMailerResponseRate: 0,
                actualMailerQuoteRatio: 0,
                actualMailerMonthlyCost: 0,
                selectedValues: ''});
    };
    handleRadioChange(e) {
        this.setState({leadSourceFilter: e.target.value});
    }
    render() {
        const mailerResponseRateCalc = this.state.mailerMonthlyCount * (this.state.mailerResponseRate / 100);
        const mailerQuoteRatioCalc = mailerResponseRateCalc * (this.state.mailerQuoteRatio / 100);
        const mailerCloseRatioCalc = mailerQuoteRatioCalc * (this.state.mailerCloseRatio / 100);
        const mailerQuotedItems = this.props.itemsPerHousehold * mailerQuoteRatioCalc;
        const mailerPerHousehold = mailerQuotedItems / this.props.itemsPerHousehold;
        const mailerQuotedPolicies = mailerQuotedItems / this.props.itemsPerPolicy;
        
        const mailerSoldItems = mailerQuotedItems * (this.state.mailerCloseRatio / 100);
        const mailerSoldHousehold = mailerPerHousehold * (this.state.mailerCloseRatio / 100);
        const mailerSoldPolicies = mailerQuotedPolicies * (this.state.mailerCloseRatio / 100);

        const mailerTotalPremium = mailerSoldItems * this.props.premiumPerItem;

        const filterLeadSource = this.props.vendorSummary ? this.props.vendorSummary.filter(v => v.typeQuoteLogLeadSourceId == this.state.selectedLeadSourceId) : [];

        const actualMailerQuotedItems = filterLeadSource.length > 0 ? filterLeadSource[0].quotedItems : 0;
        const actualMailerPerHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].quotedHouseholds : 0;
        const actualMailerQuotedPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].quotedPolicies : 0;

        const actualMailerSoldItems = filterLeadSource.length > 0 ? filterLeadSource[0].writtenItems : 0;
        const actualMailerSoldHousehold = filterLeadSource.length > 0 ? filterLeadSource[0].writtenHouseholds : 0;
        const actualMailerSoldPolicies = filterLeadSource.length > 0 ? filterLeadSource[0].writtenPolicies : 0;
        
        const actualMailerResponseRateCalc = this.state.actualMailerMonthlyCount * (this.state.actualMailerResponseRate / 100);
        const actualMailerQuoteRatioCalc = actualMailerResponseRateCalc * (this.state.actualMailerQuoteRatio / 100);
        const actualMailerCloseRatioCalc = actualMailerSoldHousehold > 0 ? actualMailerSoldHousehold / actualMailerPerHousehold : 0;

        const actualMailerTotalPremium = filterLeadSource.length > 0 ? filterLeadSource[0].writtenPremium : 0

        const actualPremiumPerItem = actualMailerTotalPremium > 0 ? actualMailerTotalPremium / actualMailerSoldItems : 0;

        const actualMailerMonthlyCostCalc = this.state.actualMailerMonthlyCost * 1;
        const actualMailerMonthlyCostWrittenItems = actualMailerMonthlyCostCalc / actualMailerSoldItems;
        const actualMailerMonthlyHousehold = actualMailerMonthlyCostCalc / actualMailerSoldHousehold;
        const actualMailerMonthlyPolicy = actualMailerMonthlyCostCalc / actualMailerSoldPolicies;

        const mailerMonthlyCostCalc = this.state.mailerMonthlyCost * 1;
        const mailerMonthlyCostWrittenItems = mailerMonthlyCostCalc / mailerSoldItems;
        const mailerMonthlyHousehold = mailerMonthlyCostCalc / mailerSoldHousehold;
        const mailerMonthlyPolicy = mailerMonthlyCostCalc / mailerSoldPolicies;

        const mailerCostPerQuotedHousehold = mailerMonthlyCostCalc / mailerPerHousehold;
        const actualMailerCostPerQuotedHousehold = actualMailerMonthlyCostCalc / actualMailerPerHousehold;

        const onChange = (value) => {
            var mailerLst = this.props.mailer.filter(t => t.typeQuoteLogLeadSourceId == value);
            if(mailerLst.length > 0){
                var mailer = mailerLst[0];
                this.setState({
                    id: mailer.id,
                    name: mailer.name,
                    selectedValues: value,
                    selectedLeadSourceId: mailer.typeQuoteLogLeadSourceId,
                    vendor: String(mailer.vendorId),
                    mailerMonthlyCount: mailer.monthlyCount, 
                    mailerResponseRate: mailer.responseRate, 
                    mailerQuoteRatio: mailer.quoteRatio,
                    actualMailerMonthlyCount: mailer.actualMonthlyCount, 
                    actualMailerResponseRate: mailer.actualResponseRate, 
                    actualMailerQuoteRatio: mailer.actualQuoteRatio, 
                    mailerCloseRatio: mailer.closeRatio,
                    mailerMonthlyCost: mailer.monthlyCost,
                    actualMailerMonthlyCost: mailer.actualMonthlyCost,
                    monthDts: mailer.monthDts
                });
            }
            else{
                console.log(value);
                this.setState({
                    id: 0,
                    name: '',
                    selectedLeadSourceId: value,
                    selectedValues: value,
                    mailerMonthlyCount: 0,
                    mailerResponseRate: 0,
                    mailerQuoteRatio: 0,
                    mailerCloseRatio: 0,
                    mailerPerHousehold: 0,
                    mailerResponseRateCalc: 0,
                    mailerQuoteRatioCalc: 0,
                    mailerCloseRatioCalc: 0,
                    mailerTotalPremium: 0,
                    mailerMonthlyCost: 0,
                    mailerMonthlyCostCalc: 0,
                    mailerCostWrittenItems: 0,
                    actualMailerMonthlyCount: 0,
                    actualMailerResponseRate: 0,
                    actualMailerQuoteRatio: 0,
                    actualMailerMonthlyCost: 0
                });
            }
        };
        const style = {
            disabledInputText: {
                width: '100%', 
                marginLeft: 10, 
                color: '#000'
            },
        };
        return(
            <div>
                <Row>
                    <Col span={10}>
                        <List header={<Title level={4}>LEAD SOURCE</Title>} itemLayout="horizontal" dataSource={this.props.mailer} renderItem={item => (
                            <List.Item actions={[<a key="list-loadmore-edit" onClick={() => this.handleListClick(item)}>edit</a>]}>
                                <List.Item.Meta title={item.name} />
                            </List.Item>
                        )} /> 
                    </Col>
                    <Col span={14}>
                        <Row style={{paddingBottom: 15}}>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Month
                                </div>
                            </Col>
                            <Col md={16}>
                                <DatePicker style={{ width: '100%', marginLeft: 10 }} onChange={this.onMonthChange} picker="month" format="MMM YYYY" value={moment(this.state.monthDts, "MMM YYYY")}   />
                            </Col>
                        </Row>
                        <Row style={{paddingBottom: 15}}>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Lead Source
                                </div>
                            </Col>
                            <Col md={16}>
                                <Radio.Group style={{marginLeft: 10}} size="small" defaultValue="Active" buttonStyle="solid" onChange={this.handleRadioChange} value={this.state.leadSourceFilter}>
                                    <Radio.Button value="Active">Active</Radio.Button>
                                    <Radio.Button value="Inactive">Inactive</Radio.Button>
                                </Radio.Group>
                                <Select
                                    virtual={false}
                                    style={{ width: '100%', marginLeft: 10 }}
                                    dropdownStyle={{ minWidth: "20%" }}
                                    placeholder="Please select"
                                    name="leadSource"
                                    value={this.state.selectedValues}
                                    onChange={onChange}>
                                    {
                                        this.state.leadSourceFilter == "Active" ?
                                        this.props.leadSource.filter(ls => ls.inactive == false).map(ls => 
                                            <Option key={ls.id} value={ls.id}>{ls.name}</Option>
                                        )
                                        :
                                        this.props.leadSource.filter(ls => ls.inactive == true).map(ls => 
                                            <Option key={ls.id} value={ls.id}>{ls.name}</Option>
                                        )
                                    }
                                </Select>
                                {/* <Cascader
                                    style={{ width: '100%', marginLeft: 10 }}
                                    options={this.props.typeVendorTypesLeadSources.filter(c => c.children.length > 0)}
                                    onChange={onChange}
                                    maxTagCount="responsive"
                                    placeholder="Select Lead Source"
                                    value={this.state.selectedValues}
                                /> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                                          
                            </Col>
                            <Col md={8} style={{textAlign: 'center', fontWeight: 'bold'}}>
                                FORECASTED
                            </Col>
                            <Col md={8} style={{textAlign: 'center', fontWeight: 'bold'}}>
                                ACTUALS
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Monthly Mailer Piece Count
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={0} max={999999} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.mailerMonthlyCount} onChange={this.onMailerMonthlyCountChange} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={0} max={999999} step={1} style={{ width: '100%', marginLeft: 10 }} value={this.state.actualMailerMonthlyCount} onChange={this.onActualMailerMonthlyCountChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Response Rate
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={.1} max={100} step={1} style={{ width: '100%',  marginLeft: 10 }} value={this.state.mailerResponseRate} onChange={this.onMailerResponseRateChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={.1} max={100} step={1} style={{ width: '100%',  marginLeft: 10 }} value={this.state.actualMailerResponseRate} onChange={this.onActualMailerResponseRateChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Quote Ratio
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={0} max={100} step={1} style={{ width: '100%',  marginLeft: 10 }} value={this.state.mailerQuoteRatio} onChange={this.onMailerQuoteRatioChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={0} max={100} step={1} style={{ width: '100%',  marginLeft: 10 }} value={this.state.actualMailerQuoteRatio} onChange={this.onActualMailerQuoteRatioChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Close Ratio
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={0} max={100} step={1} style={{ width: '100%',  marginLeft: 10 }} value={this.state.mailerCloseRatio} onChange={this.onMailerCloseRatioChange} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={0} max={100} step={1} style={{ width: '100%',  marginLeft: 10 }} value={new Intl.NumberFormat("en-US", {
                                    style: "percent",
                                    maximumFractionDigits: 2
                                    }).format(actualMailerCloseRatioCalc)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Premium Per Item
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber disabled style={style.disabledInputText} value={this.props.premiumPerItem} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')}  />
                            </Col>
                            <Col md={8}>
                                <InputNumber disabled style={style.disabledInputText} value={new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualPremiumPerItem)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Quoted Households
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={style.disabledInputText} value={mailerPerHousehold.toFixed(2)} name="quotedHouseholds" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={style.disabledInputText} value={actualMailerPerHousehold} name="quotedHouseholds" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Quoted Polices
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={style.disabledInputText} value={mailerQuotedPolicies.toFixed(2)} name="quotedPolicies" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={style.disabledInputText} value={actualMailerQuotedPolicies} name="quotedPolicies" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Quoted Items
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={style.disabledInputText} value={mailerQuotedItems.toFixed(2)} name="quotedItems" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={style.disabledInputText} value={actualMailerQuotedItems} name="quotedItems" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Sold Households
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={style.disabledInputText} value={mailerSoldHousehold.toFixed(2)} name="soldHouseholds" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={style.disabledInputText} value={actualMailerSoldHousehold} name="soldHouseholds" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Sold Polices
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={style.disabledInputText} value={mailerSoldPolicies.toFixed(2)} name="soldPolicies" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={style.disabledInputText} value={actualMailerSoldPolicies} name="soldPolicies" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <div style={{position: "absolute", right: 0}}>
                                    Sold Items
                                </div>
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <Input disabled style={style.disabledInputText} value={mailerSoldItems.toFixed(2)} name="soldItems" onChange={e => this.handleChange(e)}  />
                            </Col>
                            <Col md={8}>
                                <Input disabled style={style.disabledInputText} value={actualMailerSoldItems} name="soldItems" onChange={e => this.handleChange(e)}  />
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                TOTAL WRITTEN PREMIUM
                            </Col>
                            <Col md={8}>
                                <Text style={{marginLeft: 10}}> {new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(mailerTotalPremium)}</Text>
                            </Col>
                            <Col md={8}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualMailerTotalPremium)}</Text>
                            </Col>
                        </Row>
                        <Row md={24} style={{backgroundColor: "#F5D5CB", fontWeight: 'bold'}}>
                            MAILER ACQUISITION COST
                        </Row>
                        <Row>
                            <Col md={8}>
                                Mailer Monthly Cost
                            </Col>
                            <Col md={8} style={{paddingRight: 5}}>
                                <InputNumber min={.1} max={999999} step={1} style={{ width: '100%',  marginLeft: 10 }} value={this.state.mailerMonthlyCost} onChange={this.onMailerMonthlyCostChange} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </Col>
                            <Col md={8}>
                                <InputNumber min={.1} max={999999} step={1} style={{ width: '100%',  marginLeft: 10 }} value={this.state.actualMailerMonthlyCost} onChange={this.onActualMailerMonthlyCostChange} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')} />
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                Mailer Cost Per Quoted Household
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(mailerCostPerQuotedHousehold)}</Text>
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualMailerCostPerQuotedHousehold)}</Text>
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                Mailer Item Acquistion Cost 
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(mailerMonthlyCostWrittenItems)}</Text>
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualMailerMonthlyCostWrittenItems)}</Text>
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                Mailer Policy Acquistion Cost 
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(mailerMonthlyPolicy)}</Text>
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualMailerMonthlyPolicy)}</Text>
                            </Col>
                        </Row>
                        <Row style={{fontWeight: "bold"}}>
                            <Col md={8}>
                                Mailer Household Acquistion Cost 
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}> {new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(mailerMonthlyHousehold)}</Text>
                            </Col>
                            <Col md={8} style={{color: "red"}}>
                                <Text style={{marginLeft: 10}}>{new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(actualMailerMonthlyHousehold)}</Text>
                            </Col>
                        </Row>
                        <Row style={{paddingBottom: 10}}>
                            <Col span={24} style={{padding: 16}}>
                                <Button shape="round" type="primary" onClick={this.handleSave} style={{ position: "absolute", right: 0, fontWeight: 'bold', boxShadow: '5px 5px 5px #ccc' }}>
                                    <SaveOutlined /> SAVE
                                </Button>
                            </Col>                           
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Mailer);