import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Collapse, Row, Col, Card, Slider, InputNumber, Typography, Button, Select, List, Progress } from 'antd'
import moment from 'moment';
import PremiumAverageAgent from './PremiumAverageAgent';
const { Title } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

class SalesAgentReport extends Component {
    constructor(props) {
      super(props);
        this.state = {
        
        };
        
    }
    componentDidMount(){
        const userId = localStorage.getItem("userId");
        const agencyId = localStorage.getItem("agencyId");
        this.props.getAgent(agencyId);
        this.props.getMarketingAgentUsers(agencyId);
    }
    
    render() {

        return(
            <div>
                <Row>
                    <Col xs={24} sm={24} lg={24} style={{paddingRight: 10}}>
                        {this.props.marketingAgentUsers.map(agent => 
                        <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", marginBottom: 5 }}>
                            <Row>
                                <Col span={12}>
                                    <Row>
                                        <Col>
                                            <Title level={4}>{agent.agentName}</Title>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            Premium Goal <br /> <strong>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(agent.monthlyPremium)}</strong>
                                        </Col>
                                        <Col span={12}>
                                            Trending <br />  <strong style={{color: agent.monthlyPremium > agent.premiumAverage.totalPremium ? 'red' : 'green'}}>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(agent.premiumAverage.totalPremium)}</strong>
                                        </Col>
                                    </Row>
                                    <Row style={{paddingTop: 10}}>
                                        <Col span={12}>
                                            Written Today <br /> <strong>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(agent.premiumAverage.writtenPremiumToday)}</strong>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12} style={{position: 'absolute', right: 5}}>
                                    {
                                        agent.premiumAverage.totalPremium > 0 &&
                                            <Progress type="circle" percent={((agent.premiumAverage.totalPremium / agent.monthlyPremium) * 100).toFixed(0)} />
                                    }
                                </Col>
                            </Row>
                        </Card>
                        )} 
                    </Col>
                </Row>
                
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(SalesAgentReport);