import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Select, Table, Row, DatePicker, Col, Space, Button, Radio, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
const { RangePicker } = DatePicker;
const { Search } = Input;

class Onboarding extends Component {
    constructor(props) {
        super(props);
          this.state = {
            dateFrom: moment().subtract(7, 'days').format("MM/DD/YYYY"),
            dateTo: moment().format("MM/DD/YYYY"),
            openSearch: false,
            searchFilter: "date",
            dateFilter: "thisweek",
            searchText: ""
        }
        this.handleTableClick = this.handleTableClick.bind(this);
        this.handleOpenSearch = this.handleOpenSearch.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.handleDateRadioChange = this.handleDateRadioChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
    }
    componentDidMount() {
        const agencyId = localStorage.getItem('agencyId');
        this.props.getOnboarding(agencyId, this.state.dateFrom, this.state.dateTo)
    }
    componentDidUpdate(prevProps) {
        if(this.props.onboardingRecord != prevProps.onboardingRecord){
            const agencyId = localStorage.getItem('agencyId');
            const searchFilter = this.state.searchFilter;
            const searchText = this.state.searchText;
            if(searchFilter == "name"){
                this.props.searchOnboarding(agencyId, searchFilter, searchText);
            }else{
                this.props.getOnboarding(agencyId, this.state.dateFrom, this.state.dateTo);
            }
            
        }
    }
    handleTableClick(trailingDocuments, record){
        //alert(trailingDocuments);
        this.props.updateOnboarding(record.quoteLogSoldId, record.quoteLogId, record.bindDate, record.effectiveDate, record.auto, record.autoPremium, record.dwellingSoldCheckboxes, record.specialtySoldCheckboxes, trailingDocuments);
    }
    handleOpenSearch(){
        const openSearch = this.state.openSearch;
        this.setState({openSearch: !openSearch});
    }
    handleRadioChange(e){
        this.setState({searchFilter: e.target.value});
    }
    handleDateRadioChange(e){
        this.setState({dateFilter: e.target.value});
        var dateFrom = '';
        var dateTo = ''; 
        const agencyId = localStorage.getItem('agencyId');
        if(e.target.value == 'today'){
            dateFrom = moment().format('MM/DD/YYYY');
            dateTo = moment().format('MM/DD/YYYY'); 
        }
        if(e.target.value == 'thisWeek'){
            dateFrom = moment().startOf('week').format('MM/DD/YYYY');
            dateTo = moment().endOf('week').format('MM/DD/YYYY'); 
        }
        else if(e.target.value == 'lastWeek'){
            dateFrom = moment().subtract(1, 'week').startOf('week').format('MM/DD/YYYY');
            dateTo = moment().subtract(1, 'week').endOf('week').format('MM/DD/YYYY');
        }
        else if(e.target.value == 'thisMonth'){
            dateFrom = moment().startOf('month').format('MM/DD/YYYY');
            dateTo = moment().endOf('month').format('MM/DD/YYYY');
        }
        else if(e.target.value == 'lastMonth'){
            dateFrom = moment().subtract(1, 'month').startOf('month').format('MM/DD/YYYY');
            dateTo = moment().subtract(1, 'month').endOf('month').format('MM/DD/YYYY');
        }
        else if(e.target.value == 'all'){
            dateFrom = '';
            dateTo = '';
        }
        this.props.getOnboarding(agencyId, dateFrom, dateTo);
        
        this.setState({
            dateFrom: dateFrom,
            dateTo: dateTo 
        });
    }
    handleSearch = (searchText) => {
        const agencyId = localStorage.getItem('agencyId');
        const searchFilter = this.state.searchFilter;
        this.setState({searchText: searchText});
        this.props.searchOnboarding(agencyId, searchFilter, searchText);
    }
    handleDateRangeChange(dates, dateStrings) {
        const dateFrom = dateStrings[0];
        const dateTo = dateStrings[1];
        const agencyId = localStorage.getItem('agencyId');
        if(dateFrom != ''){
            this.props.getOnboarding(agencyId, dateFrom, dateTo);
        }
        this.setState({
            dateFrom: dateFrom,
            dateTo: dateTo 
        });
    }
    render(){
        const dateFormat = 'MM/DD/YYYY';
        const columns = [
            {
                title: 'Bind Date',
                dataIndex: 'bindDate',
                key: 'bindDate',
                render: date => moment(date).format(dateFormat),
            },
            {
                title: 'Effective Date',
                dataIndex: 'effectiveDate',
                key: 'effectiveDate',
                render: date => moment(date).format(dateFormat),
            },
            {
                title: 'Agent',
                dataIndex: 'agent',
                key: 'agent',
                //...this.getColumnSearchProps('agent'),
            },
            {
                title: 'First Name',
                dataIndex: 'firstName',
                key: 'firstName',
                //...this.getColumnSearchProps('firstName'),
            },
            {
                title: 'Last Name',
                dataIndex: 'lastName',
                key: 'lastName',
                //...this.getColumnSearchProps('lastName'),
            },
            {
                title: 'Trailing Documents',
                dataIndex: 'trailingDocuments',
                key: 'action',
                render: (text, record) => <Select size="sm"
                style={{ width: '100%' }}
                placeholder="Please select"
                name="trailingDocuments"
                value={text}
                onChange={(e) => this.handleTableClick(e, record)}>
                    <option key=""></option>
                    <option key="Outstanding">Outstanding</option>
                    <option key="SignedComplete">Signed Complete</option>
                    <option key="MailedToClient">Mailed To Client For Sig</option>
            </Select>,
            },
          ];
        return(
            <div>
                <Row>
                    <Col span={3} style={{paddingTop: 20}}>
                        <Button type="round" onClick={this.handleOpenSearch}>
                            <SearchOutlined />Sort Onboarding
                        </Button>
                    </Col>
                    {
                        this.state.openSearch == true &&
                            <Col span={24}>
                                <Row style={{paddingTop: 20}}>
                                    <Col span={20}>
                                        <Row>
                                            <Col span={24}>
                                                <Space direction="vertical">
                                                    <Radio.Group defaultValue="a" buttonStyle="solid" onChange={this.handleRadioChange} value={this.state.searchFilter}>
                                                        <Radio.Button value="date">Date Range</Radio.Button>
                                                        <Radio.Button value="name">Prospect Name</Radio.Button>
                                                    </Radio.Group>
                                                    { this.state.searchFilter == 'date' ?
                                                    <Space>
                                                        <Radio.Group defaultValue="a" buttonStyle="solid" onChange={this.handleDateRadioChange} value={this.state.dateFilter}>
                                                            <Radio.Button value="today">Today</Radio.Button>
                                                            <Radio.Button value="thisWeek">This Week</Radio.Button>
                                                            <Radio.Button value="lastWeek">Last Week</Radio.Button>
                                                            <Radio.Button value="thisMonth">This Month</Radio.Button>
                                                            <Radio.Button value="lastMonth">Last Month</Radio.Button>
                                                            <Radio.Button value="all">All Time</Radio.Button>
                                                        </Radio.Group>
                                                        <RangePicker format={'MM/DD/YYYY'} allowEmpty={true}
                                                            ranges={{
                                                                Today: [moment(), moment()],
                                                                'This Week': [moment().startOf('week'), moment().endOf('week')],
                                                                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                                                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                                            }}
                                                            defaultValue={[moment().startOf('week'), moment().endOf('week')]}
                                                            onChange={this.handleDateRangeChange}
                                                        />
                                                    </Space>
                                                    :
                                                    <Search placeholder="Search" onSearch={value => this.handleSearch(value)} enterButton />
                                                    }
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                    }
                </Row>
                <Row>
                    <Col span={24}>
                        <Table dataSource={this.props.onboarding} columns={columns} pagination={{ pageSize: 50 }} />
                    </Col>
                </Row>
            </div>
        )
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Onboarding);